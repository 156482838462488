import React, { useState, useEffect, useRef } from "react";
import unsplash from "../../api/unsplash.js";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Grid,
  Dialog,
  TextField,
  StepButton,
  Step,
  Stepper,
  CardActionArea,
  CardMedia,
  Tab,
  Tabs,
  InputAdornment,
  Divider,
  Autocomplete,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import {
  Check,
  Settings,
  GroupAdd,
  VideoLabel,
  Upload,
  ArrowForward,
  ArrowBack,
  Euro,
  Mail,
} from "@mui/icons-material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

import { ToastContainer, toast } from "react-toastify";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment as DateAdapter } from "@mui/x-date-pickers/AdapterMoment";
import GlobalSnackbar from "../Snackbar.js";
import ReactPlayer from "react-player/lazy";
import moment from "moment";
import ClienteParita from "./ClienteParita.js";

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <Settings />,
    2: <GroupAdd />,
    3: <VideoLabel />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = [
  "Video Introduzione Iter",
  "Upload Profilo",
  "Video Prima Survey",
  "Prima Survey",
  "Video Seconda Survey",
  "Seconda Survey",
];

const HomeParita = () => {
  const [globalStep, setGlobalStep] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [savedProfilo, setSavedProfilo] = useState(false);
  const [scrollToButton, setScrollToButton] = useState(false);
  const [handleLocked, setHandleLocked] = useState(false);
  const [confermato, setConfermato] = useState(false);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleAddStep = async (step) => {
    let conf = step === 1 || step === 3 || step === 5;
    setConfermato(conf);
    try {
      await unsplash.post(
        "portaleParita",
        {
          operation: "updateStep",
          step: step,
          confermato: conf ? 1 : 0,
          id: sessionStorage.getItem("Cliente"),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      sessionStorage.setItem("Step", step - 1);
      toast.success(`Step n° ${step} completato!`);
      setGlobalStep(step);
      if (!conf) {
        sendEmail(step, false);
      }
    } catch (e) {
      toast.error(`Errore durante il completamento!`);
    }
  };

  const handleComplete = () => {
    if (globalStep === 1 && activeStep === 1 && !savedProfilo) {
      setScrollToButton(true);
      toast.error(`Necessario salvare le informazioni!`);
    } else if (globalStep === 3 && activeStep === 3 && !savedProfilo) {
      setScrollToButton(true);
      toast.error(`Necessario salvare le informazioni!`);
    } else {
      setScrollToButton(false);
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      if (globalStep <= activeStep || globalStep === 0) {
        handleAddStep(activeStep + 1);
      }
      //SE SONO STATI COMPLETATI SOLO I VIDEO PASSO AL PROSSIMO STEP
      if (activeStep === 0 || activeStep === 2 || activeStep === 4) {
        handleNext();
      }
    }
  };

  const sendEmail = async (step, message) => {
    try {
      await unsplash.post(
        "portaleParita",
        {
          operation: "sollecitoEmail",
          step: step,
          cliente: sessionStorage.getItem("RagioneSociale"),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      if (message) {
        toast.success(`Sollecito inviato!`);
      }
    } catch (e) {
      toast.error(`Errore durante l'invio!`);
    }
  };

  const locked = () => {
    return (
      <div>
        <div className="absolute inset-0 z-50 w-full h-full bg-white/80 backdrop-blur-0">
          <div className="absolute left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] flex flex-col gap-5">
            <div className="container">
              <span
                onMouseOver={() => setHandleLocked(true)}
                onMouseOut={() => setHandleLocked(false)}
                onClick={() => {
                  setActiveStep(globalStep);
                  setHandleLocked(false);
                }}
                className={`lock ${handleLocked ? "unlocked" : ""}`}
              ></span>
            </div>
            <div className="text-xl font-bold text-center">
              NON DISPONIBILE
              <br />
              NECESSARIO COMPLETARE LO STEP:
              <div
                className="text-primary-600 cursor-pointer underline"
                onClick={() => setActiveStep(globalStep)}
              >
                {globalStep + 1}
                {" - "}
                {steps[globalStep === 0 ? 0 : globalStep]}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const lockedConferma = () => {
    return (
      <div>
        <div className="absolute inset-0 z-50 w-full h-full bg-white/80 backdrop-blur-0">
          <div className="absolute left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] flex flex-col gap-5">
            <div className="container">
              <span
                onMouseOver={() => setHandleLocked(true)}
                onMouseOut={() => setHandleLocked(false)}
                onClick={() => {
                  setActiveStep(globalStep);
                  setHandleLocked(false);
                }}
                className={`lock ${handleLocked ? "unlocked" : ""}`}
              ></span>
            </div>
            <div className="text-xl font-bold text-center">
              NECESSARIA AUTORIZZAZIONE RESPONSABILE MILLER
            </div>
            <div className="flex justify-center">
              <Button
                startIcon={<Mail />}
                onClick={() => sendEmail(globalStep, true)}
                sx={{ color: "white" }}
                variant="outlined"
                className="ButtonMiller"
              >
                Sollecita controllo
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const mainContent = (step) => {
    let content;
    switch (step) {
      case 0:
        content = (
          <>
            <ReactPlayer
              url="https://www.youtube.com/watch?v=Tu8qvD2PlVQ&pp=ygUMbWlsbGVyIGdyb3Vw"
              width={"100%"}
              //height={"300px"}
              style={{ borderRadius: "10px" }}
            />
          </>
        );

        break;
      case 1:
        content = (
          <div className="relative h-full">
            {(globalStep < step || globalStep === 0) && locked(globalStep)}
            <ClienteParita
            home={true}
              savedProfilo={() => setSavedProfilo(true)}
              handleScroll={scrollToButton}
            />
          </div>
        );

        break;
      case 2:
        content = (
          <>
            {(globalStep < step || globalStep === 0) && locked(globalStep)}
            {(!confermato && globalStep === 2) && lockedConferma()}
            <ReactPlayer
              url="https://www.youtube.com/watch?v=Tu8qvD2PlVQ&pp=ygUMbWlsbGVyIGdyb3Vw"
              width={"100%"}
              //height={"300px"}
              style={{ borderRadius: "10px" }}
            />
          </>
        );

        break;
      case 3:
        content = (
          <>
            {(globalStep < step || globalStep === 0) && locked(globalStep)}
            <PrimaSurvey
            home={true}
              savedProfilo={() => setSavedProfilo(true)}
              handleScroll={scrollToButton}
            />
          </>
        );

        break;
      case 4:
        content = (
          <>
            {(globalStep < step || globalStep === 0) && locked(globalStep)}
            {!confermato && lockedConferma()}
            <ReactPlayer
              url="https://www.youtube.com/watch?v=Tu8qvD2PlVQ&pp=ygUMbWlsbGVyIGdyb3Vw"
              width={"100%"}
              //height={"300px"}
              style={{ borderRadius: "10px" }}
            />
          </>
        );

        break;
      case 5:
        content = (
          <>
            {(globalStep < step || globalStep === 0) && locked(globalStep)}
            <SecondaSurvey />
          </>
        );

        break;
      default:
        break;
    }
    return content;
  };

  useEffect(() => {
    (async () => {
      let stepGetted;
      try {
        let response = await unsplash.post(
          "portaleParita",
          {
            operation: "getCliente",
            id: sessionStorage.getItem("Cliente"),
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        stepGetted = response.data[0]?.Step;
        setConfermato(response.data[0]?.Confermato === 1);
        sessionStorage.setItem("Step", stepGetted);
      } catch (e) {
        toast.error(`Errore durante il caricamento!`);
      }
      let comp = { ...completed };
      if (stepGetted !== 0) {
        let n = 0;

        while (n < stepGetted) {
          comp = { ...comp, 0: true, [n]: true };
          n++;
        }
      } else {
        comp = {};
      }
      setActiveStep(stepGetted);
      setCompleted({ ...comp });
      setGlobalStep(stepGetted);
    })();
  }, []);

  return (
    <>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
        theme="colored"
      />
      {/* <div className="flex flex-wrap">
        <div className=" bg-primary-600 dark:bg-white p-4 sm:p-7 rounded-md h-fit w-full">
          <div className="relative">
            <div className="absolute inset-y-0 right-0 top-0 pointer-events-none z-10 hidden xl:block">
              <img src={logoChiaro} alt="" className="h-20 w-20" />
            </div>
            <h1 className="text-2xl md:text-3xl text-white dark:text-primary-600 font-bold mb-1">
              Benvenuto, {sessionStorage.getItem("Username")}.
            </h1>
            <p className="text-white dark:text-primary-600 w-5/6 ">
              Visualizza e gestisci i tuoi livelli di sicurezza direttamente
              dalla dashboard dedicata. Oltre a monitorare l’attività ti
              suggeriamo tutte le azioni necessarie per migliorare la sicurezza
              della tua attività.
            </p>
          </div>
        </div>
      </div> */}
      <div className="px-2">
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          className="w-full flex justify-start md:justify-center overflow-x-auto overflow-y-hidden px-2"
        >
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <div>
          {allStepsCompleted() ? (
            <React.Fragment>
              <div className="h-[60vh] mt-10">
                {confermato ? (
                  <div className="text-3xl text-center">
                    HAI COMPLETATO TUTTI GLI STEP!
                  </div>
                ) : (
                  <div className="text-3xl text-center">
                    ATTESA VALIDAZIONE DATI...
                  </div>
                )}
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className=" flex flex-col">
                <div
                  className="relative lg:h-[calc(100vh_-_263px)] lg:max-h-[calc(100vh_-_263px)] h-[calc(100vh_-_363px)] max-h-[calc(100vh_-_363px)] mt-5 overflow-y-auto"
                  id="step-home"
                >
                  {mainContent(activeStep)}
                </div>
                <div className="flex flex-row pt-2 gap-2">
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    /* sx={{
                      backgroundColor: activeStep === 0 ? "white" : "#11526f",
                    }} */
                    variant={"outlined"}
                    //className={activeStep !== 0 && "ButtonMiller"}
                    startIcon={<ArrowBack />}
                  >
                    Indietro
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />

                  {activeStep !== steps.length &&
                    (completed[activeStep] ? (
                      <div className="text-sm flex items-center">
                        Step {activeStep + 1} già completato
                      </div>
                    ) : (globalStep >= activeStep && confermato) ||
                      globalStep === 0 ? (
                      <Button
                        variant="outlined"
                        //className="ButtonMiller"
                        startIcon={<Check />}
                        onClick={handleComplete}
                        sx={{ mr: 1 }}
                      >
                        {completedSteps() === totalSteps() - 1
                          ? "Finito"
                          : "Completa Step"}
                      </Button>
                    ) : (
                      <></>
                    ))}
                  <Button
                    onClick={handleNext}
                    variant="outlined"
                    //className="ButtonMiller"
                    startIcon={<ArrowForward />}
                  >
                    Avanti
                  </Button>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </>
  );
};

export default HomeParita;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const PrimaSurvey = ({ savedProfilo, handleScroll }) => {
  const [valueSur, setValueSur] = useState(0);
  const [primaSurvey, setPrimaSurvey] = useState([]);

  const buttonSave = useRef(null);

  const handleChangeTabSur = (event, newValue) => {
    setValueSur(newValue);
  };

  function splitCategory(domande) {
    // Create a final array to store the grouped objects
    let finalArray = [];

    // Create a map to hold arrays of objects grouped by IDParitaSurvey
    let surveyMap = {};

    // Iterate over each object in the initial array
    domande.forEach((item) => {
      // Get the IDParitaSurvey of the current object
      let surveyId = item["Nome"];

      // If the surveyId is not yet a key in the surveyMap, add it with an empty array as the value
      if (!surveyMap[surveyId]) {
        surveyMap[surveyId] = [];
      }

      // Push the current object into the array corresponding to the surveyId
      surveyMap[surveyId].push(item);
    });

    //RIORDINO I TAB IN BASE AGLI ID DELLA SUVEY
    const sortedData = domande
      .map((category) => {
        return {
          IDParitaSurvey: category?.IDParitaSurvey,
          Nome: category?.Nome,
        };
      })
      .sort((a, b) => a.IDParitaSurvey - b.IDParitaSurvey)
      .map((item) => item.Nome);

    // Define the desired order of categories based on the IDParitaSurvey
    const categoryOrder = [...new Set(sortedData)];

    // Transform the surveyMap into the desired finalArray format and order
    categoryOrder.forEach((category) => {
      if (surveyMap[category]) {
        let obj = {};
        obj[category] = surveyMap[category];
        finalArray.push(obj);
      }
    });

    return finalArray;
  }

  function unsplitCategory(groupedArray) {
    // Create an array to hold the final flattened objects
    let flattenedArray = [];

    // Iterate over each grouped object in the finalArray
    groupedArray.forEach((group) => {
      // Get the key (which is the IDParitaSurvey) and the array of items
      let surveyId = Object.keys(group)[0];
      let items = group[surveyId];

      // Iterate over the items and push them to the flattenedArray
      items.forEach((item) => {
        //carico solo gli oggetti con le risposte
        if (item.Risposta) {
          flattenedArray.push({
            ...item,
            IDCliente: sessionStorage.getItem("Cliente"),
          });
        }
      });
    });

    return flattenedArray;
  }

  const handleChangePrimaSur = (
    e,
    areaIndex,
    domandaIndex,
    nota,
    secondaPercentuale
  ) => {
    const updatedSurveys = [...primaSurvey];
    const areaKey = Object.keys(updatedSurveys[areaIndex])[0];
    if (nota) {
      updatedSurveys[areaIndex][areaKey][domandaIndex].NoteRisposta =
        e.target.value;
    } else {
      if (secondaPercentuale) {
        updatedSurveys[areaIndex][areaKey][domandaIndex].SecondaPercentuale =
          e.target.value;
      } else {
        updatedSurveys[areaIndex][areaKey][domandaIndex].Risposta =
          e.target.value;
      }
    }
    setPrimaSurvey(updatedSurveys);
  };

  const reloadSurveys = async () => {
    let response = await unsplash.post(
      "portaleParita",
      {
        operation: "getDomandeSurvey",
        grandezza: sessionStorage.getItem("Grandezza"),
        cliente: sessionStorage.getItem("Cliente"),
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );

    const firstSurSplitted = splitCategory(
      response.data.filter((el) => el.NSurvey === 1)
    );
    setPrimaSurvey(firstSurSplitted);
  };

  const handleSave = async () => {
    const risposte = unsplitCategory(primaSurvey);
    try {
      const response = await unsplash.post(
        "portaleParita",
        {
          operation: "postRisposteSurvey",
          risposte: risposte,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      toast.success(`Risposte caricate con successo: ${response.data}!`
      )
      savedProfilo();
    } catch {
      toast.error("Errore durante l'aggiornamento")
    }
    //ricarico le domande con il format predefinito
    reloadSurveys();
  };

  const handleAncor = () => {
    setValueSur(primaSurvey.length - 1);
  };

  useEffect(() => {
    (() => {
      if (handleScroll) {
        handleAncor();
      }
    })();
  }, [handleScroll]);

  useEffect(() => {
    (async () => {
      let response = await unsplash.post(
        "portaleParita",
        {
          operation: "getDomandeSurvey",
          grandezza: sessionStorage.getItem("Grandezza"),
          cliente: sessionStorage.getItem("Cliente"),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      const modified = splitCategory(
        response.data.filter((el) => el.NSurvey === 1)
      );
      setPrimaSurvey(modified);
    })();
  }, []);

  return (
    <>
      <div className="w-full">
        <Tabs
          value={valueSur}
          textColor="inherit"
          variant="scrollable"
          onChange={handleChangeTabSur}
          aria-label="basic tabs example"
        >
          {primaSurvey?.map((el) => (
            <Tab label={Object.getOwnPropertyNames(el)} />
          ))}
        </Tabs>
        {primaSurvey?.map((el, areaIndex) => (
          <TabPanel value={valueSur} index={areaIndex} key={areaIndex}>
            {el[Object.getOwnPropertyNames(el)]?.map(
              (domanda, domandaIndex) => (
                <div className="w-full flex flex-col py-2" key={domandaIndex}>
                  <div className="w-full font-semibold">
                    {domandaIndex + 1}) {domanda?.Domanda}
                  </div>
                  {domanda?.Nota && (
                    <div className="w-full text-xs italic">
                      {domanda?.Nota.split("\n").map((line, index) => (
                        <p key={index}>{line}</p>
                      ))}
                    </div>
                  )}
                  {domanda?.TipologiaInput === 0 && (
                    <div className="w-full mb-2">
                      <FormControl>
                        <RadioGroup
                          row
                          value={domanda?.Risposta}
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          onChange={(e) =>
                            handleChangePrimaSur(e, areaIndex, domandaIndex)
                          }
                        >
                          <FormControlLabel
                            value={"1"}
                            control={<Radio />}
                            label="Si"
                          />
                          <FormControlLabel
                            value={"0"}
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  )}
                  {domanda?.TipologiaInput === 1 && (
                    <div className="w-1/2 md:w-1/4 mt-3 mb-2">
                      <TextField
                        value={domanda?.Risposta}
                        id={domanda.ID}
                        label="Percentuale"
                        onChange={(e) =>
                          handleChangePrimaSur(e, areaIndex, domandaIndex)
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                          inputProps: { min: 0, max: 100 },
                        }}
                        type="number"
                        fullWidth
                      />
                    </div>
                  )}
                  {domanda?.TipologiaInput === 2 && (
                    <div className="w-full mt-3">
                      <TextField
                        multiline
                        rows={2}
                        value={domanda?.Risposta}
                        id={domanda.ID}
                        label="Risposta"
                        onChange={(e) =>
                          handleChangePrimaSur(e, areaIndex, domandaIndex)
                        }
                        fullWidth
                      />
                    </div>
                  )}
                  {domanda?.TipologiaInput === 3 && (
                    <div className="flex gap-2">
                      <div className="w-1/2 mt-3 mb-2">
                        <TextField
                          value={domanda?.Risposta}
                          id={domanda.ID}
                          label="Percentuale ultimo biennio"
                          onChange={(e) =>
                            handleChangePrimaSur(e, areaIndex, domandaIndex)
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                            inputProps: { min: 0, max: 100 },
                          }}
                          type="number"
                          fullWidth
                        />
                      </div>
                      <div className="w-1/2 mt-3 mb-2">
                        <TextField
                          value={domanda?.SecondaPercentuale}
                          id={domanda.ID}
                          label="Percentuale attuale"
                          onChange={(e) =>
                            handleChangePrimaSur(
                              e,
                              areaIndex,
                              domandaIndex,
                              false,
                              true
                            )
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                            inputProps: { min: 0, max: 100 },
                          }}
                          type="number"
                          fullWidth
                        />
                      </div>
                    </div>
                  )}
                  <div className="w-full">
                    <TextField
                      value={domanda?.NoteRisposta}
                      id={domanda.ID}
                      label="Note"
                      onChange={(e) =>
                        handleChangePrimaSur(e, areaIndex, domandaIndex, true)
                      }
                      fullWidth
                    />
                  </div>
                </div>
              )
            )}
          </TabPanel>
        ))}
        <div className="w-full flex justify-end">
          {valueSur === primaSurvey.length - 1 ? (
            <Button
              ref={buttonSave}
              variant="contained"
              className="ButtonMiller"
              startIcon={<Check />}
              onClick={handleSave}
            >
              Salva
            </Button>
          ) : (
            <Button
              variant="contained"
              className="ButtonMiller"
              startIcon={<ArrowForward />}
              onClick={() => setValueSur((prev) => prev + 1)}
            >
              Avanti
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

const SecondaSurvey = () => {
  const [valueSur, setValueSur] = useState(0);
  const [secondaSurvey, setSecondaSurvey] = useState([]);

  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleChangeTabSur = (event, newValue) => {
    setValueSur(newValue);
  };

  function splitCategory(domande) {
    // Create a final array to store the grouped objects
    let finalArray = [];

    // Create a map to hold arrays of objects grouped by IDParitaSurvey
    let surveyMap = {};

    // Iterate over each object in the initial array
    domande.forEach((item) => {
      // Get the IDParitaSurvey of the current object
      let surveyId = item["Nome"];

      // If the surveyId is not yet a key in the surveyMap, add it with an empty array as the value
      if (!surveyMap[surveyId]) {
        surveyMap[surveyId] = [];
      }

      // Push the current object into the array corresponding to the surveyId
      surveyMap[surveyId].push(item);
    });

    /* // Transform the surveyMap into the desired finalArray format
    for (let surveyId in surveyMap) {
      let obj = {};
      obj[`${surveyId}`] = surveyMap[surveyId];
      finalArray.push(obj);
    } */

    //RIORDINO I TAB IN BASE AGLI ID DELLA SUVEY
    const sortedData = domande
      .map((category) => {
        return {
          IDParitaSurvey: category?.IDParitaSurvey,
          Nome: category?.Nome,
        };
      })
      .sort((a, b) => a.IDParitaSurvey - b.IDParitaSurvey)
      .map((item) => item.Nome);

    // Define the desired order of categories based on the IDParitaSurvey
    const categoryOrder = [...new Set(sortedData)];

    // Transform the surveyMap into the desired finalArray format and order
    categoryOrder.forEach((category) => {
      if (surveyMap[category]) {
        let obj = {};
        obj[category] = surveyMap[category];
        finalArray.push(obj);
      }
    });
    return finalArray;
  }

  function unsplitCategory(groupedArray) {
    // Create an array to hold the final flattened objects
    let flattenedArray = [];

    // Iterate over each grouped object in the finalArray
    groupedArray.forEach((group) => {
      // Get the key (which is the IDParitaSurvey) and the array of items
      let surveyId = Object.keys(group)[0];
      let items = group[surveyId];

      // Iterate over the items and push them to the flattenedArray
      items.forEach((item) => {
        //carico solo gli oggetti con le risposte
        if (item.Risposta) {
          flattenedArray.push({
            ...item,
            IDCliente: sessionStorage.getItem("Cliente"),
          });
        }
      });
    });

    return flattenedArray;
  }
  const handleChangeSecondaSur = (e, areaIndex, domandaIndex) => {
    const updatedSurveys = [...secondaSurvey];
    const areaKey = Object.keys(updatedSurveys[areaIndex])[0];
    updatedSurveys[areaIndex][areaKey][domandaIndex].Risposta = e.target.value;
    setSecondaSurvey(updatedSurveys);
  };

  const reloadSurveys = async () => {
    let response = await unsplash.post(
      "portaleParita",
      {
        operation: "getDomandeSurvey",
        grandezza: sessionStorage.getItem("Grandezza"),
        cliente: sessionStorage.getItem("Cliente"),
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );

    let secondSur = response.data.filter((el) => el.NSurvey === 2);
    const secondSurSplitted = splitCategory(secondSur);
    setSecondaSurvey(secondSurSplitted);
  };

  const handleSave = async () => {
    const risposte = unsplitCategory(secondaSurvey);
    try {
      const response = await unsplash.post(
        "portaleParita",
        {
          operation: "postRisposteSurvey",
          risposte: risposte,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      toast.success(`Risposte caricate con successo: ${response.data}!`
      )

    } catch {
      toast.error("Errore durante l'aggiornamento")

    }
    //ricarico le domande con il format predefinito
    reloadSurveys();
  };

  useEffect(() => {
    (async () => {
      let response = await unsplash.post(
        "portaleParita",
        {
          operation: "getDomandeSurvey",
          grandezza: sessionStorage.getItem("Grandezza"),
          cliente: sessionStorage.getItem("Cliente"),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      let secondSur = response.data.filter((el) => el.NSurvey === 2);
      const aaa = splitCategory(secondSur);
      setSecondaSurvey(aaa);
    })();
  }, []);
  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <div className="w-full">
        <Tabs
          value={valueSur}
          textColor="inherit"
          variant="scrollable"
          onChange={handleChangeTabSur}
          aria-label="basic tabs example"
        >
          {secondaSurvey?.map((el) => (
            <Tab label={Object.getOwnPropertyNames(el)} />
          ))}
        </Tabs>
        {secondaSurvey?.map((el, areaIndex) => (
          <TabPanel value={valueSur} index={areaIndex} key={areaIndex}>
            {el[Object.getOwnPropertyNames(el)]?.map(
              (domanda, domandaIndex) => (
                <div className="w-full flex flex-col py-2" key={domandaIndex}>
                  <div className="w-full font-semibold">
                    {domandaIndex + 1}) {domanda?.Domanda}
                  </div>
                  <div className="w-full mt-2">
                    <TextField
                      multiline
                      rows={2}
                      value={domanda?.Risposta}
                      id={domanda.ID}
                      label="Risposta"
                      onChange={(e) =>
                        handleChangeSecondaSur(e, areaIndex, domandaIndex)
                      }
                      fullWidth
                    />
                  </div>
                </div>
              )
            )}
            <div className="w-full flex justify-end">
              {valueSur === secondaSurvey.length - 1 ? (
                <Button
                  variant="contained"
                  className="ButtonMiller"
                  startIcon={<Check />}
                  onClick={handleSave}
                >
                  Salva
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className="ButtonMiller"
                  startIcon={<ArrowForward />}
                  onClick={() => setValueSur((prev) => prev + 1)}
                >
                  Avanti
                </Button>
              )}
            </div>
          </TabPanel>
        ))}
      </div>
    </>
  );
};
