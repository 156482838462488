import React, { useEffect, useState, useRef } from "react";
import { read, utils, writeFile } from "xlsx";
import { useNavigate } from "react-router-dom";
import unsplash from "../api/unsplash";
import {
  DataGridPro,
  GridToolbar,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
  GridFooterContainer,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";
import EditIcon from "@mui/icons-material/Edit";
import {
  Add,
  HealthAndSafety,
  WarningOutlined,
  Delete,
  HourglassBottom,
  Error,
  CheckCircle,
  ErrorOutline,
  CheckCircleOutline,
  MenuBook,
  Close,
  Check,
  ArrowForward,
  DoneAll,
  PersonOff,
  Person,
  History,
  CheckBox,
  InsertDriveFile,
  UploadFile,
  ConnectingAirportsOutlined,
  Flight,
  Send,
  Email,
  ArrowDropUp,
  Circle,
  Public,
} from "@mui/icons-material";
import {
  LinearProgress,
  ButtonGroup,
  OutlinedInput,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Popover,
  DialogActions,
  Box,
  Typography,
  Step,
  StepLabel,
  Stepper,
  CircularProgress,
  Tooltip,
  Backdrop,
  Zoom,
  Divider,
  Snackbar,
  Alert,
} from "@mui/material";
import "../style/style.css";
import moment from "moment";
import "moment/locale/it";
import {
  LocalizationProvider,
  DatePicker,
  PickersDay,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment as DateAdapter } from "@mui/x-date-pickers/AdapterMoment";

import GlobalSnackbar from "./Snackbar";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import DettaglioLavoratore from "./DettaglioLavoratore/DettaglioLavoratore";

import Grow from "@mui/material/Grow";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import GroupsIcon from "@mui/icons-material/Groups";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Excel } from "../utils/Excel";
import { createPortal } from "react-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const Lavoratori = (props) => {
  const [skip, setSkip] = useState(false);
  const [loadingLav, setLoadingLav] = useState(true);
  const [lavoratori, setLavoratori] = useState([]);
  const [lavoratoriBackup, setLavoratoriBackup] = useState([]);
  const [lavoratoriSelezionati, setLavoratoriSelezionati] = useState([]);
  const [lavoratoriElimina, setLavoratoriElimina] = useState([]);
  const [medici, setMedici] = useState();
  const [esiti, setEsiti] = useState();
  const [tipologiaVisita, setTipologiaVisita] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mansioni, setMansioni] = useState([]);
  const [mansioniSicurezza, setMansioniSicurezza] = useState([]);
  const [mansioniSicurezzaAll, setMansioniSicurezzaAll] = useState([]);
  const [dettaglioMedicina, setDettaglioMedicina] = useState([]);

  const [sedi, setSedi] = useState([]);
  const [eliminaDialog, setEliminaDialog] = useState(false);
  const [openBack, setOpenBack] = useState(false);
  const [movies, setMovies] = useState([]);

  const anchorRef = useRef(null);
  const [openButton, setOpenButton] = useState(false);

  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const [filtroClessidera, setFiltroClessidera] = useState({
    verde: true,
    arancione: true,
    rosso: true,
    blu: true,
    attivi: true,
    passivi: false,
  });

  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      headerAlign: "center",
      hide: false,
      width: "150",
      align: "center",
      cellClassName: "Cell-end",

      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <ButtonGroup>
          {params.row.IDDistacco ? (
            params.row.Cliente === props.idAzienda ? (
              <DettaglioLavoratore
                loadLavoratori={loadLavoratori}
                lavoratore={params.row}
                cliente={props.idAzienda}
                dati={props.cliente}
              />
            ) : (
              ""
            )
          ) : (
            <DettaglioLavoratore
              loadLavoratori={loadLavoratori}
              lavoratore={params.row}
              cliente={props.idAzienda}
              dati={props.cliente}
            />
          )}
          {/* <EditIcon
              style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
            /> */}
          <IconButton
            aria-label="edit"
            onClick={() => {
              downloadStorico(params.row.ID, params.row.Cognome);
            }}
          >
            <History
              style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
            />
          </IconButton>
          <IconButton
            aria-label="edit"
            onClick={() => openDeletDialog(params.row)}
          >
            <Delete
              style={{ height: "2rem", width: "2rem", color: "#FF2768" }}
            />
          </IconButton>
        </ButtonGroup>
      ),
    },
    /*  {
      filterable: false,
      field: "",
      headerName: "Attivo",
      width: 60,
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => {
        let attivo =
          !params.row.DataTermine ||
          moment(params.row.DataTermine).format("YYYY-MM-DD") >
            moment(new Date()).format("YYYY-MM-DD");

        return (
          <div>
            {attivo ? (
              <Tooltip title="Attivo">
                <Person sx={{ color: "#40C18F" }} />
              </Tooltip>
            ) : (
              <Tooltip title="Cessato">
                <PersonOff sx={{ color: "#FF2768" }} />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      field: "StatoVisita",
      headerName: "Stato",
      width: 20,
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <div>
          {params.row.StatoVisita === "OK" ? (
            <CheckCircle sx={{ color: "#40C18F" }} />
          ) : (
            <Error
              sx={{
                color:
                  params.row.StatoVisita === "InScadenza"
                    ? "#FFC897"
                    : params.row.StatoVisita === "Parziale"
                    ? "#2265B1"
                    : "#FF2768",
              }}
            />
          )}
        </div>
      ),
    },
   {
      filterable: false,
      field: "Visita",
      headerName: "*",
      width: 10,
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <div>
          {params.row.DataUltimaVisita === null ? (
            ""
          ) :  moment(params.row.DataUltimaVisita).format("YYYY-MM-DD") <
              moment(new Date()).format("YYYY-MM-DD") &&
            params.row.Esito <= 1 ? (
            <HourglassBottom sx={{ color: "#FF2768" }} />
          ) : params.row.Esito > 1 ? (
            <HourglassBottom sx={{ color: "#40C18F" }} />
          ) : (
            <HourglassBottom sx={{ color: "#FFC897" }} />
          )}
        </div>
      ),
    },*/
    {
      field: "D",
      headerName: "Info",
      width: 120,

      cellClassName: "Cell-left",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          {!params.row.DataTermine ||
          moment(params.row.DataTermine).format("YYYY-MM-DD") >
            moment(new Date()).format("YYYY-MM-DD") ? (
            <Tooltip title="Attivo">
              <Person sx={{ color: "#40C18F", marginRight: "3px" }} />
            </Tooltip>
          ) : (
            <Tooltip title="Cessato">
              <PersonOff sx={{ color: "#FF2768", marginRight: "3px" }} />
            </Tooltip>
          )}

          {params.row.StatoVisita === "OK" ? (
            <CheckCircle sx={{ color: "#40C18F", marginRight: "3px" }} />
          ) : (
            <Error
              sx={{
                marginRight: "3px",
                color:
                  params.row.StatoVisita === "InScadenza"
                    ? "#FFC897"
                    : params.row.StatoVisita === "Parziale"
                    ? "#2265B1"
                    : "#FF2768",
              }}
            />
          )}

          {params.row.DataUltimaVisita === null ? (
            ""
          ) : /*1*/ moment(params.row.DataUltimaVisita).format("YYYY-MM-DD") <
              moment(new Date()).format("YYYY-MM-DD") &&
            params.row.Esito <= 1 ? (
            <HourglassBottom sx={{ color: "#FF2768", marginRight: "3px" }} />
          ) : params.row.Esito > 1 ? (
            <HourglassBottom sx={{ color: "#40C18F", marginRight: "3px" }} />
          ) : (
            <HourglassBottom sx={{ color: "#FFC897", marginRight: "3px" }} />
          )}

          {params.row.IDDistacco ? (
            params.row.Cliente === props.idAzienda ? (
              <Tooltip title={"Distaccato da " + params.row.RagioneSocialeDa}>
                <Flight
                  className="hover:cursor-pointer"
                  sx={{
                    rotate: "135deg",
                    color: "#9059C3",
                    marginRight: "3px",
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title={"Distaccato in " + params.row.RagSoc}>
                <Flight
                  className="hover:cursor-pointer"
                  sx={{ rotate: "45deg", color: "#9059C3", marginRight: "3px" }}
                />
              </Tooltip>
            )
          ) : (
            ""
          )}

          <Tooltip
            title={
              params.row.AreaRiservata === 1
                ? "Area riservata in utilizzo"
                : "Area Riservata non utilizzata"
            }
          >
            <Public
              className="hover:cursor-pointer"
              sx={{
                rotate: "135deg",
                color:
                  params.row.AreaRiservata === 0
                    ? "#FF2768"
                    : !params.row.AreaRiservataPassword &&
                      params.row.PrimoAccesso === 1
                    ? "#FFC897"
                    : "#40C18F",
              }}
            />
          </Tooltip>
        </>
      ),

      ///<Flight  sx={{rotate:"135deg",, color: "#9059C3"}}/>
    },

    {
      field: "Scadenza",
      headerName: "Scadenza Visita",
      width: 100,
      renderCell: (params) => (
        <div>
          {params.row.Scadenza
            ? moment(params.row.Scadenza).format("DD/MM/YYYY")
            : ""}
        </div>
      ),
    },
    { field: "SedeBlocco", headerName: "Blocco", width: 150 },
    { field: "Referente", headerName: "Referente", width: 150 },
    { field: "SedeDesc", headerName: "Sede", width: 150 },
    { field: "Cognome", headerName: "Cognome", width: 150 },
    { field: "Nome", headerName: "Nome", width: 150 },
    { field: "CF", headerName: "CF", width: 200 },
    {
      field: "MansioniSicurezzaDescrizione",
      headerName: "Mansione Sicurezza",
      width: 300,
    },
    { field: "Descrizione", headerName: "Mansione Medicina", width: 300 },
    { field: "LimPassate", headerName: "Limitazioni", width: 300 },
    { field: "Note", headerName: "Note", width: 300 },
    //{ field: "Distaccato", headerName: "Distaccato", width: 200 },
  ];

  const columnsMedicina = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      headerAlign: "center",
      hide: false,
      width: "150",
      align: "center",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <ButtonGroup>
          <DettaglioLavoratore
            loadLavoratori={loadLavoratori}
            lavoratore={params.row}
            cliente={props.idAzienda}
            dati={props.cliente}
          />
          {/* <EditIcon
              style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
            /> */}
          <IconButton
            aria-label="edit"
            onClick={() => {
              downloadStorico(params.row.ID, params.row.Cognome);
            }}
          >
            <History
              style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
            />
          </IconButton>
          <IconButton
            aria-label="edit"
            onClick={() => openDeletDialog(params.row)}
          >
            <Delete
              style={{ height: "2rem", width: "2rem", color: "#FF2768" }}
            />
          </IconButton>
        </ButtonGroup>
      ),
    },
    {
      field: "D",
      headerName: "Info",
      width: 120,

      cellClassName: "Cell-left",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          {!params.row.DataTermine ||
          moment(params.row.DataTermine).format("YYYY-MM-DD") >
            moment(new Date()).format("YYYY-MM-DD") ? (
            <Tooltip title="Attivo">
              <Person sx={{ color: "#40C18F", marginRight: "3px" }} />
            </Tooltip>
          ) : (
            <Tooltip title="Cessato">
              <PersonOff sx={{ color: "#FF2768", marginRight: "3px" }} />
            </Tooltip>
          )}

          {params.row.StatoVisita === "OK" ? (
            <CheckCircle sx={{ color: "#40C18F", marginRight: "3px" }} />
          ) : (
            <Error
              sx={{
                marginRight: "3px",
                color:
                  params.row.StatoVisita === "InScadenza"
                    ? "#FFC897"
                    : params.row.StatoVisita === "Parziale"
                    ? "#2265B1"
                    : "#FF2768",
              }}
            />
          )}

          {params.row.DataUltimaVisita === null ? (
            ""
          ) : /*1*/ moment(params.row.DataUltimaVisita).format("YYYY-MM-DD") <
              moment(new Date()).format("YYYY-MM-DD") &&
            params.row.Esito <= 1 ? (
            <HourglassBottom sx={{ color: "#FF2768", marginRight: "3px" }} />
          ) : params.row.Esito > 1 ? (
            <HourglassBottom sx={{ color: "#40C18F", marginRight: "3px" }} />
          ) : (
            <HourglassBottom sx={{ color: "#FFC897", marginRight: "3px" }} />
          )}

          {params.row.IDDistacco ? (
            params.row.Cliente === props.idAzienda ? (
              <Tooltip title={"Distaccato da " + params.row.RagioneSocialeDa}>
                <Flight
                  className="hover:cursor-pointer"
                  sx={{
                    rotate: "135deg",
                    color: "#9059C3",
                    marginRight: "3px",
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title={"Distaccato in " + params.row.RagSoc}>
                <Flight
                  className="hover:cursor-pointer"
                  sx={{ rotate: "45deg", color: "#9059C3", marginRight: "3px" }}
                />
              </Tooltip>
            )
          ) : (
            ""
          )}

          <Tooltip
            title={
              params.row.AreaRiservata === 1
                ? "Area riservata in utilizzo"
                : "Area Riservata non utilizzata"
            }
          >
            <Public
              className="hover:cursor-pointer"
              sx={{
                rotate: "135deg",
                //se password === "" && primoAccesso === 1 ? [Giallo] : primoAccesso === 0
                color:
                  params.row.AreaRiservata === 0
                    ? "#FF2768"
                    : !params.row.AreaRiservataPassword &&
                      params.row.PrimoAccesso === 1
                    ? "#FFC897"
                    : "#40C18F",
              }}
            />
          </Tooltip>
        </>
      ),

      ///<Flight  sx={{rotate:"135deg",, color: "#9059C3"}}/>
    },
    {
      field: "Scadenza",
      headerName: "Scadenza Visita",
      width: 100,
      renderCell: (params) => (
        <div>
          {params.row.Scadenza
            ? moment(params.row.Scadenza).format("DD/MM/YYYY")
            : ""}
        </div>
      ),
    },
    { field: "SedeBlocco", headerName: "Blocco", width: 150 },
    { field: "Referente", headerName: "Referente", width: 150 },
    { field: "Cognome", headerName: "Cognome", width: 150 },
    { field: "Nome", headerName: "Nome", width: 150 },
    { field: "Descrizione", headerName: "Mansione", width: 300 },
    { field: "Note", headerName: "Note", width: 300 },
    { field: "CF", headerName: "CF", width: 200 },
    {
      field: "MansioniSicurezzaDescrizione",
      headerName: "Mansione Sicurezza",
      width: 300,
    },
    { field: "Distaccato", headerName: "Distaccato", width: 200 },
    { field: "SedeDesc", headerName: "Sede", width: 300 },
    { field: "LimPassate", headerName: "Limitazioni", width: 300 },
  ];

  useEffect(() => {
    (async () => {
      try {
        //let lav = await props.Lavoratori;
        if (sessionStorage.getItem("page") === "Dettaglio Cliente") {
          const lav = await unsplash.get("lavoratori", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
            params: { id: props.idAzienda },
          });
          setLavoratori(
            lav.data.data.filter(
              (v) =>
                !v.DataTermine ||
                moment(v.DataTermine).format("YYYY-MM-DD") >
                  moment(new Date()).format("YYYY-MM-DD")
            )
          ); //è una promise
          setLavoratoriBackup(lav.data.data);
        } else {
          const lavSedi = await props.loadLavoratori();
          setLavoratori(
            lavSedi.filter(
              (v) =>
                !v.DataTermine ||
                moment(v.DataTermine).format("YYYY-MM-DD") >
                  moment(new Date()).format("YYYY-MM-DD")
            )
          );
          setLavoratoriBackup(lavSedi);
        }
        setLoadingLav(false);

        let responseMedici = await unsplash.get("medici", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { id: props.idAzienda },
        });
        setMedici(responseMedici.data.data);

        let responseEsiti = await unsplash.get("esiti", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        setEsiti(responseEsiti.data.data);

        let responseTipologiaVisita = await unsplash.get("tipologiaVisita", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        setTipologiaVisita(responseTipologiaVisita.data.data);

        let responseMansioni = await unsplash.get("mansioni", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { id: props.idAzienda },
        });
        setMansioni(responseMansioni.data.data);

        let responseMansioniSicurezza = await unsplash.get(
          "mansioniSicurezza",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
            params: { id: props.idAzienda },
          }
        );
        setMansioniSicurezza(responseMansioniSicurezza.data.data);

        let responseMansioniSicurezzaAll = await unsplash.get(
          "mansioniSicurezza",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
            params: { cliente: props.idAzienda },
          }
        );
        setMansioniSicurezzaAll(responseMansioniSicurezzaAll.data.data);

        let responseDettaglioMedicina = await unsplash.get(
          "dettaglioMedicina",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
            params: { excel: true, cliente: props.idAzienda },
          }
        );

        setDettaglioMedicina(responseDettaglioMedicina.data.data);
        let responseSedi = await unsplash.get("sedi", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { id: props.idAzienda },
        });
        const SediSenzaCantieri = responseSedi.data.data.filter(
          (elm) => elm.Cantiere === 0
        );
        setSedi(SediSenzaCantieri);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const handleImport = ($event) => {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          setMovies(rows);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };
  const massiveVisit = (e) => {
    if (lavoratoriSelezionati.length > 0) {
      setSkip(false);
      document.getElementById("myDialog").click();
    } else {
      //se vuoto mostro l'alert
      setAnchorEl(e.currentTarget);
    }
  };
  const handleCloseEl = () => {
    setAnchorEl(null);
  };
  const handleSave = async (lavoratore) => {
    let l = { ...lavoratore };
    l.Cliente = props.idAzienda;

    try {
      let respLavoratore = await unsplash.post(
        "lavoratori",
        { lavoratore: l },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      l.ID = respLavoratore.data.message.insertId;
      //setLavoratori(currentLavoratori => [...currentLavoratori,lavoratore]);
      await unsplash.post(
        "storico",
        {
          tabella: "Lavoratori",
          key: l.ID,
          utente: sessionStorage.getItem("username"),
          after: l,
          before: [],
          operazione: "Inserimento",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      setSnack({
        severity: "success",
        messaggio: "Lavoratore inserito con successo",
      });
      setOpenSnack(true);

      loadLavoratori();
      props.loadLavoratori();
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'inserimento",
      });
      setOpenSnack(true);
    }
  };
  const loadLavoratori = async () => {
    setLoadingLav(true);
    if (sessionStorage.getItem("page") === "Dettaglio Cliente") {
      let responseLavoratori = await unsplash.get("lavoratori", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { id: props.idAzienda },
      });
      let lavTemp = responseLavoratori.data.data;
      if (!filtroClessidera.attivi) {
        lavTemp = lavTemp.filter(
          (v) =>
            !(
              !v.DataTermine ||
              moment(v.DataTermine).format("YYYY-MM-DD") >
                moment(new Date()).format("YYYY-MM-DD")
            )
        );
      }
      if (!filtroClessidera.passivi) {
        lavTemp = lavTemp.filter(
          (v) =>
            !v.DataTermine ||
            moment(v.DataTermine).format("YYYY-MM-DD") >
              moment(new Date()).format("YYYY-MM-DD")
        );
      }

      setLavoratori(lavTemp);
    } else {
      const allLavoratori = await props.loadLavoratori();
      let lavTemp = allLavoratori?.filter(
        (v) =>
          !v.DataTermine ||
          moment(v.DataTermine).format("YYYY-MM-DD") >
            moment(new Date()).format("YYYY-MM-DD")
      );
      setLavoratori(lavTemp);
    }
    setLoadingLav(false);
  };
  const openDeletDialog = (lav) => {
    setLavoratoriElimina(lav);
    setEliminaDialog(true);
  };
  const handleDelete = async (idLav) => {
    if (idLav) {
      try {
        await unsplash.delete("lavoratori", {
          headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
          data: { ID: idLav.ID },
        });
        setSnack({
          severity: "success",
          messaggio: "Lavoratore eliminata con successo",
        });
        setOpenSnack(true);
        setEliminaDialog(false);
        loadLavoratori();
        props.loadLavoratori();
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante l'eliminazione",
        });
        setOpenSnack(true);
        setEliminaDialog(false);
      }
    } else {
      setSnack({
        severity: "error",
        messaggio: "Sele",
      });
    }
  };

  const filterClessidera = (e, colore) => {
    let f = { ...filtroClessidera };
    f[colore] = e.target.checked;
    setFiltroClessidera(f);
    let lavTemp = lavoratoriBackup;
    if (!f.rosso) {
      lavTemp = lavTemp.filter(
        (v) =>
          v.StatoVisita !== "Scadenza" &&
          v.StatoVisita !== "AccertamentoMancante"
      );
    }

    if (!f.verde) {
      lavTemp = lavTemp.filter((v) => v.StatoVisita !== "OK");
    }

    if (!f.arancione) {
      lavTemp = lavTemp.filter((v) => v.StatoVisita !== "InScadenza");
    }

    if (!f.blu) {
      lavTemp = lavTemp.filter((v) => v.StatoVisita !== "Parziale");
    }

    if (!f.attivi) {
      lavTemp = lavTemp.filter(
        (v) =>
          !(
            !v.DataTermine ||
            moment(v.DataTermine).format("YYYY-MM-DD") >
              moment(new Date()).format("YYYY-MM-DD")
          )
      );
    }
    if (!f.passivi) {
      lavTemp = lavTemp.filter(
        (v) =>
          !v.DataTermine ||
          moment(v.DataTermine).format("YYYY-MM-DD") >
            moment(new Date()).format("YYYY-MM-DD")
      );
    }

    setLavoratori(lavTemp);

    /* let visiteTemp = visite.filter(v => v.Esito >=1);
  
  setVisite(visiteTemp); */
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
          <Checkbox
            icon={<ErrorOutline />}
            checked={filtroClessidera.rosso}
            checkedIcon={<Error />}
            sx={{ color: "#FF2768!important" }}
            onClick={(e) => filterClessidera(e, "rosso")}
          />
          <Checkbox
            icon={<ErrorOutline />}
            checked={filtroClessidera.arancione}
            checkedIcon={<Error />}
            sx={{ color: "#FFC897!important" }}
            onClick={(e) => filterClessidera(e, "arancione")}
          />
          <Checkbox
            icon={<ErrorOutline />}
            checked={filtroClessidera.blu}
            checkedIcon={<Error />}
            sx={{ color: "#2265B1!important" }}
            onClick={(e) => filterClessidera(e, "blu")}
          />
          <Checkbox
            icon={<CheckCircleOutline />}
            checked={filtroClessidera.verde}
            checkedIcon={<CheckCircle />}
            sx={{ color: "#40C18F!important" }}
            onClick={(e) => filterClessidera(e, "verde")}
          />
          <FormControlLabel
            sx={{ marginBottom: "0px!important" }}
            control={
              <Checkbox
                checked={filtroClessidera.attivi}
                onClick={(e) => filterClessidera(e, "attivi")}
              />
            }
            label="Attivi"
          />
          <FormControlLabel
            sx={{ marginBottom: "0px!important" }}
            control={
              <Checkbox
                checked={filtroClessidera.passivi}
                onClick={(e) => filterClessidera(e, "passivi")}
              />
            }
            label="Passivi"
          />
          <Button
            startIcon={<InsertDriveFile />}
            style={{ display: lavoratoriSelezionati.length > 0 ? "" : "None" }}
            variant="text"
            size="small"
            onClick={() => document.getElementById("printLavoratori").click()}
          >
            Esporta Excel
          </Button>

          <Button
            startIcon={<Send />}
            style={{
              display:
                lavoratoriSelezionati.length === 0 ||
                lavoratoriSelezionati.length > 1
                  ? "None"
                  : lavoratoriSelezionati[0].PrimoAccesso === 0
                  ? "none"
                  : "",
            }}
            variant="text"
            size="small"
            onClick={() =>
              document.getElementById("sendCredentialButton").click()
            }
          >
            Invia credenziali
          </Button>

          <Excel
            sheets={[
              {
                Sede: sedi, //la prima proprietà deve essere uguale al nome della colonna che deve avere la dropdown list
                colonnaSel: { nome: "Descrizione", lettera: "A" }, //il nome deve essere lo stesso della colonna del DB che poi verrà sostituito dall'ID
              },
              {
                Mansione: mansioni, //la prima proprietà deve essere uguale al nome della colonna che deve avere la dropdown list
                colonnaSel: { nome: "Descrizione", lettera: "B" }, //il nome deve essere lo stesso della colonna del DB che poi verrà sostituito dall'ID
              },
              {
                MansioneSicurezza: mansioniSicurezzaAll.map((el) => {
                  let e = {};
                  e.ID = el.ID;
                  e.Sede = el.Sede;
                  e.Descrizione = el.Descrizione;
                  return e;
                }), //la prima proprietà deve essere uguale al nome della colonna che deve avere la dropdown list
                colonnaSel: { nome: "Descrizione", lettera: "C" }, //il nome deve essere lo stesso della colonna del DB che poi verrà sostituito dall'ID
                exist: "Sede",
              },
              {
                DettaglioMedicina: dettaglioMedicina.map((el) => {
                  let e = {};
                  e.ID = el.ID;
                  e.Sede = el.Sede;
                  e.Descrizione = el.Descrizione;
                  return e;
                }), //la prima proprietà deve essere uguale al nome della colonna che deve avere la dropdown list
                colonnaSel: { nome: "Descrizione", lettera: "D" }, //il nome deve essere lo stesso della colonna del DB che poi verrà sostituito dall'ID
                exist: "Sede",
              },
              {
                Sesso: [
                  { ID: 1, Descrizione: "Maschio" },
                  { ID: 2, Descrizione: "Femmina" },
                ], //la prima proprietà deve essere uguale al nome della colonna che deve avere la dropdown list
                colonnaSel: { nome: "Descrizione", lettera: "K" }, //il nome deve essere lo stesso della colonna del DB che poi verrà sostituito dall'ID
              },
            ]}
            requiredColumn={"CF"}
            getAllTableData={"Lavoratori"}
            filterColumn={["Nome", "Cognome", "CF", "DataAssunzione"]}
            thereIsData={["DataDiNascita", "DataAssunzione"]}
            excelHeader={[
              "Sede",
              "Mansione",
              "MansioneSicurezza",
              "DettaglioMedicina",
              "Nome",
              "Cognome",
              "CF",
              "DataDiNascita",
              "DataAssunzione",
              "Note",
              "Sesso",
              "LuogoDiNascita",
            ]}
            fileName={"Lavoratori"}
            reloadData={() => {
              loadLavoratori();
              props.loadLavoratori();
            }}
            handleSave={async (importedData) => {
              const impoData = importedData.map((el) => {
                let l = { ...el };
                l.Cliente = props.idAzienda;
                return l;
              });
              try {
                await unsplash.post(
                  "excel",
                  {
                    excel: impoData,
                    table: "Lavoratori",
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                  }
                );
              } catch {
                setSnack({
                  severity: "error",
                  messaggio: "Errore durante l'importazione",
                });
                setOpenSnack(true);
              }
            }}
          />
        </Grid>
      </GridToolbarContainer>
    );
  }

  const handleToggleButton = () => {
    setOpenButton(!openButton);
  };
  const handleCloseButton = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenButton(false);
  };
  function CustomFooter() {
    return (
      <GridFooterContainer
        sx={{
          display: "flex",
          justifyContent: { xs: "center", md: "flex-start" },
          px: 2,
          flexWrap: { xs: "wrap", md: "nowrap" },
        }}
      >
        {props.screenSize > "768" ? (
          <>
            <GridToolbarColumnsButton
              sx={{ color: "#11526F" }}
              style={{ border: 0 }}
            />
            <GridToolbarFilterButton
              sx={{ color: "#11526F" }}
              style={{ border: 0 }}
            />
            <GridToolbarDensitySelector
              sx={{ color: "#11526F" }}
              style={{ border: 0 }}
            />
            <GridToolbarExport
              sx={{ color: "#11526F" }}
              style={{ border: 0 }}
            />
          </>
        ) : (
          <>
            <ButtonGroup
              aria-label="split button"
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                ref={anchorRef}
                startIcon={
                  <MoreVertIcon style={{ height: "28px", width: "28px" }} />
                }
                variant="contained"
                className="ButtonToolbarWrapped"
                onClick={() => {
                  handleToggleButton();
                }}
                size="small"
              >
                <span
                  style={{
                    margin: "0  0 0 -10px",
                    alignSelf: "center",
                    fontSize: "16px",
                    textTransform: "capitalize",
                  }}
                >
                  Opzioni tabella
                </span>
              </Button>
              <Popper
                sx={{
                  zIndex: 100,
                }}
                open={openButton}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow {...TransitionProps}>
                    <Paper sx={{ width: "181px" }}>
                      <ClickAwayListener onClickAway={handleCloseButton}>
                        <MenuList id="split-button-menu" Item sx={{ p: 0 }}>
                          <MenuItem
                            key={1}
                            onClick={handleToggleButton}
                            sx={{ p: 0 }}
                          >
                            <GridToolbarColumnsButton
                              sx={{ color: "#11526F" }}
                              style={{
                                border: 0,
                                width: "100%",
                                padding: "10px 32px 10px 20px ",
                                justifyContent: "flex-start",
                              }}
                            />
                          </MenuItem>
                          <MenuItem
                            key={0}
                            onClick={handleToggleButton}
                            sx={{ p: 0 }}
                          >
                            <GridToolbarFilterButton
                              sx={{ color: "#11526F" }}
                              style={{
                                border: 0,
                                width: "100%",
                                padding: "10px 32px 10px 15px ",

                                justifyContent: "flex-start",
                              }}
                              fullWidth
                            />
                          </MenuItem>

                          <MenuItem key={2} sx={{ p: 0 }}>
                            <GridToolbarDensitySelector
                              sx={{ color: "#11526F" }}
                              style={{
                                border: 0,
                                width: "100%",
                                padding: "10px 32px 10px 18px ",

                                justifyContent: "flex-start",
                              }}
                            />
                          </MenuItem>
                          <MenuItem key={3} sx={{ p: 0 }}>
                            <GridToolbarExport
                              sx={{ color: "#11526F" }}
                              style={{
                                border: 0,
                                width: "100%",
                                padding: "10px 32px 10px 18px ",

                                justifyContent: "flex-start",
                              }}
                            />
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </ButtonGroup>
          </>
        )}
        {/*  */}
      </GridFooterContainer>
    );
  }

  const handleClose = () => {
    setEliminaDialog(false);
  };

  const downloadStorico = async (lavoratore, cognome) => {
    let responseAziendali = await unsplash.get("allegati", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      responseType: "blob",
      params: { colonna: "StoricoAllegatiLavoratore", lavoratore: lavoratore },
    });

    const url = window.URL.createObjectURL(
      new Blob([responseAziendali.data], { type: "application/zip" })
    ); //prendo il file, lo rendo blob lo aggancio a un link e scarico

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", cognome + ".zip");
    document.body.appendChild(link);
    link.click();
  };

  const downloadStoricoCliente = async (cliente) => {
    setOpenBack(true);
    let responseAziendali = await unsplash.get("allegati", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      responseType: "blob",
      params: { colonna: "StoricoAllegatiCliente", cliente: cliente },
    });

    const url = window.URL.createObjectURL(
      new Blob([responseAziendali.data], { type: "application/zip" })
    ); //prendo il file, lo rendo blob lo aggancio a un link e scarico

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "storico.zip");
    document.body.appendChild(link);
    link.click();
    setOpenBack(false);
  };

  return (
    <>
      <ModalCredenziali
        lavoratoriSelezionati={lavoratoriSelezionati}
        loadLavoratori={loadLavoratori}
      />
      <Dialog
        open={eliminaDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
        lavoratoriSelezionati={lavoratoriSelezionati}
      >
        <DialogTitle id="alert-dialog-title">{props.Title}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              SEI SICURO DI VOLER ELIMINARE {lavoratoriElimina?.Nome} -{" "}
              {lavoratoriElimina?.Cognome}?
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between", mx: 2 }}
        >
          <Button
            onClick={handleClose}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
          >
            annulla
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={() => handleDelete(lavoratoriElimina)}
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
      <MyDialog
        loadLavoratori={props.loadLavoratori}
        lavoratori={lavoratoriSelezionati}
        medici={medici}
        esiti={esiti}
        tipologiaVisita={tipologiaVisita}
        cliente={props.idAzienda}
      />
      <Grid
        container
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="flex-end"
      >
        <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
        >
          <ModalLavoratori
            operazione="Aggiungi Lavoratore"
            id="add"
            mansioni={mansioni}
            cliente={props.idAzienda}
            gruppo={props.cliente.Gruppo}
            mansioniSicurezza={mansioniSicurezza}
            sedi={sedi}
            saveFunction={handleSave}
            snack={snack}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
          />
          <Button
            startIcon={<HealthAndSafety />}
            onClick={massiveVisit}
            className="ButtonMiller"
          >
            VISITA MASSIVA
          </Button>
          <Button
            startIcon={<UploadFile />}
            onClick={() => document.getElementById("inputGroupFile").click()}
            className="ButtonMiller"
          >
            Importa Da excel
          </Button>
          <div className="custom-file" style={{ display: "none" }}>
            <input
              type="file"
              name="file"
              className="custom-file-input"
              id="inputGroupFile"
              required
              onChange={handleImport}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            <label className="custom-file-label" htmlFor="inputGroupFile">
              Choose file
            </label>
          </div>

          <Button
            startIcon={<History />}
            onClick={() => downloadStoricoCliente(props.idAzienda)}
            className="ButtonMiller"
          >
            Storico Corsi
          </Button>
        </ButtonGroup>
      </Grid>
      <Popover
        id={Boolean(anchorEl) ? "simple-popover" : undefined}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <WarningOutlined sx={{ marginLeft: "10px", color: "#FFC897" }} />
          <Typography sx={{ p: 2 }}>
            Selezionare almeno un lavoratore!
          </Typography>
        </div>
      </Popover>
      <div style={{ display: "None" }}>
        <ReactHtmlTableToExcel
          id="printLavoratori"
          className="download-table-xls-button"
          table="table-to-xls"
          filename={
            "Esportazione Lavoratori - " +
            props.ragioneSociale +
            " - " +
            moment(new Date()).format("DD/MM/YYYY")
          }
          sheet={"Lavoratori"}
          style={{ display: "None!important" }}
          buttonText="Download Log"
        />
      </div>
      <table
        id="table-to-xls"
        style={{ marginTop: 10, display: "None" }}
        cellSpacing={0}
        border={0}
      >
        <tr>
          <th>Scadenza Visita</th>
          <th>Blocco</th>
          <th>Cognome</th>
          <th>Nome</th>
          <th>CF</th>
          <th>Data di nascita</th>
          <th>Luogo di nascita</th>
          <th>Sesso</th>
          <th>
            {lavoratoriSelezionati[0]?.Gruppo === "Conter"
              ? "Classe di Rischio"
              : "Mansione"}
          </th>
          <th>Note</th>
          <th>Sede</th>
          <th>Distaccato</th>
          <th>Limitazioni</th>{" "}
        </tr>
        {lavoratoriSelezionati?.map((lav, index) => (
          <tr key={lav.ID}>
            <td>
              {" "}
              {lav.Scadenza ? moment(lav.Scadenza).format("DD/MM/YYYY") : ""}
            </td>

            <td>{lav.SedeBlocco}</td>
            <td>{lav.Cognome}</td>
            <td>{lav.Nome}</td>
            <td>{lav.CF}</td>
            <td>
              {lav.DataDiNascita
                ? moment(lav.DataDiNascita).format("DD/MM/YYYY")
                : ""}
            </td>
            <td>{lav.LuogoDiNascita}</td>
            <td>{lav?.Sesso === 1 ? "Maschio" : lav?.Sesso === 2 ? "Femmina" : ""}</td>
            <td>{lav.Descrizione}</td>
            <td>{lav.Note}</td>
            <td>{lav.SedeDesc}</td>
            <td>{lav.Distaccato}</td>
            <td>{lav.LimPassate}</td>
          </tr>
        ))}
      </table>
      <Box sx={{ height: "60vh", width: "100%" }}>
        <DataGridPro
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooterPersonalized,
          }}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          disableSelection={true}
          loading={loadingLav}
          initialState={{ pinnedColumns: { left: ["Actions"] } }}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          rows={lavoratori}
          getRowId={(lavoratori) => lavoratori.ID}
          columns={
            sessionStorage.getItem("Sicurezza") === "1"
              ? columns
              : columnsMedicina
          }
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRowData = lavoratori.filter(
              (row) =>
                selectedIDs.has(row.ID) &&
                (!row.IDDistacco || row.Cliente === props.idAzienda)
            ); // && //(!(row.Distaccato) || row.Distaccato === props.idAzienda.toString())
            // ); *
            setLavoratoriSelezionati(selectedRowData);
          }}
          checkboxSelection
          onCellClick={(params, e) => {
            if (params.field === "Actions") {
              e.stopPropagation();
            }
          }}
        />
      </Box>
    </>
  );
};

export default Lavoratori;

const MyDialog = (props) => {
  const [medicoSel, setMedicoSel] = useState("");
  const [esitoSel, setEsitoSel] = useState("");
  const [noteSel, setNoteSel] = useState("");
  const [tipologiaSel, setTipologiaSel] = useState("");
  const [luogoSel, setLuogoSel] = useState("");
  const [dataSel, setDataSel] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [accertamenti, setAccertamenti] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectionModelBk, setSelectionModelBk] = useState([]);
  const [selectedACS, setSelectedACS] = useState([]);
  const [required, setRequired] = useState({
    medico: false,
    data: false,
    tipo: false,
    luogo: false,
    ora: false,
  });
  
  const [protocolliLav, setProtocolliLav] = useState([]);
  
  const [openReport, setOpenReport] = useState(false);
  const [openButtonReport, setOpenButtonReport] = useState(false);
  const [reportLavoratori, setReportLavoratori] = useState([]);
  const [reportReferenti, setReportReferenti] = useState([]);
  const [emailLav, setEmailLav] = useState([]);
  const [emailRef, setEmailRef] = useState([]);
  const [missingEmail, setMissingEmail] = useState([]);
  const [showAccordions, setShowAccordions] = useState(false);
  const anchorRefReport = useRef(null);
  const [selectedIndexReport, setSelectedIndexReport] = useState(1);
  const [expanded, setExpanded] = useState(false);
  const [data, setData] = useState(null);

  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const optionsReport = [
    {
      icon: <GroupsIcon />,
      text: "DIPENDENTI",
      ind: 4,
    },
    {
      icon: <Person />,
      text: "REFERENTI",
      ind: 5,
    },
  ];

  const columns = [
    { field: "Descrizione", headerName: "Accertamento", width: 300 },
    {
      field: "Scadenza",
      headerName: "Scadenza",
      type: "date",
      width: 150,
      editable: true,
      renderCell: (params) =>
        params.row.Scadenza
          ? moment(params.row.Scadenza).format("DD/MM/YYYY")
          : "",
    },
    {
      field: "Importo",
      headerName: "Importo €",
      type: "number",
      width: 120,
      editable: true,
      renderCell: (params) => (params.row.Importo ? params.row.Importo : 0),
    },
    {
      field: "ImportoPassivo",
      headerName: "ImportoPassivo €",
      type: "number",
      width: 150,
      editable: true,
      renderCell: (params) =>
        params.row.ImportoPassivo ? params.row.ImportoPassivo : 0,
    },
  ];

  const loadVisitaLavoratore = async (val) => {
    let responseProtocollo = await unsplash.get("protocolloSanitario", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { lavoratore: props.lavoratori[val].ID },
    });
    setProtocolliLav(responseProtocollo.data.data)
    let year = 0;
    if (props.lavoratori[val].CF) {
      year = ageFromCf(props.lavoratori[val].CF);
    }

    let d1 = new Date();
    let d2 = new Date(
      moment(d1.setDate(d1.getDate() + 60)).format("YYYY-MM-DD")
    );

    let acs = responseProtocollo.data.data.filter(
      (p) =>
        p.Scadenza === null ||
        new Date(moment(p.Scadenza).format("YYYY-MM-DD")).getTime() <
          d2.getTime() ||
        p.Data === null
    ); // solo accertamenti scaduti
    setSelectedACS(acs);

    let responseAccertamenti = await unsplash.get("accertamenti", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { cliente: props.cliente, ultimaVisita: 0 },
    });
    let allAccertamenti = responseAccertamenti.data.data;
    let arrayOnlyID = allAccertamenti.map((acc) => acc.ID);
    //acs è il protocollo sanitario con le relative scadenze. Quindi uso quello
    for (let i = 0; i < acs.length; i++) {
      let index = arrayOnlyID.indexOf(acs[i].Accertamenti);
      if (index !== -1) {
        if (acs[i].Accertamenti === 40 || acs[i].Accertamenti === 42) {
          // SE è VDT e Accertamento = Visita generale
          let scadenza = 24;

          if (year < 50) {
            scadenza = 60;
          }

          if (acs[i].Accertamenti === 40 && acs[i].Cadenze < scadenza) {
            scadenza = acs[i].Cadenze;
          }

          allAccertamenti[index].Scadenza = moment(dataSel).add(scadenza, "M");
          acs[i].Scadenza = moment(dataSel)
            .add(acs[i].Cadenze, "M")
            .format("YYYY-MM-DD");

          /* if (year > 50) {
            allAccertamenti[index].Scadenza = moment(dataSel).add(24, "M");
            acs[i].Scadenza = moment(dataSel)
              .add(acs[i].Cadenze, "M")
              .format("YYYY-MM-DD");
          } else {
            allAccertamenti[index].Scadenza = moment(dataSel).add(60, "M");
            acs[i].Scadenza = moment(dataSel)
              .add(acs[i].Cadenze, "M")
              .format("YYYY-MM-DD");
          } */
        } else {
          allAccertamenti[index].Scadenza = moment(dataSel).add(
            acs[i].Cadenze,
            "M"
          );
          acs[i].Scadenza = moment(dataSel)
            .add(acs[i].Cadenze, "M")
            .format("YYYY-MM-DD");
        }
      }
    }
    //setSelectionModel(acs.map(acc => {return {ID:acc.Accertamenti}}))
    if(tipologiaSel !== 2){
      let accFiltered = acs?.filter(acc=> acc.Preassuntiva === 0)
      setSelectionModel(
        accFiltered.map((acc) => {
          return {
            ID: acc.Accertamenti,
            Scadenza: acc.Scadenza,
            Digiuno: acc.Digiuno,
            Preassuntiva: acc.Preassuntiva,
            }
        })
      )
    }else{
      setSelectionModel(
        acs.map((acc) => {
          return {
            ID: acc.Accertamenti,
            Scadenza: acc.Scadenza,
            Digiuno: acc.Digiuno,
            Preassuntiva: acc.Preassuntiva,
            }
        })
      )
    }
    setSelectionModelBk(
      acs.map((acc) => {
        return {
          ID: acc.Accertamenti,
          Scadenza: acc.Scadenza,
          Digiuno: acc.Digiuno,
          Preassuntiva: acc.Preassuntiva,
          }
      })
    )

    setAccertamenti(allAccertamenti);
    setLoading(false);
  };

  const handleClickOpen = async () => {
    await setRequired({
      medico: false,
      data: false,
      tipo: false,
      luogo: false,
    });
    loadVisitaLavoratore(0);
    setActiveStep(0);
    await setDataSel(new Date());
    setOpen(true);
    await setMedicoSel("");
    await setEsitoSel("");
    await setTipologiaSel("");
    await setNoteSel("");
    await setLuogoSel("");
    await setData(null);
  };
  const handleClose = () => {
    setOpen(false);
    setTipologiaSel("");
    setEmailLav([]);
    setEmailRef([]);
    setReportReferenti([]);
    setReportLavoratori([]);
  };
  const handleNext = async () => {
    //qua faccio le operazioni ogni volta
    let newSkipped = skipped;

    if (activeStep + 1 < props.lavoratori.length) {
      loadVisitaLavoratore(activeStep + 1);
    }
    await setRequired({
      medico: false,
      data: false,
      tipo: false,
      luogo: false,
    });
    //await setDataSel(new Date());
    await setNoteSel("");
    //await setLuogoSel("");
    //await setData(null);
    await setEsitoSel("");
    //await setTipologiaSel("");
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    /*if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
   
    */
    //qui devo caricare con la query i dati
    if (activeStep === props.lavoratori.length - 1) {
      props.loadLavoratori();
    }
  };

  const handleSave = async () => {
    let req = { ...required };
    let checkOk = true; //lo uso per controllare che tutto sia ok
    if (medicoSel === "" || medicoSel === null) {
      req.medico = true;
      checkOk = false;
    } else {
      req.medico = false;
    }
    if (tipologiaSel === "" || tipologiaSel === null) {
      req.tipo = true;
      checkOk = false;
    } else {
      req.tipo = false;
    }

    if (dataSel === "" || dataSel === null) {
      req.data = true;
      checkOk = false;
    } else {
      req.data = false;
    }

    if (luogoSel === "" || luogoSel === null) {
      req.luogo = true;
      checkOk = false;
    } else {
      req.luogo = false;
    }

    if (data === "" || data === null) {
      req.ora = true;
      checkOk = false;
    } else {
      req.ora = false;
    }

    setRequired(req);
    if (checkOk) {
      let missingEmailLav = [...emailLav];
      let missingEmailRef = [...emailRef];
      let addLavoratore = [...reportLavoratori];
      let referentiTot = [...reportReferenti];
      let thereIsDigiuno = selectionModel.filter((sel) => sel.Digiuno === 1);

      let lav = {
        visita: props.tipologiaVisita.filter((el) => el.ID === tipologiaSel)[0]
          .Descrizione,
        giorno: moment(dataSel).format("YYYY/MM/DD"),
        ora: moment(data).format("HH:mm"),
        luogo: luogoSel,
        scadenza: "?",
        sede: props.lavoratori[activeStep]?.SedeDesc,
        cf: props.lavoratori[activeStep]?.CF,
        mansione: props.lavoratori[activeStep]?.Descrizione,
        medico: props.medici.filter((el) => el.ID === medicoSel)[0].Descrizione,
        digiuno: thereIsDigiuno.length > 0 ? true : false,
        nome: props.lavoratori[activeStep]?.Nome,
        cognome: props.lavoratori[activeStep]?.Cognome,
        email: props.lavoratori[activeStep]?.Email,
        conter:
          props?.lavoratori[activeStep]?.Gruppo === "Conter"
            ? props?.lavoratori[activeStep]?.Mansione
            : 0,
      };
      //const referenti = reportReferenti?.map((el) => el?.email);
      if (
        props.lavoratori[activeStep]?.Email === null ||
        props.lavoratori[activeStep]?.Email === ""
      ) {
        missingEmailLav.push({
          nome: props.lavoratori[activeStep]?.Nome,
          cognome: props.lavoratori[activeStep]?.Cognome,
        });
      } else {
        addLavoratore.push({ ...lav });
      }
      if (
        props.lavoratori[activeStep]?.EmailReferente === null ||
        props.lavoratori[activeStep]?.EmailReferente === ""
      ) {
        missingEmailRef.push({
          referente: props.lavoratori[activeStep]?.Referente,
        });
      } else {
        if (referentiTot.length > 0) {
          var isExisting = false;
          const updatedRef = referentiTot?.map((el) => {
            let ref = { ...el };
            if (el.email === props.lavoratori[activeStep]?.EmailReferente) {
              isExisting = true;
              let updateArr = [...ref.lavoratori];
              updateArr.push({ ...lav });
              ref.lavoratori = [...updateArr];
              return ref;
            } else {
              isExisting = false;
              return referentiTot.push({
                referente: props.lavoratori[activeStep]?.Referente,
                email: props.lavoratori[activeStep]?.EmailReferente,
                giorno: moment(dataSel).format("YYYY/MM/DD"),
                luogo: luogoSel,
                medico: props.medici.filter((el) => el.ID === medicoSel)[0]
                  .Descrizione,
                email: props.lavoratori[activeStep]?.EmailReferente,
                lavoratori: [{ ...lav }],
                conter:
                  props?.lavoratori[activeStep]?.Gruppo === "Conter"
                    ? props?.lavoratori[activeStep]?.Mansione
                    : 0,
              });
            }
          });
          if (isExisting) {
            referentiTot = [...updatedRef];
          }
        } else {
          referentiTot.push({
            referente: props.lavoratori[activeStep]?.Referente,
            email: props.lavoratori[activeStep]?.EmailReferente,
            giorno: moment(dataSel).format("YYYY/MM/DD"),
            luogo: luogoSel,
            medico: props.medici.filter((el) => el.ID === medicoSel)[0]
              .Descrizione,
            lavoratori: [{ ...lav }],
            conter:
              props?.lavoratori[activeStep]?.Gruppo === "Conter"
                ? props?.lavoratori[activeStep]?.Mansione
                : 0,
          });
        }
      }
      setEmailLav(missingEmailLav);
      setEmailRef(missingEmailRef);
      setReportReferenti(referentiTot);
      setReportLavoratori(addLavoratore);
      let d = moment(dataSel).format("YYYY-MM-DD");
      d = d + " " + data.format("HH:mm:ss");
      try {
        let response = await unsplash.post(
          "visiteMediche",
          {
            accertamenti: selectionModel,
            medico: medicoSel,
            cliente: props.cliente,
            mansione: props.lavoratori[activeStep].Mansione,
            data: d,
            esito: esitoSel,
            luogo: luogoSel,
            note: noteSel,
            tipoVisita: tipologiaSel,
            lavoratore: props.lavoratori[activeStep].ID,
            distaccatoDa: props.lavoratori[activeStep].DistaccatoDa,
            vecchiaVisita: props.lavoratori[activeStep].IDUltimaVisita,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Visita Medica inserita con successo",
        });
        setOpenSnack(true);
        //setOpen(false);
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante l'inserimento",
        });
        setOpenSnack(true);
        //setOpen(false);
      }
    }
  };

  const handleReorderCheck = async () => {
    let IDSelected = selectionModel.map((s) => s.ID);

    let top = [];
    let bottom = [];
    for (let i = 0; i < accertamenti.length; i++) {
      if (IDSelected.indexOf(accertamenti[i].ID) >= 0) {
        top.push(accertamenti[i]);
      } else {
        bottom.push(accertamenti[i]);
      }
    }

    setAccertamenti(top.concat(bottom));
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
          <Button
            size="small"
            onClick={() => handleReorderCheck()}
            startIcon={<CheckBox />}
          >
            Riordina
          </Button>
        </Grid>
      </GridToolbarContainer>
    );
  }
  const handleToggleButtonReport = () => {
    setOpenButtonReport(!openButtonReport);
  };

  const handleCloseButtonReport = (event) => {
    if (
      anchorRefReport.current &&
      anchorRefReport.current.contains(event.target)
    ) {
      return;
    }
    setOpenButtonReport(false);
  };

  const handleMenuItemClickReport = (event, index) => {
    document.getElementById(`${index}`).click();
    //setSelectedIndex(index);
    setOpenButtonReport(false);
  };
  const handleCloseReport = () => {
    setOpenReport(false);
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleDataChange = (event) => {
    let d = dataSel;
    setData(event); //.format("HH:mm")

    //utilizzo la forma abbreviata della condizione if (Guard clauses)
    if (event)
      return (d =
        moment(d).format("YYYY/MM/DD") + " " + event.format("HH:mm:ss"));
  };
  const hadleReport = async (type) => {
    if (type === "dipendenti") {
      if (reportLavoratori.length !== 0) {
        try {
          await unsplash.post(
            "report",
            {
              type: "visiteMassive",
              dipendenti: reportLavoratori,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );
          setSnack({
            severity: "success",
            messaggio: "Report inviato con successo",
          });
          setOpenSnack(true);
          if (emailLav.length !== 0 || emailRef.length !== 0) {
            setMissingEmail(emailLav);
            setShowAccordions(true);
          }
        } catch (error) {
          setSnack({
            severity: "error",
            messaggio: "Errore durante l'invio del report",
          });
          setOpenSnack(true);
        }
      } else {
        setSnack({
          severity: "error",
          messaggio: "Controllare l'indirizzo email dei dipendenti",
        });
        setOpenSnack(true);
      }
    } else {
      if (reportReferenti.length !== 0) {
        try {
          await unsplash.post(
            "report",
            {
              type: "visiteMassive",
              referenti: reportReferenti,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );
          setSnack({
            severity: "success",
            messaggio: "Report inviato con successo",
          });
          setOpenSnack(true);
          if (emailLav.length !== 0 || emailRef.length !== 0) {
            setMissingEmail(emailRef);
            setShowAccordions(true);
          }
        } catch (error) {
          setSnack({
            severity: "error",
            messaggio: "Errore durante l'invio del report",
          });
          setOpenSnack(true);
        }
      } else {
        setSnack({
          severity: "error",
          messaggio: "Controllare l'indirizzo email del referente",
        });
        setOpenSnack(true);
      }
    }
  };
  return (
    <div>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Button
        variant="outlined"
        sx={{ display: "none" }}
        onClick={handleClickOpen}
        id="myDialog"
      >
        Open form dialog
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
        TransitionComponent={Transition}
      >
        <DialogTitle>Visita Massiva</DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep}>
              {props.lavoratori.map((lav, index) => {
                const stepProps = {};
                const labelProps = {};
                stepProps.completed = false;
                //if (isStepSkipped(index)) {

                //}
                return (
                  <Step
                    key={lav.ID}
                    {...stepProps}
                    sx={{
                      display:
                        props.lavoratori[activeStep]?.ID === lav.ID
                          ? "Block"
                          : "None",
                    }}
                  >
                    <StepLabel {...labelProps}>
                      {lav.Cognome + " " + lav.Nome + " - " + lav.Descrizione}{" "}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === props.lavoratori.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  Visite Massive completate!
                </Typography>

                {showAccordions && missingEmail.length > 0 ? (
                  <>
                    <Typography sx={{ mt: 2 }}>
                      Errori durante l'invio del report
                    </Typography>
                    <Accordion
                      sx={{ marginTop: 2 }}
                      expanded={expanded === "panel1"}
                      onChange={handleChange("panel1")}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon sx={{ color: "whitesmoke" }} />
                        }
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                      >
                        <Typography sx={{ width: "40%" }}>
                          Indirizzi email mancanti per:
                        </Typography>
                        <b style={{ width: "5%" }}>{missingEmail.length}</b>
                        {missingEmail.length !== 0 ? (
                          <Circle
                            sx={{
                              color: "#FF2768",
                            }}
                          >
                            {" "}
                          </Circle>
                        ) : (
                          <></>
                        )}
                      </AccordionSummary>
                      <AccordionDetails>
                        {missingEmail?.map((el, index) => (
                          <div key={index}>
                            -
                            <b>
                              {el?.referente ? el?.referente : el?.nome}{" "}
                              {el?.cognome ? el.cognome : ""}
                            </b>
                          </div>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  </>
                ) : (
                  <></>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {required.tipo ||
                      required.data ||
                      required.medico ||
                      required.ora ||
                      required.luogo ? (
                        <p style={{ color: "#FF2768" }}>
                          controlla i campi obbligatori!
                        </p>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Medico
                        </InputLabel>
                        <Select
                          error={required.medico}
                          helperText={
                            required.medico ? "Medico obbligatorio" : ""
                          }
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={medicoSel}
                          label="Medico"
                          onChange={(e) => setMedicoSel(e.target.value)}
                        >
                          {props.medici?.map((medico) => (
                            <MenuItem key={medico.ID} value={medico.ID}>
                              {medico.Descrizione}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <LocalizationProvider dateAdapter={DateAdapter}>
                        <DatePicker
                          slotProps={{ textField: { fullWidth: true } }}
                          ampmInClock={false}
                          renderInput={(props) => (
                            <TextField
                              fullWidth
                              {...props}
                              error={required.data}
                            />
                          )}
                          label="Data"
                          value={moment(dataSel)}
                          onChange={(newValue) => {
                            setDataSel(newValue);
                            if (newValue) {
                              let allAccertamenti = accertamenti;
                              let arrayOnlyID = allAccertamenti.map(
                                (acc) => acc.ID
                              );

                              let idProtocolli = selectedACS.map(
                                (s) => s.Accertamenti
                              ); // prendo gli id nel protocollo
                              for (let i = 0; i < selectionModel.length; i++) {
                                let presente = idProtocolli.indexOf(
                                  selectionModel[i].ID
                                );

                                let index = arrayOnlyID.indexOf(
                                  selectionModel[i].ID
                                );

                                if (presente >= 0) {
                                  allAccertamenti[index].Scadenza = moment(
                                    newValue
                                  ).add(selectedACS[presente].Cadenze, "M");
                                  selectionModel[i].Scadenza = moment(
                                    newValue
                                  ).add(selectedACS[presente].Cadenze, "M");
                                } else {
                                  allAccertamenti[index].Scadenza =
                                    moment(newValue);
                                  selectionModel[i].Scadenza = moment(newValue);
                                }
                              }

                              setAccertamenti(allAccertamenti);
                            }
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={3}>
                      <LocalizationProvider dateAdapter={DateAdapter}>
                        <TimePicker
                          slotProps={{ textField: { fullWidth: true } }}
                          ampmInClock={false}
                          renderInput={(props) => (
                            <TextField
                              fullWidth
                              {...props}
                              error={required.ora}
                            />
                          )}
                          label="Ora"
                          value={moment(data)}
                          onChange={handleDataChange}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Tipologia Visita
                        </InputLabel>
                        <Select
                          error={required.tipo}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={tipologiaSel}
                          label="Tipologia Visita"
                          helperText={
                            required.data ? "Tipologia obbligatoria" : ""
                          }
                          onChange={(e) => {
                            setTipologiaSel(e.target.value);
                            //se è preassuntiva
                            if(e.target.value !== 2){
                              const idAccertamentiPreassuntiva= protocolliLav?.filter(prot=> prot.Preassuntiva===1)?.map(el=> el?.Accertamenti);
                              setSelectionModel(selectionModelBk?.filter(acc=> !idAccertamentiPreassuntiva.includes(acc.ID)));
                            }else{
                              setSelectionModel(selectionModelBk);
                            }
                          }}
                        >
                          {props.tipologiaVisita?.map((tipologia) => (
                            <MenuItem key={tipologia.ID} value={tipologia.ID}>
                              {tipologia.Descrizione}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        error={required.luogo}
                        helperText={required.luogo ? "Luogo obbligatorio" : ""}
                        label="Luogo"
                        multiline
                        value={luogoSel}
                        onChange={(e) => setLuogoSel(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ height: 400, width: "100%" }}>
                        <DataGridPro
                          components={{
                            Toolbar: CustomToolbar,
                            Footer: CustomFooterPersonalized,
                          }}
                          slots={{
                            loadingOverlay: LinearProgress,
                          }}
                          loading={loading}
                          disableSelectionOnClick
                          checkboxSelection
                          disableColumnMenu={true}
                          localeText={
                            itIT.components.MuiDataGrid.defaultProps.localeText
                          }
                          rows={accertamenti}
                          getRowId={(accertamenti) => accertamenti.ID}
                          columns={columns}
                          selectionModel={selectionModel?.map((sel) => sel.ID)}
                          onSelectionModelChange={(ids) => {
                            const selectedIDs = new Set(ids);
                            const selectedRowData = accertamenti.filter((row) =>
                              selectedIDs.has(row.ID)
                            );
                            //qua fai la magia con le date

                            let allAccertamenti = accertamenti;
                            let arrayOnlyID = allAccertamenti.map(
                              (acc) => acc.ID
                            );

                            let idProtocolli = selectedACS.map(
                              (s) => s.Accertamenti
                            ); // prendo gli id nel protocollo
                            for (let i = 0; i < selectedRowData.length; i++) {
                              let presente = idProtocolli.indexOf(
                                selectedRowData[i].ID
                              );

                              let index = arrayOnlyID.indexOf(
                                selectedRowData[i].ID
                              );

                              if (presente >= 0) {
                                allAccertamenti[index].Scadenza = moment(
                                  dataSel
                                ).add(selectedACS[presente].Cadenze, "M");
                              } else {
                                allAccertamenti[index].Scadenza =
                                  moment(dataSel);
                              }
                            }
                            setAccertamenti(allAccertamenti);

                            setSelectionModel(selectedRowData);
                            /* setSelectionModelBk(selectedRowData); */
                          }}
                          onCellEditCommit={(params, event) => {
                            let row = { ...params.row };
                            if (params.field === "Scadenza") {
                              row[params.field] = moment(params.value).format(
                                "YYYY-MM-DD"
                              );
                            } else {
                              row[params.field] = params.value;
                            }

                            let selAcc = [...selectionModel];
                            let findIndex = selAcc.findIndex(
                              (acc) => acc.ID == params.row.ID
                            ); //trovo la posizione
                            if (findIndex !== -1) {
                              selAcc.splice(findIndex, 1);
                            }
                            selAcc.push(row);
                            setSelectionModel(selAcc);
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Esito
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={esitoSel}
                          label="Esito"
                          onChange={(e) => setEsitoSel(e.target.value)}

                          //onChange={handleChange}
                        >
                          {props.esiti?.map((esito) => (
                            <MenuItem key={esito.ID} value={esito.ID}>
                              {esito.Descrizione}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Note"
                        multiline
                        value={noteSel}
                        onChange={(e) => setNoteSel(e.target.value)}
                        sx={{ marginTop: "20px" }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </React.Fragment>
            )}
          </Box>
        </DialogContent>
        <DialogActions
          xs={12}
          item
          sx={{
            display: "flex",
            mt: 2,
            justifyContent: "space-between",
            px: 2,
          }}
          fullWidth
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={handleClose}
            color="error"
          >
            Esci
          </Button>

          {/* <Button onClick={handleNext}>
              {activeStep === props.lavoratori.length - 1 ? "Fine" : "Avanti"}
            </Button> */}
          {activeStep === props.lavoratori.length - 1 ? (
            <Button
              variant="contained"
              className="ButtonMiller"
              startIcon={<DoneAll />}
              onClick={handleNext}
              color="success"
            >
              Fine
            </Button>
          ) : activeStep === props.lavoratori.length ? (
            <>
              {/*<ButtonGroup
                variant="contained"
                className="ButtonMiller"
                ref={anchorRefReport}
                aria-label="split button"
              >
                <Button
                  size="small"
                  startIcon={<Email />}
                  variant="contained"
                  className="ButtonMiller"
                  onClick={handleToggleButtonReport}
                >
                  REPORT
                </Button> 
                <Button
                  variant="contained"
                  className="ButtonMiller"
                  size="small"
                  aria-controls={
                    openButtonReport ? "split-button-menu" : undefined
                  }
                  aria-expanded={openButtonReport ? "true" : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={handleToggleButtonReport}
                >
                  {openButtonReport ? <ArrowDropUp /> : <ArrowDropDownIcon />}
                </Button>
              </ButtonGroup>*/}
              <Popper
                sx={{
                  zIndex: 100,
                }}
                open={openButtonReport}
                anchorEl={anchorRefReport.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow {...TransitionProps}>
                    <Paper sx={{ ml: "30px" }}>
                      <ClickAwayListener onClickAway={handleCloseButtonReport}>
                        <MenuList id="split-button-menu" autoFocusItem>
                          {optionsReport.map((option, index) => (
                            <MenuItem
                              key={option}
                              selected={option.ind === selectedIndexReport}
                              onClick={(event) => {
                                handleMenuItemClickReport(event, option.ind);
                              }}
                            >
                              <span style={{ color: "#11526f" }}>
                                {option.icon} {option.text}
                              </span>
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
              <div style={{ display: "none" }}>
                <Button
                  id="4"
                  variant="contained"
                  className="ButtonMiller"
                  onClick={() => {
                    hadleReport("dipendenti");
                  }}
                >
                  Dipendente
                </Button>

                <Button
                  id="5"
                  variant="contained"
                  className="ButtonMiller"
                  sx={{ display: props.operation === "Edit" ? "" : "None" }}
                  onClick={() => {
                    hadleReport("referenti");
                  }}
                >
                  Referenti
                </Button>
              </div>
            </>
          ) : (
            <Button
              variant="contained"
              className="ButtonMiller"
              startIcon={<ArrowForward />}
              onClick={handleNext}
              color="success"
            >
              Avanti
            </Button>
          )}

          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={handleSave}
            color="success"
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

/*
TOGLO GLI ACCERTAMENTI E LI RICREO


*/

const ModalLavoratori = (props) => {
  const [dettaglioMedicina, setDettaglioMedicina] = useState([]);
  const [mansioniSicurezza, setMansioniSicurezza] = useState([]);
  const [open, setOpen] = useState(false);
  const [lavoratore, setLavoratore] = useState({
    Nome: "",
    Cognome: "",
    CF: "",
    Mansione: 0,
    MansioneSicurezza: 0,
    DataDiNascita: null,
    LuogoDiNascita: "",
    DataAssunzione: null,
    DataTermine: null,
    Telefono: "",
    Email: "",
    Sesso: "",
    MalattiaProfessionale: 0,
    StoricoGPCSC: 0,
    DescrizioneMalattiaProfessionale: "",
    Distaccato: "",
    GPCSC: "",
    GPCSCspesevive: "",
    DtaCostiGestione: null,
    Somministrato: 0,
  });
  const [validation, setValidation] = useState({
    DataAssunzione: false,
    Nome: false,
    Cognome: false,
  });

  const handleClickOpen = async () => {
    /* let responseDettaglioMedicina = await unsplash.get("dettaglioMedicina", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: props.lavoratore.Sede },
    }); 

    setDettaglioMedicina(responseDettaglioMedicina.data.data);*/
    setValidation({
      DataAssunzione: false,
      Nome: false,
      Cognome: false,
      Mansione: false,
    });
    setLavoratore({
      Nome: "",
      Cognome: "",
      CF: "",
      DataAssunzione: null,
      Mansione: 0,
      MansioneSicurezza: 0,
      DataDiNascita: null,
      LuogoDiNascita: "",
      DataAssunzione: null,
      DataTermine: null,
      Telefono: "",
      Email: "",
      Sesso: "",
      MalattiaProfessionale: 0,
      StoricoGPCSC: 0,
      DescrizioneMalattiaProfessionale: "",
      Distaccato: "",
      GPCSC: "",
      GPCSCspesevive: "",
      DtaCostiGestione: null,
      Somministrato: 0,
      Alert: 1,
    });
    setMansioniSicurezza([]);
    setDettaglioMedicina([]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (e) => {
    let l = { ...lavoratore };
    l[e.target.name] = e.target.value;
    setLavoratore(l);
  };

  const handleSave = () => {
    let v = { ...validation };
    let check = false;
    if (lavoratore.Nome === "" || lavoratore.Nome === null) {
      v.Nome = true;
      check = true;
    } else {
      v.Nome = false;
    }

    if (lavoratore.Cognome === "" || lavoratore.Cognome === null) {
      v.Cognome = true;
      check = true;
    } else {
      v.Cognome = false;
    }

    if (
      lavoratore.DataAssunzione === "" ||
      lavoratore.DataAssunzione === null
    ) {
      v.DataAssunzione = true;
      check = true;
    } else {
      v.DataAssunzione = false;
    }

    setValidation(v);
    if (check) {
      return;
    }

    props.saveFunction(lavoratore);
    setOpen(false);
  };
  const loadDettaglioMedicina = async (sede) => {
    let responseDettaglioMedicina = await unsplash.get("dettaglioMedicina", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: sede },
    });

    setDettaglioMedicina(responseDettaglioMedicina.data.data);
  };

  const loadMansioneSicurezza = async (idSede) => {
    let responseMansioniSicurezza = await unsplash.get("mansioniSicurezza", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: props.cliente, sede: idSede },
    });
    setMansioniSicurezza(responseMansioniSicurezza.data.data);
  };

  return (
    <>
      <GlobalSnackbar
        severity={props.snack.severity}
        messaggio={props.snack.messaggio}
        openSnack={props.openSnack}
        handleCloseSnack={props.handleCloseSnack}
      />
      <Button
        startIcon={<Add />}
        onClick={handleClickOpen}
        id={props.id}
        className="ButtonMiller"
      >
        Aggiungi
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth="lg"
        maxWidth="lg"
        TransitionComponent={Transition}
      >
        <DialogTitle sx={{ mx: "auto" }}>{props.operazione}</DialogTitle>
        <DialogContent>
          <Grid container xs={12} spacing={1} sx={{ mt: 1 }}>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-multiple-name-label">Sede</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  onChange={(e) => {
                    let l = { ...lavoratore };
                    l.Sede = e.target.value;
                    setLavoratore(l);
                    loadDettaglioMedicina(e.target.value);
                    loadMansioneSicurezza(e.target.value);
                  }}
                  input={<OutlinedInput label="Sede" />}
                >
                  {props.sedi?.map((sede) => (
                    <MenuItem key={sede.ID} value={sede.ID}>
                      {sede.Descrizione}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-multiple-name-label">Mansione</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  onChange={(e) => {
                    let l = { ...lavoratore };
                    l.Mansione = e.target.value;
                    setLavoratore(l);
                  }}
                  input={<OutlinedInput label="Mansione" />}
                >
                  {props.mansioni?.map((mansione) => (
                    <MenuItem key={mansione.ID} value={mansione.ID}>
                      {mansione.Descrizione}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-multiple-name-label">
                  Mansione Sicurezza
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  onChange={(e) => {
                    let l = { ...lavoratore };
                    l.MansioneSicurezza = e.target.value;
                    setLavoratore(l);
                  }}
                  input={<OutlinedInput label="Mansione Sicurezza" />}
                >
                  {mansioniSicurezza?.map((mansione) => (
                    <MenuItem key={mansione.ID} value={mansione.ID}>
                      {mansione.Descrizione}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-multiple-name-label">
                  Dettaglio Medicina
                </InputLabel>
                <Select
                  value={lavoratore.DettaglioMedicina}
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  onChange={(e) => {
                    let l = { ...lavoratore };
                    l.DettaglioMedicina = e.target.value;
                    setLavoratore(l);
                  }}
                  input={<OutlinedInput label="Dettaglio Medicina" />}
                >
                  {dettaglioMedicina?.map((d) => {
                    let lastValue =
                      props.gruppo === "Conter" ? d.Referente : d.NomeMedico;
                    return (
                      <MenuItem key={d.ID} value={d.ID}>
                        {d.Blocco + " - " + d.Categoria + " - " + lastValue}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  slotProps={{ textField: { fullWidth: true } }}
                  ampmInClock={false}
                  renderInput={(props) => (
                    <TextField
                      fullWidth
                      required
                      {...props}
                      error={validation.DataAssunzione}
                      helperText={
                        validation.DataAssunzione
                          ? "Data assunzione obbligatoria"
                          : ""
                      }
                    />
                  )}
                  label="Data Assunzione"
                  value={moment(lavoratore.DataAssunzione)}
                  onChange={(newValue) => {
                    let l = { ...lavoratore };
                    l.DataAssunzione = newValue
                      ? newValue.format("YYYY-MM-DD")
                      : null;
                    setLavoratore(l);
                    //setDal(newValue?newValue.format("YYYY-MM-DD"):null);
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} md={4}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  slotProps={{ textField: { fullWidth: true } }}
                  ampmInClock={false}
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  label="Data Termine"
                  value={moment(lavoratore.DataTermine)}
                  onChange={(newValue) => {
                    let l = { ...lavoratore };
                    l.DataTermine = newValue
                      ? newValue.format("YYYY-MM-DD")
                      : null;
                    setLavoratore(l);
                    //setDal(newValue?newValue.format("YYYY-MM-DD"):null);
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <Divider
                sx={{
                  color: "rgba(58, 53, 65, 0.6)",
                  marginTop: "5px",
                  marginBottom: "15px",
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                error={validation.Nome}
                required
                value={lavoratore.Nome}
                name="Nome"
                label="Nome"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChange}
                helperText={validation.Nome ? "Nome obbligatorio" : ""}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                error={validation.Cognome}
                value={lavoratore.Cognome}
                name="Cognome"
                label="Cognome"
                type="text"
                required
                fullWidth
                variant="outlined"
                onChange={handleChange}
                helperText={validation.Cognome ? "Cognome obbligatorio" : ""}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <InputLabel id="Sesso">Sesso</InputLabel>
                <Select
                  label="Sesso"
                  labelId="Sesso"
                  id="demo-simple-select"
                  value={lavoratore.Sesso}
                  onChange={(e) => {
                    let l = { ...lavoratore };
                    l.Sesso = e.target.value;
                    setLavoratore(l);
                  }}
                >
                  <MenuItem value={1}>Maschio</MenuItem>
                  <MenuItem value={2}>Femmina</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                value={lavoratore.CF}
                name="CF"
                label="Codice Fiscale"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChange}
                inputProps={{ maxLength: 16 }}
              />
            </Grid>

            <Grid item xs={6} md={4}>
              <TextField
                value={lavoratore.Email}
                name="Email"
                label="Email"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  slotProps={{ textField: { fullWidth: true } }}
                  ampmInClock={false}
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  label="Data Di Nascita"
                  value={moment(lavoratore.DataDiNascita)}
                  onChange={(newValue) => {
                    let l = { ...lavoratore };
                    l.DataDiNascita = newValue
                      ? newValue.format("YYYY-MM-DD")
                      : null;
                    setLavoratore(l);
                    //setDal(newValue?newValue.format("YYYY-MM-DD"):null);
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                value={lavoratore.Telefono}
                name="Telefono"
                label="Telefono"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={6} md={4}>
              <TextField
                value={lavoratore.LuogoDiNascita}
                name="LuogoDiNascita"
                label="Luogo di Nascita"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                value={lavoratore.Distaccato}
                name="Distaccato"
                label="Distaccato"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  slotProps={{ textField: { fullWidth: true } }}
                  ampmInClock={false}
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  label="Data Fatturazione Costi Gestione"
                  value={moment(lavoratore.DtaCostiGestione)}
                  onChange={(newValue) => {
                    let l = { ...lavoratore };
                    l.DtaCostiGestione = newValue
                      ? newValue.format("YYYY-MM-DD")
                      : null;
                    setLavoratore(l);
                    //setDal(newValue?newValue.format("YYYY-MM-DD"):null);
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={6} md={4}>
              <TextField
                value={lavoratore.GPCSCspesevive}
                name="GPCSCspesevive"
                label="Cartella Sanitaria Cessati (Spese Vive)"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={6} md={4}>
              <TextField
                value={lavoratore.DescrizioneMalattiaProfessionale}
                name="DescrizioneMalattiaProfessionale"
                label="Descrizione Malattia Professionale"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                value={lavoratore.GPCSC}
                name="GPCSC"
                label="Cartella Sanitaria Cessati"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChange}
                helperText={validation ? "Blocco obbligatorio" : ""}
              />
            </Grid>
            <Grid item xs={6} md={8}>
              <TextField
                value={lavoratore.Note}
                name="Note"
                //rows={3}
                label="Note"
                multiline
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ mt: 1, display: "flex", justifyContent: "space-between" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    name="StoricoGPCSC"
                    checked={lavoratore.StoricoGPCSC === 1 ? true : false}
                    onChange={(e) => {
                      let l = { ...lavoratore };
                      l.StoricoGPCSC = e.target.checked ? 1 : 0;
                      setLavoratore(l);
                    }}
                  />
                }
                label="Storico Gestione Cartella Sanitaria Cessati"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    name="MalattiaProfessionale"
                    checked={
                      lavoratore.MalattiaProfessionale === 1 ? true : false
                    }
                    onChange={(e) => {
                      let l = { ...lavoratore };
                      l.MalattiaProfessionale = e.target.checked ? 1 : 0;
                      setLavoratore(l);
                    }}
                  />
                }
                label="Malattia Professionale"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="Somministrato"
                    checked={lavoratore.Somministrato === 1 ? true : false}
                    onChange={(e) => {
                      let l = { ...lavoratore };
                      l.Somministrato = e.target.checked ? 1 : 0;
                      setLavoratore(l);
                    }}
                  />
                }
                label="Somministrato"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="Alert"
                    checked={lavoratore.Alert === 1 ? true : false}
                    onChange={(e) => {
                      let l = { ...lavoratore };
                      l.Alert = e.target.checked ? 1 : 0;
                      setLavoratore(l);
                    }}
                  />
                }
                label="Includi nell'Alert"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="EscludiKPI"
                    checked={lavoratore?.EscludiKPI === 1 ? true : false}
                    onChange={(e) => {
                      let l = { ...lavoratore };
                      l.EscludiKPI = e.target.checked ? 1 : 0;
                      setLavoratore(l);
                    }}
                  />
                }
                label="Escludi da KPI"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            xs={12}
            item
            sx={{
              display: "flex",
              mt: 2,
              justifyContent: "space-between",
              px: 2,
            }}
            fullWidth
          >
            <Button
              onClick={handleClose}
              variant="contained"
              className="ButtonMiller"
              startIcon={<Close />}
            >
              Chiudi
            </Button>
            <Button
              onClick={handleSave}
              variant="contained"
              className="ButtonMiller"
              startIcon={<Check />}
            >
              Salva
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ageFromCf = (cf) => {
  let mesi = {
    A: 0,
    B: 1,
    C: 2,
    D: 3,
    E: 4,
    H: 5,
    L: 6,
    M: 7,
    P: 8,
    R: 9,
    S: 10,
    T: 11,
  };

  let annoNascita = cf.substring(6, 8);
  let mese = mesi[cf.charAt(8)];
  let giornoNascita =
    parseInt(cf.substring(9, 11)) > 40
      ? parseInt(cf.substring(9, 11)) - 40
      : parseInt(cf.substring(9, 11));
  let yearTwoDigits = parseInt(new Date().getFullYear().toString().substr(-2));

  let year =
    yearTwoDigits > annoNascita
      ? 2000 + parseInt(annoNascita)
      : 1900 + parseInt(annoNascita);

  return calcAge(new Date(year, mese, giornoNascita));
  function calcAge(birthday) {
    birthday = new Date(birthday);
    let today = new Date();

    var years = today.getFullYear() - birthday.getFullYear();

    if (
      today.getMonth() < birthday.getMonth() ||
      (today.getMonth() == birthday.getMonth() &&
        today.getDate() < birthday.getDate())
    ) {
      years--;
    }

    return years;
  }
};

const ModalCredenziali = (props) => {
  const [severity, setSeverity] = useState("success");
  const [text, setText] = useState("Ok");
  const [open, setOpen] = useState(false);

  const [openAlert, setOpenAlert] = useState(false);
  const handleClickOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendMail = async () => {
    try {
      await unsplash.post(
        "portaleLavoratori",
        { operation: "generateMail", lavoratori: props.lavoratoriSelezionati },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setText("Credenziali inviate con successo");
      setSeverity("success");
      props.loadLavoratori();
      setOpen(false);
    } catch {
      setText("Errore durante l'invio delle credenziali");
      setSeverity("error");
    }

    setOpenAlert(true);
  };

  return (
    <>
      {createPortal(
        <Snackbar
          open={openAlert}
          autoHideDuration={6000}
          style={{ marginTop: "50px" }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={() => setOpenAlert(false)}
        >
          <Alert severity={severity} sx={{ width: "100%" }} variant="filled">
            {text}
          </Alert>
        </Snackbar>,
        document.getElementById("portal")
      )}
      <Button
        startIcon={<Add />}
        onClick={handleClickOpen}
        id="sendCredentialButton"
        style={{ display: "None" }}
        className="ButtonMiller"
      >
        Aggiungi
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth="sm"
        maxWidth="sm"
        TransitionComponent={Transition}
      >
        <DialogTitle sx={{ mx: "auto" }}>
          Invio credenziali area personale
        </DialogTitle>
        <DialogContent>
          Attenzione sei sicuro di voler inviare le credenziali agli utenti
          selezionati?
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between", mx: 2 }}
        >
          <Button
            onClick={handleClose}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
          >
            Chiudi
          </Button>
          <Button
            onClick={handleSendMail}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
          >
            Invia
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
