import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import unsplash from "../api/unsplash";
import { useNavigate } from "react-router-dom";

import {
  TextField,
  Box,
  Typography,
  Tab,
  Tabs,
  Grid,
  Checkbox,
  FormControlLabel,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Popover,
} from "@mui/material";

import Lavoratori from "./Lavoratori";
import {
  AccountCircle,
  AttachFile,
  Work,
  Check,
  FormatListNumbered,
  HealthAndSafety,
  AccountTree,
  School,
  TimeToLeave,
  DocumentScanner
} from "@mui/icons-material";
import SecurityIcon from "@mui/icons-material/Security";
import { useLocation } from "react-router";
import Allegati from "./Allegati";
import GlobalSnackbar from "./Snackbar";
import AdempimentiCliente from "./DettaglioCliente/AdempimentiCliente";
import MansioniSicurezza from "./MansioniSicurezza";
import DettaglioMedicina from "./DettaglioMedicina";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import Organigramma from "./Organigramma";
import Reparti from "./Reparti";
import ExploreIcon from "@mui/icons-material/Explore";
import CorsiSede from "./CorsiSede";
import MediciKm from "./MediciKm";
import Dvr from "./DVR";

mapboxgl.accessToken =
  "pk.eyJ1Ijoic2FmZXR5bWlsbGVyIiwiYSI6ImNsZjJjeXJ0czBoYTgzcXM5dHF4cHF5ZTIifQ._S6DjunEpFelVN8zfqPRpQ";

mapboxgl.accessToken =
  "pk.eyJ1Ijoic2FmZXR5bWlsbGVyIiwiYSI6ImNsZjJjeXJ0czBoYTgzcXM5dHF4cHF5ZTIifQ._S6DjunEpFelVN8zfqPRpQ";

export const SediDetail = (props) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const marker = useRef(null);
  const [zoom, setZoom] = useState(12);

  const location = useLocation();
  const [sede, setSede] = useState();
  const [oldSede, setOldSede] = useState();
  const [value, setValue] = React.useState(0);
  const [lavoratori, setLavoratori] = useState([]);
  const [cliente, setCliente] = useState([]);
  const [utenti, setUtenti] = useState([]);
  const [utentiSicurezza, setUtentiSicurezza] = useState([]);
  const [utentiFormazione, setUtentiFormazione] = useState([]);
  const [memoVisibili, setMemoVisibili] = useState([]);

  const [openSnack, setOpenSnack] = useState(false);

  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleInputChange = (e) => {
    let copiaCliente = { ...sede };
    copiaCliente[e.target.name] = e.target.value;
    setSede(copiaCliente);

    if (copiaCliente.Lon && copiaCliente.Lat) {
      if (marker.current) {
        marker.current.remove();
      } //SE IL MARKER C'è Già lo elimino

      marker.current = new mapboxgl.Marker()
        .setLngLat({ lng: copiaCliente.Lon, lat: copiaCliente.Lat })
        .addTo(map.current); //Creo IL MARKER
      map.current.flyTo({
        center: { lng: copiaCliente.Lon, lat: copiaCliente.Lat }, //centro nelle nuove cordinate
      });
    }
  };

  //save data
  const saveData = async () => {
    try {
      let responseModifica = await unsplash.put(
        "sedi",
        { cliente: location.state.idC, sede: sede },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      await unsplash
        .post(
          "storico",
          {
            tabella: "Sedi",
            key: sede.ID,
            utente: sessionStorage.getItem("username"),
            after: sede,
            before: oldSede,
            operazione: "Modifica",
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        )
        .then((res) => {});
      setSnack({
        severity: "success",
        messaggio: "Sede modificata con successo",
      });
      setOpenSnack(true);
      setOldSede(sede);
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante il salvataggio",
      });
      setOpenSnack(true);
    }
  };

  const reloadLavoratori = async () => {
    try {
      let responseAziendali = await unsplash.get("lavoratori", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: {
          clienteId: location.state.idC,
          sedeId: location.state.sedeId,
          dettaglioSedi: true,
        },
      });

      setLavoratori(responseAziendali);
      return responseAziendali.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // è un loop qui carico i dati
    (async () => {
      try {
        let responseAziendali = unsplash.get("lavoratori", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: {
            clienteId: location.state.idC,
            sedeId: location.state.sedeId,
            dettaglioSedi: true,
          },
        });

        setLavoratori(responseAziendali);

        let responseUtenti = await unsplash.get("utenti", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        const utentiMedicina = responseUtenti.data.data.filter(
          (elm) => elm.tipoUtente === "Medicina"
        );
        const utentiSicurezza = responseUtenti.data.data.filter(
          (elm) => elm.tipoUtente === "Sicurezza" && elm.username !== "federico"
        );
        const utentiFormazione = responseUtenti.data.data.filter(
          (elm) => elm.tipoUtente === "Formazione"
        );
        setUtenti(utentiMedicina);
        setSede(location.state.sede);
        setOldSede(location.state.sede);

        let response = await unsplash.post(
          "clientDetail",
          { auth: "a5d42c3ed7977afa2d8e922569e7940b", id: location.state.idC },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        setCliente(response.data.data[0]);

        //CARICHIAMO LA MAPPA
        map.current = new mapboxgl.Map({
          container: mapContainer.current,
          style: "mapbox://styles/mapbox/streets-v12",
          center: [location.state.sede.Lon, location.state.sede.Lat],
          zoom: zoom,
        });

        marker.current = new mapboxgl.Marker()
          .setLngLat({
            lng: location.state.sede.Lon,
            lat: location.state.sede.Lat,
          })
          .addTo(map.current);
      } catch (error) {
        console.error(error.message);
      }
    })();
  }, []);
  useEffect(() => {
    if (value === 0) {
      (async () => {
        try {
          //se ho i dati direttamente dallo state li carico
          if (location.state.sede.Lon && location.state.sede.Lat) {
            // Load the map
            map.current = new mapboxgl.Map({
              container: mapContainer.current,
              style: "mapbox://styles/mapbox/streets-v12",
              center: [location.state.sede.Lon, location.state.sede.Lat],
              zoom: zoom,
            });
            
            marker.current = new mapboxgl.Marker()
              .setLngLat({
                lng: location.state.sede.Lon,
                lat: location.state.sede.Lat,
              })
              .addTo(map.current);
              //se ho appena salvato i valori della lon e lat e successivamente cambio tab, carico i valori direttamente dall'oggetto sede
          } else if (sede?.Lon && sede?.Lat) {
            // Load the map
            map.current = new mapboxgl.Map({
              container: mapContainer.current,
              style: "mapbox://styles/mapbox/streets-v12",
              center: [sede?.Lon, sede?.Lat],
              zoom: zoom,
            });

            marker.current = new mapboxgl.Marker()
              .setLngLat({
                lng: sede?.Lon,
                lat: sede?.Lat,
              })
              .addTo(map.current);
          } else {
            // Load the map
            map.current = new mapboxgl.Map({
              container: mapContainer.current,
              style: "mapbox://styles/mapbox/streets-v12",
              center: [location.state.sede.Lon, location.state.sede.Lat],
              zoom: zoom,
            });

            marker.current = new mapboxgl.Marker()
              .setLngLat({
                lng: location.state.sede.Lon,
                lat: location.state.sede.Lat,
              })
              .addTo(map.current);
          }
        } catch (error) {
          console.error(error.message);
        }
      })();
    }
  }, [value]);

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Grid item xs={12}>
        <Card sx={{ position: "relative", my: "auto" }}>
          <CardHeader
            sx={{ textAlign: "center" }}
            title={
              location.state.RagioneSociale + " - " + location.state.bloccoSede
            }
            titleTypographyProps={{ variant: "h4" }}
          />
        </Card>

        <Card sx={{ marginTop: "10px" }}>
          <CardContent sx={{ p: 0 }}>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  variant="scrollable"
                >
                  <Tab
                    icon={<Work />}
                    iconPosition="start"
                    label="Dati Sede"
                    {...a11yProps(0)}
                  />
                  <Tab
                    icon={<HealthAndSafety />}
                    iconPosition="start"
                    label="Dettaglio Medicina"
                    {...a11yProps(1)}
                  />
                  <Tab
                    icon={<AccountCircle />}
                    iconPosition="start"
                    label="Lavoratori"
                    {...a11yProps(1)}
                  />
                  <Tab
                    icon={<AttachFile />}
                    iconPosition="start"
                    label="Allegati"
                    {...a11yProps(2)}
                  />

                  <Tab
                    icon={<SecurityIcon />}
                    iconPosition="start"
                    label="Mansioni Sicurezza"
                    {...a11yProps(3)}
                  />

                  {/* <Tab
                    icon={<LocationOn />}
                    iconPosition="start"
                    label="Sopralluoghi"
                    {...a11yProps(4)}
                  /> */}
                  <Tab
                    icon={<FormatListNumbered />}
                    iconPosition="start"
                    label="Adempimenti"
                    {...a11yProps(5)}
                  />
                  <Tab
                    icon={<School />}
                    iconPosition="start"
                    label="Piano Corsi"
                    {...a11yProps(5)}
                  />
                  <Tab
                    icon={<AccountTree />}
                    iconPosition="start"
                    label="Organigramma"
                    {...a11yProps(5)}
                  />
                  <Tab
                    icon={<ExploreIcon />}
                    iconPosition="start"
                    label="Reparti"
                    {...a11yProps(5)}
                  />
                  <Tab
                    icon={<DocumentScanner />}
                    iconPosition="start"
                    label="DVR"
                    {...a11yProps(5)}
                  />
                  {cliente.Gruppo === "Gavio" &&<Tab
                    icon={<TimeToLeave />}
                    iconPosition="start"
                    label="KM Medici"
                    {...a11yProps(5)}
                  />}
                </Tabs>
              </Box>

              <TabPanel value={value} index={0}>
                {sede === undefined ? (
                  <div>
                    <center>
                      <CircularProgress color="primary" />
                    </center>
                  </div>
                ) : (
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4} className="relative">
                      {!location.state.sede.Lon &&
                        (!sede?.Lon || !sede?.Lat) && (
                          <div className="absolute text-white text-lg font-thin text-center top-[60%] left-[50%] -translate-x-[50%] -translate-y-[60%] z-50 ">
                            Inserire Longitudine e Latitudine
                          </div>
                        )}
                      <div ref={mapContainer} className="map-container" />
                    </Grid>

                    <Grid item xs={12} md={8}>
                      <Grid container>
                        <Grid
                          container
                          xs={12}
                          sx={{ flexWrap: { xs: "wrap", md: "nowrap" } }}
                          columnGap={2}
                        >
                          <Grid item xs={12} md={6}>
                            <TextField
                              value={sede.Descrizione}
                              margin="dense"
                              name="Descrizione"
                              label="Sede"
                              type="text"
                              fullWidth
                              variant="outlined"
                              onChange={handleInputChange}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              value={sede.Ubicazione}
                              margin="dense"
                              name="Ubicazione"
                              label="Città"
                              type="text"
                              fullWidth
                              variant="outlined"
                              onChange={handleInputChange}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          xs={12}
                          sx={{ flexWrap: { xs: "wrap", md: "nowrap" } }}
                          columnGap={2}
                        >
                          <Grid item xs={12} md={6}>
                            <TextField
                              value={sede.Via}
                              margin="dense"
                              name="Via"
                              label="Via"
                              type="text"
                              fullWidth
                              variant="outlined"
                              onChange={handleInputChange}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              value={sede.Cap}
                              margin="dense"
                              name="Cap"
                              label="Cap"
                              type="text"
                              fullWidth
                              variant="outlined"
                              onChange={handleInputChange}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          xs={12}
                          sx={{ flexWrap: { xs: "wrap", md: "nowrap" } }}
                          columnGap={2}
                        >
                          <Grid item xs={12} md={6}>
                            <TextField
                              value={sede.Paese}
                              margin="dense"
                              name="Paese"
                              label="Paese"
                              type="text"
                              fullWidth
                              variant="outlined"
                              onChange={handleInputChange}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              value={sede.Ateco}
                              margin="dense"
                              name="Ateco"
                              label="Cod. Ateco"
                              type="text"
                              fullWidth
                              variant="outlined"
                              onChange={handleInputChange}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          xs={12}
                          sx={{ flexWrap: { xs: "wrap", md: "nowrap" } }}
                          columnGap={2}
                        >
                          <Grid item xs={12} md={6}>
                            <TextField
                              value={sede.Inail}
                              margin="dense"
                              name="Inail"
                              label="INAIL"
                              type="text"
                              fullWidth
                              variant="outlined"
                              onChange={handleInputChange}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              value={sede.Inps}
                              margin="dense"
                              name="Inps"
                              label="INPS"
                              type="text"
                              fullWidth
                              variant="outlined"
                              onChange={handleInputChange}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          xs={12}
                          sx={{
                            flexWrap: { xs: "wrap", md: "nowrap" },
                            marginTop: 1,
                          }}
                          columnGap={2}
                        >
                          <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                              <InputLabel>Utente Medicina</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                value={sede.UtenteMedicina}
                                label="Utente Medicina"
                                onChange={(e) => {
                                  let copiaSede = { ...sede };
                                  copiaSede.UtenteMedicina = e.target.value;
                                  setSede(copiaSede);
                                }}
                              >
                                <MenuItem value={null}>Seleziona...</MenuItem>
                                {utenti?.map((u) => (
                                  <MenuItem value={u.ID}>{u.username}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <FormControlLabel
                              style={{
                                marginLeft: 0,
                                marginRight: 0,
                                marginBottom: 0,
                              }}
                              control={
                                <Checkbox
                                  checked={sede.Cantiere === 1 ? true : false}
                                  onChange={(e) => {
                                    let sedeUpdate = { ...sede };
                                    sedeUpdate.Cantiere = e.target.checked
                                      ? 1
                                      : 0;
                                    setSede(sedeUpdate);
                                  }}
                                />
                              }
                              label="Cantiere"
                            />
                          </Grid>
                        </Grid>

                        <Grid item xs={12} marginTop={0.5}>
                          <TextField
                            multiline={2}
                            value={sede.DescrizioneAttivita}
                            margin="dense"
                            name="DescrizioneAttivita"
                            label="Descrizione Attività"
                            type="text"
                            fullWidth
                            variant="outlined"
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <hr
                          style={{
                            height: "2px",
                            marginTop: "10px",
                            marginBottom: "5px",
                            width: "100%",
                            marginLeft: "16px",
                          }}
                        />

                        <Grid
                          container
                          xs={12}
                          sx={{ flexWrap: { xs: "wrap", md: "nowrap" } }}
                          columnGap={2}
                        >
                          <Grid item xs={12} md={6}>
                            <TextField
                              value={sede.Lon}
                              margin="dense"
                              name="Lon"
                              label="Longitudine"
                              type="number"
                              fullWidth
                              variant="outlined"
                              onChange={handleInputChange}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              value={sede.Lat}
                              margin="dense"
                              name="Lat"
                              label="Latitudine"
                              type="number"
                              fullWidth
                              variant="outlined"
                              onChange={handleInputChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        justifyContent: "flex-end",
                        display: "flex",
                      }}
                    >
                      <Button
                        variant="contained"
                        className="ButtonMiller"
                        startIcon={<Check />}
                        onClick={saveData}
                      >
                        Salva
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </TabPanel>

              <TabPanel value={value} index={1}>
                <DettaglioMedicina
                  sede={location.state.sedeId}
                  idAzienda={location.state.idC}
                  RagioneSociale={cliente.RagioneSociale}
                  infoSede={location.state.sede}
                  logo={location.state.logo}
                  bloccoSede={location.state.sede.Descrizione}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Lavoratori
                  Lavoratori={lavoratori}
                  idAzienda={location.state.idC}
                  cliente={cliente}
                  loadLavoratori={reloadLavoratori}
                  ragioneSociale={location.state.RagioneSociale}
                />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Allegati
                  idAzienda={location.state.idC}
                  id={sede?.ID}
                  colonna="Sede"
                />
              </TabPanel>
              <TabPanel value={value} index={4}>
                <MansioniSicurezza
                  idAzienda={location.state.idC}
                  sede={location.state.sedeId}
                />
              </TabPanel>
              <TabPanel value={value} index={5}>
                <AdempimentiCliente
                  idAzienda={location.state.idC}
                  sede={location.state.sedeId}
                />
              </TabPanel>
              <TabPanel value={value} index={6}>
                <CorsiSede
                  idAzienda={cliente.ID}
                  idSede={sede?.ID}
                  RagioneSociale={cliente.RagioneSociale}
                  sedeCF={location.state.sedeCF}
                  infoSede={location.state.sede}
                  regSoc={location.state.infoSede}
                  sedeLoc={location.state.sede.Ubicazione}
                  sedeInd={location.state.sede.Via}
                  sedeCap={location.state.sede.Cap}
                  logo={location.state.logo}
                  bloccoSede={location.state.sede.Descrizione}
                  idCliente={location.state.idC}
                  sede={location.state.sedeId}
                />
              </TabPanel>
              <TabPanel value={value} index={7}>
                <Organigramma
                  sedeCF={location.state.sedeCF}
                  infoSede={location.state.sede}
                  regSoc={location.state.infoSede}
                  sedeLoc={location.state.sede.Ubicazione}
                  sedeInd={location.state.sede.Via}
                  sedeCap={location.state.sede.Cap}
                  logo={location.state.logo}
                  bloccoSede={location.state.sede.Descrizione}
                  idCliente={location.state.idC}
                  sede={location.state.sedeId}
                />
              </TabPanel>
              <TabPanel value={value} index={8}>
                <Reparti sede={sede} />
              </TabPanel>
              <TabPanel value={value} index={9}>
                  <Dvr
                    idAzienda={cliente.ID}
                    idSede={sede?.ID}
                    sede={sede}
                    cliente={cliente}
                  />
                </TabPanel>
              {cliente.Gruppo === "Gavio" && (
                <TabPanel value={value} index={10}>
                  <MediciKm
                    idAzienda={cliente.ID}
                    idSede={sede?.ID}
                    RagioneSociale={cliente.RagioneSociale}
                    sedeCF={location.state.sedeCF}
                    infoSede={location.state.sede}
                    regSoc={location.state.infoSede}
                    sedeLoc={location.state.sede.Ubicazione}
                    sedeInd={location.state.sede.Via}
                    sedeCap={location.state.sede.Cap}
                    logo={location.state.logo}
                    bloccoSede={location.state.sede.Descrizione}
                    idCliente={location.state.idC}
                    sede={location.state.sedeId}
                  />
                </TabPanel>
              )}
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

//`data:image/png;base64,${this.state.sede.Logo}`

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        thickness={7}
        {...props}
        sx={{
          color:
            props.value <= 50
              ? "#FF2768"
              : props.value <= 75
              ? "#2265B1"
              : "#40C18F",
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography component="div" color="text.secondary" variant="h5">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
