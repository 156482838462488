import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Box,
  Tab,
  Tabs,
  Button,
  Typography,
  Grid,
  TextField,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  FormControl,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Paper,
} from "@mui/material";
import PropTypes from "prop-types";
import unsplash from "../api/unsplash";

import {
  LocalizationProvider,
  DatePicker,
  PickersDay,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment as DateAdapter } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import momentTimezone from "moment-timezone";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { Download, Upload } from "@mui/icons-material";
import GlobalSnackbar from "./Snackbar";

const Allegati3B = () => {
  const [medici, setMedici] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [medicoSel, setMedicoSel] = useState(null);
  const [value, setValue] = useState(0);
  const [clienti, setClienti] = useState([]);
  const [clienteSel, setClienteSel] = useState(0);
  const [sedi, setSedi] = useState([]);
  const [sedeSel, setSedeSel] = useState("");
  const [dal, setDal] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [al, setAl] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [cessati, setCessati] = useState(true);
  const [risultatoIdoneita, setRisultatoIdoneita] = useState([]);
  const [risultatoAccertamenti, setRisultatoAccertamenti] = useState([]);
  const [risultatoRischi, setRisultatoRischi] = useState([]);

  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleOpen = async () => {
    setOpen(true);
    if (clienteSel) {
      let response = await unsplash.get("medici/visite", {
        params: {
          cliente: clienteSel,
          dal: dal,
          al: al,
          cessati: cessati,
        },
        headers: {
          //Idoneita
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setMedici(
        response.data.data?.map((med) => {
          return { id: med.ID, label: med.Cognome + " " + med.Nome };
        })
      );
    } else {
      setSnack({
        severity: "error",
        messaggio: "Seleziona un Cliente",
      });
      setOpenSnack(true);
    }
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    /*setCessati(true);
        setClienteSel(0);
        if(newValue === 0){
        setDal(new Date());
        setAl(new Date());
        }else{
          setDal(null);
          setAl(null);
        }
        setSedeSel("");  
        setRisultato([]);*/
  };

  const handleChangeCliente = async (cliente) => {
    setSedeSel("");

    if (cliente) {
      setClienteSel(cliente.id);

      let response = await unsplash.get("sedi", {
        params: { id: cliente.id },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      let unitaProd = [];
      for (let i = 0; i < response.data.data.length; i++) {
        /*
let sede = response.data.data[i].UnitaProduttiva; 

if(unitaProd.indexOf(response.data.data[i].UnitaProduttiva) === -1 && response.data.data[i].UnitaProduttiva!="" && response.data.data[i].UnitaProduttiva){
  unitaProd.push(response.data.data[i].UnitaProduttiva);
}*/

        let sede = response.data.data[i].Descrizione;

        if (
          unitaProd.indexOf(response.data.data[i].Descrizione) === -1 &&
          response.data.data[i].Descrizione != "" &&
          response.data.data[i].Descrizione
        ) {
          unitaProd.push(sede);
        }
      }
      setSedi(unitaProd);
    } else {
      setSedi([]);
      setClienteSel(0);
    }
  };

  const handleLoad = async () => {
    let response = await unsplash.get("allegato3B", {
      params: {
        tipo: 0,
        cliente: clienteSel,
        unita: sedeSel,
        dal: dal,
        al: al,
        cessati: cessati,
        medico: medicoSel?.id,
      },
      headers: {
        //Idoneita
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setRisultatoIdoneita(response.data.res);

    let responseRischi = await unsplash.get("allegato3B", {
      params: {
        tipo: 1,
        cliente: clienteSel,
        unita: sedeSel,
        dal: dal,
        al: al,
        cessati: cessati,
        medico: medicoSel?.id,
      },
      headers: {
        //Rischi
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setRisultatoRischi(responseRischi.data.res);

    let responseAccertamenti = await unsplash.get("allegato3B", {
      params: {
        tipo: 2,
        cliente: clienteSel,
        unita: sedeSel,
        dal: dal,
        al: al,
        cessati: cessati,
        medico: medicoSel?.id,
      },
      headers: {
        //Accertamenti
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setRisultatoAccertamenti(responseAccertamenti.data.res);
  };

  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      try {
        let response = await unsplash.get("clienti", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        let inserd = [];
        let cli = [];
        for (let i = 0; i < response.data.data.length; i++) {
          //per evitare doppioni

          if (inserd.indexOf(response.data.data[i].RagioneSociale) === -1) {
            cli.push({
              label: response.data.data[i].RagioneSociale,
              id: response.data.data[i].ID,
            });
            inserd.push(response.data.data[i].RagioneSociale);
          }
        }

        setClienti(cli);
      } catch (error) {
        console.error(error.message);
      }
    };
    //prova
    fetchData();
  }, []);

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Grid item xs={12}>
        <Card sx={{ marginTop: "10px", boxShadow: "none" }}>
          <CardContent>
            <Box>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={clienti}
                      fullWidth
                      onChange={(event, newValue) => {
                        handleChangeCliente(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Cliente" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <LocalizationProvider dateAdapter={DateAdapter}>
                      <DatePicker
                        ampmInClock={false}
                        renderInput={(props) => <TextField {...props} />}
                        label="Dal"
                        value={moment(dal)}
                        onChange={(newValue) => {
                          setDal(
                            newValue ? newValue.format("YYYY-MM-DD") : null
                          );
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <LocalizationProvider dateAdapter={DateAdapter}>
                      <DatePicker
                        ampmInClock={false}
                        renderInput={(props) => <TextField {...props} />}
                        label="Al"
                        value={moment(al)}
                        onChange={(newValue) => {
                          setAl(
                            newValue ? newValue.format("YYYY-MM-DD") : null
                          );
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    sx={{
                      height: "fit-content",
                      display: "flex",
                      justifyContent: "flex-end",
                      flexWrap: "nowrap",
                      my: "auto",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={handleLoad}
                      className="ButtonMiller"
                      startIcon={<Upload />}
                    >
                      Carica
                    </Button>
                    {risultatoIdoneita.length > 0 ? (
                      <Button
                        sx={{ marginLeft: 2 }}
                        variant="contained"
                        onClick={() =>
                          document
                            .querySelector("#test-table-xls-button")
                            .click()
                        }
                        className="ButtonMiller"
                        startIcon={<Download />}
                      >
                        Esporta
                      </Button>
                    ) : (
                      <></>
                    )}
                    <div className="hidden">
                      <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        /* className={
                          risultatoIdoneita.length > 0
                            ? "download-table-xls-button"
                            : "download-table-xls-button-hide"
                        } */
                        table="tabellaAllegato3B"
                        filename={"Allegato3B"}
                        sheet={"Allegato3B"}
                        buttonText="Esporta "
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={sedi}
                      fullWidth
                      value={sedeSel}
                      onChange={(event, newValue) => {
                        setSedeSel(newValue ? newValue : "");
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Unità Prod." />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      open={open}
                      onOpen={handleOpen}
                      onClose={() => setOpen(false)}
                      options={medici}
                      loading={loading}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Medici"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      id="combo-box-demo"
                      onChange={(event, newValue) => {
                        setMedicoSel(newValue ? newValue : "");
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={cessati}
                          onChange={() => setCessati(!cessati)}
                        />
                      }
                      label="Considera Cessati"
                    />
                  </Grid>
                </Grid>

                <TableContainer component={Paper} sx={{ display: "none" }}>
                  <Table
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                    id="tabellaAllegato3B"
                  >
                    <TableRow>
                      <TableCell>Descrizione</TableCell>
                      <TableCell align="right">Maschi</TableCell>
                      <TableCell align="right">Femmine</TableCell>
                      <TableCell align="right">Totale</TableCell>
                    </TableRow>
                    {risultatoIdoneita.map((row) => (
                      <TableRow
                        key={row.Descrizione}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.Descrizione}
                        </TableCell>
                        <TableCell align="right">{row.Maschi}</TableCell>
                        <TableCell align="right">{row.Femmine}</TableCell>
                        <TableCell align="right">{row.Tot}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow> </TableRow>
                    <TableRow>
                      <TableCell>Descrizione</TableCell>
                      <TableCell align="right">Maschi Tot.</TableCell>
                      <TableCell align="right">Femmine Tot.</TableCell>
                      <TableCell align="right">Maschi Visitati</TableCell>
                      <TableCell align="right">Femmine Visitate</TableCell>
                      <TableCell align="right">
                        Maschi con idoneità parziali
                      </TableCell>
                      <TableCell align="right">
                        Femmine con idoneità parziali
                      </TableCell>
                      <TableCell align="right">Maschi con inidoneità</TableCell>
                      <TableCell align="right">
                        Femmine con inidoneità
                      </TableCell>
                    </TableRow>
                    {risultatoRischi.map((row) => (
                      <TableRow
                        key={row.Descrizione}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.Descrizione}
                        </TableCell>
                        <TableCell align="right">{row.Maschi}</TableCell>
                        <TableCell align="right">{row.Femmine}</TableCell>
                        <TableCell align="right">
                          {row.NMaschiVisitati}
                        </TableCell>
                        <TableCell align="right">
                          {row.NFemmineVisitate}
                        </TableCell>
                        <TableCell align="right">
                          {row.NMaschiIdoneita}
                        </TableCell>
                        <TableCell align="right">
                          {row.NFemmineIdoneita}
                        </TableCell>
                        <TableCell align="right">
                          {row.NMaschiInidoneita}
                        </TableCell>
                        <TableCell align="right">
                          {row.NFemmineInidoneita}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow></TableRow>
                    <TableRow>
                      <TableCell>Descrizione</TableCell>
                      <TableCell align="right">Maschi</TableCell>
                      <TableCell align="right">Femmine</TableCell>
                    </TableRow>
                    {risultatoAccertamenti.map((row) => (
                      <TableRow
                        key={row.Accertamento}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.Accertamento}
                        </TableCell>
                        <TableCell align="right">{row.NMaschi}</TableCell>
                        <TableCell align="right">{row.NFemmine}</TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </TableContainer>

                <Tabs
                  value={value}
                  textColor="inherit"
                  variant="scrollable"
                  onChange={handleChangeTab}
                  aria-label="basic tabs example"
                >
                  <Tab label="Idoneità" {...a11yProps(0)} />
                  <Tab label="Rischi" {...a11yProps(1)} />
                  <Tab label="Accertamenti" {...a11yProps(2)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                {/*Idoneità*/}

                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 650 }}
                      aria-label="simple table"
                      id="tabellaIdoneita"
                    >
                      <TableHead sx={{ backgroundColor: "#11526F" }}>
                        <TableRow>
                          <TableCell sx={{ color: "white" }}>
                            Descrizione
                          </TableCell>
                          <TableCell align="right" sx={{ color: "white" }}>
                            Maschi
                          </TableCell>
                          <TableCell align="right" sx={{ color: "white" }}>
                            Femmine
                          </TableCell>
                          <TableCell align="right" sx={{ color: "white" }}>
                            Totale
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {value === 0
                          ? risultatoIdoneita.map((row) => (
                              <TableRow
                                key={row.Descrizione}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {row.Descrizione}
                                </TableCell>
                                <TableCell align="right">
                                  {row.Maschi}
                                </TableCell>
                                <TableCell align="right">
                                  {row.Femmine}
                                </TableCell>
                                <TableCell align="right">{row.Tot}</TableCell>
                              </TableRow>
                            ))
                          : ""}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                {/*Rischi*/}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        id="tabellaRischi"
                      >
                        <TableHead sx={{ backgroundColor: "#11526F" }}>
                          <TableRow>
                            <TableCell sx={{ color: "white" }}>
                              Descrizione
                            </TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>
                              Maschi Tot.
                            </TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>
                              Femmine Tot.
                            </TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>
                              Maschi Visitati
                            </TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>
                              Femmine Visitate
                            </TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>
                              Maschi con idoneità parziali
                            </TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>
                              Femmine con idoneità parziali
                            </TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>
                              Maschi con inidoneità
                            </TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>
                              Femmine con inidoneità
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {value === 1
                            ? risultatoRischi.map((row) => (
                                <TableRow
                                  key={row.Descrizione}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {row.Descrizione}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.Maschi}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.Femmine}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.NMaschiVisitati}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.NFemmineVisitate}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.NMaschiIdoneita}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.NFemmineIdoneita}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.NMaschiInidoneita}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.NFemmineInidoneita}
                                  </TableCell>
                                </TableRow>
                              ))
                            : ""}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={2}>
                {/*Accertamenti*/}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        id="tabellaAccertamenti"
                      >
                        <TableHead sx={{ backgroundColor: "#11526F" }}>
                          <TableRow>
                            <TableCell sx={{ color: "white" }}>
                              Descrizione
                            </TableCell>
                            <TableCell align="right" sx={{ color: "white" }}>
                              Maschi
                            </TableCell>
                            <TableCell align="right" sx={{ color: "white" }}>
                              Femmine
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {value === 2
                            ? risultatoAccertamenti.map((row) => (
                                <TableRow
                                  key={row.Accertamento}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {row.Accertamento}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.NMaschi}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.NFemmine}
                                  </TableCell>
                                </TableRow>
                              ))
                            : ""}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </TabPanel>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default Allegati3B;
