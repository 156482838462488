import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import unsplash from "../api/unsplash";
import Avatar from "@mui/material/Avatar";
import GlobalSnackbar from "./Snackbar";
import LinearProgress from "@mui/material/LinearProgress";
import Switch from "@mui/material/Switch";

import {
  Grid,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Input,
  CardActionArea,
  CardMedia,
  Divider,
  CardActions,
  InputAdornment,
  ButtonGroup,
  Tooltip,
  FormControlLabel,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Zoom,
  DialogContentText,
  DialogActions,
  Autocomplete,
  Tabs,
  Tab,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import { IconButton } from "@mui/material";

import {
  DataGridPro,
  itIT,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";
import PropTypes from "prop-types";
import {
  Add,
  Delete,
  Close,
  Check,
  Search,
  MenuBook,
  Circle,
  Public,
  Send,
  DoneAll,
} from "@mui/icons-material";
import { createPortal } from "react-dom";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment as DateAdapter } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import DettaglioParita from "./DettaglioParita";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function CustomToolbarG() {
  return (
    <GridToolbarContainer>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <GridToolbarQuickFilter placeholder="Cerca..." />
      </Grid>
    </GridToolbarContainer>
  );
}

const ParitaClienti = () => {
  const navigate = useNavigate();
  const [paritaClienti, setParitaClienti] = useState([]);
  const [paritaClientiVisibili, setParitaClientiVisibili] = useState([]);
  const [newCliente, setNewCliente] = useState({
    Logo: "",
    Denominazione: "",
    RagioneSociale: "",
    SedeLegale: "",
    SedeAmministrativa: "",
    Telefono: "",
    Sito: "",
    Pec: "",
    Piva: "",
    Rea: "",
    CodiceAteco: 0,
    NomeRappresentante: "",
    CognomeRappresentante: "",
    DataNascitaRappresentante: null,
    LuogoNascitaRappresentante: "",
    Logo: "",
    NominativoResponsabile: "",
    MembriComitato: "",
    Grandezza: "",
    Cessato: 0,
    Settore: "",
    Storia: "",
    Missione: "",
  });
  const [codiciAtecoSel, setCodiciAtecoSel] = useState(null);
  const [codiciAteco, setCodiciAteco] = useState([]);

  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [valueFilter, setValueFilter] = useState("");
  const [filtro, setFiltro] = useState({
    cessati: false,
    attivi: true,
  });

  const [kpiParita, setKpiParita] = useState([]);
  const [paritaClientiSel, setParitaClientiSel] = useState([]);

  const [openAlert, setOpenAlert] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const columns = [
    /* {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "130",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={async () => {
              await setCliente(params.row);
              document.getElementById("edit").click();
              handleClickOpen();
            }}
          >
            <EditIcon
              style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
            />
          </IconButton>

          <IconButton
            style={{ display: "none" }}
            aria-label="delete"
            onClick={async () => {
              setCliente(params.row);
              toggleAlert();
            }}
          >
            <Delete
              style={{ height: "2rem", width: "2rem", color: "#FF2768" }}
            />
          </IconButton>
        </>
      ),
    }, */
    {
      field: "RagioneSociale",
      headerName: "Ragione Sociale",
      width: 150,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "Grandezza",
      headerName: "Grandezza",
      width: 180,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let val = "";
        switch (params.row.Grandezza) {
          case 1:
            val = "Micro";
            break;
          case 2:
            val = "Piccola";
            break;
          case 3:
            val = "Media/Grande";
            break;

          default:
            break;
        }
        return val;
      },
    },
    {
      field: "*",
      headerName: "Step Confermato",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <Circle
            sx={{
              color:
                params.row.Confermato === 1 || params.row.Step === 0
                  ? "#40C18F"
                  : "#FF2768",
              fontSize: 32,
            }}
          />
        </>
      ),
    },
    {
      field: "Step",
      headerName: "Step Attuale",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let categoria = "";
        switch (params.row.Step) {
          case 0:
            categoria = "Video Introduzione Iter";
            break;
          case 1:
            categoria = "Upload Profilo";
            break;
          case 2:
            categoria = "Video Prima Survey";
            break;
          case 3:
            categoria = "Prima Survey";
            break;
          case 4:
            categoria = "Video Seconda Survey";
            break;
          case 5:
            categoria = "Seconda Survey";
            break;
          case 6:
            categoria = "Completato";
            break;

          default:
            break;
        }
        return <>{categoria}</>;
      },
    },
    {
      field: "SedeLegale",
      headerName: "Sede Legale",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Telefono",
      headerName: "Telefono",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Sito",
      headerName: "Sito",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Pec",
      headerName: "Pec",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
  ];

  const toggleAlert = () => {
    setOpenAlert(!openAlert);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const reloadAfterUpload = async () => {
    try {
      let response = await unsplash.get("parita", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setParitaClienti(response.data.data);
      setParitaClientiVisibili(
        response.data.data?.filter((el) => el.Cessato === 0)
      );
      let responseAteco = await unsplash.get("parita/ateco", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setCodiciAteco(responseAteco.data.data);
      setLoading(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    (async () => {
      reloadAfterUpload();
      let kpiParitaResp = await unsplash.get("kpiParita", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setKpiParita(kpiParitaResp.data.data);
    })();
  }, []);

  const handleSave = async () => {
    try {
      await unsplash.post(
        "parita",
        {
          parita: newCliente,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setSnack({
        severity: "success",
        messaggio: "Cliente parità creato con successo",
      });
      setOpenSnack(true);

      reloadAfterUpload();
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante la creazione",
      });
      setOpenSnack(true);
    }
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setNewCliente({
      Logo: "",
      Denominazione: "",
      RagioneSociale: "",
      SedeLegale: "",
      SedeAmministrativa: "",
      Telefono: "",
      Sito: "",
      Pec: "",
      Piva: "",
      Rea: "",
      CodiceAteco: 0,
      NomeRappresentante: "",
      CognomeRappresentante: "",
      DataNascitaRappresentante: null,
      LuogoNascitaRappresentante: "",
      Logo: "",
      NominativoResponsabile: "",
      MembriComitato: "",
      Grandezza: "",
      Cessato: 0,
      Settore: "",
      Storia: "",
      Missione: "",
    });
    setCodiciAtecoSel(null);
  };

  const filter = (e, tipologia) => {
    let f = { ...filtro };
    f[tipologia] = e.target.checked;
    setFiltro(f);

    var paritaClientiX = []; // popolo l'array
    var i;
    for (i = 0; i < paritaClienti.length; i++) {
      if (
        paritaClienti[i].RagioneSociale.toLowerCase().includes(
          valueFilter.toLowerCase()
        )
      ) {
        paritaClientiX.push(paritaClienti[i]);
      }

      if (!f.cessati) {
        paritaClientiX = paritaClientiX.filter((c) => c.Cessato !== 1);
      }

      if (!f.attivi) {
        paritaClientiX = paritaClientiX.filter((c) => c.Cessato !== 0);
      }
    }
    setParitaClientiVisibili(paritaClientiX);
  };

  const handleChange = (e) => {
    let copiaCliente = { ...newCliente };
    copiaCliente[e.target.name] = e.target.value;
    setNewCliente(copiaCliente);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
          <FormControlLabel
            sx={{
              marginBottom: "0px!important",
              fontSize: "13px",
              color: "#11526F",
            }}
            control={
              <Checkbox
                size="small"
                checked={filtro.attivi}
                onClick={(e) => filter(e, "attivi")}
              />
            }
            label="Attivi"
          />
          <FormControlLabel
            sx={{
              marginBottom: "0px!important",
              fontSize: "13px",
              color: "#11526F",
            }}
            control={
              <Checkbox
                size="small"
                checked={filtro.cessati}
                onClick={(e) => filter(e, "cessati")}
              />
            }
            label="Cessati"
          />
        </Grid>
      </GridToolbarContainer>
    );
  }
  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <div className="flex w-full justify-end gap-2">
              {/* {paritaClientiSel.length > 0 && (
                <Button
                  startIcon={<DoneAll />}
                  sx={{ color: "white" }}
                  variant="outlined"
                  className="ButtonMiller"
                  onClick={handleConfermaStep}
                >
                  Conferma Step
                </Button>
              )} */}
              {/* <DettaglioParita
                id="edit"
                cliente={cliente}
                saveFunction={handleSave}
                title="Modifica Cliente"
              />
              <DettaglioParita
                id="new"
                cliente={cliente}
                saveFunction={handleSave}
                title="Nuovo Cliente"
              /> */}
              <Button
                startIcon={<Add />}
                onClick={handleClickOpen}
                //id={location.state?.id}
                sx={{ color: "white" }}
                variant="outlined"
                className="ButtonMiller"
              >
                Aggiungi
              </Button>
            </div>
            <Box
              sx={{
                height: "70vh",
                width: 1,
              }}
            >
              <DataGridPro
                components={{
                  Toolbar: CustomToolbar,
                  Footer: CustomFooterPersonalized,
                }}
                slots={{
                  loadingOverlay: LinearProgress,
                }}
                loading={loading}
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                rows={paritaClientiVisibili}
                initialState={{ pinnedColumns: { left: ["Actions"] } }}
                columns={columns}
                getRowId={(row) => row.ID}
                disableSelectionOnClick
                onRowClick={async (params, event) => {
                  navigate("/dettaglioParita", {
                    state: {
                      cliente: params.row,
                      id: "edit",
                      title: "Modifica Cliente",
                      kpi: kpiParita,
                    },
                  });
                }}
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
        TransitionComponent={Transition}
      >
        <DialogTitle
          sx={{
            mx: "auto",
          }}
        >
          Nuovo Cliente
        </DialogTitle>
        <DialogContent sx={{ my: 2 }}>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} md={6}>
              <TextField
                value={newCliente.RagioneSociale}
                id="outlined-basic"
                label="Ragione Sociale"
                name="RagioneSociale"
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={newCliente?.Denominazione}
                id="outlined-basic"
                label="Denominazione"
                name="Denominazione"
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="Grandezza">Grandezza Impresa</InputLabel>
                <Select
                  label="Grandezza Impresa"
                  labelId="Grandezza"
                  id="demo-simple-select"
                  value={newCliente.Grandezza}
                  onChange={(e) => {
                    let l = { ...newCliente };
                    l.Grandezza = e.target.value;
                    setNewCliente(l);
                  }}
                >
                  <MenuItem value={1}>Micro</MenuItem>
                  <MenuItem value={2}>Piccola</MenuItem>
                  <MenuItem value={3}>Media/Grande</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={newCliente?.Sito}
                id="outlined-basic"
                label="Sito Web"
                name="Sito"
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={newCliente?.SedeLegale}
                id="outlined-basic"
                label="Sede Legale"
                name="SedeLegale"
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={newCliente?.SedeAmministrativa}
                id="outlined-basic"
                label="Sede Amministrativa"
                name="SedeAmministrativa"
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={newCliente?.Telefono}
                id="outlined-basic"
                label="Telefono"
                name="Telefono"
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={newCliente?.Pec}
                id="outlined-basic"
                label="PEC"
                name="Pec"
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={newCliente?.Piva}
                id="outlined-basic"
                label="Partita IVA"
                name="Piva"
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={newCliente?.Rea}
                id="outlined-basic"
                label="REA"
                name="Rea"
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                id="combo-box-demo"
                options={codiciAteco}
                fullWidth
                value={codiciAtecoSel}
                getOptionLabel={(option) =>
                  option.Lettera +
                  " - " +
                  option.Codice +
                  " - " +
                  option.Descrizione
                }
                onChange={(event, newValue) => {
                  setCodiciAtecoSel(newValue);
                  if (newValue) {
                    setNewCliente({
                      ...newCliente,
                      CodiceAteco: newValue.ID,
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Codice Ateco" />
                )}
              />
            </Grid>
            {/* <Grid item xs={12} md={6} display={"flex"} alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={newCliente?.Cessato === 1}
                    onChange={(e) =>
                      setNewCliente({
                        ...newCliente,
                        Cessato: e.target.checked ? 1 : 0,
                      })
                    }
                  />
                }
                label="Cessato"
              />
            </Grid> */}
            <Grid item xs={12}>
              <TextField
                multiline
                rows={3}
                value={newCliente?.Storia}
                id="outlined-basic"
                label="Storia"
                name="Storia"
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                rows={3}
                value={newCliente?.Missione}
                id="outlined-basic"
                label="Missione"
                name="Missione"
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={newCliente?.NomeRappresentante}
                id="outlined-basic"
                label="Nome Rappresentante"
                name="NomeRappresentante"
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={newCliente?.CognomeRappresentante}
                id="outlined-basic"
                label="Cognome Rappresentante"
                name="CognomeRappresentante"
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  sx={{ width: "100%" }}
                  ampmInClock={false}
                  value={
                    newCliente?.DataNascitaRappresentante
                      ? moment(newCliente?.DataNascitaRappresentante)
                      : null
                  }
                  renderInput={(props) => (
                    <TextField size="small" fullWidth {...props} />
                  )}
                  label="Data Di Nascita Rappresentante"
                  onChange={(newValue) => {
                    setNewCliente({
                      ...newCliente,
                      DataNascitaRappresentante: newValue,
                    });
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={newCliente?.LuogoNascitaRappresentante}
                id="outlined-basic"
                label="Luogo Di Nascita Rappresentante"
                name="LuogoNascitaRappresentante"
                onChange={handleChange}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mx: 2,
          }}
        >
          <Button
            onClick={handleClose}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
          >
            Chiudi
          </Button>
          <Button
            onClick={handleSave}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ParitaClienti;
