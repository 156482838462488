import React, { useEffect, useState } from "react";
import "../style/style.css";

import "../fonts/verdana.ttf";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import Login from "./Login";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import PrivateRouteCliente from "./PrivateRouteCliente";
import AppContent from "./AppContent";
import Homepage from "./Homepage";
import Clienti from "./Clienti";
import DettaglioLavoratore from "./DettaglioLavoratore/DettaglioLavoratore";
import Medici from "./Medici";
import DettaglioMedico from "./DettaglioMedico";
import VisiteMediche from "./VIsiteMediche";
import Allegati3B from "./Allegati3B";
import Idoneita from "./Idoneita";
import ProceduraGuidata from "./ProceduraGuidata";
import PrefatturazioneMedicina from "./PrefatturazioneMedicina";
import AnagarficaCorsi from "./AnagarficaCorsi";
import Formatori from "./Formatori";
import Scadenzario from "./ScadenzarioCorsi";
import Corsi from "./Corsi";
import CorsiAttestati from "./CorsiAttestati";
import PrefatturazioneCorsi from "./PrefatturazioneCorsi";
import SetPassword from "./SetPassword";
import Adempimenti from "./Adempimenti";
import Anomalie from "./Anomalie";
import { RiepilogativoVisite } from "./RiepilogativoVisite";
import ScadenzarioVisite from "./ScadenzarioVisite";
import LavoratoriSicurezza from "./LavoratoriSicurezza";
import UtentiClienti from "./UtentiClienti";
import Memo from "./Memo";
import { CategoriaAdempimenti } from "./CategoriaAdempimenti";
import ClientDetail from "./ClientDetail";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import MansioniSicurezza from "./MansioniSicurezza";
import CollegaVirtuale from "./CollegaVirtuale";
import { SediDetail } from "./SediDetail";
import { AnagraficaRischi } from "./AnagraficaRischi";
import { AnagraficaAccertamenti } from "./AnagraficaAccertamenti";
import { AnagraficaMansioni } from "./AnagraficaMansioni";
import { RiepilogativoFormazione } from "./RiepilogativoFormazione";
import KpiMedicina from "./KpiMedicina";
import KpiFormazione from "./KpiFormazione";
import { AnagraficheDPI } from "./AnagraficheDPI";
import Attrezzature from "./Attrezzature";
import Miglioramenti from "./Miglioramenti";
//import Chat from "./Chat";

import HomeCliente from "./AccessoCliente/Home";

import LoginCliente from "./AccessoCliente/Login";

import AppContentCliente from "./AccessoCliente/AppContent";
import LavoratoriCliente from "./AccessoCliente/Lavoratori";
import MedicinaCliente from "./AccessoCliente/Medicina";
import CorsiCliente from "./AccessoCliente/Corsi";
import DocumentiCliente from "./AccessoCliente/Documenti";
import SopralluoghiCliente from "./AccessoCliente/Sopralluoghi";

import "react-toastify/dist/ReactToastify.css";

import VisiteMedicheCliente from "./AccessoCliente/VisiteMediche";
import IdoneitaAllegateCliente from "./AccessoCliente/IdoneitaAllegate";
import LoginMedicoCliente from "./AccessoCliente/LoginMedico";
import HomepageMedicoCliente from "./AccessoCliente/HomepageMedico";
import HelpPageCliente from "./AccessoCliente/HelpPage";
import Consumi from "./Consumi";
import Distaccati from "./Distaccati";
import SetPasswordLavoratore from "./AccessoLavoratore/SetPasswordLavoratore";
import LoginLavoratore from "./AccessoLavoratore/LoginLavoratore";
import PrivateRouteLavoratore from "./PrivateRouteLavoratore";
import AppContentLavoratori from "./AccessoLavoratore/AppContentLavoratori";
import HomeLavoratori from "./AccessoLavoratore/HomeLavoratori";
import AllegatiLavoratori from "./AccessoLavoratore/AllegatiLavoratori";
import FormazioneLavoratore from "./AccessoLavoratore/FormazioneLavoratore";
import MedicinaLavoratore from "./AccessoLavoratore/MedicinaLavoratore";
import LoginMedico from "./AccessoMedico/LoginMedico";
import PrivateRouteMedico from "./PrivateRouteMedico";
import HomeMedico from "./AccessoMedico/HomeMedico";
import AppContentMedico from "./AccessoMedico/AppContentMedico";
import AllegatiMedici from "./AccessoMedico/AllegatiMedici";
import VisiteMedici from "./AccessoMedico/VisiteMedici";
import SetPasswordMedico from "./AccessoMedico/SetPasswordMedico";
import moment from "moment";
import { ProfiloLavoratore } from "./AccessoLavoratore/ProfiloLavoratore";
import { ProfiloMedico } from "./AccessoMedico/ProfiloMedico";
import DettaglioVisiteMedici from "./AccessoMedico/DettaglioVisiteMedici";
import { TipologiePrestazioni } from "./TipologiePrestazioni";
import ParitaClienti from "./Parita";
import SetPasswordParita from "./AccessoParita/SetPasswordParita";
import LoginParita from "./AccessoParita/LoginParita";
import AppContentParita from "./AccessoParita/AppContentParita";
import HomeParita from "./AccessoParita/HomeParita";
import ClienteParita from "./AccessoParita/ClienteParita";
import VideoParita from "./AccessoParita/VideoParita";
import SurveyParita from "./AccessoParita/SurveyParita";
import { ProfiloParita } from "./AccessoParita/ProfiloParita";
import DettaglioParita from "./DettaglioParita";
import { LivelliParita } from "./LivelliParita";
import { GruppiParita } from "./GruppiParita";
import { CcnlDescrizioneParita } from "./CcnlDescrizioneParita";
import { KpiParita } from "./KpiParita";
import { TipologieCcnlParita } from "./TipologieCcnlParita";
import AgentiBiologici from "./AgentiBiologici";

moment.tz.setDefault("Europe/Rome");

LicenseInfo.setLicenseKey(
  "5b30fac79aff89e43e91021018cca43dTz04MTU1MyxFPTE3MzU5OTY0NDYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const App = () => {
  //VEDIAMO SE VA MEGLIO
  const [screenSize, getDimension] = useState(window.innerWidth);
  const setDimension = () => {
    getDimension(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);
  }, [screenSize]);
  return (
    <div>
      <BrowserRouter>
        <Routes>
          {/* PARITA DI GENERE */}
          <Route
            path="/AreaParita/setPassword"
            element={<SetPasswordParita />}
          />
          <Route path="/AreaParita/Login" element={<LoginParita />} />

          <Route //AccessoParita
            path="/AreaParita"
            element={
              <PrivateRouteCliente>
                <AppContentParita />
              </PrivateRouteCliente>
            }
          >
            <Route
              path="/AreaParita/Home"
              element={
                <PrivateRouteCliente>
                  <HomeParita />
                </PrivateRouteCliente>
              }
            />
            <Route
              path="/AreaParita/Cliente"
              element={
                <PrivateRouteCliente>
                  <ClienteParita />
                </PrivateRouteCliente>
              }
            />
            <Route
              path="/AreaParita/Video"
              element={
                <PrivateRouteCliente>
                  <VideoParita />
                </PrivateRouteCliente>
              }
            />
            <Route
              path="/AreaParita/Survey"
              element={
                <PrivateRouteCliente>
                  <SurveyParita />
                </PrivateRouteCliente>
              }
            />
            <Route
              path="/AreaParita/Profilo"
              element={
                <PrivateRouteCliente>
                  <ProfiloParita />
                </PrivateRouteCliente>
              }
            />
          </Route>
          {/* AREA CLIENTI */}
          <Route
            path="/AreaClienti"
            element={
              <PrivateRouteCliente>
                <AppContentCliente />
              </PrivateRouteCliente>
            }
          >
            <Route
              path="/AreaClienti/ScadenzarioVisite"
              element={
                <PrivateRouteCliente>
                  <LavoratoriCliente />
                </PrivateRouteCliente>
              }
            />
            <Route
              path="/AreaClienti/DashboardMedico"
              element={
                <PrivateRouteCliente>
                  <HomepageMedicoCliente />
                </PrivateRouteCliente>
              }
            />
            <Route
              path="/AreaClienti/Dashboard"
              element={
                <PrivateRouteCliente>
                  <HomeCliente />
                </PrivateRouteCliente>
              }
            />
            <Route
              path="/AreaClienti/corsi"
              element={
                <PrivateRouteCliente>
                  <CorsiCliente />
                </PrivateRouteCliente>
              }
            />
            <Route
              path="/AreaClienti/Documenti"
              element={
                <PrivateRouteCliente>
                  <DocumentiCliente />
                </PrivateRouteCliente>
              }
            />
            <Route
              path="/AreaClienti/Help"
              element={
                <PrivateRouteCliente>
                  <HelpPageCliente />
                </PrivateRouteCliente>
              }
            />
            <Route
              path="/AreaClienti/sopralluoghi"
              element={
                <PrivateRouteCliente>
                  <SopralluoghiCliente />
                </PrivateRouteCliente>
              }
            />
            <Route
              path="/AreaClienti/visiteMediche"
              element={
                <PrivateRouteCliente>
                  <VisiteMedicheCliente />
                </PrivateRouteCliente>
              }
            />
            {/* <Route path="/idoneitaStop" element={<PrivateRoute><IdoneitaAllegate /></PrivateRoute>} />*/}
            <Route
              path="/AreaClienti/idoneita"
              element={
                <PrivateRouteCliente>
                  <MedicinaCliente />
                </PrivateRouteCliente>
              }
            />
          </Route>

          {/* Lavoratori */}
          <Route
            path="/AreaLavoratore/setPassword"
            element={<SetPasswordLavoratore />}
          />
          <Route path="/AreaLavoratore/Login" element={<LoginLavoratore />} />

          <Route //AccessoLavoratore
            path="/AreaLavoratore"
            element={
              <PrivateRouteCliente>
                <AppContentLavoratori />
              </PrivateRouteCliente>
            }
          >
            <Route
              path="/AreaLavoratore/Home"
              element={
                <PrivateRouteLavoratore>
                  <HomeLavoratori />
                </PrivateRouteLavoratore>
              }
            />
            <Route
              path="/AreaLavoratore/Documenti"
              element={
                <PrivateRouteLavoratore>
                  <AllegatiLavoratori />
                </PrivateRouteLavoratore>
              }
            />
            <Route
              path="/AreaLavoratore/Formazione"
              element={
                <PrivateRouteLavoratore>
                  <FormazioneLavoratore />
                </PrivateRouteLavoratore>
              }
            />
            <Route
              path="/AreaLavoratore/Medicina"
              element={
                <PrivateRouteLavoratore>
                  <MedicinaLavoratore />
                </PrivateRouteLavoratore>
              }
            />
            <Route
              path="/AreaLavoratore/Profilo"
              element={
                <PrivateRouteLavoratore>
                  <ProfiloLavoratore />
                </PrivateRouteLavoratore>
              }
            />
          </Route>

          {/* FINE Lavoratori */}

          {/* MEDICO */}
          <Route
            path="/AreaMedico/setPassword"
            element={<SetPasswordMedico />}
          />
          <Route path="/AreaMedico/Login" element={<LoginMedico />} />

          <Route //AccessoLavoratore
            path="/AreaMedico"
            element={
              <PrivateRouteMedico>
                <AppContentMedico />
              </PrivateRouteMedico>
            }
          >
            <Route
              path="/AreaMedico/Home"
              element={
                <PrivateRouteMedico>
                  <HomeMedico />
                </PrivateRouteMedico>
              }
            />
            <Route
              path="/AreaMedico/Documenti"
              element={
                <PrivateRouteMedico>
                  <AllegatiMedici />
                </PrivateRouteMedico>
              }
            />
            <Route
              path="/AreaMedico/Visita"
              element={
                <PrivateRouteMedico>
                  <VisiteMedici />
                </PrivateRouteMedico>
              }
            />
            <Route
              path="/AreaMedico/DettaglioVisitaMedico"
              element={
                <PrivateRouteMedico>
                  <DettaglioVisiteMedici />
                </PrivateRouteMedico>
              }
            />
            <Route
              path="/AreaMedico/Profilo"
              element={
                <PrivateRouteMedico>
                  <ProfiloMedico />
                </PrivateRouteMedico>
              }
            />
          </Route>

          {/* FINE MEDICI*/}

          <Route path="/login" element={<Login />} />
          <Route path="/setPassword" element={<SetPassword />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <AppContent />
              </PrivateRoute>
            }
          >
            <Route path="/" element={<Homepage screenSize={screenSize} />} />

            {/* <Route
              path="/Chat"
              element={
                <PrivateRoute>
                  <Chat screenSize={screenSize} />
                </PrivateRoute>
              }
            /> */}
            <Route
              path="/dettaglioCliente"
              element={
                <PrivateRoute>
                  <ClientDetail screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/distaccati"
              element={
                <PrivateRoute>
                  <Distaccati screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/dettaglioSedi"
              element={
                <PrivateRoute>
                  <SediDetail screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/kpiMedicina"
              element={
                <PrivateRoute>
                  <KpiMedicina screenSize={screenSize} />
                </PrivateRoute>
              }
            />

            <Route
              path="/Consumi"
              element={
                <PrivateRoute>
                  <Consumi screenSize={screenSize} />
                </PrivateRoute>
              }
            />

            <Route
              path="/kpiFormazione"
              element={
                <PrivateRoute>
                  {" "}
                  <KpiFormazione screenSize={screenSize} />{" "}
                </PrivateRoute>
              }
            />

            <Route
              path="/clienti"
              element={
                <PrivateRoute>
                  <Clienti screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/utentiClienti"
              element={
                <PrivateRoute>
                  <UtentiClienti screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/Parita"
              element={
                <PrivateRoute>
                  <ParitaClienti screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/Livelli"
              element={
                <PrivateRoute>
                  <LivelliParita screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/Qualifica-Contrattuale"
              element={
                <PrivateRoute>
                  <GruppiParita screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/CCNL-Applicato"
              element={
                <PrivateRoute>
                  <TipologieCcnlParita screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/Inquadramento"
              element={
                <PrivateRoute>
                  <CcnlDescrizioneParita screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/Kpi"
              element={
                <PrivateRoute>
                  <KpiParita screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/dettaglioParita"
              element={
                <PrivateRoute>
                  <DettaglioParita screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/RiepilogativoVisite"
              element={
                <PrivateRoute>
                  <RiepilogativoVisite screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/CollegaVirtuale"
              element={
                <PrivateRoute>
                  <CollegaVirtuale screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/Adempimenti"
              element={
                <PrivateRoute>
                  <Adempimenti screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/Attrezzature"
              element={
                <PrivateRoute>
                  <Attrezzature screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/dettaglioLavoratore"
              element={
                <PrivateRoute>
                  <DettaglioLavoratore screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/medici"
              element={
                <PrivateRoute>
                  <Medici screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/dettaglioMedico"
              element={
                <PrivateRoute>
                  <DettaglioMedico screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/Anomalie"
              element={
                <PrivateRoute>
                  <Anomalie screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/visiteMediche"
              element={
                <PrivateRoute>
                  <VisiteMediche screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/Allegato3B"
              element={
                <PrivateRoute>
                  <Allegati3B screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/ProceduraGuidata"
              element={
                <PrivateRoute>
                  <ProceduraGuidata screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/PrefatturazioneMedicina"
              element={
                <PrivateRoute>
                  <PrefatturazioneMedicina screenSize={screenSize} />
                </PrivateRoute>
              }
            />

            <Route
              path="/AnagraficaCorsi"
              element={
                <PrivateRoute>
                  <AnagarficaCorsi screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/Corsi"
              element={
                <PrivateRoute>
                  <Corsi screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/LavoratoriSicurezza"
              element={
                <PrivateRoute>
                  <LavoratoriSicurezza screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/CorsiAttestati"
              element={
                <PrivateRoute>
                  <CorsiAttestati screenSize={screenSize} />
                </PrivateRoute>
              }
            />

            <Route
              path="/PrefatturazioneCorsi"
              element={
                <PrivateRoute>
                  <PrefatturazioneCorsi screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/Formatori"
              element={
                <PrivateRoute>
                  <Formatori screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/Scadenzario"
              element={
                <PrivateRoute>
                  <Scadenzario screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/ScadenzarioMedicina"
              element={
                <PrivateRoute>
                  <ScadenzarioVisite screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/Memo"
              element={
                <PrivateRoute>
                  <Memo screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/MansioniSicurezza"
              element={
                <PrivateRoute>
                  <MansioniSicurezza screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/CategoriaAdempimenti"
              element={
                <PrivateRoute>
                  <CategoriaAdempimenti screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/AnagraficaRischi"
              element={
                <PrivateRoute>
                  <AnagraficaRischi screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/AnagraficaAccertamenti"
              element={
                <PrivateRoute>
                  <AnagraficaAccertamenti screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/AnagraficaMansioni"
              element={
                <PrivateRoute>
                  <AnagraficaMansioni screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/RiepilogativoFormazione"
              element={
                <PrivateRoute>
                  <RiepilogativoFormazione screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/AnagraficheDPI"
              element={
                <PrivateRoute>
                  <AnagraficheDPI screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/Miglioramenti"
              element={
                <PrivateRoute>
                  <Miglioramenti screenSize={screenSize} />
                </PrivateRoute>
              }
            />
            <Route
              path="/AgentiBiologici"
              element={
                <PrivateRoute>
                  <AgentiBiologici screenSize={screenSize} />
                </PrivateRoute>
              }
            />
              <Route
                path="/TipologiePrestazioni"
                element={
                  <PrivateRoute>
                    <TipologiePrestazioni screenSize={screenSize} />
                  </PrivateRoute>
                }
              />
            {/* CHIUSURA CONTENT */}
          </Route>
          <Route
            path="/Idoneita"
            element={
              <PrivateRoute>
                <Idoneita screenSize={screenSize} />
              </PrivateRoute>
            }
          />

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;

/*


*/
const NotFoundPage = () => {
  return (
    <div
      style={{
        height: "100vh",
        backgroundColor: "#11526F",
        paddingTop: "20%",
        paddingBottom: "20%",
      }}
    >
      <center>
        <h1 style={{ color: "white", fontSize: 100 }}>404</h1>
        <h3 style={{ color: "white" }}>PAGINA NON TROVATA</h3>
        <p>
          <Link to="/" style={{ color: "white" }}>
            HOMEPAGE
          </Link>
        </p>
      </center>
    </div>
  );
};
