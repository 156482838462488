import React, { useEffect, useState } from "react";
import unsplash from "../api/unsplash";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
  Tooltip,
  Checkbox,
  Chip,
  Select,
  Card,
  Grid,
  CardContent,
  OutlinedInput,
  MenuItem,
  ListItemText,
  FormControl,
  InputLabel,
  FormControlLabel,
  Zoom,
  LinearProgress,
} from "@mui/material";
import {
  DataGridPro,
  GridToolbar,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";
import {
  Add,
  Circle,
  Search,
  HideSource,
  Delete,
  Person,
  PersonOff,
  Close,
  ConnectingAirportsOutlined,
  Edit,
  Flight,
} from "@mui/icons-material";
import GlobalSnackbar from "./Snackbar";
import Allegati from "./Allegati";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const Scadenzario = () => {
  const [scadenzeCorsi, setScadenzeCorsi] = useState([]);
  const [loading, setLoading] = useState(true);
  const [scadenzeCorsiBackup, setScadenzeCorsiBackup] = useState([]);
  const [filtro, setFiltro] = useState({
    verde: true,
    arancione: true,
    rosso: true,
    viola: true,
    blue: true,
    attivi: true,
    passivi: false,
  });
  const [corsi, setCorsi] = useState([]);
  const [corsiSel, setCorsiSel] = useState([]);
  const [lavoratore, setLavoratore] = useState(0);
  const [loaded, setLoaded] = useState(true);

  const columns = [
    {
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: 60,
      headerAlign: "center",
      align: "center",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <IconButton
            onClick={async () => {
              await setLavoratore(params.row.Lavoratore);
              document.getElementById("allegati").click();
            }}
          >
            <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
          </IconButton>
        </>
      ),
    },
    {
      field: "D",
      headerName: "D",
      width: 10,

      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <div>
          {params.row.RagioneSocialeDa ? (
            <Tooltip title={"Distaccato da " + params.row.RagioneSocialeDa}>
              <Flight
                className="hover:cursor-pointer"
                sx={{ rotate: "135deg", color: "#9059C3" }}
              />
            </Tooltip>
          ) : (
            ""
          )}
        </div>
      ),
      ///<Flight  sx={{rotate:"135deg",, color: "#9059C3"}}/>
    },
    {
      filterable: false,
      field: "DataTermine",
      headerName: "Attivo",
      width: 60,
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => {
        let attivo =
          !params.row.DataTermine ||
          moment(params.row.DataTermine).format("YYYY-MM-DD") >
            moment(new Date()).format("YYYY-MM-DD");

        return (
          <div>
            {attivo ? (
              <Tooltip title="Attivo">
                <Person sx={{ color: "#40C18F" }} />
              </Tooltip>
            ) : (
              <Tooltip title="Cessato">
                <PersonOff sx={{ color: "#FF2768" }} />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      field: "Cliente",
      headerName: "Cliente",
      //width: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Cognome",
      headerName: "Cognome",
      //width: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Nome",
      headerName: "Nome",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "*",
      headerName: "Corsi",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      valueGetter: (params, row) => {
        let scadenze = "";
        params.row?.Corsi.map((c) => {
          if (corsiSel.length > 0 && corsiSel.includes(c.Ruolo)) {
            scadenze += `${c.Ruolo}: ${c.Scadenza} `;
          }else if (corsiSel.length === 0){
            scadenze += `${c.Ruolo}: ${c.Scadenza} `;
          }
        });
        return scadenze;
      },
      renderCell: (params) => {
        return (
          <div>
            {params.row.Corsi.map((c) => {
              let colore = "",
                testo = "";
              let date = new Date();
              let show = true;
              // add a day
              date.setDate(date.getDate() + 90);
              if (c.BaseOk === 0) {
                colore = "#8E75AC";
                testo = "Corso Base mancante";
                if (filtro.viola === true) {
                  show = true;
                } else {
                  show = false;
                }
              } else if (c.BaseOk === 0 && c.Scadenza !== "") {
                colore = "#11526f";
                testo = "Aggiornamento senza corso base";
                if (filtro.blue === true) {
                  show = true;
                } else {
                  show = false;
                }
              } else if (c.Scadenza === "") {
                colore = "#40C18F";
                testo = "Nessuna scadenza";
                if (filtro.verde === true) {
                  show = true;
                } else {
                  show = false;
                }
              } else if (
                moment(c.Scadenza).format("YYYY-MM-DD") <
                moment(new Date()).format("YYYY-MM-DD")
              ) {
                colore = "#FF2768";
                testo = "Scaduto il " + moment(c.Scadenza).format("DD/MM/YYYY");

                if (filtro.rosso === true) {
                  show = true;
                } else {
                  show = false;
                }
              } else if (
                moment(c.Scadenza).format("YYYY-MM-DD") <
                moment(date).format("YYYY-MM-DD")
              ) {
                colore = "#FFC897";
                testo =
                  "In scadenza il " + moment(c.Scadenza).format("DD/MM/YYYY");
                if (filtro.arancione === true) {
                  show = true;
                } else {
                  show = false;
                }
              } else {
                colore = "#40C18F";
                testo = "Scadrà il " + moment(c.Scadenza).format("DD/MM/YYYY");
                if (filtro.verde === true) {
                  show = true;
                } else {
                  show = false;
                }
              }
              let showCorso = true;
              if (corsiSel.length > 0 && !corsiSel.includes(c.Ruolo)) {
                showCorso = false;
              }
              if (show && showCorso) {
                return (
                  <Tooltip title={testo}>
                    <Chip
                      label={c.Ruolo}
                      size="small"
                      sx={{
                        width: "70px",
                        marginLeft: "2px",
                        backgroundColor:
                          c.BaseOk === 0 && c.Scadenza !== "" && filtro.blue
                            ? "#11526f"
                            : colore,
                        color: "white",
                      }}
                    />
                  </Tooltip>
                );
              }
            })}
          </div>
        );
      },
    },
    {
      field: "Descrizione",
      headerName: "Mansione",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "MansioniSicurezza",
      headerName: "MansioniSicurezza",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Note",
      headerName: "Note",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Sedi",
      headerName: "Sede",
      //width: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />

          <Tooltip title="Ok">
            <Checkbox
              icon={<HideSource />}
              checked={filtro.verde}
              checkedIcon={<Circle />}
              sx={{ color: "#40C18F!important" }}
              onClick={(e) => filterData(e, "verde", corsiSel)}
            />
          </Tooltip>
          <Tooltip title="In scadenza">
            <Checkbox
              icon={<HideSource />}
              checked={filtro.arancione}
              checkedIcon={<Circle />}
              sx={{ color: "#FFC897!important" }}
              onClick={(e) => filterData(e, "arancione", corsiSel)}
            />
          </Tooltip>
          <Tooltip title="Scaduti">
            <Checkbox
              icon={<HideSource />}
              checked={filtro.rosso}
              checkedIcon={<Circle />}
              sx={{ color: "#FF2768!important" }}
              onClick={(e) => filterData(e, "rosso", corsiSel)}
            />
          </Tooltip>
          <Tooltip title="Corso base mancante">
            <Checkbox
              icon={<HideSource />}
              checked={filtro.viola}
              checkedIcon={<Circle />}
              sx={{ color: "#8E75AC!important" }}
              onClick={(e) => filterData(e, "viola", corsiSel)}
            />
          </Tooltip>
          <Tooltip title="Aggiornamento senza corso base">
            <Checkbox
              icon={<HideSource />}
              checked={filtro.blue}
              checkedIcon={<Circle />}
              sx={{ color: "#11526f!important" }}
              onClick={(e) => filterData(e, "blue", corsiSel)}
            />
          </Tooltip>
          <FormControlLabel
            sx={{ marginBottom: "0px!important" }}
            control={
              <Checkbox
                checked={filtro.attivi}
                onClick={(e) => filterData(e, "attivi", corsiSel)}
              />
            }
            label="Attivi"
          />
          <FormControlLabel
            sx={{ marginBottom: "0px!important" }}
            control={
              <Checkbox
                checked={filtro.passivi}
                onClick={(e) => filterData(e, "passivi", corsiSel)}
              />
            }
            label="Passivi"
          />
        </Grid>
      </GridToolbarContainer>
    );
  }

  const filterData = (e, colore, corsi) => {
    let f = { ...filtro };
    if (colore) {
      f[colore] = e.target.checked;
      setFiltro(f);
    }
    let scadenzeTemp = scadenzeCorsiBackup;
    let checkArr = []; //metto i colori che in base al flag popolo

    if (f.verde) {
      checkArr.push("verde");
    }
    if (f.arancione) {
      checkArr.push("arancione");
    }
    if (f.rosso) {
      checkArr.push("rosso");
    }

    if (f.viola) {
      checkArr.push("viola");
    }

    if (f.rosso && f.verde && f.viola && f.arancione && corsi.length === 0) {
    } else {
      if (corsi.length > 0) {
        scadenzeTemp = scadenzeTemp.filter(
          (s) =>
            s.Corsi.filter(
              (sf) => checkArr.includes(sf.Colore) && corsi.includes(sf.Ruolo)
            ).length > 0
        );
      } else {
        scadenzeTemp = scadenzeTemp.filter(
          (s) => s.Corsi.filter((sf) => checkArr.includes(sf.Colore)).length > 0
        );
      }
    }

    if (!f.attivi) {
      scadenzeTemp = scadenzeTemp.filter(
        (v) =>
          !(
            !v.DataTermine ||
            moment(v.DataTermine).format("YYYY-MM-DD") >
              moment(new Date()).format("YYYY-MM-DD")
          )
      );
    }
    if (!f.passivi) {
      scadenzeTemp = scadenzeTemp.filter(
        (v) =>
          !v.DataTermine ||
          moment(v.DataTermine).format("YYYY-MM-DD") >
            moment(new Date()).format("YYYY-MM-DD")
      );
    }

    setScadenzeCorsi(scadenzeTemp);

    /* let visiteTemp = visite.filter(v => v.Esito >=1);
  
  setVisite(visiteTemp); */
  };

  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      try {
        let response = await unsplash.get("scadenzario", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        setScadenzeCorsi(
          response.data.data.filter(
            (v) =>
              !v.DataTermine ||
              moment(v.DataTermine).format("YYYY-MM-DD") >
                moment(new Date()).format("YYYY-MM-DD")
          )
        );
        setLoading(false);

        setScadenzeCorsiBackup(response.data.data);

        setLoaded(false);
        let responsecorsiCliente = await unsplash.get(
          "corsiCliente",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          } //props.idAzienda}}
        );

        setCorsi(responsecorsiCliente.data.data);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);

  const handleChangeCorsiSel = (event) => {
    setCorsiSel(event.target.value);
    filterData("", "", event.target.value);
  };

  return (
    <>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs="12">
                <FormControl sx={{ m: 1, width: 300 }}>
                  <InputLabel id="demo-multiple-checkbox-label">
                    Corsi
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={corsiSel}
                    onChange={handleChangeCorsiSel}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {corsi.map((c) => (
                      <MenuItem key={c.Codice} value={c.Codice}>
                        <Checkbox checked={corsiSel.indexOf(c.Codice) > -1} />
                        <ListItemText primary={c.Codice} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <PopUpCorsi lavoratore={lavoratore} />
            <Box
              sx={{
                height: { xs: "75vh", md: "60vh", xl: "75vh" },
                width: 1,
              }}
            >
              <DataGridPro
                sx={{ fontSize: 12 }}
                components={{
                  Toolbar: CustomToolbar,
                  Footer: CustomFooterPersonalized,
                }}
                slots={{
                  loadingOverlay: LinearProgress,
                }}
                loading={loading}
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                rows={scadenzeCorsi}
                initialState={{
                  pinnedColumns: { left: ["Actions", "DataTermine"] },
                }}
                columns={columns}
                getRowId={(row) => row.Lavoratore}
                pageSize={100}
                disableSelectionOnClick
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default Scadenzario;

const PopUpCorsi = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        aria-label="edit"
        id="allegati"
        onClick={handleClickOpen}
        sx={{ display: "None" }}
      >
        Allegati
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={"xl"}
        maxWidth={"xl"}
        TransitionComponent={Transition}
      >
        <DialogTitle
          sx={{
            paddingTop: "10px",

            textAlign: "center",
          }}
        >
          Corso
        </DialogTitle>
        <DialogContent dividers={false}>
          <div style={{ marginTop: "16px" }}>
            <Allegati
              idLavoratore={props.lavoratore}
              id={props.lavoratore}
              colonna="Lavoratore"
            />
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            px: 3,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={handleClose}
          >
            Chiudi
          </Button>
          {/* <Button onClick={() => props.myFunction(props.id)} >
            Conferma
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
};
