import React, { useEffect, useState } from "react";
import unsplash from "../api/unsplash";
import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";

import {
  Delete,
  Add,
  Check,
  Close,
  Engineering,
  Construction,
  Warning,
} from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import {
  TextField,
  Button,
  Box,
  Typography,
  Tab,
  Tabs,
  Grid,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ButtonGroup,
  IconButton,
  Zoom,
  Autocomplete,
  LinearProgress,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import GlobalSnackbar from "./Snackbar";
import PropTypes from "prop-types";
import EngineeringIcon from "@mui/icons-material/Engineering";
import StarIcon from "@mui/icons-material/Star";
/*  */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <GridToolbarQuickFilter placeholder="Cerca..." />
      </Grid>
    </GridToolbarContainer>
  );
}
const MansioniSicurezza = (props) => {
  const [mansioniSicurezza, setMansioniSicurezza] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rischiSicurezza, setRischiSicurezza] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleSaving = async (operazione, mansione, after, before) => {
    //after sono i rischiSicurezza aggiornati
    //before sono i rischiSicurezza vecchi se presenti
    if (operazione === "Inserimento") {
      try {
        await unsplash.post(
          "mansioniSicurezza",
          { mansione: mansione, cliente: props.idAzienda },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        setMansioniSicurezza([]);
        let responseMansioniSicurezza = await unsplash.get(
          "mansioniSicurezza",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setMansioniSicurezza(responseMansioniSicurezza.data.data);

        setSnack({
          severity: "success",
          messaggio: "Mansione inserita con successo",
        });
        setOpenSnack(true);
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante l'inserimento",
        });
        setOpenSnack(true);
      }
    } else {
      try {
        await unsplash.post(
          "rischiSicurezza",
          {
            mansione: mansione,
            cliente: props.idAzienda,
            after: after,
            before: before,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Mansione modificata con successo",
        });
        setOpenSnack(true);
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante la modifica",
        });
        setOpenSnack(true);
      }
    }
  };

  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "150",
      headerAlign: "center",
      align: "center",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <Modal
            check={false}
            title="Modifica"
            tipologia="Modifica"
            mansioniSicurezza={mansioniSicurezza}
            selezionata={params.row.ID}
            cliente={props.idAzienda}
            idAzienda={props.idAzienda}
            testoSel={params.row.Testo}
            descrizioneSel={params.row.Descrizione}
            reloadData={()=>reloadData()}
            mansione={params.row}
            myFunction={(operazione, mansione, after, before) =>
              handleSaving(operazione, mansione, after, before)
            }
          />
          <DeleteRow
            check={false}
            title="Elimina Mansione Sicurezza"
            tipologia="Elimina"
            mansioniSicurezza={mansioniSicurezza}
            tot={params.row}
            selezionata={params.row.ID}
            cliente={props.idAzienda}
            snack={snack}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
            myFunction={() => reloadData()}
          />
        </>
      ),
    },
    { field: "Descrizione", headerName: "Descrizione", flex: 1 },
  ];

  const reloadData = async () => {
    let responseMansioniSicurezza = await unsplash.get("mansioniSicurezza", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: props.idAzienda, sede: props.sede },
    });
    setMansioniSicurezza(responseMansioniSicurezza.data.data);
  };

  useEffect(() => {
    //carico le mansioniSicurezza del cliente
    const fetchData = async () => {
      let responseMansioniSicurezza = await unsplash.get("mansioniSicurezza", {
        params: { id: props.idAzienda, sede: props.sede },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setMansioniSicurezza(responseMansioniSicurezza.data.data);
      let responseRischiSicurezza = await unsplash.get("rischiSicurezza", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setRischiSicurezza(responseRischiSicurezza.data.data);
      setLoading(false);
    };
    fetchData();
  }, []);
  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Grid
        container
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="flex-end"
      >
        <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
        >
          <Modal
            check={true}
            title="Aggiungi"
            tipologia="Inserimento"
            mansioniSicurezza={mansioniSicurezza}
            rischiSicurezza={rischiSicurezza}
            selezionata="NO"
            cliente={props.idAzienda}
            idAzienda={props.idAzienda}
            sede={props.sede}
            myFunction={() => reloadData()}
          />
        </ButtonGroup>
      </Grid>
      <div style={{ height: "60vh", width: "100%" }}>
        <DataGridPro
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooterPersonalized,
          }}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          loading={loading}
          initialState={{ pinnedColumns: { left: ["Actions"] } }}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          rows={mansioniSicurezza}
          getRowId={(row) => row.ID}
          columns={columns}
          disableSelectionOnClick
        />
      </div>
    </>
  );
};

//MODAL
const Modal = (props) => {
  const [visible, setVisible] = useState(false);
  const [mansioniNonAssociate, setMansioniNonAssociate] = useState([]);
  const [rischiSicurezza, setRischiSicurezza] = useState([]);
  const [relRischiSicurezza, setRelRischiSicurezza] = useState([]);
  const [loading, setLoading] = useState(true);
  const [relAttrezzature, setRelAttrezzature] = useState([]);
  const [loadingAtt, setLoadingAtt] = useState(true);
  const [relMansioniSicurezza, setRelMansioniSicurezza] = useState([]);
  const [loadingSic, setLoadingSic] = useState(true);
  const [attrezzature, setAttrezzature] = useState([]);
  const [relMansioniSicurezzaNonAss, setRelMansioniSicurezzaNonAss] = useState(
    []
  );
  const [mansione, setMansione] = useState(null);
  const [mansioneSel, setMansioneSel] = useState(null);
  const [nuova, setNuova] = useState(false);
  const [descrizioneMansione, setDescrizioneMansione] = useState("");
  const [testoMansione, setTestoMansione] = useState("");
  const [value, setValue] = useState(0);

  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleOpen = async () => {
    setNuova(false);

    if (props.tipologia === "Modifica") {
      setDescrizioneMansione(props.descrizioneSel);
      setTestoMansione(props.testoSel);
      let responseAttrezzature = await unsplash.get(
        "atrezzattureMansioniSicurezza",
        {
          params: { IDRelClientiMansioniSicurezza: props.selezionata },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setRelAttrezzature(responseAttrezzature.data.data);
      setLoadingAtt(false);
      let response = await unsplash.get("attrezzature", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setAttrezzature(response.data.data);

      let responseRischiSicurezza = await unsplash.get("rischiSicurezza", {
        params: { id: props.selezionata },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setRelRischiSicurezza(responseRischiSicurezza.data.data);
      console.log(responseRischiSicurezza.data.data);
      setLoading(false);
      //prendo le Mansioni Medicina già associate
      let responseMansioniMedicina = await unsplash.get("mansioniSicurezza", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { mansione: props.selezionata },
      });

      setRelMansioniSicurezza(responseMansioniMedicina.data.data);
      setLoadingSic(false);

      let responseMansioni = await unsplash.get("mansioni", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { id: props.idAzienda },
      });
      setRelMansioniSicurezzaNonAss(responseMansioni.data.data);
    } else {
      setDescrizioneMansione("");
      //prendo mansioni ASSOCIATE
      let responseMansioniSicurezza = await unsplash.get("mansioniSicurezza", {
        params: { id: props.idAzienda, rel: true },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      /* const idAssociati = responseMansioniSicurezza.data.data.map(
        (el) => el.ID_Mansioni
      ); */

      //prendo mansioni NON ASSOCIATE
      let responseMansioniSicurezzaNuove = await unsplash.get(
        "mansioniSicurezza",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      const differenceMansioniSicurezza =
        responseMansioniSicurezzaNuove.data.data.filter(
          (el) =>
            props.mansioniSicurezza
              .map((mans) => mans.ID_Mansioni)
              .indexOf(el.ID) === -1
        );
      setMansioniNonAssociate(differenceMansioniSicurezza);
    }

    let responseRischiSicurezza = await unsplash.get("rischiSicurezza", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setRischiSicurezza(responseRischiSicurezza.data.data);
  };

  const handleSaveModal = async () => {
    if (nuova) {
      let mansioneEsistente = mansioniNonAssociate.filter(
        (item) =>
          item.Descrizione.toLowerCase().replaceAll(" ", "") ===
          descrizioneMansione.toLowerCase().replaceAll(" ", "")
      );

      if (mansioneEsistente.length > 0) {
        setSnack({
          severity: "error",
          messaggio: "Mansione già esistente",
        });
        setOpenSnack(true);
        return;
      } else {
        try {
          await unsplash.post(
            "mansioniSicurezza",
            { descrizione: descrizioneMansione },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );
          setSnack({
            severity: "success",
            messaggio: "Mansione creata con successo",
          });
          setOpenSnack(true);
        } catch {
          setSnack({
            severity: "error",
            messaggio: "Errore durante la creazione della mansione",
          });
          setOpenSnack(true);
        }
      }
    } else {
      let manSicu = {
        ID_Mansioni: mansione,
        ID_Cliente: props.cliente,
        ID_Sede: props.sede,
      };
      try {
        await unsplash.post(
          "mansioniSicurezza",
          { mansioneSicu: manSicu },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Mansione creata con successo",
        });
        setOpenSnack(true);
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante la creazione della mansione",
        });
        setOpenSnack(true);
      }
    }
    setMansione(null);
    setMansioneSel(null);
    setVisible(false);
    props.myFunction();
  };
  const columnsMansioni = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "150",
      headerAlign: "center",
      align: "center",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <ModalMansioniMedicina
            check={false}
            title="Modifica"
            tipologia="Modifica"
            mansione={params.row}
            mansioniSicurezzaAssociati={relMansioniSicurezza}
            mansioniSicurezzaNonAssociati={relMansioniSicurezzaNonAss}
            snack={snack}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
            setSnack={setSnack}
            reload={() => reloadDataMansioniSicurezza()}
            errorMsg={() => msg(false, "la modifica")}
            success={() => msg(true, "Mansione modificata")}
          />
          <DeleteRow
            check={false}
            title="Elimina Mansione"
            tipologia="Elimina"
            tot={params.row}
            deleteMansione={true}
            snack={snack}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
            myFunction={() => reloadDataMansioniSicurezza()}
          />
        </>
      ),
    },
    { field: "Descrizione", headerName: "Descrizione", flex: 1 },
  ];

  const columnsRischi = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "200",
      headerAlign: "center",
      align: "center",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <ModalRischi
            check={false}
            title="Modifica"
            tipologia="Modifica"
            rischio={params.row}
            rischiSicurezzaAssociati={relRischiSicurezza}
            rischiSicurezzaNonAssociati={rischiSicurezza}
            snack={snack}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
            setSnack={setSnack}
            reload={() => reloadData()}
            errorMsg={() => msg(false, "la modifica")}
            success={() => msg(true, "Rischio modificato")}
          />
          <ModalDPI
            rischio={params.row}
            idAzienda={props.idAzienda}
            idMansione={props.selezionata}
          />
          <ModalMiglioramenti
            rischio={params.row}
            idAzienda={props.idAzienda}
            idMansione={props.selezionata}
          />
          <DeleteRow
            check={false}
            title="Elimina Rischio"
            tipologia="Elimina"
            tot={params.row}
            deleteRischi={true}
            snack={snack}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
            myFunction={() => reloadData()}
          />
        </>
      ),
    },
    { field: "Nome", headerName: "Rischio", flex: 1 },
    {
      field: "Gravita",
      headerName: "Danno",
      flex: 1,
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => {
        let val = params.row.Gravita;
        switch (val) {
          case 1:
            return "LIEVE";
            break;
          case 2:
            return "MODESTO";
            break;
          case 3:
            return "GRAVE";
            break;
          case 4:
            return "GRAVISSIMO";
            break;

          default:
            break;
        }
      },
    },
    {
      field: "Probabilita",
      headerName: "Probabilita",
      flex: 1,
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => {
        let val = params.row.Probabilita;
        switch (val) {
          case 1:
            return "IMPROBABILE";
            break;
          case 2:
            return "POCO PROBABILE";
            break;
          case 3:
            return "PROBABILE";
            break;
          case 4:
            return "MOLTO PROBABILE";
            break;

          default:
            break;
        }
      },
    },
  ];

  const columnAttrezzature = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "150",
      headerAlign: "center",
      align: "center",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <ModalAttrezzature
            check={false}
            title="Modifica"
            tipologia="Modifica"
            attrezzatura={params.row}
            attrezzature={attrezzature}
            snack={snack}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
            reload={() => reloadAttrezzature()}
            errorMsg={() => msg(false, "la modifica")}
            success={() => msg(true, "Attrezzatura modificata")}
          />
          <DeleteRow
            check={false}
            title="Elimina Attrezzatura"
            tipologia="Elimina"
            tot={params.row}
            deleteAttrezzature={true}
            snack={snack}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
            myFunction={() => reloadAttrezzature()}
          />
        </>
      ),
    },
    {
      field: "Attrezzatura",
      headerName: "Attrezzature",
      hide: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
  ];

  const updateTestoMansione = async () => {
    console.log({
      Testo: testoMansione,
      Descrizione: descrizioneMansione,
      ID: props.mansione.ID_Mansioni,
    });
    try {
      await unsplash.put(
        "anagraficaMansioni",
        {
          categoria: {
            Testo: testoMansione,
            Descrizione: descrizioneMansione,
            ID: props.mansione.ID_Mansioni,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setSnack({
        severity: "success",
        messaggio: "Mansione aggiornata con successo",
      });
      setOpenSnack(true);
      props.reloadData();
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'aggiornamento",
      });
      setOpenSnack(true);
    }
  };
  const reloadAttrezzature = async () => {
    let responseAttrezzature = await unsplash.get(
      "atrezzattureMansioniSicurezza",
      {
        params: { IDRelClientiMansioniSicurezza: props.selezionata },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    setRelAttrezzature(responseAttrezzature.data.data);
    let response = await unsplash.get("attrezzature", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setAttrezzature(response.data.data);
  };
  const reloadDataMansioniSicurezza = async () => {
    let responseMansioniMedicina = await unsplash.get("mansioniSicurezza", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { mansione: props.selezionata },
    });

    setRelMansioniSicurezza(responseMansioniMedicina.data.data);
  };
  const reloadData = async () => {
    let responseRischiSicurezza = await unsplash.get("rischiSicurezza", {
      params: { id: props.selezionata },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setRelRischiSicurezza(responseRischiSicurezza.data.data);
  };
  const msg = async (res, msg) => {
    if (res) {
      setSnack({
        severity: "success",
        messaggio: `${msg} con successo`,
      });
      setOpenSnack(true);
    } else {
      setSnack({ severity: "error", messaggio: `Errore durante ${msg}` });
      setOpenSnack(true);
    }
  };
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      {props.title === "Aggiungi" ? (
        <Button
          onClick={() => {
            handleOpen();
            setVisible(!visible);
          }}
          sx={{ color: "white" }}
          variant="outlined"
          className="ButtonMiller"
          startIcon={<Add />}
        >
          Aggiungi
        </Button>
      ) : (
        <Tooltip title="Modifica">
          <IconButton
            onClick={() => {
              handleOpen();
              setVisible(!visible);
            }}
            aria-label="edit"
          >
            <EditIcon
              style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
            />
          </IconButton>
        </Tooltip>
      )}

      <Dialog
        open={visible}
        onClose={() => setVisible(false)}
        fullWidth={true}
        maxWidth="lg"
        TransitionComponent={Transition}
      >
        <GlobalSnackbar
          severity={snack.severity}
          messaggio={snack.messaggio}
          openSnack={openSnack}
          handleCloseSnack={handleCloseSnack}
        />
        <DialogTitle>{props.title} Mansione</DialogTitle>
        <DialogContent>
          {props.tipologia === "Modifica" ? (
            <>
              <TextField
                label="Descrizione"
                fullWidth
                style={{ marginTop: "5px" }}
                value={descrizioneMansione}
                disabled={true}
                onChange={(e) => {
                  setDescrizioneMansione(e.target.value);
                }}
              />
              <TextField
                label="Testo"
                fullWidth
                multiline
                style={{ marginTop: "10px" }}
                value={testoMansione}
                onChange={(e) => {
                  setTestoMansione(e.target.value);
                }}
              />
              <div className="w-full flex justify-end mt-3">
                <Button
                  onClick={() => updateTestoMansione()}
                  sx={{ color: "white" }}
                  variant="outlined"
                  className="ButtonMiller"
                  startIcon={<Check />}
                >
                  Salva
                </Button>
              </div>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  textColor="inherit"
                  variant="scrollable"
                  onChange={handleChangeTab}
                  aria-label="basic tabs example"
                >
                  <Tab icon={<Warning />} iconPosition="start" label="Rischi" />
                  <Tab
                    icon={<EngineeringIcon />}
                    iconPosition="start"
                    label="Mansioni"
                  />
                  <Tab
                    icon={<Construction />}
                    iconPosition="start"
                    label="Attrezzature"
                  />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Grid item xs={12} md={12}>
                  <ModalRischi
                    idMansioneRel={props.selezionata}
                    check={true}
                    title="Aggiungi"
                    tipologia="Inserimento"
                    rischiSicurezzaAssociati={relRischiSicurezza}
                    rischiSicurezzaNonAssociati={rischiSicurezza}
                    snack={snack}
                    openSnack={openSnack}
                    handleCloseSnack={handleCloseSnack}
                    setSnack={setSnack}
                    errorMsg={() => msg(false, "la creazione")}
                    success={() => msg(true, "Rischio creato")}
                    reload={() => reloadData()}
                  />
                  {/* QUANDO la mansione è stata selezionata faccio comparire tutti i rischi associati in una tabella */}
                  <Box
                    sx={{
                      height: 400,
                      width: "100%",
                      "& .azzurro": {
                        backgroundColor: "#C5D9F0",
                        color: "#000000",
                      },
                      "& .verde": {
                        backgroundColor: "#40C18F",
                        color: "#000000",
                      },
                      "& .arancio": {
                        backgroundColor: "#FD912A",
                        color: "#000000",
                      },
                      "& .rosso": {
                        backgroundColor: "#FF2768",
                        color: "#000000",
                      },
                    }}
                  >
                    <DataGridPro
                      components={{
                        Toolbar: CustomToolbar,
                        Footer: CustomFooterPersonalized,
                      }}
                      localeText={
                        itIT.components.MuiDataGrid.defaultProps.localeText
                      }
                      slots={{
                        loadingOverlay: LinearProgress,
                      }}
                      loading={loading}
                      rows={relRischiSicurezza}
                      initialState={{ pinnedColumns: { left: ["Actions"] } }}
                      getRowId={(relRischiSicurezza) => relRischiSicurezza.ID}
                      columns={columnsRischi}
                      disableSelectionOnClick
                      getCellClassName={(params) => {
                        if (params.field === "Actions") {
                          return "";
                        }
                        let livello =
                          params.row.Gravita * params.row.Probabilita;
                        return livello === 1
                          ? "azzurro"
                          : 2 <= livello && livello <= 4
                          ? "verde"
                          : 5 <= livello && livello <= 8
                          ? "arancio"
                          : livello > 8
                          ? "rosso"
                          : "";
                      }}
                    />
                  </Box>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid item xs={12} md={12}>
                  <ModalMansioniMedicina
                    idMansioneRel={props.selezionata}
                    idAzienda={props.idAzienda}
                    check={true}
                    title="Aggiungi"
                    tipologia="Inserimento"
                    mansioniSicurezzaAssociati={relMansioniSicurezza}
                    mansioniSicurezzaNonAssociati={relMansioniSicurezzaNonAss}
                    rischiSicurezzaAssociati={relRischiSicurezza}
                    rischiSicurezzaNonAssociati={rischiSicurezza}
                    snack={snack}
                    openSnack={openSnack}
                    handleCloseSnack={handleCloseSnack}
                    setSnack={setSnack}
                    errorMsg={() => msg(false, "la creazione")}
                    success={() => msg(true, "Mansione creata")}
                    reload={() => reloadDataMansioniSicurezza()}
                  />

                  {/* QUANDO la mansione è stata selezionata faccio comparire tutti i rischi associati in una tabella */}
                  <Box
                    sx={{
                      height: 400,
                      width: "100%",
                    }}
                  >
                    <DataGridPro
                      components={{
                        Toolbar: CustomToolbar,
                        Footer: CustomFooterPersonalized,
                      }}
                      slots={{
                        loadingOverlay: LinearProgress,
                      }}
                      loading={loadingSic}
                      localeText={
                        itIT.components.MuiDataGrid.defaultProps.localeText
                      }
                      initialState={{ pinnedColumns: { left: ["Actions"] } }}
                      rows={relMansioniSicurezza}
                      getRowId={(mansioni) => mansioni.ID}
                      columns={columnsMansioni}
                      disableSelectionOnClick
                    />
                  </Box>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Grid item xs={12} md={12}>
                  <ModalAttrezzature
                    idMansioneRel={props.selezionata}
                    idAzienda={props.idAzienda}
                    attrezzature={attrezzature}
                    check={true}
                    title="Aggiungi"
                    tipologia="Inserimento"
                    mansioniSicurezzaAssociati={relMansioniSicurezza}
                    mansioniSicurezzaNonAssociati={relMansioniSicurezzaNonAss}
                    rischiSicurezzaAssociati={relRischiSicurezza}
                    rischiSicurezzaNonAssociati={rischiSicurezza}
                    snack={snack}
                    openSnack={openSnack}
                    setSnack={setSnack}
                    setOpenSnack={setOpenSnack}
                    handleCloseSnack={handleCloseSnack}
                    errorMsg={() => msg(false, "la creazione")}
                    success={() => msg(true, "Mansione creata")}
                    reload={() => reloadAttrezzature()}
                  />

                  {/* QUANDO la mansione è stata selezionata faccio comparire tutti i rischi associati in una tabella */}
                  <Box
                    sx={{
                      height: 400,
                      width: "100%",
                    }}
                  >
                    <DataGridPro
                      components={{
                        Toolbar: CustomToolbar,
                        Footer: CustomFooterPersonalized,
                      }}
                      slots={{
                        loadingOverlay: LinearProgress,
                      }}
                      loading={loadingAtt}
                      localeText={
                        itIT.components.MuiDataGrid.defaultProps.localeText
                      }
                      initialState={{ pinnedColumns: { left: ["Actions"] } }}
                      rows={relAttrezzature}
                      getRowId={(mansioni) => mansioni.ID}
                      columns={columnAttrezzature}
                      disableSelectionOnClick
                    />
                  </Box>
                </Grid>
              </TabPanel>
            </>
          ) : (
            <>
              <FormControlLabel
                control={
                  <Checkbox checked={nuova} onChange={() => setNuova(!nuova)} />
                }
                label="Nuova"
              />
              <TextField
                label="Descrizione"
                fullWidth
                style={{ display: nuova ? "" : "None" }}
                value={descrizioneMansione}
                onChange={(e) => {
                  setDescrizioneMansione(e.target.value);
                }}
              ></TextField>

              {/* <FormControl fullWidth style={{ display: nuova ? "None" : "" }}>
                <InputLabel id="demo-multiple-name-label">Mansione</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  onChange={(e) => {
                    setMansione(e.target.value);
                    console.log(e.target.value);
                    //handleRelRischiSicurezza(e.target.value);
                  }}
                  input={<OutlinedInput label="Mansione" />}
                >
                  {mansioniNonAssociate?.map((mansione) => (
                    <MenuItem key={mansione.ID} value={mansione.ID}>
                      {mansione.Descrizione}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
              <Grid item xs={12} md={12}>
                <Autocomplete
                  id="combo-box-demo"
                  options={mansioniNonAssociate}
                  getOptionLabel={(option) => option.Descrizione}
                  value={mansioneSel}
                  fullWidth
                  onChange={(event, newValue) => {
                    setMansione(newValue?.ID);
                    setMansioneSel(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Mansione" />
                  )}
                />
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            mt: 2,
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={() => {
              setVisible(false);
              setMansioneSel(null);
            }}
          >
            Chiudi
          </Button>
          <Button
            style={{ display: props.tipologia === "Modifica" ? "None" : "" }}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={handleSaveModal}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ModalRischi = (props) => {
  const [visible, setVisible] = useState(false);
  const [relRischiNonAssociati, setRelRischiNonAssociati] = useState([]);
  const [rischio, setRischio] = useState({
    ID_Rischi: 0,
    ID_RelClientiMansioniSicurezza: props.idMansioneRel,
    Probabilita: 0,
    Gravita: 0,
  });
  const [probabilita, setProbabilita] = useState([
    { ID: 1, Descrizione: "IMPROBABILE" },
    { ID: 2, Descrizione: "POCO PROBABILE" },
    { ID: 3, Descrizione: "PROBABILE" },
    { ID: 4, Descrizione: "MOLTO PROBABILE" },
  ]);
  const [gravita, setGravita] = useState([
    { ID: 1, Descrizione: "LIEVE" },
    { ID: 2, Descrizione: "MODESTO" },
    { ID: 3, Descrizione: "GRAVE" },
    { ID: 4, Descrizione: "GRAVISSIMO" },
  ]);

  const [openSnack, setOpenSnack] = useState(false);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleOpen = async () => {
    const rischiAssociati = props.rischiSicurezzaAssociati.map(
      (el) => el.ID_Rischi
    );
    if (props.tipologia === "Modifica") {
      let ris = { ...props.rischio };
      setRischio(ris);
      const rischi = props.rischiSicurezzaNonAssociati.filter((elm) => {
        return (
          rischiAssociati.indexOf(elm.ID) === -1 ||
          elm.ID === props.rischio.ID_Rischi
        );
      });
      setRelRischiNonAssociati(rischi);
    } else {
      const rischi = props.rischiSicurezzaNonAssociati.filter((elm) => {
        return rischiAssociati.indexOf(elm.ID) === -1;
      });
      setRelRischiNonAssociati(rischi);
    }
  };

  const handleSaveModal = async () => {
    if (props.tipologia === "Inserimento") {
      try {
        const responseNewMansione = await unsplash.post(
          "rischiSicurezza",
          { relRischiSicurezza: rischio },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        props.success();
        props.reload();
      } catch {
        props.errorMsg();
      }
      setVisible(false);
    } else {
      try {
        const responseNewMansione = await unsplash.put(
          "rischiSicurezza",
          { relRischiSicurezza: rischio },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        props.success();
        props.reload();
      } catch {
        props.errorMsg();
      }
      setVisible(false);
    }
  };

  return (
    <>
      {props.title === "Aggiungi" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "5px",
            alignItems: "center",
          }}
        >
          <h4 style={{ marginBottom: 0, width: "100%", textAlign: "center" }}>
            Rischi Mansione
          </h4>
          <Button
            onClick={() => {
              handleOpen();
              setVisible(!visible);
            }}
            sx={{ color: "white" }}
            variant="outlined"
            className="ButtonMiller"
            startIcon={<Add />}
          >
            Aggiungi
          </Button>
        </div>
      ) : (
        <Tooltip title="Modifica">
          <IconButton
            onClick={() => {
              handleOpen();
              setVisible(!visible);
            }}
            aria-label="edit"
          >
            <EditIcon
              style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
            />
          </IconButton>
        </Tooltip>
      )}
      <Dialog
        open={visible}
        onClose={() => setVisible(false)}
        fullWidth={true}
        maxWidth="lg"
        TransitionComponent={Transition}
      >
        <DialogTitle>{props.title} Rischio</DialogTitle>
        <DialogContent>
          <GlobalSnackbar
            severity={snack.severity}
            messaggio={snack.messaggio}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
          />
          <Grid xs={12} md={12} item fullWidth sx={{ marginTop: "5px" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-multiple-name-label">Rischio</InputLabel>
              <Select
                value={rischio.ID_Rischi}
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                onChange={(e) => {
                  let rischi = { ...rischio };
                  rischi.ID_Rischi = e.target.value;
                  setRischio(rischi);
                }}
                input={<OutlinedInput label="Rischio" />}
              >
                {relRischiNonAssociati?.map((mansione) => (
                  <MenuItem key={mansione.ID} value={mansione.ID}>
                    {mansione.Nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid container fullWidth sx={{ marginTop: "10px" }} spacing={2}>
            <Grid xs={12} md={6} item fullWidth>
              <FormControl fullWidth>
                <InputLabel id="demo-multiple-name-label">Danno</InputLabel>
                <Select
                  value={rischio.Gravita}
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  onChange={(e) => {
                    let rischi = { ...rischio };
                    rischi.Gravita = e.target.value;
                    setRischio(rischi);
                  }}
                  input={<OutlinedInput label="Danno" />}
                >
                  {gravita?.map((grav) => (
                    <MenuItem key={grav.ID} value={grav.ID}>
                      {grav.Descrizione}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={12} md={6} item fullWidth>
              <FormControl fullWidth>
                <InputLabel id="demo-multiple-name-label">
                  Probabilità
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  value={rischio.Probabilita}
                  onChange={(e) => {
                    let rischi = { ...rischio };
                    rischi.Probabilita = e.target.value;
                    setRischio(rischi);
                  }}
                  input={<OutlinedInput label="Probabilità" />}
                >
                  {probabilita?.map((mansione) => (
                    <MenuItem key={mansione.ID} value={mansione.ID}>
                      {mansione.Descrizione}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid xs={12} md={12} item fullWidth sx={{ marginTop: "5px" }}>
              <TextField
                value={rischio?.AzioneDiMiglioramento}
                //id="outlined-basic"
                label="Azioni di Miglioramento"
                name="AzioneDiMiglioramento"
                onChange={(e) => {
                  let rischi = { ...rischio };
                  rischi.AzioneDiMiglioramento = e.target.value;
                  setRischio(rischi);
                }}
                fullWidth
                type="text"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={() => {
              setVisible(false);
              setRischio({
                ID_Rischi: 0,
                ID_RelClientiMansioniSicurezza: 0,
                Probabilita: 0,
                Gravita: 0,
              });
            }}
          >
            Chiudi
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={() => handleSaveModal()}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ModalMansioniMedicina = (props) => {
  const [visible, setVisible] = useState(false);
  const [relMansioniNonAssociate, setRelMansioniNonAssociate] = useState([]);
  const [relMansioniSicurezzaMedicina, setRelMansioniSicurezzaMedicina] =
    useState({
      ID_MansioniMedicina: 0,
      ID_MansioniSicurezza: props.idMansioneRel,
    });

  const [openSnack, setOpenSnack] = useState(false);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleOpen = async () => {
    const soloIdAssociati = props.mansioniSicurezzaAssociati.map(
      (el) => el.ID_MansioniMedicina
    );
    if (props.tipologia === "Modifica") {
      let ris = { ...props.mansione };
      setRelMansioniSicurezzaMedicina(ris);
      const rischi = props.mansioniSicurezzaNonAssociati.filter((elm) => {
        return (
          soloIdAssociati.indexOf(elm.ID_RelMansioni) === -1 ||
          elm.ID_RelMansioni === props.mansione.ID_MansioniMedicina
        );
      });
      setRelMansioniNonAssociate(rischi);
    } else {
      const mansioniNonAssociate = props.mansioniSicurezzaNonAssociati.filter(
        (el) => {
          return soloIdAssociati.indexOf(el.ID_RelMansioni) === -1;
        }
      );
      setRelMansioniNonAssociate(mansioniNonAssociate);
    }
  };

  const handleSaveModal = async () => {
    if (props.tipologia === "Inserimento") {
      try {
        const responseNewMansione = await unsplash.post(
          "mansioniSicurezza",
          { mansione: relMansioniSicurezzaMedicina },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        props.success();
        props.reload();
      } catch {
        props.errorMsg();
      }
      setVisible(false);
    } else {
      //modifica
      try {
        const responseNewMansione = await unsplash.put(
          "mansioniSicurezza",
          { mansione: relMansioniSicurezzaMedicina },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        props.success();
        props.reload();
      } catch {
        props.errorMsg();
      }
      setVisible(false);
    }
  };

  return (
    <>
      {props.title === "Aggiungi" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "5px",
            alignItems: "center",
          }}
        >
          <h4 style={{ marginBottom: 0, width: "100%", textAlign: "center" }}>
            Mansioni Medicina
          </h4>
          <Button
            onClick={() => {
              handleOpen();
              setVisible(!visible);
            }}
            sx={{ color: "white" }}
            variant="outlined"
            className="ButtonMiller"
            startIcon={<Add />}
          >
            Aggiungi
          </Button>
        </div>
      ) : (
        <Tooltip title="Modifica">
          <IconButton
            onClick={() => {
              handleOpen();
              setVisible(!visible);
            }}
            aria-label="edit"
          >
            <EditIcon
              style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
            />
          </IconButton>
        </Tooltip>
      )}
      <Dialog
        open={visible}
        onClose={() => setVisible(false)}
        fullWidth={true}
        maxWidth="lg"
        TransitionComponent={Transition}
      >
        <DialogTitle>{props.title} Mansione Medicina</DialogTitle>
        <DialogContent>
          <GlobalSnackbar
            severity={snack.severity}
            messaggio={snack.messaggio}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
          />
          <Grid xs={12} md={12} item fullWidth sx={{ marginTop: "5px" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-multiple-name-label">
                Mansione Medicina
              </InputLabel>
              <Select
                value={relMansioniSicurezzaMedicina.ID_MansioniMedicina}
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                onChange={(e) => {
                  let mans = { ...relMansioniSicurezzaMedicina };
                  mans.ID_MansioniMedicina = e.target.value;
                  setRelMansioniSicurezzaMedicina(mans);
                }}
                input={<OutlinedInput label="Mansione Medicina" />}
              >
                {relMansioniNonAssociate?.map((mansione) => (
                  <MenuItem
                    key={mansione.ID_RelMansioni}
                    value={mansione.ID_RelMansioni}
                  >
                    {mansione.Descrizione}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={() => {
              setVisible(false);
              setRelMansioniSicurezzaMedicina({
                ID_MansioneMedicina: 0,
                ID_RelClientiMansioniSicurezza: 0,
              });
            }}
          >
            Chiudi
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={() => handleSaveModal()}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const DeleteRow = (props) => {
  const [visible, setVisible] = useState(false);

  const handleDelete = async () => {
    let idMansione = props.tot.ID;
    if (props.deleteAttrezzature) {
      try {
        await unsplash.delete("atrezzattureMansioniSicurezza", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          data: { ID: props.tot.ID },
        });

        props.setSnack({
          severity: "success",
          messaggio: "Eliminazione effettuata correttamente",
        });
        props.setOpenSnack(true);
      } catch {
        props.setSnack({
          severity: "error",
          messaggio: "Errore durante la creazione",
        });
        props.setOpenSnack(true);
      }
    } else if (props.deleteMansione) {
      try {
        await unsplash.delete("mansioniSicurezza", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          data: { mansione: props.tot.ID },
        });

        props.setSnack({
          severity: "success",
          messaggio: "Eliminazione effettuata correttamente",
        });
        props.setOpenSnack(true);
      } catch {
        props.setSnack({
          severity: "error",
          messaggio: "Errore durante la creazione",
        });
        props.setOpenSnack(true);
      }
    } else if (props.deleteRischi) {
      try {
        await unsplash.delete("rischiSicurezza", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          data: { id: props.tot.ID },
        });

        props.setSnack({
          severity: "success",
          messaggio: "Eliminazione effettuata correttamente",
        });
        props.setOpenSnack(true);
      } catch {
        props.setSnack({
          severity: "error",
          messaggio: "Errore durante la creazione",
        });
        props.setOpenSnack(true);
      }
    } else {
      try {
        await unsplash.delete("mansioniSicurezza", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          data: { id: idMansione },
        });
        //prendo tutti i rischi collegati alla mansione clienti
        let responseRischiSicurezza = await unsplash.get("rischiSicurezza", {
          params: { id: idMansione },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        const idRischiDaEliminare = responseRischiSicurezza.data.data.map(
          (elm) => elm.ID
        );

        let responseMansioniMedicina = await unsplash.get("mansioniSicurezza", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { mansione: props.selezionata },
        });
        const idMansioniDaEliminare = responseMansioniMedicina.data.data.map(
          (elm) => elm.ID
        );

        if (idMansioniDaEliminare.length > 0) {
          await unsplash.delete("mansioniSicurezza", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
            data: { mansione: idMansioniDaEliminare },
          });

          props.setSnack({
            severity: "success",
            messaggio: "Eliminazione effettuata correttamente",
          });
          props.setOpenSnack(true);
        } else {
          props.setSnack({
            severity: "success",
            messaggio: "Eliminazione effettuata correttamente",
          });
          props.setOpenSnack(true);
        }
        if (idRischiDaEliminare.length > 0) {
          /* await unsplash.delete("rischiSicurezza", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
            data: { id: idRischiDaEliminare },
          }); */

          props.setSnack({
            severity: "success",
            messaggio: "Eliminazione effettuata correttamente",
          });
          props.setOpenSnack(true);
        } else {
          props.setSnack({
            severity: "success",
            messaggio: "Eliminazione effettuata correttamente",
          });
          props.setOpenSnack(true);
        }
      } catch {
        props.setSnack({
          severity: "error",
          messaggio: "Errore durante la l'eliminazione",
        });
        props.setOpenSnack(true);
      }
    }
    setVisible(false);
    props.myFunction();
  };
  const handleClose = () => {
    setVisible(false);
  };
  return (
    <>
      <Tooltip title="Elimina">
        <IconButton
          onClick={() => {
            setVisible(!visible);
          }}
          aria-label="edit"
        >
          <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
        </IconButton>
      </Tooltip>
      <Dialog
        open={visible}
        onClose={() => setVisible(false)}
        fullWidth={true}
        maxWidth="xs"
        TransitionComponent={Transition}
      >
        <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare:
            <br />
            <b>
              {props.deleteAttrezzature
                ? props.tot.Attrezzatura
                : props.tot.Nome}
            </b>
            ?
          </DialogContentText>
        </DialogContent>

        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button onClick={() => setVisible(false)}>Annulla</Button>
          <Button onClick={() => handleDelete()}>Conferma</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const DeleteRowMiglioramentiAssociato = (props) => {
  const [visible, setVisible] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleDelete = async () => {
    try {
      await unsplash.delete("mansioniRischiMiglioramento", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: { ID: props.Miglioramenti.ID },
      });
      props.setSnack({
        severity: "success",
        messaggio: "Miglioramento eliminato correttamente",
      });
      props.setOpenSnack(true);
    } catch {
      props.setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione del Miglioramento",
      });
      props.setOpenSnack(true);
    }
    props.loadMiglioramenti();
    setVisible(false);
  };

  return (
    <>
      <Tooltip title="Elimina">
        <IconButton
          onClick={() => {
            setVisible(!visible);
          }}
          aria-label="edit"
        >
          <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
        </IconButton>
      </Tooltip>
      <Dialog
        open={visible}
        onClose={() => setVisible(false)}
        fullWidth={true}
        maxWidth="xs"
        TransitionComponent={Transition}
      >
        <DialogTitle>Eliminazione DPI</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare:
            <br />
            <b>{props.Miglioramenti.Miglioramento}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 2,
          }}
        >
          <Button onClick={() => setVisible(false)}>Annulla</Button>
          <Button onClick={() => handleDelete()}>Conferma</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
const DeleteRowDpiAssociato = (props) => {
  const [visible, setVisible] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleDelete = async () => {
    try {
      await unsplash.delete("mansioniRischiDPI", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: { ID: props.Dpi.ID },
      });
      props.setSnack({
        severity: "success",
        messaggio: "DPI eliminato correttamente",
      });
      props.setOpenSnack(true);
    } catch {
      props.setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione del DPI",
      });
      props.setOpenSnack(true);
    }
    props.loadDPI();
    setVisible(false);
  };

  return (
    <>
      <Tooltip title="Elimina">
        <IconButton
          onClick={() => {
            setVisible(!visible);
          }}
          aria-label="edit"
        >
          <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
        </IconButton>
      </Tooltip>
      <Dialog
        open={visible}
        onClose={() => setVisible(false)}
        fullWidth={true}
        maxWidth="xs"
        TransitionComponent={Transition}
      >
        <DialogTitle>Eliminazione DPI</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare:
            <br />
            <b>{props.Dpi.Tipologia}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 2,
          }}
        >
          <Button onClick={() => setVisible(false)}>Annulla</Button>
          <Button onClick={() => handleDelete()}>Conferma</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ModalMiglioramenti = (props) => {
  const [miglioramenti, setMiglioramenti] = useState([]);
  const [listaMiglioramenti, setListaMiglioramenti] = useState([]);
  const [miglioramentiAssociati, setMiglioramentiAssociati] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [miglioramento, setMiglioramento] = useState({
    ID_Rischi: 0,
    ID_RelClientiMansioniSicurezza: props.idMansioneRel,
    Eliminato: 0,
  });

  const [openSnack, setOpenSnack] = useState(false);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleOpen = async () => {
    /* let responseMiglioramenti = await unsplash.get("anagraficaMiglioramenti", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setMiglioramenti(responseMiglioramenti.data.data);

    let responseProtezioni = await unsplash.get("protezioni", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setProtezioni(responseProtezioni.data.data); */
    let response = await unsplash.get("miglioramenti", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    setListaMiglioramenti(response.data.data);
    let responseMansioniRischiMiglioramenti = await unsplash.get(
      "mansioniRischiMiglioramento",
      {
        params: {
          IDMansioneSicurezza: props.idMansione,
          IDRischioMansioneSicurezza: props.rischio.ID,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    setMiglioramentiAssociati(responseMansioniRischiMiglioramenti.data.data);
    setLoading(false);
  };

  const columnsMiglioramenti = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "150",
      headerAlign: "center",
      align: "center",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <DeleteRowMiglioramentiAssociato
            Miglioramenti={params.row}
            loadMiglioramenti={loadMiglioramenti}
            snack={snack}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
          />
        </>
      ),
    },
    { field: "Miglioramento", headerName: "Miglioramenti", flex: 1 },
    { field: "Note", headerName: "Note", flex: 1 },
  ];

  const loadMiglioramenti = async () => {
    let responseMansioniRischiMiglioramenti = await unsplash.get(
      "mansioniRischiMiglioramento",
      {
        params: {
          IDMansioneSicurezza: props.idMansione,
          IDRischioMansioneSicurezza: props.rischio.ID,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    setMiglioramentiAssociati(responseMansioniRischiMiglioramenti.data.data);
  };

  return (
    <>
      <Tooltip title="Miglioramenti">
        <IconButton
          onClick={() => {
            handleOpen();
            setVisible(!visible);
          }}
          aria-label="edit"
        >
          <StarIcon
            style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
          />
        </IconButton>
      </Tooltip>
      <Dialog
        open={visible}
        onClose={() => setVisible(false)}
        fullWidth={true}
        maxWidth="lg"
        TransitionComponent={Transition}
      >
        <DialogTitle>Miglioramenti - {props.rischio.Descrizione}</DialogTitle>
        <DialogContent>
          <GlobalSnackbar
            severity={snack.severity}
            messaggio={snack.messaggio}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
          />
          <Grid
            container
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="flex-end"
            sx={{ paddingTop: "40px" }}
          >
            <ButtonGroup
              variant="contained"
              aria-label="outlined primary button group"
            >
              <ModalMiglioramentiAssocia
                /* protezioni={protezioni}*/
                miglioramenti={listaMiglioramenti}
                rischio={props.rischio}
                idAzienda={props.idAzienda}
                idMansione={props.idMansione}
                loadMiglioramenti={loadMiglioramenti}
                snack={snack}
                openSnack={openSnack}
                handleCloseSnack={handleCloseSnack}
                setSnack={setSnack}
                setOpenSnack={setOpenSnack}
              />
            </ButtonGroup>
          </Grid>
          <Grid item xs={12} md={12}>
            {/* QUANDO la mansione è stata selezionata faccio comparire tutti i rischi associati in una tabella */}
            <Box
              sx={{
                height: 400,
                width: "100%",
              }}
            >
              <DataGridPro
                components={{
                  Toolbar: CustomToolbar,
                  Footer: CustomFooterPersonalized,
                }}
                slots={{
                  loadingOverlay: LinearProgress,
                }}
                loading={loading}
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                initialState={{ pinnedColumns: { left: ["Actions"] } }}
                rows={miglioramentiAssociati}
                getRowId={(miglioramenti) => miglioramenti.ID}
                columns={columnsMiglioramenti}
                disableSelectionOnClick
              />
            </Box>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={() => {
              setVisible(false);
              setMiglioramento({
                ID_Rischi: 0,
                ID_RelClientiMansioniSicurezza: 0,
                Probabilita: 0,
                Gravita: 0,
              });
            }}
          >
            Chiudi
          </Button>
          {/* <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={() => handleSaveModal()}
          >
            Salva
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};
const ModalDPI = (props) => {
  const [dpi, setDpi] = useState([]);
  const [protezioni, setProtezioni] = useState([]);
  const [dpiAssociati, setDpiAssociati] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [rischio, setRischio] = useState({
    ID_Rischi: 0,
    ID_RelClientiMansioniSicurezza: props.idMansioneRel,
    Probabilita: 0,
    Gravita: 0,
  });
  const [probabilita, setProbabilita] = useState([
    { ID: 1, Descrizione: "IMPROBABILE" },
    { ID: 2, Descrizione: "POCO PROBABILE" },
    { ID: 3, Descrizione: "PROBABILE" },
    { ID: 4, Descrizione: "MOLTO PROBABILE" },
  ]);
  const [gravita, setGravita] = useState([
    { ID: 1, Descrizione: "LIEVE" },
    { ID: 2, Descrizione: "MODESTO" },
    { ID: 3, Descrizione: "GRAVE" },
    { ID: 4, Descrizione: "GRAVISSIMO" },
  ]);

  const [openSnack, setOpenSnack] = useState(false);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleOpen = async () => {
    let responseDpi = await unsplash.get("anagraficaDPI", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setDpi(responseDpi.data.data);

    let responseProtezioni = await unsplash.get("protezioni", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setProtezioni(responseProtezioni.data.data);
    let responseMansioniRischiDPI = await unsplash.get("mansioniRischiDPI", {
      params: {
        IDMansioneSicurezza: props.idMansione,
        IDRischioMansioneSicurezza: props.rischio.ID,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setDpiAssociati(responseMansioniRischiDPI.data.data);
    setLoading(false);
  };

  const columnsDpi = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "150",
      headerAlign: "center",
      align: "center",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <DeleteRowDpiAssociato
            Dpi={params.row}
            loadDPI={loadDPI}
            snack={snack}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
          />
        </>
      ),
    },
    {
      field: "Immagine",
      headerName: "Pittogramma",
      width: "120",
      renderCell: (params) => (
        <img
          style={{ width: "40px" }}
          src={
            params.row.Immagine
              ? "data:image/png;base64," + params.row.Immagine
              : require("../assets/images/MILLERLogo.jpg")
          }
        />
      ),
    },
    {
      field: "PittogrammiCar",
      headerName: "Caratteristiche",
      flex: 1,
      renderCell: (params) => {
        return params.row.PittogrammiCar?.map((el) => (
          <img
            style={{ width: "50px" }}
            src={
              el.PittogrammiCar
                ? "data:image/png;base64," + el.PittogrammiCar
                : require("../assets/images/MILLERLogo.jpg")
            }
          />
        ));
      },
    },
    { field: "Tipologia", headerName: "Tipologia", flex: 1 },
  ];

  const loadDPI = async () => {
    let responseMansioniRischiDPI = await unsplash.get("mansioniRischiDPI", {
      params: {
        IDMansioneSicurezza: props.idMansione,
        IDRischioMansioneSicurezza: props.rischio.ID,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setDpiAssociati(responseMansioniRischiDPI.data.data);
  };

  return (
    <>
      <Tooltip title="DPI">
        <IconButton
          onClick={() => {
            handleOpen();
            setVisible(!visible);
          }}
          aria-label="edit"
        >
          <Engineering
            style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
          />
        </IconButton>
      </Tooltip>
      <Dialog
        open={visible}
        onClose={() => setVisible(false)}
        fullWidth={true}
        maxWidth="lg"
        TransitionComponent={Transition}
      >
        <DialogTitle>DPI - {props.rischio.Nome}</DialogTitle>
        <DialogContent>
          <GlobalSnackbar
            severity={snack.severity}
            messaggio={snack.messaggio}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
          />
          <Grid
            container
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="flex-end"
            sx={{ paddingTop: "40px" }}
          >
            <ButtonGroup
              variant="contained"
              aria-label="outlined primary button group"
            >
              <ModalDPIAssocia
                protezioni={protezioni}
                dpi={dpi}
                rischio={props.rischio}
                idAzienda={props.idAzienda}
                idMansione={props.idMansione}
                loadDPI={loadDPI}
                snack={snack}
                openSnack={openSnack}
                handleCloseSnack={handleCloseSnack}
                setSnack={setSnack}
                setOpenSnack={setOpenSnack}
              />
            </ButtonGroup>
          </Grid>
          <Grid item xs={12} md={12}>
            {/* QUANDO la mansione è stata selezionata faccio comparire tutti i rischi associati in una tabella */}
            <Box
              sx={{
                height: 400,
                width: "100%",
              }}
            >
              <DataGridPro
                components={{
                  Toolbar: CustomToolbar,
                  Footer: CustomFooterPersonalized,
                }}
                slots={{
                  loadingOverlay: LinearProgress,
                }}
                loading={loading}
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                initialState={{ pinnedColumns: { left: ["Actions"] } }}
                rows={dpiAssociati}
                getRowId={(dpi) => dpi.ID}
                columns={columnsDpi}
                disableSelectionOnClick
              />
            </Box>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={() => {
              setVisible(false);
              setRischio({
                ID_Rischi: 0,
                ID_RelClientiMansioniSicurezza: 0,
                Probabilita: 0,
                Gravita: 0,
              });
            }}
          >
            Chiudi
          </Button>
          {/* <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={() => handleSaveModal()}
          >
            Salva
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

const ModalMiglioramentiAssocia = (props) => {
  const [miglioramenti, setMiglioramenti] = useState([]);
  const [miglioramentiSel, setMiglioramentiSel] = useState(null);
  const [visible, setVisible] = useState(false);
  const [idMiglioramento, setIdMiglioramento] = useState(null);
  const [note, setNote] = useState("");
  const [openSnack, setOpenSnack] = useState(false);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleOpen = async () => {
    setVisible(!visible);
    setMiglioramenti(props.miglioramenti);
  };

  const saveMiglioramentoRschio = async (idDpi) => {
    let obj = {};
    obj.IDMansioneSicurezza = props.idMansione;
    obj.IDRischioMansioneSicurezza = props.rischio.ID;
    obj.IDCliente = props.idAzienda;
    obj.Note = note;
    obj.IDMiglioramento = idMiglioramento;
    try {
      const responseNewMansione = await unsplash.post(
        "mansioniRischiMiglioramento",
        { mansioniRischiMiglioramento: obj },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      props.setSnack({
        severity: "success",
        messaggio: "Associazione Miglioramento effettuata correttamente",
      });
      props.setOpenSnack(true);
    } catch {
      props.setSnack({
        severity: "error",
        messaggio: "Errore durante la creazione del Miglioramento",
      });
      props.setOpenSnack(true);
    }

    setVisible(false);
    setNote("");
    setMiglioramentiSel(null);
    setIdMiglioramento(null);
    props.loadMiglioramenti();
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        sx={{ color: "white" }}
        variant="outlined"
        className="ButtonMiller"
        startIcon={<Add />}
      >
        Aggiungi
      </Button>
      <Dialog
        open={visible}
        onClose={() => setVisible(false)}
        fullWidth={true}
        maxWidth="lg"
        TransitionComponent={Transition}
      >
        <GlobalSnackbar
          severity={snack.severity}
          messaggio={snack.messaggio}
          openSnack={openSnack}
          handleCloseSnack={handleCloseSnack}
        />
        <DialogTitle>Associa Miglioramento</DialogTitle>
        <DialogContent style={{ paddingTop: "30px" }}>
          <GlobalSnackbar
            severity={snack.severity}
            messaggio={snack.messaggio}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
          />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                value={miglioramentiSel}
                onChange={(event, newValue) => {
                  setMiglioramentiSel(newValue);
                  if (newValue) {
                    setIdMiglioramento(newValue.ID);
                  }
                }}
                options={miglioramenti}
                getOptionLabel={(option) => option.Miglioramento}
                renderInput={(params) => (
                  <TextField {...params} label="Miglioramento" />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                value={note}
                label="Note"
                onChange={(e) => setNote(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={() => setVisible(false)}
          >
            Chiudi
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={saveMiglioramentoRschio}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
const ModalDPIAssocia = (props) => {
  const [dpi, setDpi] = useState([]);
  const [protezioni, setProtezioni] = useState([]);
  const [protezioneSel, setProtezioneSel] = useState(null);
  const [pittCaratteristiche, setPittCaratteristiche] = useState([]);
  const [visible, setVisible] = useState(false);
  const [dpiSel, setDpiSel] = useState(null);
  const [note, setNote] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleOpen = async () => {
    setVisible(!visible);
    setProtezioni(props.protezioni);
  };

  const handleSelect = (event) => {
    const selectedValues = event.target.value;
    setSelectedOptions(selectedValues);
  };

  const loadPittogrammiCaratteristiche = async (idDpi) => {
    let responseDpi = await unsplash.get("pittogrammaCaratteristiche/:id", {
      params: {
        ID: idDpi,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setSelectedOptions([]);
    setPittCaratteristiche(responseDpi.data.data);
  };

  const loadDpiOnCategoria = async (idCategoria) => {
    setDpi(props.dpi.filter((d) => d.IDProtezione === idCategoria));
  };

  const saveDpiRschio = async (idDpi) => {
    let obj = {};
    obj.IDMansioneSicurezza = props.idMansione;
    obj.IDRischioMansioneSicurezza = props.rischio.ID;
    obj.IDCliente = props.idAzienda;
    obj.Note = note;
    obj.IDDPI = dpiSel.ID;

    try {
      const responseNewMansione = await unsplash.post(
        "mansioniRischiDPI",
        { mansioniRischiDPI: obj },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      let insertId = responseNewMansione.data.message.insertId;
      let arr = selectedOptions.map((el) => el?.IDPittogrammaCaratteristiche);
      if (arr.length > 0) {
        await unsplash.post(
          "relMansioniRischiDPICaratteristiche",
          {
            IDRelMansioniRischiDPI: insertId,
            IDCaratteristichePittogrammi: arr,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
      }
      props.setSnack({
        severity: "success",
        messaggio: "Associazione DPI effettuata correttamente",
      });
      props.setOpenSnack(true);
    } catch {
      props.setSnack({
        severity: "error",
        messaggio: "Errore durante la creazione del DPI",
      });
      props.setOpenSnack(true);
    }

    setVisible(false);
    setNote("");
    setProtezioneSel(null);
    setDpiSel(null);
    setSelectedOptions([]);
    //IDRelMansioniRischiDPI,IDCaratteristichePittogrammi
    props.loadDPI();
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        sx={{ color: "white" }}
        variant="outlined"
        className="ButtonMiller"
        startIcon={<Add />}
      >
        Aggiungi
      </Button>
      <Dialog
        open={visible}
        onClose={() => setVisible(false)}
        fullWidth={true}
        maxWidth="lg"
        TransitionComponent={Transition}
      >
        <GlobalSnackbar
          severity={snack.severity}
          messaggio={snack.messaggio}
          openSnack={openSnack}
          handleCloseSnack={handleCloseSnack}
        />
        <DialogTitle>Associa DPI</DialogTitle>
        <DialogContent style={{ paddingTop: "30px" }}>
          <GlobalSnackbar
            severity={snack.severity}
            messaggio={snack.messaggio}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
          />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                value={protezioneSel}
                onChange={(event, newValue) => {
                  setProtezioneSel(newValue);
                  if (newValue) {
                    loadDpiOnCategoria(newValue.ID);
                  } else {
                    setDpi([]);
                    setDpiSel(null);
                    setPittCaratteristiche([]);
                    setSelectedOptions([]);
                  }
                }}
                options={protezioni}
                getOptionLabel={(option) => option.Descrizione}
                renderInput={(params) => (
                  <TextField {...params} label="Categoria Protezione" />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                value={dpiSel}
                onChange={(event, newValue) => {
                  setDpiSel(newValue);

                  loadPittogrammiCaratteristiche(newValue.IDPittogramma);
                }}
                options={dpi}
                getOptionLabel={(option) => option.Tipologia}
                renderOption={(props, option) => (
                  <li {...props}>
                    <img
                      src={
                        option.Pittogramma
                          ? "data:image/png;base64," + option.Pittogramma
                          : require("../assets/images/MILLERLogo.jpg")
                      }
                      alt={option.Tipologia}
                      style={{ width: 30, marginRight: 10 }}
                    />
                    {option.Tipologia}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Dpi"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          {dpiSel && (
                            <img
                              src={
                                dpiSel.Pittogramma
                                  ? "data:image/png;base64," +
                                    dpiSel.Pittogramma
                                  : require("../assets/images/MILLERLogo.jpg")
                              }
                              alt={dpiSel.Tipologia}
                              style={{ width: 30, marginRight: 10 }}
                            />
                          )}
                          {params.InputProps.startAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Caratteristiche
                </InputLabel>
                <Select
                  label="Caratteristiche"
                  multiple
                  fullWidth
                  value={selectedOptions}
                  onChange={handleSelect}
                  renderValue={(selected) => (
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {selected.map((value) => (
                        <>
                          <Tooltip key={value} title={value.Tipologia}>
                            <img
                              src={
                                value.Immagine
                                  ? "data:image/png;base64," + value.Immagine
                                  : require("../assets/images/MILLERLogo.jpg")
                              }
                              alt={value.Tipologia}
                              style={{ width: 30, marginLeft: "5px" }}
                            />
                          </Tooltip>{" "}
                        </>
                      ))}
                    </div>
                  )}
                >
                  {pittCaratteristiche.map((option) => (
                    <MenuItem key={option} value={option}>
                      <img
                        src={
                          option.Immagine
                            ? "data:image/png;base64," + option.Immagine
                            : require("../assets/images/MILLERLogo.jpg")
                        }
                        alt={option.Tipologia}
                        style={{ width: 30, marginRight: 8 }}
                      />
                      {option.Tipologia}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                value={note}
                label="Note"
                onChange={(e) => setNote(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={() => setVisible(false)}
          >
            Chiudi
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={saveDpiRschio}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

//NON FINITO

const ModalAttrezzature = (props) => {
  const [visible, setVisible] = useState(false);
  const [nuova, setNuova] = useState(false);
  const [attrezzaturaSel, setAttrezzaturaSel] = useState(null);
  const [attrezzature, setAttrezzature] = useState([]);
  const [attrezzatura, setAttrezzatura] = useState({
    IDAttrezzatura: 0,
    IDRelClientiMansioniSicurezza: props.idMansioneRel,
    Note: "",
  });
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const loadData = async () => {
    await unsplash.get(
      "atrezzattureMansioniSicurezza",
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
  };

  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleOpen = async () => {
    setAttrezzature(props.attrezzature);
    setNuova(false);
    if (props.tipologia === "Modifica") {
      setAttrezzatura(props.attrezzatura);
      setAttrezzaturaSel(
        props.attrezzature?.filter(
          (el) => el.ID === props.attrezzatura.IDAttrezzatura
        )[0]
      );
    } else {
      setAttrezzatura({
        IDAttrezzatura: 0,
        IDRelClientiMansioniSicurezza: props.idMansioneRel,
        Note: "",
      });
      setAttrezzaturaSel(null);
    }
  };

  const handleChange = (e) => {
    let copiaDpi = { ...attrezzatura };
    copiaDpi[e.target.name] = e.target.value;
    setAttrezzatura(copiaDpi);
  };

  const handleSaveModal = async () => {
    if (nuova) {
      try {
        await unsplash.post(
          "attrezzature",
          {
            attrezzatura: attrezzatura,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Attrezzatura creata con successo",
        });
        setOpenSnack(true);
        let response = await unsplash.get("attrezzature", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        setAttrezzature(response.data.data);
        setNuova(false);
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante la creazione",
        });
        setOpenSnack(true);
      }
    } else {
      if (props.tipologia === "Inserimento") {
        try {
          await unsplash.post(
            "atrezzattureMansioniSicurezza",
            {
              IDRelClientiMansioniSicurezza:
                attrezzatura?.IDRelClientiMansioniSicurezza,
              IDAttrezzatura: attrezzatura?.IDAttrezzatura,
              Note: attrezzatura?.Note,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );
          props.setSnack({
            severity: "success",
            messaggio: "Attrezzatura associata con successo",
          });
          props.setOpenSnack(true);
        } catch {
          props.setSnack({
            severity: "success",
            messaggio: "Errore durante l'associazione",
          });
          props.setOpenSnack(true);
        }
      } else {
        try {
          await unsplash.put(
            "atrezzattureMansioniSicurezza",
            {
              ID: attrezzatura?.ID,
              Note: attrezzatura?.Note,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );
          props.setSnack({
            severity: "success",
            messaggio: "Attrezzatura aggiornata con successo",
          });
          props.setOpenSnack(true);
        } catch {
          props.setSnack({
            severity: "success",
            messaggio: "Errore durante l'aggiornamento",
          });
          props.setOpenSnack(true);
        }
      }
      setVisible(false);
    }
    props.reload();
  };

  return (
    <>
      {props.title === "Aggiungi" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "5px",
            alignItems: "center",
          }}
        >
          <h4 style={{ marginBottom: 0, width: "100%", textAlign: "center" }}>
            Attrezzature
          </h4>
          <Button
            onClick={() => {
              handleOpen();
              setVisible(!visible);
            }}
            sx={{ color: "white" }}
            variant="outlined"
            className="ButtonMiller"
            startIcon={<Add />}
          >
            Aggiungi
          </Button>
        </div>
      ) : (
        <Tooltip title="Modifica">
          <IconButton
            onClick={() => {
              handleOpen();
              setVisible(!visible);
            }}
            aria-label="edit"
          >
            <EditIcon
              style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
            />
          </IconButton>
        </Tooltip>
      )}
      <Dialog
        open={visible}
        onClose={() => setVisible(false)}
        fullWidth={true}
        maxWidth="lg"
        TransitionComponent={Transition}
      >
        <DialogTitle>Attrezzature</DialogTitle>
        <DialogContent>
          <GlobalSnackbar
            severity={snack.severity}
            messaggio={snack.messaggio}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
          />
          <Grid xs={12} md={12} item fullWidth sx={{ marginTop: "5px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={nuova}
                  onClick={(e) => setNuova(!nuova)}
                />
              }
              label="Nuova"
            />
          </Grid>
          <>
            {!nuova ? (
              <>
                <Grid xs={12} md={12} item fullWidth sx={{ marginTop: "5px" }}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={attrezzature}
                    getOptionLabel={(option) => option.Attrezzatura}
                    fullWidth
                    value={attrezzaturaSel}
                    onChange={(event, newValue) => {
                      setAttrezzaturaSel(newValue);
                      let r = { ...attrezzatura };
                      r.IDAttrezzatura = newValue ? newValue.ID : null;
                      setAttrezzatura(r);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth label="Attrezzatura" />
                    )}
                    disabled={props.tipologia !== "Inserimento"}
                  />
                </Grid>
                <Grid xs={12} md={12} item fullWidth sx={{ marginTop: "5px" }}>
                  <TextField
                    value={attrezzatura?.Note}
                    id="outlined-basic"
                    label="Note"
                    name="Note"
                    onChange={handleChange}
                    fullWidth
                    type="text"
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  xs={12}
                  md={12}
                  item
                  fullWidth
                  sx={{ marginTop: "5px" }}
                ></Grid>
                <TextField
                  value={attrezzatura?.Attrezzatura}
                  id="outlined-basic"
                  label="Attrezzatura"
                  name="Attrezzatura"
                  onChange={handleChange}
                  fullWidth
                  type="text"
                />
                <Grid xs={12} md={12} item fullWidth sx={{ marginTop: "5px" }}>
                  <TextField
                    value={attrezzatura?.Descrizione}
                    id="outlined-basic"
                    label="Descrizione"
                    name="Descrizione"
                    onChange={handleChange}
                    fullWidth
                    type="text"
                  />
                </Grid>
              </>
            )}
          </>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={() => {
              setVisible(false);
            }}
          >
            Chiudi
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={handleSaveModal}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MansioniSicurezza;
