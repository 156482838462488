import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import unsplash from "../api/unsplash";
import GlobalSnackbar from "./Snackbar";
import LinearProgress from "@mui/material/LinearProgress";
import { MimeType, TemplateHandler } from "easy-template-x";
import { saveAs } from "file-saver";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
  ReferenceLine,
} from "recharts";

import {
  Grid,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Input,
  CardActionArea,
  CardMedia,
  Divider,
  CardActions,
  InputAdornment,
  ButtonGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Zoom,
  DialogContentText,
  DialogActions,
  Autocomplete,
  Tabs,
  Tab,
  Typography,
  Snackbar,
  Alert,
  CardHeader,
  Grow,
  Popper,
  ClickAwayListener,
  MenuList,
  Paper,
  CircularProgress,
  RadioGroup,
  Radio,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import { IconButton } from "@mui/material";

import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";
import PropTypes from "prop-types";
import {
  Add,
  Delete,
  Close,
  Check,
  Search,
  MenuBook,
  Circle,
  Public,
  Send,
  DoneAll,
  ReportProblem,
  Upload,
  Print,
  Download,
  InsertDriveFile,
  ArrowDropUp,
  ArrowDropDown,
  Percent,
  CheckBoxRounded,
  ErrorOutlineRounded,
  CheckBoxOutlined,
  RemoveRedEye,
  CheckBox,
  ConnectingAirportsOutlined,
} from "@mui/icons-material";
import { createPortal } from "react-dom";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment as DateAdapter } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import "jspdf-autotable";
import { Comitato, Mansionario } from "./AccessoParita/ClienteParita";
import FileUploader from "./FileUploader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function CustomToolbarG() {
  return (
    <GridToolbarContainer>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <GridToolbarQuickFilter placeholder="Cerca..." />
      </Grid>
    </GridToolbarContainer>
  );
}

const ext = {
  doc: "application/msword",
  dot: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  dotx: "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  docm: "application/vnd.ms-word.document.macroEnabled.12",
  dotm: "application/vnd.ms-word.template.macroEnabled.12",
  xls: "application/vnd.ms-excel",
  xlt: "application/vnd.ms-excel",
  xla: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xltx: "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  xlsm: "application/vnd.ms-excel.sheet.macroEnabled.12",
  xltm: "application/vnd.ms-excel.template.macroEnabled.12",
  xlam: "application/vnd.ms-excel.addin.macroEnabled.12",
  xlsb: "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
  ppt: "application/vnd.ms-powerpoint",
  pot: "application/vnd.ms-powerpoint",
  pps: "application/vnd.ms-powerpoint",
  ppa: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  potx: "application/vnd.openxmlformats-officedocument.presentationml.template",
  ppsx: "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  ppam: "application/vnd.ms-powerpoint.addin.macroEnabled.12",
  pptm: "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
  potm: "application/vnd.ms-powerpoint.template.macroEnabled.12",
  ppsm: "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
  mdb: "application/vnd.ms-access",
  png: "application/png",
  pdf: "application/pdf",
};

const DettaglioParita = () => {
  const location = useLocation();

  const [allegati, setAllegati] = useState([]);
  const [mansionario, setMansionario] = useState([]);

  const [openButton, setOpenButton] = useState(false);
  const anchorRef = useRef(null);
  const [openButtonProcedure, setOpenButtonProcedure] = useState(false);
  const anchorRefProcedure = useRef(null);
  const optionsModuli = [
    {
      icon: <Print />,
      text: "Mod. 01 Politica",
    },
    {
      icon: <Print />,
      text: "Mod. 02 Regolamento",
    },
    {
      icon: <Print />,
      text: "Mod. 02.01 Atto Comitato",
    },
    {
      icon: <Print />,
      text: "Mod. 02.02 Nomina Comitato",
    },
    {
      icon: <Print />,
      text: "Mod. 02.03 Nomina RSGPG",
    },
    {
      icon: <Print />,
      text: "Mod. 02.04 Verbale Riunione",
    },
    {
      icon: <Print />,
      text: "Mod. 03 Dichiarazione assenza",
    },
    {
      icon: <Print />,
      text: "Mod. 03.01 Dichiarazione D.LGS 105-2022",
    },
    {
      icon: <Print />,
      text: "Mod. 04 Elenco Doc",
    },
    {
      icon: <Print />,
      text: "Mod. 05 Esigenze",
    },
    {
      icon: <Print />,
      text: "Mod. 05 Analisi",
    },
    {
      icon: <Print />,
      text: "Mod. 05.03 Mansionario",
    },
    {
      icon: <Print />,
      text: "Mod. 05.04 Identificazione",
    },
    {
      icon: <Print />,
      text: "Mod. 06 Piano Strategico",
    },
    {
      icon: <Print />,
      text: "Mod. 06.01 Scheda piano d'azione",
    },
    {
      icon: <Print />,
      text: "Mod. 07 Piano Cominicazione",
    },
    {
      icon: <Print />,
      text: "Mod. 07.01 Piano formazione",
    },
    {
      icon: <Print />,
      text: "Mod. 07.02 Informativa valorizzazione",
    },
    {
      icon: <Print />,
      text: "Mod. 07.03 Informativa congedi",
    },
    {
      icon: <Print />,
      text: "Mod. 07.04 Informativa parità",
    },
    {
      icon: <Print />,
      text: "Mod. 07.05 Rapporto",
    },
    {
      icon: <Print />,
      text: "Mod. 07.06 Questionario",
    },
    {
      icon: <Print />,
      text: "Mod. 08 Registro",
    },
    {
      icon: <Print />,
      text: "Mod. 09.01 Programma",
    },
    {
      icon: <Print />,
      text: "Mod. 09.02 Revisione",
      disabled: true,
    },
    {
      icon: <Print />,
      text: "Mod. 10.01 Rapporto NC",
    },
    {
      icon: <Print />,
      text: "Mod. 10.02 Rapporto reclami",
    },
    {
      icon: <Print />,
      text: "Mod. 10.03 Rapporto segnalazioni",
    },
    {
      icon: <Print />,
      text: "Mod. 10.04 Registro",
    },
    {
      icon: <Print />,
      text: "Mod. 10.05 Rapporto azioni",
    },
  ];
  const optionsProcedure = [
    {
      icon: <Print />,
      text: "PSGPG 01 Gestione Documentazione",
    },
    {
      icon: <Print />,
      text: "PSGPG 02 Gestione Comunicazione ",
    },
    {
      icon: <Print />,
      text: "PSGPG 03 Gestione Risorse Umane",
    },
    {
      icon: <Print />,
      text: "PSGPG 04 Contrasto violenze",
    },
    {
      icon: <Print />,
      text: "PSGPG 05 Non conformità",
    },
    {
      icon: <Print />,
      text: "PSGPG 06 Azioni correttive",
    },
    {
      icon: <Print />,
      text: "PSGPG 07 Audit interni",
    },
    {
      icon: <Print />,
      text: "PSGPG 08 Riesame Direzione",
    },
    {
      icon: <Print />,
      text: "PSGPG 09 Gestione Opinioni",
    },
  ];

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            padding: "10px",
            borderRadius: "5px",
            color: "#fff",
          }}
        >
          <p className="mb-2">{`Punteggio: ${payload[0].value}/${punteggi[0]?.Totale}`}</p>
          <p className="">{`Minimo: ${punteggi[0]?.PunteggioMinimo?.toFixed(
            2
          )}`}</p>
        </div>
      );
    }
    return null;
  };

  const [punteggi, setPunteggi] = useState(0);
  const [value, setValue] = useState(0);
  const [accessi, setAccessi] = useState([]);
  const [primaSurvey, setPrimaSurvey] = useState([]);
  const [secondaSurvey, setSecondaSurvey] = useState([]);

  const [cliente, setCliente] = useState({
    Logo: "",
    Denominazione: "",
    RagioneSociale: "",
    SedeLegale: "",
    SedeAmministrativa: "",
    Telefono: "",
    Sito: "",
    Pec: "",
    Piva: "",
    Rea: "",
    CodiceAteco: 0,
    NomeResponsabile: "",
    CognomeResponsabile: "",
    NomeRappresentante: "",
    CognomeRappresentante: "",
    DataNascitaRappresentante: null,
    LuogoNascitaRappresentante: "",
    Logo: "",
    MembriComitato: "",
    Grandezza: "",
    Cessato: 0,
    Settore: "",
    Storia: "",
    Missione: "",
  });
  const [paritaClientiSel, setParitaClientiSel] = useState([]);
  const [testoEmail, setTestoEmail] = useState("");

  const [codiciAtecoSel, setCodiciAtecoSel] = useState(null);
  const [codiciAteco, setCodiciAteco] = useState([]);

  const [comitato, setComitato] = useState([]);

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const [open, setOpen] = useState(false);
  const [openMail, setOpenMail] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleToggleButton = () => {
    setOpenButton(!openButton);
  };

  const handleMenuItemClick = (event, index) => {
    document.getElementById(`${index}`).click();
    //setSelectedIndex(index);
    setOpenButton(false);
  };

  const handleCloseButton = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenButton(false);
  };
  const handleToggleButtonProcedure = () => {
    setOpenButtonProcedure(!openButtonProcedure);
  };

  const handleMenuItemClickProcedure = (event, index) => {
    document.getElementById(`${index}`).click();
    //setSelectedIndex(index);
    setOpenButtonProcedure(false);
  };

  const handleCloseButtonProcedure = (event) => {
    if (
      anchorRefProcedure.current &&
      anchorRefProcedure.current.contains(event.target)
    ) {
      return;
    }
    setOpenButtonProcedure(false);
  };

  const primaSurveyColumns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: 70,
      renderCell: (params) => (
        <>
          <RispostaPrimaSurvey
            id="edit"
            risposta={params.row}
            saveFunction={handleSavePrimaSurvey}
            title="Modifica Risposta"
            snack={snack}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
          />
        </>
      ),
    },
    {
      field: "Domanda",
      headerName: "Domanda",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "NoteRisposta",
      headerName: "Note Risposta",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Risposta",
      headerName: "Risposta",
      width: 80,
      align: "center",
      headerAlign: "center",
      valueGetter: (params, row) => {
        let content;
        switch (params.row?.TipologiaInput) {
          case 0:
            content =
              params.row?.Risposta === "1" ? "Si" : "No"
            break;
          case 1:
            content = params.row?.Risposta ? params.row?.Risposta + "%" : "0%";
            break;
          case 3:
            if (params.row?.Risposta && params.row?.SecondaPercentuale) {
              content = params.row?.Risposta + "% - " + params.row?.SecondaPercentuale + "%";
            }
            break;

          default:
            break;
        }
        return `${content}`;
      },
      renderCell: (params) => {
        let content;
        switch (params.row?.TipologiaInput) {
          case 0:
            content =
              params.row?.Risposta === "1" ? (
                <Check sx={{ fontSize: 32, color: "#40C18F" }} />
              ) : (
                <Close sx={{ fontSize: 32, color: "#FF2768" }} />
              );
            break;
          case 1:
            content = params.row?.Risposta ? params.row?.Risposta + "%" : "0%";
            break;
          case 3:
            if (params.row?.Risposta && params.row?.SecondaPercentuale) {
              content =
                params.row?.Risposta +
                "% - " +
                params.row?.SecondaPercentuale +
                "%";
            }
            break;

          default:
            break;
        }
        {
        }
        return <>{content}</>;
      },
    },
    {
      field: "Punteggio",
      headerName: "Punteggio",
      width: 80,
      align: "center",
      headerAlign: "center",
      valueGetter: (params, row) => {
        let content = 0;
        switch (params.row?.TipologiaInput) {
          case 0:
            content = params.row?.Risposta === "1" && params.row?.Punteggi;
            break;
          case 1:
            const kpiRiferimento = location.state.kpi?.filter(
              (el) => el.Lettera === location.state.cliente?.Lettera
            );
            if (
              params.row?.TipologiaInput === 1 &&
              params.row?.Risposta &&
              params.row?.Percentuale
            ) {
              if (
                params.row?.Percentuale > 0
                  ? Number(params.row?.Risposta) >= params.row?.Percentuale
                  : //tolgo il meno davanti alla percentuale
                    Number(params.row?.Risposta) <= Math.abs(params.row?.Percentuale)
              ) {
                content = params.row?.Punteggi;
              }
            } else if (
              params.row?.TipologiaInput === 1 &&
              params.row?.Risposta &&
              !params.row?.Percentuale
            ) {
              const kpi = kpiRiferimento[0];
              if (
                kpi?.length > 0 ||
                kpi[params.row?.Benchmark] + 10 <= Number(params.row?.Risposta)
              ) {
                content = params.row?.Punteggi;
              }
            }

            break;
          case 3:
            if (Number(params.row?.Risposta) + 10 <= params.row?.SecondaPercentuale) {
              content = params.row?.Punteggi;
            }
            break;
          default:
            break;
        }
        let punteggi = params.row?.Punteggi ? params.row?.Punteggi : "";
        let con = content ? content : 0;
        return `${con}/${punteggi}`;
      },
      renderCell: (params) => {
        //return <>{params.row.Risposta === "1" ? <>{params.row.Punteggi}</> : 0}</>
        let content = 0;
        switch (params.row.TipologiaInput) {
          case 0:
            content = params.row.Risposta === "1" && params.row.Punteggi;
            break;
          case 1:
            const kpiRiferimento = location.state.kpi?.filter(
              (el) => el.Lettera === location.state.cliente?.Lettera
            );
            if (
              params.row?.TipologiaInput === 1 &&
              params.row?.Risposta &&
              params.row?.Percentuale
            ) {
              if (
                params.row?.Percentuale > 0
                  ? Number(params.row?.Risposta) >= params.row?.Percentuale
                  : //tolgo il meno davanti alla percentuale
                    Number(params.row?.Risposta) <=
                    Math.abs(params.row?.Percentuale)
              ) {
                content = params.row.Punteggi;
              }
            } else if (
              params.row?.TipologiaInput === 1 &&
              params.row?.Risposta &&
              !params.row?.Percentuale
            ) {
              const kpi = kpiRiferimento[0];
              if (
                kpi?.length > 0 ||
                kpi[params.row?.Benchmark] + 10 <= Number(params.row?.Risposta)
              ) {
                content = params.row.Punteggi;
              }
            }

            break;
          case 3:
            if (
              Number(params.row?.Risposta) + 10 <=
              params.row?.SecondaPercentuale
            ) {
              content = params.row.Punteggi;
            }
            break;
          default:
            break;
        }
        return (
          <>
            {content ? content : 0}/{params.row.Punteggi}
          </>
        );
      },
    },
  ];
  const secondaSurveyColumns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: 70,
      renderCell: (params) => (
        <>
          <RispostaSecondaSurvey
            id="edit"
            risposta={params.row}
            saveFunction={handleSavePrimaSurvey}
            title="Modifica Risposta"
            snack={snack}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
          />
        </>
      ),
    },
    {
      field: "Domanda",
      headerName: "Domanda",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Risposta",
      headerName: "Risposta",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];
  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: 130,
      renderCell: (params) => (
        <>
          <NewAccessiParita
            id="edit"
            utenteCliente={params.row}
            saveFunction={handleSaveAccessi}
            title="Modifica Utente"
            snack={snack}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
          />
          <DeleteDialog accesso={params.row} myFunction={handleDelete} />
        </>
      ),
    },
    {
      field: "*",
      headerName: "Accesso",
      width: 80,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <Circle
            sx={{
              color: params.row.Password ? "#40C18F" : "#FF2768",
              fontSize: 32,
            }}
          />
        </>
      ),
    },
    {
      field: "Username",
      headerName: "Utente",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Email",
      headerName: "Email",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];
  const columnsMansionario = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <Mansionario
            id="edit"
            mansionario={params.row}
            saveFunction={handleSaveMansionario}
            title="Modifica Mansionario"
            snack={snack}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
          />
          <DeleteDialogMansionario
            mansione={params.row}
            myFunction={handleDeleteMansionario}
          />
        </>
      ),
    },
    {
      field: "Gruppo",
      headerName: "Qualifica Contrattuale",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Tipologia",
      headerName: "CCNL Applicato",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Livello",
      headerName: "Livello",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Ccnl",
      headerName: "Inquadramento",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "AttivitaSvolta",
      headerName: "Mansione",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Responsabilita",
      headerName: "Responsabilita",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Competenze",
      headerName: "Competenze Richieste",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];
  const columnsComitato = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <Comitato
            id="edit"
            comitato={params.row}
            saveFunction={handleSaveComitato}
            title="Modifica Membro del Comitato"
            snack={snack}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
          />
          <DeleteDialogComitato
            comitato={params.row}
            myFunction={handleDeleteComitato}
          />
        </>
      ),
    },
    {
      field: "Nome",
      headerName: "Nome",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Cognome",
      headerName: "Cognome",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];
  const columnsAllegati = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={() =>
              downloadFile(
                params.row.ID,
                params.row.Estensione,
                params.row.Descrizione,
                "download"
              )
            }
          >
            <Download
              style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
            />
          </IconButton>

          {params.row.Estensione?.toLowerCase() === ".pdf" && (
            <IconButton
              aria-label="edit"
              onClick={() =>
                downloadFile(
                  params.row.ID,
                  params.row.Estensione,
                  params.row.Descrizione,
                  "visualizza"
                )
              }
            >
              <RemoveRedEye
                style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
              />
            </IconButton>
          )}
          <DeleteDialogFile
            categoria={params.row}
            myFunction={() =>
              handleDeleteFile(params.row.ID, params.row.Estensione, "delete")
            }
          />
        </>
      ),
    },
    { field: "Descrizione", headerName: "Descrizione", flex: 1 },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }

  const handleSaveAccessi = async (utente, operazione) => {
    try {
      await unsplash.post(
        "parita",
        {
          accesso: { ...utente, IDParitaCliente: location.state?.cliente?.ID },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setSnack({
        severity: "success",
        messaggio: "Cliente parità creato con successo",
      });
      setOpenSnack(true);

      reloadAfterUpload();
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante la creazione",
      });
      setOpenSnack(true);
    }
  };

  const handleDelete = async (accesso) => {
    try {
      await unsplash.delete("parita/accesso", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          id: accesso.ID,
        },
      });

      setSnack({
        severity: "success",
        messaggio: "Utente eliminato con successo",
      });
      setOpenSnack(true);
      reloadAfterUpload();
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione",
      });
      setOpenSnack(true);
    }
  };

  const loadAll = async () => {
    let response = await unsplash.post(
      "portaleParita",
      {
        operation: "getDomandeSurvey",
        grandezza: cliente?.Grandezza,
        cliente: location.state?.cliente.ID,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    let responseAteco = await unsplash.get("parita/ateco", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setCodiciAteco(responseAteco.data.data);
    if (cliente?.CodiceAteco) {
      setCodiciAtecoSel(
        responseAteco.data.data?.filter(
          (codice) => codice.ID === cliente.CodiceAteco
        )[0]
      );
    }

    let primaSur = response.data.filter((el) => el.NSurvey === 1);
    let secondaSur = response.data.filter((el) => el.NSurvey === 2);
    setPrimaSurvey(primaSur);
    handleConteggioCertificazione(primaSur);
    setSecondaSurvey(secondaSur);
    if (cliente?.Logo) {
      const img = new Image();
      img.onload = () => {
        setDimensions({ width: img.width, height: img.height });
      };
      img.src = cliente.Logo;
    }
  };

  const downloadFile = async (id, estensione, descrizione, operazione) => {
    try {
      // Make the POST request to trigger the download and response from the server
      const response = await unsplash.post(
        "ftp",
        {
          file: id + estensione,
          nomeOriginale: descrizione.includes(estensione)
            ? descrizione
            : descrizione + estensione,
          operazione: "download",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: ext[estensione.replace(".", "")] })
      );
      const link = document.createElement("a");
      link.href = url;
      if (operazione === "download") {
        link.setAttribute(
          "download",
          descrizione.includes(estensione)
            ? descrizione
            : descrizione + estensione
        );
        document.body.appendChild(link);
        link.click();
      }

      if (operazione === "visualizza") {
        if (estensione === ".pdf") {
          window.open(url);
        }
      }
    } catch (error) {
      let messaggio = "Errore durante la visualizzazione del file: ";
      if (operazione === "download") {
        messaggio = "Errore durante il download del file: ";
      }
      setOpenSnack(true);
      if (error.response) {
        if (error.response.status === 404) {
          messaggio = messaggio + "File non trovato.";
        } else if (error.response.status === 500) {
          messaggio =
            messaggio +
            "Errore interno del server. Si prega di riprovare più tardi.";
        } else {
          messaggio = messaggio + "Errore sconosciuto.";
        }
      } else if (error.request) {
        // La richiesta è stata effettuata ma non è stata ricevuta alcuna risposta
        messaggio =
          messaggio +
          "Nessuna risposta ricevuta dal server. Si prega di controllare la connessione a Internet.";
      } else {
        // Si è verificato qualche altro errore
        messaggio =
          messaggio +
          "Si è verificato un errore. Si prega di riprovare più tardi.";
      }
      setSnack({
        severity: "error",
        messaggio: messaggio,
      });
      console.error("Errore durante il download del file:", error);
    }
  };
  const handleDeleteFile = async (id, estensione, operazione) => {
    try {
      await unsplash.post(
        "ftp",
        { file: id + estensione, operazione: "delete" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setSnack({
        severity: "success",
        messaggio: "Allegato eliminato con successo",
      });
      setOpenSnack(true);
      reloadAfterUploadFile();
    } catch (error) {
      console.error(error);
    }
  };

  const reloadAfterUploadFile = async () => {
    let responseAziendali = await unsplash.get("allegati", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { colonna: "Parita", ID: location.state.cliente?.ID },
    });
    setAllegati(responseAziendali.data.data);
  };

  const reloadAfterUpload = async () => {
    let response = await unsplash.get("parita", {
      params: { accessi: location.state?.cliente?.ID },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setAccessi(response.data.data);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseMail = () => {
    setOpenMail(false);
    setTestoEmail("");
  };
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange = (e) => {
    let copiaCliente = { ...cliente };
    copiaCliente[e.target.name] = e.target.value;
    setCliente(copiaCliente);
  };

  const handleChangeImage = async (e) => {
    const file = e.target.files[0]; // prendo la foto caricata sempre nell'evento
    var reader = new FileReader();
    let logo;
    reader.readAsDataURL(file);
    reader.onload = function () {
      document.getElementById("Logo").src = reader.result;
      logo = reader.result.replace("data:image/png;base64,", "");
      logo = reader.result.replace("data:image/jpeg;base64,", "");
      setCliente({ ...cliente, Logo: logo });
    };
    reader.onerror = function (error) {};
  };

  const fromStepToCategory = (step) => {
    let category = "";
    switch (step) {
      case 2:
        category = "DATI AZIENDALI";
        break;
      case 4:
        category = "PRIMA SURVEY";
        break;
      case 6:
        category = "SECONDA SURVEY";
        break;

      default:
        break;
    }
    return category;
  };
  const handleConfermaStep = async () => {
    try {
      await unsplash.post(
        "portaleParita",
        {
          operation: "updateStep",
          step: cliente?.Step,
          confermato: 1,
          id: cliente?.ID,
          utenti: accessi.filter((u) => u.Password).map((el) => el?.Email),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setSnack({
        severity: "success",
        messaggio: "Step confermato con successo",
      });
      setOpenSnack(true);
      setCliente({ ...cliente, Confermato: 1 });
      handleClose();
    } catch (e) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'aggiornamento",
      });
      setOpenSnack(true);
    }
  };
  const sendSegnalazione = async () => {
    try {
      await unsplash.post(
        "portaleParita",
        {
          operation: "updateStep",
          step: cliente?.Step - 1,
          confermato: 1,
          id: cliente?.ID,
          utenti: accessi.filter((u) => u.Password).map((el) => el?.Email),
          testo: testoEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setSnack({
        severity: "success",
        messaggio: "Segnalazione inviata con successo",
      });
      setOpenSnack(true);
      handleCloseMail();
    } catch (e) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'aggiornamento",
      });
      setOpenSnack(true);
    }
  };

  const handleSavePrimaSurvey = async (risposta) => {
    console.log(risposta);
    try {
      await unsplash.post(
        "portaleParita",
        {
          operation: "putRispostaSurvey",
          risposta: { ...risposta, IDParitaCliente: cliente?.ID },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setSnack({
        severity: "success",
        messaggio: "Mansione aggiornata con successo",
      });
      setOpenSnack(true);
      loadAll();
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'aggiornamento",
      });
      setOpenSnack(true);
    }
  };

  const handleSave = async () => {
    try {
      await unsplash.put(
        "parita",
        {
          parita: cliente,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setSnack({
        severity: "success",
        messaggio: "Cliente parità aggiornato con successo",
      });
      setOpenSnack(true);
      loadAll();
      reloadAfterUpload();
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'aggiornamento",
      });
      setOpenSnack(true);
    }
  };

  const calculateScaledWidth = (fixedHeight) => {
    if (dimensions.height === 0) return 0; // Prevent division by zero

    return (fixedHeight / dimensions.height) * dimensions.width;
  };

  const handleDownloadFile = async (response, data) => {
    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      })
    );
    const a = document.createElement("a");
    a.href = url;
    a.download = data.outputFile;
    document.body.appendChild(a);
    a.click();

    // Cleanup
    a.remove();
    window.URL.revokeObjectURL(url);
  };

  const handleDownloadFileExcel = async (response, data) => {
    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    const a = document.createElement("a");
    a.href = url;
    a.download = data.outputFile;
    document.body.appendChild(a);
    a.click();

    // Cleanup
    a.remove();
    window.URL.revokeObjectURL(url);
  };

  const handleStampa = async (data) => {
    if (!cliente?.Logo) {
      setSnack({
        severity: "error",
        messaggio: "Caricare logo!",
      });
      setOpenSnack(true);
    } else {
      try {
        let response = await unsplash.post(
          "parita/stampe",
          {
            stampa: { ...data },
          },
          {
            headers: {
              ContentType: "application/octet-stream",
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
            responseType: "blob",
          }
        );

        handleDownloadFile(response, data);

        setSnack({
          severity: "success",
          messaggio: "Stampa scaricata con successo",
        });
        setOpenSnack(true);
      } catch (error) {
        setSnack({
          severity: "error",
          messaggio: "Errore durante la stampa",
        });
        setOpenSnack(true);
      }
    }
  };
  //#region MANSIONARIO
  const loadMansionario = async () => {
    let responseMansionario = await unsplash.get("parita/mansionario", {
      params: { IDCliente: location.state?.cliente.ID },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setMansionario(responseMansionario.data.data);
  };
  const handleSaveMansionario = async (mansionario, operaizone) => {
    if (operaizone === "new") {
      try {
        await unsplash.post(
          "parita/mansionario",
          {
            mansione: {
              ...mansionario,
              IDParitaCliente: location.state?.cliente.ID,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Mansione creata con successo",
        });
        setOpenSnack(true);
        loadMansionario();
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante l'aggiornamento",
        });
        setOpenSnack(true);
      }
    } else {
      try {
        await unsplash.put(
          "parita/mansionario",
          {
            mansione: mansionario,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Mansione aggiornata con successo",
        });
        setOpenSnack(true);
        loadMansionario();
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante l'aggiornamento",
        });
        setOpenSnack(true);
      }
    }
  };
  const handleDeleteMansionario = async (ID) => {
    try {
      await unsplash.delete("parita/mansionario", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          id: ID,
        },
      });
      loadMansionario();
      setSnack({
        severity: "success",
        messaggio: "Membro del comitato eliminato con successo",
      });
      setOpenSnack(true);
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante la cancellazione",
      });
      setOpenSnack(true);
    }
  };
  //#endregion

  //#region COMITATO

  const handleDeleteComitato = async (ID) => {
    try {
      await unsplash.delete("comitatoParita", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          id: ID,
        },
      });
      loadComitato();
      setSnack({
        severity: "success",
        messaggio: "Membro del comitato eliminato con successo",
      });
      setOpenSnack(true);
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante la cancellazione",
      });
      setOpenSnack(true);
    }
  };

  const loadComitato = async () => {
    let responseComitato = await unsplash.get("comitatoParita", {
      params: { id: location.state.cliente?.ID },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setComitato(responseComitato.data.data);
  };

  const handleSaveComitato = async (comitatoSel, operaizone) => {
    if (operaizone === "new") {
      try {
        await unsplash.post(
          "comitatoParita",
          {
            comitato: {
              ...comitatoSel,
              IDParitaCliente: location.state.cliente?.ID,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Membro del comitato creato con successo",
        });
        setOpenSnack(true);
        loadComitato();
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante l'aggiornamento",
        });
        setOpenSnack(true);
      }
    } else {
      try {
        await unsplash.put(
          "comitatoParita",
          {
            comitato: comitatoSel,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Membro del comitato aggiornato con successo",
        });
        setOpenSnack(true);
        loadComitato();
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante l'aggiornamento",
        });
        setOpenSnack(true);
      }
    }
  };
  //#endregion
  const handleStampaExcel = async (data) => {
    try {
      let response = await unsplash.post(
        "parita/stampeExcel",
        {
          stampa: { ...data },
        },
        {
          headers: {
            ContentType: "application/octet-stream",
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          responseType: "blob",
        }
      );

      handleDownloadFileExcel(response, data);

      setSnack({
        severity: "success",
        messaggio: "Stampa scaricata con successo",
      });
      setOpenSnack(true);
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante la stampa",
      });
      setOpenSnack(true);
    }
  };

  //#region STAMPE MODULI

  const stampaMod01Politica = async () => {
    let response = await unsplash.post(
      "portaleParita",
      {
        operation: "getDomandeSurvey",
        grandezza: location.state.cliente?.Grandezza,
        cliente: location.state.cliente?.ID,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    let areas = {
      Area1: "",
      Area2: "",
      Area3: "",
      Area4: "",
      Area5: "",
      Area6: "",
    };
    response.data?.map((quest) => {
      switch (quest?.ID) {
        case 34:
          areas = { ...areas, Area1: quest?.Risposta };
          break;
        case 40:
          areas = { ...areas, Area2: quest?.Risposta };
          break;
        case 46:
          areas = { ...areas, Area3: quest?.Risposta };
          break;
        case 52:
          areas = { ...areas, Area4: quest?.Risposta };
          break;
        case 58:
          areas = { ...areas, Area5: quest?.Risposta };
          break;
        case 64:
          areas = { ...areas, Area6: quest?.Risposta };
          break;
        default:
          break;
      }
    });

    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      Storia: cliente?.Storia,
      Missione: cliente?.Missione,
      ...areas,
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "Mod01Politica",
      outputFile: `Mod. 01 Politica (${cliente?.RagioneSociale}).docx`,
    };
    handleStampa(data);
  };

  const stampaMod02Regolamento = async () => {
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "Mod02Regolamento",
      outputFile: `Mod. 02 Regolamento del comitato guida (${cliente?.RagioneSociale}).docx`,
    };
    handleStampa(data);
  };

  const stampaMod02AttoComitato = async () => {
    let responseComitato = await unsplash.get("comitatoParita", {
      params: { id: cliente?.ID },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    const fixedHeight = 120;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      Piva: cliente?.Piva,
      Sito: cliente?.Sito,
      SedeLegale: cliente?.SedeLegale,
      Pec: cliente?.Pec,
      Rea: cliente?.Rea,
      Telefono: cliente?.Telefono,
      BudgetAnnuale: cliente?.BudgetAnnuale,
      NominativoResponsabile:
        cliente?.NomeResponsabile + " " + cliente?.CognomeResponsabile,
      comitato: [...responseComitato.data.data],
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "Mod02AttoComitato",
      outputFile: `Mod. 02.01 Atto di istituzione comitato guida (${cliente?.RagioneSociale}).docx`,
    };
    if (responseComitato.data.data?.length < 2) {
      setSnack({
        severity: "error",
        messaggio: "Caricare almeno 2 membri del comitato!",
      });
      setOpenSnack(true);
    } else {
      handleStampa(data);
    }
  };
  const stampaMod02NominaComitato = async () => {
    let responseComitato = await unsplash.get("comitatoParita", {
      params: { id: cliente?.ID },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    if (responseComitato.data.data?.length < 2) {
      setSnack({
        severity: "error",
        messaggio: "Caricare almeno 2 membri del comitato!",
      });
      setOpenSnack(true);
    } else {
      responseComitato.data.data?.map((el) => {
        const fixedHeight = 120;
        const scaledWidth = calculateScaledWidth(fixedHeight);
        let data = {
          data: moment(new Date()).format("DD/MM/YYYY"),
          RagioneSociale: cliente?.RagioneSociale,
          Piva: cliente?.Piva,
          Sito: cliente?.Sito,
          SedeLegale: cliente?.SedeLegale,
          Pec: cliente?.Pec,
          Rea: cliente?.Rea,
          Telefono: cliente?.Telefono,
          Nominativo: el?.Nome + " " + el.Cognome,
          image: {
            _type: "image",
            source: cliente?.Logo,
            format: MimeType.Png,
            width: scaledWidth,
            height: fixedHeight,
          },
          nomeFile: "Mod02NominaComitato",
          outputFile: `Mod. 02.02 Nomina Comitato guida (${
            el?.Nome + " " + el.Cognome
          }).docx`,
        };
        handleStampa(data);
      });
    }
  };
  const stampaMod02NominaRSGPG = async () => {
    const fixedHeight = 120;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      Piva: cliente?.Piva,
      Sito: cliente?.Sito,
      SedeLegale: cliente?.SedeLegale,
      Pec: cliente?.Pec,
      Rea: cliente?.Rea,
      Telefono: cliente?.Telefono,
      Nominativo:
        cliente?.NomeResponsabile + " " + cliente?.CognomeResponsabile,
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "Mod02NominaRSGPG",
      outputFile: `Mod. 02.03 Nomina RSGPG (${
        cliente?.NomeResponsabile + " " + cliente?.CognomeResponsabile
      }).docx`,
    };
    if (!cliente?.NomeResponsabile || !cliente?.CognomeResponsabile) {
      setSnack({
        severity: "error",
        messaggio:
          "Inserire Responsabile del Sistema di Gestione della Parità!",
      });
      setOpenSnack(true);
    } else {
      handleStampa(data);
    }
  };
  const stampaMod02VerbaleRiunione = async () => {
    let responseComitato = await unsplash.get("comitatoParita", {
      params: { id: cliente?.ID },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    const fixedHeight = 120;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      Piva: cliente?.Piva,
      Sito: cliente?.Sito,
      SedeLegale: cliente?.SedeLegale,
      Pec: cliente?.Pec,
      Rea: cliente?.Rea,
      Telefono: cliente?.Telefono,
      comitato: [...responseComitato.data.data],
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "Mod02VerbaleRiunione",
      outputFile: `Mod. 02.04 Verbale di riunione del comitato (${cliente?.RagioneSociale}).docx`,
    };
    if (responseComitato.data.data?.length < 2) {
      setSnack({
        severity: "error",
        messaggio: "Caricare almeno 2 membri del comitato!",
      });
      setOpenSnack(true);
    } else {
      handleStampa(data);
    }
  };

  const stampaMod03Dichiarazione = async () => {
    const fixedHeight = 120;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      Piva: cliente?.Piva,
      Sito: cliente?.Sito,
      SedeLegale: cliente?.SedeLegale,
      Pec: cliente?.Pec,
      Rea: cliente?.Rea,
      Telefono: cliente?.Telefono,
      DataNascitaRappresentante: moment(
        cliente?.DataNascitaRappresentante
      ).format("DD/MM/YYYY"),
      LuogoNascitaRappresentante: cliente?.LuogoNascitaRappresentante,
      Rappresentante:
        cliente?.NomeRappresentante + " " + cliente?.CognomeRappresentante,
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "Mod03Dichiarazione",
      outputFile: `Mod. 03 Dichiarazione assenza cause giudiziali (${cliente?.RagioneSociale}).docx`,
    };
    if (
      !cliente?.DataNascitaRappresentante ||
      !cliente?.LuogoNascitaRappresentante ||
      !cliente?.NomeRappresentante ||
      !cliente?.CognomeRappresentante
    ) {
      setSnack({
        severity: "error",
        messaggio: "Inserire tutti i dati del Rappresentante!",
      });
      setOpenSnack(true);
    } else {
      handleStampa(data);
    }
  };

  const stampaMod0301Dichiarazione = async () => {
    const fixedHeight = 120;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      Piva: cliente?.Piva,
      Sito: cliente?.Sito,
      SedeLegale: cliente?.SedeLegale,
      Pec: cliente?.Pec,
      Rea: cliente?.Rea,
      Telefono: cliente?.Telefono,
      DataNascitaRappresentante: moment(
        cliente?.DataNascitaRappresentante
      ).format("DD/MM/YYYY"),
      LuogoNascitaRappresentante: cliente?.LuogoNascitaRappresentante,
      Rappresentante:
        cliente?.NomeRappresentante + " " + cliente?.CognomeRappresentante,
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "Mod0301Dichiarazione",
      outputFile: `Mod. 03 Dichiarazione assenza cause giudiziali (${cliente?.RagioneSociale}).docx`,
    };
    if (
      !cliente?.DataNascitaRappresentante ||
      !cliente?.LuogoNascitaRappresentante ||
      !cliente?.NomeRappresentante ||
      !cliente?.CognomeRappresentante
    ) {
      setSnack({
        severity: "error",
        messaggio: "Inserire tutti i dati del Rappresentante!",
      });
      setOpenSnack(true);
    } else {
      handleStampa(data);
    }
  };

  const stampaMod04ElencoDoc = async () => {
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "Mod04ElencoDoc",
      outputFile: `Mod. 04 Elenco documenti (${cliente?.RagioneSociale}).docx`,
    };
    handleStampa(data);
  };

  const stampaMod05Esigenze = async () => {
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "Mod05Esigenze",
      outputFile: `Mod. 05.01 Esigenze e aspettative parti interessate (${cliente?.RagioneSociale}).docx`,
    };
    handleStampa(data);
  };

  const stampaMod05Analisi = async () => {
    let response = await unsplash.post(
      "portaleParita",
      {
        operation: "getDomandeSurvey",
        grandezza: location.state.cliente?.Grandezza,
        cliente: location.state.cliente?.ID,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    let areas = {
      Area1_1: "",
      Area1_2: "",
      Area1_3: "",
      Area1_6: "",
      Area2_2: "",
      Area2_3: "",
      Area2_6: "",
      Area3_2: "",
      Area3_3: "",
      Area3_6: "",
      Area4_2: "",
      Area4_3: "",
      Area4_6: "",
      Area5_2: "",
      Area5_3: "",
      Area5_6: "",
      Area6_2: "",
      Area6_3: "",
      Area6_6: "",
    };
    response.data?.map((quest) => {
      switch (quest?.ID) {
        case 31:
          areas = { ...areas, Area1_1: quest?.Risposta };
          break;
        case 32:
          areas = { ...areas, Area1_2: quest?.Risposta };
          break;
        case 33:
          areas = { ...areas, Area1_3: quest?.Risposta };
          break;
        case 36:
          areas = { ...areas, Area1_6: quest?.Risposta };
          break;
        case 38:
          areas = { ...areas, Area2_2: quest?.Risposta };
          break;
        case 39:
          areas = { ...areas, Area2_3: quest?.Risposta };
          break;
        case 42:
          areas = { ...areas, Area2_6: quest?.Risposta };
          break;
        case 44:
          areas = { ...areas, Area3_2: quest?.Risposta };
          break;
        case 45:
          areas = { ...areas, Area3_3: quest?.Risposta };
          break;
        case 48:
          areas = { ...areas, Area3_6: quest?.Risposta };
          break;
        case 50:
          areas = { ...areas, Area4_2: quest?.Risposta };
          break;
        case 51:
          areas = { ...areas, Area4_3: quest?.Risposta };
          break;
        case 54:
          areas = { ...areas, Area4_6: quest?.Risposta };
          break;
        case 56:
          areas = { ...areas, Area5_2: quest?.Risposta };
          break;
        case 57:
          areas = { ...areas, Area5_3: quest?.Risposta };
          break;
        case 60:
          areas = { ...areas, Area5_6: quest?.Risposta };
          break;
        case 62:
          areas = { ...areas, Area6_2: quest?.Risposta };
          break;
        case 63:
          areas = { ...areas, Area6_3: quest?.Risposta };
          break;
        case 66:
          areas = { ...areas, Area6_6: quest?.Risposta };
          break;

        default:
          break;
      }
    });
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      nomeFile: "Mod05Analisi",
      outputFile: `Mod. 05 Analisi del contesto (${cliente?.RagioneSociale}).xlsx`,
      ...areas,
    };
    handleStampaExcel(data);
  };

  const stampaMod05Organigramma = async () => {
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "Mod05Organigramma",
      outputFile: `Mod. 05.02 Organigramma (${cliente?.RagioneSociale}).docx`,
    };
    handleStampa(data);
  };

  const stampaMod05Mansionario = async () => {
    let responseMansionario = await unsplash.get("parita/mansionario", {
      params: { IDCliente: cliente?.ID },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      items: [...responseMansionario.data.data],
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "Mod05Mansionario",
      outputFile: `Mod. 05.03 Mansionario (${cliente?.RagioneSociale}).docx`,
    };
    if (responseMansionario.data.data?.length === 0) {
      setSnack({
        severity: "error",
        messaggio: "Caricare i dati del Mansionario!",
      });
      setOpenSnack(true);
    } else {
      handleStampa(data);
    }
  };

  const stampaMod05Identificazione = async () => {
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "Mod05Identificazione",
      outputFile: `Mod. 05.04 Identificazione processi (${cliente?.RagioneSociale}).docx`,
    };
    handleStampa(data);
  };

  const stampaMod06PianoStrategico = async () => {
    let responseAteco = await unsplash.get("parita/ateco", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    let ateco = responseAteco.data.data?.filter(
      (el) => el.ID === cliente?.CodiceAteco
    )[0];
    let response = await unsplash.post(
      "portaleParita",
      {
        operation: "getDomandeSurvey",
        grandezza: location.state.cliente?.Grandezza,
        cliente: location.state.cliente?.ID,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    let areas = {
      Area2: "",
      Area3: "",
      Area5: "",
    };
    response.data?.map((quest) => {
      switch (quest?.ID) {
        case 37:
          areas = { ...areas, Area2: quest?.Risposta };
          break;
        case 43:
          areas = { ...areas, Area3: quest?.Risposta };
          break;
        case 55:
          areas = { ...areas, Area5: quest?.Risposta };
          break;
        default:
          break;
      }
    });
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      CodiceAteco: ateco?.Codice,
      Lettera: ateco?.Lettera,
      DescrizioneAteco: ateco?.Descrizione,
      Storia: cliente?.Storia,
      Missione: cliente?.Missione,
      ...areas,
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "Mod06PianoStrategico",
      outputFile: `Mod. 06 Piano strategico (${cliente?.RagioneSociale}).docx`,
    };
    if (!ateco) {
      setSnack({
        severity: "error",
        messaggio: "Inserisci il Codice Ateco!",
      });
      setOpenSnack(true);
    } else {
      handleStampa(data);
    }
  };

  const stampaMod0601SchedaPiano = async () => {
    let response = await unsplash.post(
      "portaleParita",
      {
        operation: "getDomandeSurvey",
        grandezza: location.state.cliente?.Grandezza,
        cliente: location.state.cliente?.ID,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    let areas = {
      Area1_1: "",
      Area1_2: "",
      Area1_3: "",
      Area1_6: "",
      Area2_2: "",
      Area2_3: "",
      Area2_6: "",
      Area3_2: "",
      Area3_3: "",
      Area3_6: "",
      Area4_2: "",
      Area4_3: "",
      Area4_6: "",
      Area5_2: "",
      Area5_3: "",
      Area5_6: "",
      Area6_2: "",
      Area6_3: "",
      Area6_6: "",
    };
    response.data?.map((quest) => {
      switch (quest?.ID) {
        case 31:
          areas = { ...areas, Area1_1: quest?.Risposta };
          break;
        case 32:
          areas = { ...areas, Area1_2: quest?.Risposta };
          break;
        case 33:
          areas = { ...areas, Area1_3: quest?.Risposta };
          break;
        case 34:
          areas = { ...areas, Area1_4: quest?.Risposta };
          break;
        case 35:
          areas = { ...areas, Area1_5: quest?.Risposta };
          break;
        case 36:
          areas = { ...areas, Area1_6: quest?.Risposta };
          break;
        case 37:
          areas = { ...areas, Area2_1: quest?.Risposta };
          break;
        case 38:
          areas = { ...areas, Area2_2: quest?.Risposta };
          break;
        case 39:
          areas = { ...areas, Area2_3: quest?.Risposta };
          break;
        case 40:
          areas = { ...areas, Area2_4: quest?.Risposta };
          break;
        case 41:
          areas = { ...areas, Area2_5: quest?.Risposta };
          break;
        case 42:
          areas = { ...areas, Area2_6: quest?.Risposta };
          break;
        case 43:
          areas = { ...areas, Area3_1: quest?.Risposta };
          break;
        case 44:
          areas = { ...areas, Area3_2: quest?.Risposta };
          break;
        case 45:
          areas = { ...areas, Area3_3: quest?.Risposta };
          break;
        case 46:
          areas = { ...areas, Area3_4: quest?.Risposta };
          break;
        case 47:
          areas = { ...areas, Area3_5: quest?.Risposta };
          break;
        case 48:
          areas = { ...areas, Area3_6: quest?.Risposta };
          break;
        case 49:
          areas = { ...areas, Area4_1: quest?.Risposta };
          break;
        case 50:
          areas = { ...areas, Area4_2: quest?.Risposta };
          break;
        case 51:
          areas = { ...areas, Area4_3: quest?.Risposta };
          break;
        case 52:
          areas = { ...areas, Area4_4: quest?.Risposta };
          break;
        case 53:
          areas = { ...areas, Area4_5: quest?.Risposta };
          break;
        case 54:
          areas = { ...areas, Area4_6: quest?.Risposta };
          break;
        case 55:
          areas = { ...areas, Area5_1: quest?.Risposta };
          break;
        case 56:
          areas = { ...areas, Area5_2: quest?.Risposta };
          break;
        case 57:
          areas = { ...areas, Area5_3: quest?.Risposta };
          break;
        case 58:
          areas = { ...areas, Area5_4: quest?.Risposta };
          break;
        case 59:
          areas = { ...areas, Area5_5: quest?.Risposta };
          break;
        case 60:
          areas = { ...areas, Area5_6: quest?.Risposta };
          break;
        case 61:
          areas = { ...areas, Area6_1: quest?.Risposta };
          break;
        case 62:
          areas = { ...areas, Area6_2: quest?.Risposta };
          break;
        case 63:
          areas = { ...areas, Area6_3: quest?.Risposta };
          break;
        case 64:
          areas = { ...areas, Area6_4: quest?.Risposta };
          break;
        case 65:
          areas = { ...areas, Area6_5: quest?.Risposta };
          break;
        case 66:
          areas = { ...areas, Area6_6: quest?.Risposta };
          break;

        default:
          break;
      }
    });
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      nomeFile: "Mod0601",
      outputFile: `Mod. 06.01 Scheda piano d'azione (${cliente?.RagioneSociale}).xlsx`,
      ...areas,
    };
    handleStampaExcel(data);
  };
  const stampaMod07PianoComunicazione = async () => {
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      nomeFile: "Mod07",
      outputFile: `Mod. 07 Piano cominicazione (${cliente?.RagioneSociale}).xlsx`,
    };
    handleStampaExcel(data);
  };
  const stampaMod0701PianoFormazione = async () => {
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      nomeFile: "Mod0701",
      outputFile: `Mod. 07.01 Piano formazione (${cliente?.RagioneSociale}).xlsx`,
    };
    handleStampaExcel(data);
  };
  const stampaMod08Registro = async () => {
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      nomeFile: "Mod08",
      outputFile: `Mod. 08 Registro prescrizioni legali (${cliente?.RagioneSociale}).xlsx`,
    };
    handleStampaExcel(data);
  };

  const stampaMod07Informativa = async () => {
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "Mod07Informativa",
      outputFile: `Mod. 07.02 Informativa valorizzazione genitorialità e cura (${cliente?.RagioneSociale}).docx`,
    };
    handleStampa(data);
  };
  const stampaMod0703Informativa = async () => {
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "Mod0703Informativa",
      outputFile: `Mod. 07.03 Informativa congedi (${cliente?.RagioneSociale}).docx`,
    };
    handleStampa(data);
  };
  const stampaMod0704Informativa = async () => {
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      SedeLegale: cliente?.SedeLegale,
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "Mod0704Informativa",
      outputFile: `Mod. 07.04 Informativa sulla parità di genere (${cliente?.RagioneSociale}).docx`,
    };
    handleStampa(data);
  };

  const stampaMod0705Rapporto = async () => {
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "Mod0705Rapporto",
      outputFile: `Mod. 07.05 Rapporto NC (${cliente?.RagioneSociale}).docx`,
    };
    handleStampa(data);
  };

  const stampaMod0706Questionario = async () => {
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "Mod0706Questionario",
      outputFile: `Mod. 07.06 questionario per il monitoraggio della percezione del personale in tema di parità di genere (${cliente?.RagioneSociale}).docx`,
    };
    handleStampa(data);
  };
  const stampaMod0901Programma = async () => {
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "Mod0901Programma",
      outputFile: `Mod. 09.01 Programma audit interno (${cliente?.RagioneSociale}).docx`,
    };
    handleStampa(data);
  };
  const stampaMod0902Revisione = async () => {
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "Mod0902Revisione",
      outputFile: `Mod. 09.02 Revisione periodica (${cliente?.RagioneSociale}).docx`,
    };
    handleStampa(data);
  };
  const stampaMod1001Rapporto = async () => {
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "Mod1001Rapporto",
      outputFile: `Mod. 10.01 Rapporto NC (${cliente?.RagioneSociale}).docx`,
    };
    handleStampa(data);
  };
  const stampaMod1002Rapporto = async () => {
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "Mod1002Rapporto",
      outputFile: `Mod. 10.02 Rapporto reclami (${cliente?.RagioneSociale}).docx`,
    };
    handleStampa(data);
  };

  const stampaMod1003Rapporto = async () => {
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "Mod1003Rapporto",
      outputFile: `Mod. 10.03 Rapporto segnalazioni (${cliente?.RagioneSociale}).docx`,
    };
    handleStampa(data);
  };

  const stampaMod1004Registro = async () => {
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      anno: moment(new Date()).format("YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "Mod1004Registro",
      outputFile: `Mod. 10.04 Registro NC, reclami, segnalazioni AC (${cliente?.RagioneSociale}).docx`,
    };
    handleStampa(data);
  };

  const stampaMod1005Rapporto = async () => {
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "Mod1005Rapporto",
      outputFile: `Mod. 10.05 Rapporto azioni correttive (${cliente?.RagioneSociale}).docx`,
    };
    handleStampa(data);
  };
  //#endregion

  //#region STAMPE PROCEDURE

  const stampaPSGPG01 = async () => {
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "PSGPG01",
      outputFile: `PSGPG 01 Gestione della documentazione (${cliente?.RagioneSociale}).docx`,
    };
    handleStampa(data);
  };
  const stampaPSGPG02 = async () => {
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "PSGPG02",
      outputFile: `PSGPG 02 Gestione della comunicazione (${cliente?.RagioneSociale}).docx`,
    };
    handleStampa(data);
  };
  const stampaPSGPG03 = async () => {
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "PSGPG03",
      outputFile: `PSGPG 03 Gestione delle risorse umane (${cliente?.RagioneSociale}).docx`,
    };
    handleStampa(data);
  };
  const stampaPSGPG04 = async () => {
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "PSGPG04",
      outputFile: `PSGPG 04 Contrasto violenze nei luoghi di lavoro (${cliente?.RagioneSociale}).docx`,
    };
    handleStampa(data);
  };
  const stampaPSGPG05 = async () => {
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "PSGPG05",
      outputFile: `PSGPG 05 Non conformità e reclami (${cliente?.RagioneSociale}).docx`,
    };
    handleStampa(data);
  };
  const stampaPSGPG06 = async () => {
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "PSGPG06",
      outputFile: `PSGPG 06 Azioni correttive (${cliente?.RagioneSociale}).docx`,
    };
    handleStampa(data);
  };
  const stampaPSGPG07 = async () => {
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "PSGPG07",
      outputFile: `PSGPG 07 Audit interni (${cliente?.RagioneSociale}).docx`,
    };
    handleStampa(data);
  };
  const stampaPSGPG08 = async () => {
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "PSGPG08",
      outputFile: `PSGPG 08 Riesame della direzione (${cliente?.RagioneSociale}).docx`,
    };
    handleStampa(data);
  };
  const stampaPSGPG09 = async () => {
    const fixedHeight = 40;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    let data = {
      data: moment(new Date()).format("DD/MM/YYYY"),
      RagioneSociale: cliente?.RagioneSociale,
      image: {
        _type: "image",
        source: cliente?.Logo,
        format: MimeType.Png,
        width: scaledWidth,
        height: fixedHeight,
      },
      nomeFile: "PSGPG09",
      outputFile: `PSGPG 09 Gestione delle opinioni e dei suggerimenti (${cliente?.RagioneSociale}).docx`,
    };
    handleStampa(data);
  };
  //#endregion

  //#region PUNTEGGIO KPI
  const handleConteggioCertificazione = async (survey) => {
    let kpiParita = await unsplash.get("kpiParita", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    let kpiRiferimento;
    if (cliente?.CodiceAteco) {
      kpiRiferimento = kpiParita.data.data.filter(
        (el) => el.Lettera === cliente?.Lettera
      );
    } else {
      kpiRiferimento = kpiParita.data.data.filter(
        (el) => el.Lettera === location.state.cliente?.Lettera
      );
    }
    let rispostePercentualiBenchmark = survey.filter(
      (el) => el.TipologiaInput === 1 && el.Risposta && !el.Percentuale
    );

    let nRisposteCorrette = 0;
    //Calcolo il puteggio alle riusposte semplici(si/no)
    let punteggioRisposteSiNo = survey
      .filter((el) => el.TipologiaInput === 0 && el.Risposta === "1")
      .reduce((a, b) => {
        nRisposteCorrette = nRisposteCorrette + 1;
        return (a = a + b.Punteggi);
      }, 0);

    //filtro risposte con percentuali definite
    let rispostePercentualiEsistenti = survey.filter(
      (el) => el.TipologiaInput === 1 && el.Risposta && el.Percentuale
    );

    //sommo solo i punteggi delle risposte che hanno superato la percentuale di riferimento
    let punteggioRispostePercentualiEsistenti =
      rispostePercentualiEsistenti.reduce((accumulator, risposta) => {
        if (
          //ho inserito il meno solo per differnziare se la condizione dovesse essere <= oppure >=
          risposta.Percentuale > 0
            ? Number(risposta.Risposta) >= risposta.Percentuale
            : //tolgo il meno davanti alla percentuale
              Number(risposta.Risposta) <= Math.abs(risposta.Percentuale)
        ) {
          nRisposteCorrette = nRisposteCorrette + 1;
          return accumulator + risposta.Punteggi;
        } else {
          return accumulator;
        }
        //},0)
      }, punteggioRisposteSiNo);

    //filtro risposte con percentuali doppie da confrontare
    let rispostePercentualiAConfronto = survey.filter(
      (el) => el.TipologiaInput === 3 && el.Risposta && el.SecondaPercentuale
    );

    //confronto i due valori delle percentuale dei precendenti anni e quella attuale, se la differenza è maggiore del 10% allora vero se no falso
    let punteggioRispostePercentualiAConfronto =
      rispostePercentualiAConfronto.reduce((accumulator, risposta) => {
        if (
          //ho inserito il meno solo per differnziare se la condizione dovesse essere <= oppure >=
          Number(risposta.Risposta) + 10 <=
          risposta.SecondaPercentuale
        ) {
          nRisposteCorrette = nRisposteCorrette + 1;
          return accumulator + risposta.Punteggi;
        } else {
          return accumulator;
        }
      }, punteggioRispostePercentualiEsistenti);

    let punteggioRispostePercentualiBenchmark =
      rispostePercentualiBenchmark.reduce((accumulator, risposta) => {
        const kpi = kpiRiferimento[0];
        if (
          //ho inserito il meno solo per differnziare se la condizione dovesse essere <= oppure >=
          kpi?.length > 0 ||
          kpi[risposta?.Benchmark] + 10 <= Number(risposta?.Risposta)
        ) {
          nRisposteCorrette = nRisposteCorrette + 1;
          return accumulator + risposta.Punteggi;
        } else {
          return accumulator;
        }
      }, punteggioRispostePercentualiAConfronto);

    // Calculate the segments based on the values
    const chartData = [
      {
        name: "KPI Score",
        Totale: survey?.reduce((a, b) => a + b.Punteggi, 0),
        Punteggio: punteggioRispostePercentualiBenchmark, // The actual score achieved
        RemainingScore:
          survey?.reduce((a, b) => a + b.Punteggi, 0) -
          punteggioRispostePercentualiBenchmark, // The remaining score to reach the total
        PunteggioMinimo:
          (60 / 100) * survey?.reduce((a, b) => a + b.Punteggi, 0),
      },
    ];

    setPunteggi(chartData);
  };
  //#endregion

  useEffect(() => {
    (async () => {
      reloadAfterUpload();
      reloadAfterUploadFile();
      setCliente({ ...cliente, ...location.state?.cliente });

      let response = await unsplash.post(
        "portaleParita",
        {
          operation: "getDomandeSurvey",
          grandezza: location.state?.cliente?.Grandezza,
          cliente: location.state?.cliente.ID,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      let responseAteco = await unsplash.get("parita/ateco", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      /* let onlyAtecoPrincipal = responseAteco.data.data.filter((el) =>
        el.Codice.match(/[a-z]/i)
      ); */
      setCodiciAteco(responseAteco.data.data);
      loadComitato();
      loadMansionario();
      if (location.state?.cliente?.CodiceAteco) {
        setCodiciAtecoSel(
          responseAteco.data.data?.filter(
            (codice) => codice.ID === location.state?.cliente.CodiceAteco
          )[0]
        );
      }

      let primaSur = response.data.filter((el) => el.NSurvey === 1);
      let secondaSur = response.data.filter((el) => el.NSurvey === 2);
      setPrimaSurvey(primaSur);
      handleConteggioCertificazione(primaSur);
      setSecondaSurvey(secondaSur);
      console.log(secondaSur);
      if (location.state?.cliente?.Logo) {
        const img = new Image();
        img.onload = () => {
          setDimensions({ width: img.width, height: img.height });
        };
        img.src = location.state.cliente.Logo;
      }
    })();
  }, []);

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Grid item xs={12}>
        <Card sx={{ position: "relative", my: "auto" }}>
          <CardHeader
            sx={{ textAlign: "center" }}
            title={location.state.cliente?.RagioneSociale}
            titleTypographyProps={{ variant: "h4" }}
          />
        </Card>

        <Card sx={{ marginTop: "10px" }}>
          <CardContent sx={{ p: 0 }}>
            {cliente?.Confermato === 0 && cliente?.Step !== 0 && (
              <div className="w-full flex justify-end pt-2 pr-6 gap-2">
                <Button
                  startIcon={<DoneAll />}
                  sx={{ color: "white" }}
                  variant="outlined"
                  className="ButtonMiller"
                  onClick={() => setOpen(true)}
                >
                  Conferma {fromStepToCategory(cliente?.Step)}
                </Button>
                <Button
                  startIcon={<ReportProblem />}
                  sx={{ color: "white" }}
                  className="ButtonMillerError"
                  onClick={() => setOpenMail(true)}
                >
                  Compilazione errata
                </Button>
              </div>
            )}
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  textColor="inherit"
                  variant="scrollable"
                  onChange={handleChangeTab}
                  aria-label="basic tabs example"
                >
                  <Tab label="Generali" />
                  <Tab label="Comitato" />
                  <Tab label="Organigramma" />
                  <Tab label="Mansionario" />
                  <Tab label="Accessi" />
                  <Tab label="Prima Survey" />
                  <Tab label="Seconda Survey" />
                  <Tab label="Stampe" />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Box>
                  <Grid container spacing={2} mt={1}>
                    <Grid item xs={12}>
                      <CardActionArea
                        onClick={() => {
                          document.getElementById("file").click();
                        }}
                      >
                        <CardMedia
                          component="img"
                          image={
                            cliente.Logo !== ""
                              ? cliente.Logo.includes("data:image/")
                                ? cliente.Logo
                                : "data:image/png;base64," + cliente.Logo
                              : require("../assets/images/MILLERLogo.jpg")
                          }
                          src={
                            cliente.Logo !== ""
                              ? cliente.Logo.includes("data:image/")
                                ? cliente.Logo
                                : "data:image/png;base64," + cliente.Logo
                              : require("../assets/images/MILLERLogo.jpg")
                          }
                          id="Logo"
                          alt="img"
                          sx={{
                            height: "150px",
                            objectFit: "contain",
                            display: "flex",
                          }}
                        />
                      </CardActionArea>
                      <Button
                        fullWidth
                        className="ButtonMiller"
                        startIcon={<Upload />}
                        variant="contained"
                        onClick={() => {
                          document.getElementById("file").click();
                        }}
                      >
                        Carica Logo
                      </Button>
                      <input
                        type="file"
                        id="file"
                        accept="image/*"
                        onChange={(e) => handleChangeImage(e)}
                        style={{ display: "none" }}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        value={cliente?.RagioneSociale}
                        id="outlined-basic"
                        label="Ragione Sociale"
                        name="RagioneSociale"
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        value={cliente?.Denominazione}
                        id="outlined-basic"
                        label="Denominazione"
                        name="Denominazione"
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <InputLabel id="Grandezza">
                          Grandezza Impresa
                        </InputLabel>
                        <Select
                          label="Grandezza Impresa"
                          labelId="Grandezza"
                          id="demo-simple-select"
                          value={cliente?.Grandezza}
                          onChange={(e) => {
                            let l = { ...cliente };
                            l.Grandezza = e.target.value;
                            setCliente(l);
                          }}
                        >
                          <MenuItem value={1}>Micro</MenuItem>
                          <MenuItem value={2}>Piccola</MenuItem>
                          <MenuItem value={3}>Media/Grande</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        value={cliente?.Sito}
                        id="outlined-basic"
                        label="Sito Web"
                        name="Sito"
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        value={cliente?.SedeLegale}
                        id="outlined-basic"
                        label="Sede Legale"
                        name="SedeLegale"
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        value={cliente?.SedeAmministrativa}
                        id="outlined-basic"
                        label="Sede Amministrativa"
                        name="SedeAmministrativa"
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        value={cliente?.Telefono}
                        id="outlined-basic"
                        label="Telefono"
                        name="Telefono"
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        value={cliente?.Pec}
                        id="outlined-basic"
                        label="PEC"
                        name="Pec"
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        value={cliente?.Piva}
                        id="outlined-basic"
                        label="Partita IVA"
                        name="Piva"
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        value={cliente?.Rea}
                        id="outlined-basic"
                        label="REA"
                        name="Rea"
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={codiciAteco}
                        fullWidth
                        value={codiciAtecoSel}
                        getOptionLabel={(option) =>
                          option.Lettera +
                          " - " +
                          option.Codice +
                          " - " +
                          option.Descrizione
                        }
                        onChange={(event, newValue) => {
                          setCodiciAtecoSel(newValue);
                          if (newValue) {
                            setCliente({
                              ...cliente,
                              CodiceAteco: newValue.ID,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Codice Ateco" />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        value={cliente?.NomeRappresentante}
                        id="outlined-basic"
                        label="Nome Rappresentante Legale dell'Impresa"
                        name="NomeRappresentante"
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        value={cliente?.CognomeRappresentante}
                        id="outlined-basic"
                        label="Cognome Rappresentante Legale dell'Impresa"
                        name="CognomeRappresentante"
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LocalizationProvider dateAdapter={DateAdapter}>
                        <DatePicker
                          sx={{ width: "100%" }}
                          ampmInClock={false}
                          value={
                            cliente?.DataNascitaRappresentante
                              ? moment(cliente?.DataNascitaRappresentante)
                              : null
                          }
                          renderInput={(props) => (
                            <TextField size="small" fullWidth {...props} />
                          )}
                          label="Data Di Nascita Rappresentante Legale dell'Impresa"
                          onChange={(newValue) => {
                            setCliente({
                              ...cliente,
                              DataNascitaRappresentante: newValue,
                            });
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        value={cliente?.LuogoNascitaRappresentante}
                        id="outlined-basic"
                        label="Luogo Di Nascita Rappresentante Legale dell'Impresa"
                        name="LuogoNascitaRappresentante"
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        multiline
                        rows={3}
                        value={cliente?.Storia}
                        id="outlined-basic"
                        label="Storia"
                        name="Storia"
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        multiline
                        rows={3}
                        value={cliente?.Missione}
                        id="outlined-basic"
                        label="Mission"
                        name="Missione"
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={6}
                      display={"flex"}
                      alignItems="center"
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked={cliente?.Cessato === 1}
                            onChange={(e) =>
                              setCliente({
                                ...cliente,
                                Cessato: e.target.checked ? 1 : 0,
                              })
                            }
                          />
                        }
                        label="Cessato"
                      />
                    </Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                      }}
                      item
                      xs={12}
                    >
                      <Button
                        onClick={handleSave}
                        variant="contained"
                        className="ButtonMiller"
                        startIcon={<Check />}
                      >
                        Salva
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div className="w-full grid grid-cols-2 gap-3">
                  <div className="col-span-2 md:col-span-1">
                    <TextField
                      value={cliente?.NomeResponsabile}
                      id="outlined-basic"
                      label="Nome Responsabile del Sistema di Gestione della Parità"
                      name="NomeResponsabile"
                      onChange={handleChange}
                      fullWidth
                    />
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <TextField
                      value={cliente?.CognomeResponsabile}
                      id="outlined-basic"
                      label="Cognome Responsabile del Sistema di Gestione della Parità"
                      name="CognomeResponsabile"
                      onChange={handleChange}
                      fullWidth
                    />
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <TextField
                      value={cliente?.BudgetAnnuale}
                      id="outlined-basic"
                      type="number"
                      label="Budget Annuale"
                      name="BudgetAnnuale"
                      onChange={handleChange}
                      fullWidth
                    />
                  </div>
                  <div className="col-span-2 flex justify-end">
                    <Button
                      variant="contained"
                      className="ButtonMiller"
                      startIcon={<Check />}
                      onClick={handleSave}
                    >
                      Salva
                    </Button>
                  </div>
                  <div className="col-span-2 mt-5">
                    <Divider />
                    <h1 className="text-2xl center uppercase text-center pt-2">
                      Membri del Comitato
                    </h1>
                  </div>
                  <div className="col-span-2 md:col-span-2">
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                      gap={2}
                    >
                      <Comitato
                        id="new"
                        saveFunction={handleSaveComitato}
                        title="Nuovo Membro del Comitato"
                        snack={snack}
                        openSnack={openSnack}
                        handleCloseSnack={handleCloseSnack}
                        setSnack={setSnack}
                        setOpenSnack={setOpenSnack}
                      />
                    </Grid>
                    <Box sx={{ height: "60vh" }}>
                      <DataGridPro
                        components={{
                          Toolbar: CustomToolbarG,
                          Footer: CustomFooterPersonalized,
                        }}
                        localeText={
                          itIT.components.MuiDataGrid.defaultProps.localeText
                        }
                        rows={comitato}
                        columns={columnsComitato}
                        getRowId={(row) => row.ID}
                      />
                    </Box>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <div className="w-full flex justify-end">
                  <FileUploader
                    colonna={"Parita"}
                    id={cliente?.ID}
                    reloadAfterUpload={reloadAfterUploadFile}
                  />
                </div>
                {/* {allegati.map((allegato) => {
                return (
                  <div className="w-1/2 my-2">{allegato?.Descrizione}</div>
                );
              })} */}
                <Box sx={{ height: "60vh", width: "100%" }}>
                  <DataGridPro
                    components={{
                      Toolbar: CustomToolbar,
                      Footer: CustomFooterPersonalized,
                    }}
                    localeText={
                      itIT.components.MuiDataGrid.defaultProps.localeText
                    }
                    columns={columnsAllegati}
                    rows={allegati}
                    getRowId={(allegati) => allegati.ID}
                  />
                </Box>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  gap={2}
                >
                  <Mansionario
                    id="new"
                    saveFunction={handleSaveMansionario}
                    title="Nuova Mansione"
                    snack={snack}
                    openSnack={openSnack}
                    handleCloseSnack={handleCloseSnack}
                    setSnack={setSnack}
                    setOpenSnack={setOpenSnack}
                  />
                </Grid>
                <Box sx={{ height: "60vh" }}>
                  <DataGridPro
                    components={{
                      Toolbar: CustomToolbarG,
                      Footer: CustomFooterPersonalized,
                    }}
                    localeText={
                      itIT.components.MuiDataGrid.defaultProps.localeText
                    }
                    rows={mansionario}
                    columns={columnsMansionario}
                    getRowId={(row) => row.ID}
                  />
                </Box>
              </TabPanel>
              <TabPanel value={value} index={4}>
                <Box sx={{ height: "60vh" }}>
                  <Grid item xs={12} md={12}>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                      gap={2}
                    >
                      <ModalCredenziali
                        lavoratoriSelezionati={paritaClientiSel}
                        loadLavoratori={reloadAfterUpload}
                      />
                      <NewAccessiParita
                        id="new"
                        //utenteCliente={vistaUtenteCliente}
                        saveFunction={handleSaveAccessi}
                        title="Nuovo Utente"
                        snack={snack}
                        openSnack={openSnack}
                        handleCloseSnack={handleCloseSnack}
                        setSnack={setSnack}
                        setOpenSnack={setOpenSnack}
                      />
                    </Grid>
                  </Grid>
                  <DataGridPro
                    components={{
                      Toolbar: CustomToolbarG,
                      Footer: CustomFooterPersonalized,
                    }}
                    slots={{
                      loadingOverlay: LinearProgress,
                    }}
                    //loading={loading}
                    localeText={
                      itIT.components.MuiDataGrid.defaultProps.localeText
                    }
                    rows={accessi}
                    initialState={{ pinnedColumns: { left: ["Actions"] } }}
                    columns={columns}
                    getRowId={(row) => row.ID}
                    onSelectionModelChange={(ids) => {
                      const selectedIDs = new Set(ids);
                      const selectedRowData = accessi.filter((row) =>
                        selectedIDs.has(row.ID)
                      );
                      setParitaClientiSel(selectedRowData);
                    }}
                    checkboxSelection
                    onCellClick={(params, e) => {
                      if (params.field === "Actions") {
                        e.stopPropagation();
                      }
                    }}
                  />
                </Box>
              </TabPanel>
              <TabPanel value={value} index={5}>
                <div className="flex w-full items-center">
                  <Box sx={{ height: "60vh", width: "100%" }}>
                    <DataGridPro
                      components={{
                        Toolbar: CustomToolbarG,
                        Footer: CustomFooterPersonalized,
                      }}
                      slots={{
                        loadingOverlay: LinearProgress,
                      }}
                      localeText={
                        itIT.components.MuiDataGrid.defaultProps.localeText
                      }
                      rows={primaSurvey}
                      columns={primaSurveyColumns}
                      getRowId={(row) => row.ID}
                    />
                  </Box>
                  <div className="w-1/5">
                    {punteggi?.length > 0 && (
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart
                          data={punteggi}
                          margin={{
                            top: 25,
                            right: 0,
                            left: 0,
                            bottom: 0,
                          }}
                          style={{ fontSize: 12 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip content={<CustomTooltip />} />

                          {/* Render the filled score as a solid bar */}
                          <Bar dataKey="Punteggio" stackId="a">
                            {punteggi?.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={
                                  (entry.Punteggio /
                                    (entry.Punteggio + entry.RemainingScore)) *
                                    100 <=
                                  60
                                    ? "#FF2768"
                                    : "#40C18F"
                                }
                              />
                            ))}
                          </Bar>

                          {/* Render a transparent bar with a dotted border for the total */}
                          <Bar
                            dataKey="RemainingScore"
                            stackId="a"
                            fill="transparent"
                            stroke="#8884d8"
                            strokeDasharray="4 4"
                          />

                          {/* Render the passing score as a red reference line */}
                          <ReferenceLine
                            y={punteggi[0]?.PunteggioMinimo}
                            stroke="rgb(255, 39, 104)"
                            strokeWidth={2}
                            /* label={{
                              fontWeight: 700,
                              position: "left",
                              value: "Cert.",
                              fill: "rgb(255, 39, 104)",
                            }} */
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    )}
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={6}>
                <Box sx={{ height: "60vh" }}>
                  <DataGridPro
                    components={{
                      Toolbar: CustomToolbarG,
                      Footer: CustomFooterPersonalized,
                    }}
                    slots={{
                      loadingOverlay: LinearProgress,
                    }}
                    localeText={
                      itIT.components.MuiDataGrid.defaultProps.localeText
                    }
                    rows={secondaSurvey}
                    columns={secondaSurveyColumns}
                    getRowId={(row) => row.ID}
                  />
                </Box>
              </TabPanel>
              <TabPanel value={value} index={7}>
                {cliente && (
                  <ButtonGroup
                    variant="contained"
                    className="ButtonMiller border border-[#11526f] ml-4 h-[30px]"
                    ref={anchorRef}
                    aria-label="split button"
                  >
                    <Button
                      size="small"
                      startIcon={<InsertDriveFile />}
                      variant="contained"
                      className="ButtonMiller"
                      onClick={() => {
                        handleToggleButton();
                      }}
                    >
                      MODULI
                    </Button>
                    <Button
                      variant="contained"
                      className="ButtonMiller"
                      size="small"
                      aria-controls={
                        openButton ? "split-button-menu" : undefined
                      }
                      aria-expanded={openButton ? "true" : undefined}
                      aria-label="select merge strategy"
                      aria-haspopup="menu"
                      onClick={() => {
                        handleToggleButton();
                      }}
                    >
                      {openButton ? <ArrowDropUp /> : <ArrowDropDown />}
                    </Button>
                  </ButtonGroup>
                )}
                <div style={{ display: "none" }}>
                  <Button
                    id="0"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaMod01Politica}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    Mod. 01 Politica
                  </Button>
                  <Button
                    id="1"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaMod02Regolamento}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    Mod. 02 Regolamento
                  </Button>
                  <Button
                    id="2"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaMod02AttoComitato}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    Mod. 02.01 Atto Comitato
                  </Button>
                  <Button
                    id="3"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaMod02NominaComitato}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    Mod. 02.02 Nomina Comitato
                  </Button>
                  <Button
                    id="4"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaMod02NominaRSGPG}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    Mod. 02.03 Nomina RSGPG
                  </Button>
                  <Button
                    id="5"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaMod02VerbaleRiunione}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    Mod. 02.04 Verbale Riunione
                  </Button>
                  <Button
                    id="6"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaMod03Dichiarazione}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    Mod. 03 Dichiarazione
                  </Button>
                  <Button
                    id="7"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaMod0301Dichiarazione}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    Mod. 03.01 Dichiarazione
                  </Button>
                  <Button
                    id="8"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaMod04ElencoDoc}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    Mod. 04 Elenco Doc
                  </Button>
                  <Button
                    id="9"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaMod05Esigenze}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    Mod. 05 Esigenze
                  </Button>
                  {/* <Button
                    id="10"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaMod05Organigramma}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    Mod. 05.02 Organigramma
                  </Button> */}
                  <Button
                    id="10"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaMod05Analisi}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    Mod. 05 Analisi del contesto
                  </Button>
                  <Button
                    id="11"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaMod05Mansionario}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    Mod. 05.03 Mansionario
                  </Button>
                  <Button
                    id="12"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaMod05Identificazione}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    Mod. 05.04 Identificazione
                  </Button>
                  <Button
                    id="13"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaMod06PianoStrategico}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    Mod. 06 Piano strategico
                  </Button>
                  <Button
                    id="14"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaMod0601SchedaPiano}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    Mod. 06.01 Scheda piano d'azione
                  </Button>
                  <Button
                    id="15"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaMod07PianoComunicazione}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    Mod. 07 Piano Cominicazione
                  </Button>
                  <Button
                    id="16"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaMod0701PianoFormazione}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    07.01 Piano formazione
                  </Button>
                  <Button
                    id="17"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaMod07Informativa}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    Mod. 07 Informativa
                  </Button>
                  <Button
                    id="18"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaMod0703Informativa}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    Mod. 07.03 Informativa
                  </Button>
                  <Button
                    id="19"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaMod0704Informativa}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    Mod. 07.04 Informativa
                  </Button>
                  <Button
                    id="20"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaMod0705Rapporto}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    Mod. 07.05 Rapporto
                  </Button>
                  <Button
                    id="21"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaMod0706Questionario}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    Mod. 07.06 Questionario
                  </Button>
                  <Button
                    id="22"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaMod08Registro}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    Mod. 08 Registro
                  </Button>
                  <Button
                    id="23"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaMod0901Programma}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    Mod. 09.01 Programma
                  </Button>
                  <Button
                    id="24"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaMod0902Revisione}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    Mod. 09.02 Revisione
                  </Button>
                  <Button
                    id="25"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaMod1001Rapporto}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    Mod. 10.01 Rapporto
                  </Button>
                  <Button
                    id="26"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaMod1002Rapporto}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    Mod. 10.02 Rapporto
                  </Button>
                  <Button
                    id="27"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaMod1003Rapporto}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    Mod. 10.03 Rapporto
                  </Button>
                  <Button
                    id="28"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaMod1004Registro}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    Mod. 10.04 Registro
                  </Button>
                  <Button
                    id="29"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaMod1005Rapporto}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    Mod. 10.05 Rapporto
                  </Button>
                </div>
                <Popper
                  sx={{
                    zIndex: 100,
                  }}
                  open={openButton}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps}>
                      <Paper sx={{ ml: "15px" }}>
                        <ClickAwayListener onClickAway={handleCloseButton}>
                          <MenuList
                            id="split-button-menu"
                            autoFocusItem
                            sx={{ maxHeight: 350, overflowY: "auto" }}
                          >
                            {optionsModuli.map((option, index) => (
                              <MenuItem
                                key={option}
                                onClick={(event) => {
                                  if (!option?.disabled) {
                                    handleMenuItemClick(event, index);
                                  }
                                }}
                              >
                                <span style={{ color: "#11526f" }}>
                                  {option.icon} {option.text}
                                </span>
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>

                <ButtonGroup
                  variant="contained"
                  className="ButtonMiller border border-[#11526f] ml-4 h-[30px]"
                  ref={anchorRefProcedure}
                  aria-label="split button"
                >
                  <Button
                    size="small"
                    startIcon={<InsertDriveFile />}
                    variant="contained"
                    className="ButtonMiller"
                    onClick={() => {
                      handleToggleButtonProcedure();
                    }}
                  >
                    PROCEDURE
                  </Button>
                  <Button
                    variant="contained"
                    className="ButtonMiller"
                    size="small"
                    aria-controls={
                      openButtonProcedure ? "split-button-menu" : undefined
                    }
                    aria-expanded={openButtonProcedure ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={() => {
                      handleToggleButtonProcedure();
                    }}
                  >
                    {openButton ? <ArrowDropUp /> : <ArrowDropDown />}
                  </Button>
                </ButtonGroup>
                <div style={{ display: "none" }}>
                  <Button
                    id="100"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaPSGPG01}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    PSGPG 01
                  </Button>
                  <Button
                    id="101"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaPSGPG02}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    PSGPG 02
                  </Button>
                  <Button
                    id="102"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaPSGPG03}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    PSGPG 03
                  </Button>
                  <Button
                    id="103"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaPSGPG04}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    PSGPG 04
                  </Button>
                  <Button
                    id="104"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaPSGPG05}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    PSGPG 05
                  </Button>
                  <Button
                    id="105"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaPSGPG06}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    PSGPG 06
                  </Button>
                  <Button
                    id="106"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaPSGPG07}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    PSGPG 07
                  </Button>
                  <Button
                    id="107"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaPSGPG08}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    PSGPG 08
                  </Button>
                  <Button
                    id="108"
                    size="small"
                    startIcon={<Print />}
                    onClick={stampaPSGPG09}
                    sx={{ color: "white" }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    PSGPG 09
                  </Button>
                </div>
                <Popper
                  sx={{
                    zIndex: 100,
                  }}
                  open={openButtonProcedure}
                  anchorEl={anchorRefProcedure.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps}>
                      <Paper sx={{ ml: "150px" }}>
                        <ClickAwayListener
                          onClickAway={handleCloseButtonProcedure}
                        >
                          <MenuList
                            id="split-button-menu"
                            autoFocusItem
                            sx={{ maxHeight: 350, overflowY: "auto" }}
                          >
                            {optionsProcedure.map((option, index) => (
                              <MenuItem
                                key={option}
                                onClick={(event) => {
                                  handleMenuItemClickProcedure(
                                    event,
                                    index + 100
                                  );
                                }}
                              >
                                <span style={{ color: "#11526f" }}>
                                  {option.icon} {option.text}
                                </span>
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </TabPanel>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="sm"
        TransitionComponent={Transition}
      >
        <DialogTitle
          sx={{
            mx: "auto",
          }}
        >
          Conferma Step
        </DialogTitle>
        <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
          Sei sicuro di voler confermare lo step ed inviare l'email?
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <Button
            onClick={handleClose}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
          >
            Chiudi
          </Button>
          <Button
            onClick={handleConfermaStep}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
          >
            Invia
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openMail}
        onClose={handleCloseMail}
        fullWidth={true}
        maxWidth="md"
        TransitionComponent={Transition}
      >
        <DialogTitle
          sx={{
            mx: "auto",
          }}
        >
          Invia Segnalazione
        </DialogTitle>
        <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
          <Grid container mt={2} xs={12}>
            <TextField
              multiline
              value={testoEmail}
              id="outlined-basic"
              label="Testo Mail"
              rows={5}
              onChange={(e) => setTestoEmail(e.target.value)}
              fullWidth
            />
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <Button
            onClick={handleCloseMail}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
          >
            Chiudi
          </Button>
          <Button
            onClick={sendSegnalazione}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
          >
            Invia
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ModalCredenziali = (props) => {
  const [severity, setSeverity] = useState("success");
  const [text, setText] = useState("Ok");
  const [open, setOpen] = useState(false);

  const [openAlert, setOpenAlert] = useState(false);
  const handleClickOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendMail = async () => {
    try {
      await unsplash.post(
        "portaleParita",
        { operation: "generateMail", utenti: props.lavoratoriSelezionati },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setText("Credenziali inviate con successo");
      setSeverity("success");
      props.loadLavoratori();
      setOpen(false);
    } catch {
      setText("Errore durante l'invio delle credenziali");
      setSeverity("error");
    }

    setOpenAlert(true);
  };

  return (
    <>
      {props.lavoratoriSelezionati.length > 0 && (
        <Button
          startIcon={<Send />}
          sx={{ color: "white" }}
          variant="outlined"
          className="ButtonMiller"
          onClick={() =>
            document.getElementById("sendCredentialButton").click()
          }
        >
          Invia credenziali
        </Button>
      )}
      {createPortal(
        <Snackbar
          open={openAlert}
          autoHideDuration={6000}
          style={{ marginTop: "50px" }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={() => setOpenAlert(false)}
        >
          <Alert severity={severity} sx={{ width: "100%" }} variant="filled">
            {text}
          </Alert>
        </Snackbar>,
        document.getElementById("portal")
      )}
      <Button
        startIcon={<Add />}
        onClick={handleClickOpen}
        id="sendCredentialButton"
        style={{ display: "None" }}
        className="ButtonMiller"
      >
        Aggiungi
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth="sm"
        maxWidth="sm"
        TransitionComponent={Transition}
      >
        <DialogTitle sx={{ mx: "auto" }}>
          Invio credenziali area parità di genere
        </DialogTitle>
        <DialogContent>
          Attenzione sei sicuro di voler inviare le credenziali agli utenti
          selezionati?
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between", mx: 2 }}
        >
          <Button
            onClick={handleClose}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
          >
            Chiudi
          </Button>
          <Button
            onClick={handleSendMail}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
          >
            Invia
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const NewAccessiParita = (props) => {
  const [open, setOpen] = useState(false);
  const [utenteCliente, setUtenteCliente] = useState({
    Username: "",
    Cliente: "",
    Email: "",
    PrimoAccesso: 0,
    Attivo: 0,
  });
  const [validation, setValidation] = useState({
    Username: false,
    Email: false,
  });

  const handleClickOpen = async () => {
    if (props.id === "edit") {
      setUtenteCliente({
        ...props.utenteCliente,
      });
    }
    setOpen(true);
    setValidation({ Username: false, Email: false });
  };
  const handleClose = () => {
    setUtenteCliente({
      Username: "",
      Cliente: "",
      Email: "",
      Dettaglio: 0,
      Attivo: 0,
    });
    setOpen(false);
    setValidation({ Username: false, Email: false });
  };

  const handleChangeNoSpaces = (e) => {
    let copiaUtenteCliente = { ...utenteCliente };

    // Prevent spaces in the input value
    let inputValue = e.target.value.replace(/\s/g, "");

    copiaUtenteCliente[e.target.name] = inputValue;

    setUtenteCliente(copiaUtenteCliente);
  };
  const handleChange = (e) => {
    let copiaUtenteCliente = { ...utenteCliente };
    if (e.target.name === "Password") {
      copiaUtenteCliente[e.target.name] = e.target.value;
    } else {
      copiaUtenteCliente[e.target.name] = e.target.value;
    }

    setUtenteCliente(copiaUtenteCliente);
  };
  const handleSave = async () => {
    var check = false;
    let val = { ...validation };
    if (utenteCliente.Username === "" || utenteCliente.Username === null) {
      val.Username = true;
      check = true;
    } else {
      val.Username = false;
    }
    if (utenteCliente.Email === "" || utenteCliente.Email === null) {
      val.Email = true;
      check = true;
    } else {
      val.Email = false;
    }

    setValidation(val);
    if (!check) {
      props.saveFunction(utenteCliente, props.id);
      handleClose();
    }
  };

  return (
    <>
      {props.id === "edit" ? (
        <IconButton id={props.id} aria-label="edit" onClick={handleClickOpen}>
          <EditIcon
            style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
          />
        </IconButton>
      ) : (
        <Button
          startIcon={<Add />}
          onClick={handleClickOpen}
          id={props.id}
          sx={{ color: "white" }}
          variant="outlined"
          className="ButtonMiller"
        >
          Aggiungi
        </Button>
      )}

      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth="lg"
          TransitionComponent={Transition}
        >
          <DialogTitle
            sx={{
              mx: "auto",
            }}
          >
            {props.title}
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={2} sx={{ alignItems: "flex-end" }}>
              {props.id === "edit" && (
                <Grid
                  item
                  xs={12}
                  md={12}
                  display={"flex"}
                  justifyContent="center"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={utenteCliente?.PrimoAccesso === 1}
                        disabled
                        /* onChange={(e) =>
                      setUtenteCliente({
                        ...utenteCliente,
                        PrimoAccesso: e.target.checked ? 1 : 0,
                      })
                    } */
                      />
                    }
                    label="Primo Accesso"
                  />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={props.id === "new" ? false : true}
                  value={utenteCliente.Username}
                  id="outlined-basic"
                  label="Username"
                  name="Username"
                  onChange={handleChangeNoSpaces}
                  fullWidth
                  sx={{ mt: 2 }}
                  error={validation?.Username}
                  helperText={validation?.Username ? "Campo obbligatorio" : ""}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={props.id === "new" ? false : true}
                  value={utenteCliente.Email}
                  id="outlined-basic"
                  label="Email"
                  name="Email"
                  onChange={handleChange}
                  fullWidth
                  sx={{ mt: 2 }}
                  error={validation?.Email}
                  helperText={validation?.Email ? "Campo obbligatorio" : ""}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2,
                }}
              >
                <Button
                  onClick={handleClose}
                  variant="contained"
                  className="ButtonMiller"
                  startIcon={<Close />}
                >
                  Chiudi
                </Button>
                {props.id === "new" && (
                  <Button
                    onClick={handleSave}
                    variant="contained"
                    className="ButtonMiller"
                    startIcon={<Check />}
                  >
                    Salva
                  </Button>
                )}
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Grid>
    </>
  );
};
const RispostaPrimaSurvey = (props) => {
  const [open, setOpen] = useState(false);
  const [risposta, setRisposta] = useState(null);

  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleClickOpen = async () => {
    if (props.id === "edit") {
      setRisposta({
        ...props.risposta,
      });
      console.log(props.risposta);
    }
    setOpen(true);
  };
  const handleClose = () => {
    setRisposta(null);
    setOpen(false);
  };

  const handleChange = (e) => {
    setRisposta({ ...risposta, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    props.saveFunction(risposta);
    handleClose();
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      {props.id === "edit" ? (
        <IconButton id={props.id} aria-label="edit" onClick={handleClickOpen}>
          <EditIcon
            style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
          />
        </IconButton>
      ) : (
        <Button
          startIcon={<Add />}
          onClick={handleClickOpen}
          id={props.id}
          sx={{ color: "white" }}
          variant="outlined"
          className="ButtonMiller"
        >
          Aggiungi
        </Button>
      )}

      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth="lg"
          TransitionComponent={Transition}
        >
          <DialogTitle
            sx={{
              mx: "auto",
            }}
          >
            {props.title}
          </DialogTitle>

          <DialogContent>
            <div className="w-full flex flex-col py-2">
              <div className="w-full mb-2">{props.risposta?.Domanda}</div>
              {props.risposta?.TipologiaInput === 0 && (
                <div className="w-full mb-2">
                  <FormControl>
                    <RadioGroup
                      row
                      value={risposta?.Risposta}
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={(e) => {
                        setRisposta({ ...risposta, Risposta: e.target.value });
                      }}
                    >
                      <FormControlLabel
                        value={"1"}
                        control={<Radio />}
                        label="Si"
                      />
                      <FormControlLabel
                        value={"0"}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              )}
              {props.risposta?.TipologiaInput === 1 && (
                <div className="w-1/2 md:w-1/4 mt-3 mb-2">
                  <TextField
                    value={risposta?.Risposta}
                    label="Percentuale"
                    onChange={(e) => {
                      setRisposta({ ...risposta, Risposta: e.target.value });
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      inputProps: { min: 0, max: 100 },
                    }}
                    type="number"
                    fullWidth
                  />
                </div>
              )}
              {props.risposta?.TipologiaInput === 2 && (
                <div className="w-full mt-3">
                  <TextField
                    multiline
                    rows={2}
                    value={risposta?.Risposta}
                    label="Risposta"
                    onChange={(e) => {
                      setRisposta({ ...risposta, Risposta: e.target.value });
                    }}
                    fullWidth
                  />
                </div>
              )}
              {props.risposta?.TipologiaInput === 3 && (
                <div className="flex gap-2">
                  <div className="w-1/2 mt-3 mb-2">
                    <TextField
                      value={risposta?.Risposta}
                      label="Percentuale ultimo biennio"
                      onChange={(e) => {
                        setRisposta({ ...risposta, Risposta: e.target.value });
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                        inputProps: { min: 0, max: 100 },
                      }}
                      type="number"
                      fullWidth
                    />
                  </div>
                  <div className="w-1/2 mt-3 mb-2">
                    <TextField
                      value={risposta?.SecondaPercentuale}
                      label="Percentuale attuale"
                      onChange={(e) => {
                        setRisposta({
                          ...risposta,
                          SecondaPercentuale: e.target.value,
                        });
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                        inputProps: { min: 0, max: 100 },
                      }}
                      type="number"
                      fullWidth
                    />
                  </div>
                </div>
              )}
              <div className="w-full">
                <TextField
                  value={risposta?.NoteRisposta}
                  label="Note"
                  onChange={(e) => {
                    setRisposta({ ...risposta, NoteRisposta: e.target.value });
                  }}
                  fullWidth
                />
              </div>
            </div>

            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2,
                }}
              >
                <Button
                  onClick={handleClose}
                  variant="contained"
                  className="ButtonMiller"
                  startIcon={<Close />}
                >
                  Chiudi
                </Button>

                <Button
                  onClick={handleSave}
                  variant="contained"
                  className="ButtonMiller"
                  startIcon={<Check />}
                >
                  Salva
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Grid>
    </>
  );
};
const RispostaSecondaSurvey = (props) => {
  const [open, setOpen] = useState(false);
  const [risposta, setRisposta] = useState(null);

  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleClickOpen = async () => {
    if (props.id === "edit") {
      setRisposta({
        ...props.risposta,
      });
      console.log(props.risposta);
    }
    setOpen(true);
  };
  const handleClose = () => {
    setRisposta(null);
    setOpen(false);
  };

  const handleSave = async () => {
    props.saveFunction(risposta);
    handleClose();
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <IconButton id={props.id} aria-label="edit" onClick={handleClickOpen}>
        <EditIcon style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
      </IconButton>

      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth="lg"
          TransitionComponent={Transition}
        >
          <DialogTitle
            sx={{
              mx: "auto",
            }}
          >
            {props.title}
          </DialogTitle>

          <DialogContent>
            <div className="w-full flex flex-col py-2">
              <div className="w-full mb-2">{props.risposta?.Domanda}</div>
              <div className="w-full">
                <TextField
                  value={risposta?.Risposta}
                  label="Risposta"
                  onChange={(e) => {
                    setRisposta({ ...risposta, Risposta: e.target.value });
                  }}
                  fullWidth
                />
              </div>
            </div>

            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2,
                }}
              >
                <Button
                  onClick={handleClose}
                  variant="contained"
                  className="ButtonMiller"
                  startIcon={<Close />}
                >
                  Chiudi
                </Button>

                <Button
                  onClick={handleSave}
                  variant="contained"
                  className="ButtonMiller"
                  startIcon={<Check />}
                >
                  Salva
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Grid>
    </>
  );
};

const DeleteDialog = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
          Sei sicuro di voler eliminare il cliente selezionato?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare il seguente cliente:
            <br />
            <b>{props.accesso?.Username}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button
            onClick={() => {
              props.myFunction(props.accesso);
              handleClose();
            }}
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const DeleteDialogComitato = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
          Sei sicuro di voler eliminare il cliente selezionato?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare il seguente cliente:
            <b>
              {" " + props.comitato?.Nome} - {props.comitato?.Cognome}
            </b>
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button
            onClick={() => {
              props.myFunction(props.comitato.ID);
              handleClose();
            }}
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const DeleteDialogFile = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
          Sei sicuro di voler eliminare l'allegato selezionato?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare il seguente allegato :
            <b>{props.categoria.Descrizione}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button onClick={() => props.myFunction()}>Conferma</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const DeleteDialogMansionario = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
          {props.comitato
            ? "Sei sicuro di voler eliminare il membro selezionato?"
            : "Sei sicuro di voler eliminare la mansione selezionata?"}
        </DialogTitle>
        <DialogContent>
          {props.comitato ? (
            <DialogContentText textAlign={"center"}>
              Attenzione, sei sicuro di voler eliminare il seguente membro:
              <b>
                {" " + props.comitato?.Nome} - {props.comitato?.Cognome}
              </b>
            </DialogContentText>
          ) : (
            <DialogContentText textAlign={"center"}>
              Attenzione, sei sicuro di voler eliminare il seguente mansione:
              <b>
                {" " + props.mansione?.Gruppo} - {props.mansione?.Livello}
              </b>
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button
            onClick={() => {
              if (props.comitato) {
                props.myFunction(props.comitato.ID);
              } else {
                props.myFunction(props.mansione.ID);
              }
            }}
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DettaglioParita;
