import React, { useEffect, useState } from "react";
import unsplash from "../api/unsplash";
import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";
import "jspdf-autotable";
import {
  Edit,
  Delete,
  Add,
  HealthAndSafety,
  Check,
  Close,
  LocalHospital,
  ArrowBack,
  ArrowForward,
} from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import {
  TextField,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ButtonGroup,
  IconButton,
  Zoom,
  LinearProgress,
  DialogContentText,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import GlobalSnackbar from "./Snackbar";
import {
  LocalizationProvider,
  DatePicker,
  PickersDay,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment as DateAdapter } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <GridToolbarQuickFilter placeholder="Cerca..." />
      </Grid>
    </GridToolbarContainer>
  );
}

const Mansioni = (props) => {
  const [mansioni, setMansioni] = useState([]);
  const [protocolliExtra, setProtocolliExtra] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [medici, setMedici] = useState([]);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleSaving = async (operazione, mansione, after, before) => {
    //after sono i rischi aggiornati
    //before sono i rischi vecchi se presenti
    if (operazione === "Inserimento") {
      try {
        await unsplash.post(
          "mansioni",
          { mansione: mansione, cliente: props.idAzienda },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        await unsplash.post(
          "rischi",
          { mansione: mansione, cliente: props.idAzienda, after: after },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        setMansioni([]);
        let responseMansioni = await unsplash.get("mansioni", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { id: props.idAzienda },
        });
        setMansioni(responseMansioni.data.data);
        setSnack({
          severity: "success",
          messaggio: "Mansione inserita con successo",
        });
        setOpenSnack(true);
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante l'inserimento",
        });
        setOpenSnack(true);
      }
    } else {
      try {
        await unsplash.post(
          "rischi",
          {
            mansione: mansione,
            cliente: props.idAzienda,
            after: after,
            before: before,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: " Modifica Mansione avvenuta con successo",
        });
        setOpenSnack(true);
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante la modifica",
        });
        setOpenSnack(true);
      }
    }
  };

  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "150",
      headerAlign: "center",
      align: "center",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <Modal
            check={false}
            title="Modifica"
            tipologia="Modifica"
            mansioni={mansioni}
            selezionata={params.row.ID}
            cliente={props.idAzienda}
            descrizioneSel={params.row.Descrizione}
            myFunction={(operazione, mansione, after, before) =>
              handleSaving(operazione, mansione, after, before)
            }
          />
          <ProtocolloSanitario
            title="Protocollo Sanitario"
            mansione={params.row.ID}
            descrizione={params.row.Descrizione}
            cliente={props.idAzienda}
            snack={snack}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
          />
          <DeleteRow
            check={false}
            title="Elimina"
            tipologia="Elimina"
            mansioni={mansioni}
            tot={params.row}
            selezionata={params.row.ID}
            cliente={props.idAzienda}
            snack={snack}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
            myFunction={() => reloadData()}
          />
        </>
      ),
    },
    { field: "Descrizione", headerName: "Descrizione", flex: 1 },
  ];

  const reloadData = async () => {
    setMansioni([]);
    let responseMansioni = await unsplash.get("mansioni", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: props.idAzienda },
    });
    setMansioni(responseMansioni.data.data);
    let responseProtocolliExtra = await unsplash.get("protocollo", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: {
        extra: true,
        cliente: props.idAzienda,
      },
    });
    setProtocolliExtra(responseProtocolliExtra.data.data);
    setLoaded(true);
  };

  useEffect(async () => {
    //carico le mansioni del cliente

    let responseMansioni = await unsplash.get("mansioni", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: props.idAzienda },
    });

    setMansioni(responseMansioni.data.data);
    setLoading(false);

    let responseMediciCliente = await unsplash.get(
      "medici",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { id: props.idAzienda },
      } //props.idAzienda}}
    );
    setMedici(responseMediciCliente.data.data);
    let responseProtocolliExtra = await unsplash.get("protocolloSanitario", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: {
        extra: true,
        cliente: props.idAzienda,
      },
    });
    setProtocolliExtra(responseProtocolliExtra.data.data);
    console.log(responseProtocolliExtra.data.data);
    setLoaded(true);
  }, []);

  const stampaProtocollo = async () => {
    let responseProtocollo = await unsplash.get("protocolloSanitario", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      responseType: "blob",
      params: { stampa: true, cliente: props.idAzienda },
    });
    let protocollo = responseProtocollo.data;

    const url = window.URL.createObjectURL(
      new Blob([protocollo], { type: "application/pdf" })
    ); //prendo il file, lo rendo blob lo aggancio a un link e scarico

    const link = document.createElement("a");
    link.href = url;
    window.open(url);
  };
  return (
    <div>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Grid
        container
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="flex-end"
      >
        <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
        >
          {!props?.proceduraGuidata ? (
            <StampaProtocollo
              medici={medici}
              extra={protocolliExtra}
              idAzienda={props.idAzienda}
              ragioneSociale={props.ragioneSociale}
            />
          ) : (
            <></>
          )}
          <Modal
            check={true}
            title="Aggiungi"
            tipologia="Inserimento"
            mansioni={mansioni}
            selezionata="NO"
            cliente={props.idAzienda}
            myFunction={(operazione, mansione, after, before) =>
              handleSaving(operazione, mansione, after, before)
            }
          />
        </ButtonGroup>
      </Grid>
      <div style={{ height: "60vh", width: "100%" }}>
        <DataGridPro
          initialState={{ pinnedColumns: { left: ["Actions"] } }}
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooterPersonalized,
          }}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          loading={loading}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          rows={mansioni}
          getRowId={(mansioni) => mansioni.ID_RelMansioni}
          columns={columns}
          disableSelectionOnClick
          onSelectionChange={(newSelection) => {
            // setRighe(newSelection.rows);
          }}
        />
      </div>
      {props?.proceduraGuidata ? (
        <>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 2,
              px: 1,
            }}
            fullWidth
          >
            <Button
              variant="contained"
              className="ButtonMiller"
              startIcon={<ArrowBack />}
              onClick={() => props.handleBack()}
            >
              Indietro
            </Button>
            <Button
              variant="contained"
              className="ButtonMiller"
              endIcon={<ArrowForward />}
              onClick={() => {
                if (mansioni.length > 0) {
                  props.handleComplete();
                } else {
                  props.setSnack({
                    severity: "error",
                    messaggio: "Aggiungi almeno una Mansione",
                  });
                  props.setOpenSnack(true);
                }
              }}
            >
              Completa Passaggio
            </Button>
          </Grid>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

//MODAL
const Modal = (props) => {
  const [visible, setVisible] = useState(false);
  const [mansioniVisibili, setMansionVisibili] = useState([]);
  const [mansioniComplete, setMansioniComplete] = useState([]);
  const [rischi, setRischi] = useState([]);
  const [rischiSel, setRischiSel] = useState([]);
  const [mansione, setMansione] = useState(0);
  const [rischiConfronto, setRischiConfronto] = useState([]);
  const [nuova, setNuova] = useState(false);
  const [descrizioneMansione, setDescrizioneMansione] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleOpen = async () => {
    setRischiSel([]);

    setNuova(false);

    if (props.tipologia === "Modifica") {
      setDescrizioneMansione(props.descrizioneSel);
    } else {
      setDescrizioneMansione("");
    }

    let responseRischi = await unsplash.get("rischi", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { mansione: -1, cliente: -1 },
    });
    setRischi(responseRischi.data.data);

    let responseMansioni = await unsplash.get("mansioni", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: -1 },
    });
    setMansioniComplete(responseMansioni.data.data);
    setMansionVisibili(
      responseMansioni.data.data.filter(
        (item) => !props.mansioni.some((mans) => mans.Mansione === item.ID)
      )
    );

    //da tenere
    if (props.selezionata !== "NO") {
      let responseRischiSel = await unsplash.get("rischi", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { mansione: props.selezionata, cliente: props.cliente },
      });
      let arr = responseRischiSel.data.data.map((a) => a.Rischio);
      setRischiSel(arr);
      setRischiConfronto(arr);
    }
  };

  const handleSaveModal = async () => {
    let operazione = props.selezionata === "NO" ? "Inserimento" : "Modifica";
    if (nuova) {
      let mansioneEsistente = mansioniComplete.filter(
        (item) =>
          item.Descrizione.toLowerCase().replaceAll(" ", "") ===
          descrizioneMansione.toLowerCase().replaceAll(" ", "")
      );

      if (mansioneEsistente.length > 0) {
        setSnack({
          severity: "error",
          messaggio: "Mansione inserita già esistente",
        });
        setOpenSnack(true);
        return;
      } else {
        const responseNewMansione = await unsplash.post(
          "mansioni",
          { descrizione: descrizioneMansione },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        props.myFunction(
          operazione,
          responseNewMansione.data.message.insertId,
          rischiSel,
          rischiConfronto
        );
        setVisible(false);
      }
    } else {
      props.myFunction(
        operazione,
        props.selezionata === "NO" ? mansione : props.selezionata,
        rischiSel,
        rischiConfronto
      );
      setVisible(false);
    }
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      {props.title === "Aggiungi" ? (
        <Button
          onClick={() => {
            handleOpen();
            setVisible(!visible);
          }}
          sx={{ color: "white" }}
          variant="outlined"
          className="ButtonMiller"
          startIcon={<Add />}
        >
          Aggiungi
        </Button>
      ) : (
        <Tooltip title="Modifica">
          <IconButton
            onClick={() => {
              handleOpen();
              setVisible(!visible);
            }}
            aria-label="edit"
          >
            <EditIcon
              style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
            />
          </IconButton>
        </Tooltip>
      )}
      <Dialog
        TransitionComponent={Transition}
        open={visible}
        onClose={() => setVisible(false)}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle>
          {props.title} <b>{props.descrizioneSel}</b>
        </DialogTitle>
        <DialogContent>
          {props.selezionata !== "NO" ? (
            "" /*(
            <TextField
              id="outlined-read-only-input"
              label="Mansione"
              fullWidth
              defaultValue={
                props.descrizioneSel
              }
              InputProps={{
                readOnly: true,
              }}
            />
          ) */
          ) : (
            <>
              <FormControlLabel
                control={
                  <Checkbox checked={nuova} onChange={() => setNuova(!nuova)} />
                }
                label="Nuova"
              />
              <TextField
                label="Descrizione"
                fullWidth
                style={{ display: nuova ? "" : "None" }}
                value={descrizioneMansione}
                onChange={(e) => {
                  setDescrizioneMansione(e.target.value);
                }}
              ></TextField>
              <FormControl fullWidth style={{ display: nuova ? "None" : "" }}>
                <InputLabel id="demo-multiple-name-label">Mansione</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  onChange={(e) => setMansione(e.target.value)}
                  input={<OutlinedInput label="Mansione" />}
                >
                  {mansioniVisibili?.map((mansione) => (
                    <MenuItem key={mansione.ID} value={mansione.ID}>
                      {mansione.Descrizione}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
          <Grid container spacing={2} sx={{ marginTop: "10px" }}>
            {rischi?.map((rischio) => {
              return (
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    key={rischio.ID}
                    control={
                      <Checkbox
                        sx={{}}
                        name={rischio.Descrizione}
                        checked={rischiSel.includes(rischio.ID)}
                        onClick={(e) => {
                          if (e.target.checked) {
                            let y = [...rischiSel];
                            y.push(rischio.ID);

                            setRischiSel(y);
                          } else {
                            let index = rischiSel.indexOf(rischio.ID);
                            let y = [...rischiSel];
                            if (index > -1) {
                              y.splice(index, 1);
                              setRischiSel(y);
                            }
                          }
                        }}
                      />
                    }
                    label={rischio.Descrizione}
                  />
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            mt: 1,
            justifyContent: "space-between",
            px: 2,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={() => setVisible(false)}
          >
            Chiudi
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={() => handleSaveModal()}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const StampaProtocollo = (props) => {
  const [visible, setVisible] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [medici, setMedici] = useState([]);

  const [coordinatore, setCoordinatore] = useState(false);
  const [medicoCoordinatore, setMedicoCoordinatore] = useState(0);

  const [dataStampa, setDataStampa] = useState(null);
  const [extra, setExtra] = useState([]);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleDelete = async () => {
    let idMansione = props.tot.ID_RelMansioni;
    try {
      await unsplash.delete("mansioni", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: { ID: idMansione },
      });
      props.setSnack({
        severity: "success",
        messaggio: "Eliminazione effettuata correttamente",
      });
      props.setOpenSnack(true);
    } catch {
      props.setSnack({
        severity: "error",
        messaggio: "Errore durante la creazione",
      });
      props.setOpenSnack(true);
    }
    setVisible(false);
    props.myFunction();
  };
  const handleClose = () => {
    setVisible(false);
  };
  const handleOpen = async () => {
    setCoordinatore(false);
    setVisible(true);
    setMedici(
      props.medici.map((m) => {
        return {
          Medico: m.Descrizione,
          ID: m.ID,
          Checked: false,
          Coordinatore: false,
        };
      })
    );
    setMedicoCoordinatore(0);
  };
  const handleCboxChange = (e, index) => {
    let m = [...medici];
    m[index].Checked = !m[index].Checked;

    setMedici(m);
  };

  const stampaProtocollo = async () => {
    let mediciSelezionati = medici.filter((m) => m.Checked).map((m) => m.ID);

    if (mediciSelezionati.length === 1) {
      let responseProtocollo = await unsplash.get("protocolloSanitario", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        responseType: "blob",
        params: {
          stampa: true,
          cliente: props.idAzienda,
          medici: mediciSelezionati,
          ragioneSociale: props.ragioneSociale,
          coordinatore: 0,
          data: dataStampa,
          extra: props.extra?.length === 1 ? props.extra : extra,
        },
      });
      let protocollo = responseProtocollo.data;

      const url = window.URL.createObjectURL(
        new Blob([protocollo], { type: "application/pdf" })
      ); //prendo il file, lo rendo blob lo aggancio a un link e scarico

      const link = document.createElement("a");
      link.href = url;
      window.open(url);
    } else {
      setCoordinatore(true);
    }
  };
  const stampaProtocolloCoordinatore = async () => {
    let mediciSelezionati = medici.filter((m) => m.Checked).map((m) => m.ID);

    let responseProtocollo = await unsplash.get("protocolloSanitario", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      responseType: "blob",
      params: {
        stampa: true,
        cliente: props.idAzienda,
        medici: mediciSelezionati,
        ragioneSociale: props.ragioneSociale,
        coordinatore: medicoCoordinatore,
        data: dataStampa,
        extra: props.extra?.length === 1 ? props.extra : extra,
      },
    });
    let protocollo = responseProtocollo.data;

    const url = window.URL.createObjectURL(
      new Blob([protocollo], { type: "application/pdf" })
    ); //prendo il file, lo rendo blob lo aggancio a un link e scarico

    const link = document.createElement("a");
    link.href = url;
    window.open(url);
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />

      <Button
        sx={{ color: "white" }}
        variant="outlined"
        className="ButtonMiller"
        startIcon={<LocalHospital />}
        onClick={() => handleOpen()}
      >
        Protocollo Sanitario
      </Button>

      <Dialog
        TransitionComponent={Transition}
        open={visible}
        onClose={() => {
          setDataStampa(null);
          setVisible(false);
        }}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>Selezione Medici</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} my={2}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  ampmInClock={false}
                  renderInput={(props) => <TextField {...props} />}
                  label="Data"
                  value={moment(dataStampa)}
                  onChange={(newValue) => {
                    setDataStampa(
                      newValue ? newValue.format("YYYY-MM-DD") : null
                    );
                  }}
                />
              </LocalizationProvider>
            </Grid>
            {props.extra?.length > 1 && (
              <>
                <div className="w-full pl-4">Seleziona l'extra da inserire:</div>
                {[...new Set(props.extra?.map(man => man.Descrizione))]?.map((mansione) => (
                  <FormControlLabel
                  sx={{width: "100%", paddingLeft: "20px"}}
                  control={
                    <Checkbox
                      name={mansione}
                      checked={extra.includes(mansione)}
                      onChange={(e) => {
                        if(e.target.checked){
                          setExtra([...extra, e.target.name]);
                          console.log([...extra, e.target.name]);
                        }else{
                          setExtra(extra.filter((ext=> ext !==e.target.name)));
                          console.log(extra.filter((ext=> ext !==e.target.name)));
                        }
                      }}
                    />
                  }
                  label={mansione}
                />
                ))}
              </>
            )}
            {coordinatore ? (
              <Grid item xs={12}>
                Seleziona il medico coordinatore
              </Grid>
            ) : (
              <Grid item xs={12}>
                Seleziona i medici da includere nel protocollo sanitario
              </Grid>
            )}
            <Grid item xs={12}>
              <Select
                style={{ display: coordinatore ? "" : "None" }}
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                //defaultValue={accertamento.Accertamenti}
                onChange={(e) => {
                  setMedicoCoordinatore(e.target.value);
                }}
                input={<OutlinedInput label="Medico Coordinatore" fullWidth />}
              >
                {medici
                  .filter((m) => m.Checked)
                  .map((m) => (
                    <MenuItem key={m.ID} value={m.ID}>
                      {m.Medico}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            {medici.map((m, index) => {
              if (!coordinatore) {
                return (
                  <Grid item xs={6} key={m.ID}>
                    <FormControlLabel
                      sx={{ width: "100%" }}
                      control={
                        <Checkbox
                          name={m.Medico}
                          checked={m.Checked}
                          onChange={(e) => {
                            handleCboxChange(e, index);
                          }}
                        />
                      }
                      label={m.Medico}
                    />
                  </Grid>
                );
              }
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            xs={12}
            item
            sx={{
              display: "flex",
              mt: 2,
              justifyContent: "space-between",
              px: 2,
            }}
            fullWidth
          >
            <Button
              variant="contained"
              className="ButtonMiller"
              startIcon={<Close />}
              onClick={() => {
                setDataStampa(null);
                setExtra([]);
                setVisible(false);
              }}
            >
              Annulla
            </Button>
            <Button
              variant="contained"
              className="ButtonMiller"
              startIcon={<Check />}
              onClick={() =>
                coordinatore
                  ? stampaProtocolloCoordinatore()
                  : stampaProtocollo()
              }
            >
              Conferma e stampa
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

const DeleteRow = (props) => {
  const [visible, setVisible] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleDelete = async () => {
    let idMansione = props.tot.ID_RelMansioni;
    try {
      await unsplash.delete("mansioni", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: { ID: idMansione },
      });
      props.setSnack({
        severity: "success",
        messaggio: "Eliminazione effettuata correttamente",
      });
      props.setOpenSnack(true);
    } catch {
      props.setSnack({
        severity: "error",
        messaggio: "Errore durante la creazione",
      });
      props.setOpenSnack(true);
    }
    setVisible(false);
    props.myFunction();
  };
  const handleClose = () => {
    setVisible(false);
  };
  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Tooltip title="Protocollo sanitario">
        <IconButton
          onClick={() => {
            setVisible(!visible);
          }}
          aria-label="edit"
        >
          <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
        </IconButton>
      </Tooltip>
      <Dialog
        TransitionComponent={Transition}
        open={visible}
        onClose={() => setVisible(false)}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle>Eliminazione Mansione</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sei sicuro di voler eliminare <b>{props?.tot?.Descrizione}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 2,
          }}
        >
          <Button
            /* variant="contained"
              className="ButtonMiller"
              startIcon={<Close />} */
            onClick={() => setVisible(false)}
          >
            Annulla
          </Button>
          <Button
            /* variant="contained"
              className="ButtonMiller"
              startIcon={<Check />} */
            onClick={() => handleDelete()}
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

//PROTOCOLLO SANITARIO
const ProtocolloSanitario = (props) => {
  const [visible, setVisible] = useState(false);
  const [protocollo, setProtocolloSanitario] = useState([]);
  const [loading, setLoading] = useState(true);
  const [elencoAccertamenti, setElencoAccertamenti] = useState([]);
  const [accertamento, setAccertamento] = useState({
    Accertamenti: 0,
    Cadenze: 0,
    LineeGuida: "",
    ID: 0,
  });
  const [open, setOpen] = React.useState(false);
  const columns = [
    {
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "150",
      headerAlign: "center",
      align: "center",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <IconButton
            onClick={async () => {
              let acc = { ...accertamento };
              acc.Accertamenti = params.row.Accertamenti;
              acc.Cadenze = params.row.Cadenze;
              acc.LineeGuida = params.row.LineeGuida;
              acc.ID = params.row.ID;
              acc.Operazione = "Modifica";
              acc.Preassuntiva = params.row.Preassuntiva;
              acc.Extra = params.row.Extra;
              console.log(acc);
              await setAccertamento(acc);
              const responseAccertamenti = await unsplash.get("accertamenti", {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
              });
              await setElencoAccertamenti(responseAccertamenti.data.data);
              setOpen(true);
            }}
          >
            <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
          </IconButton>
          <DeleteProtocollo
            protocollo={params.row}
            myFunction={() => handleDelete(params.row.ID)}
          />
        </>
      ),
    },
    { field: "ID", headerName: "ID", hide: true },
    { field: "Descrizione", headerName: "Accertamenti", flex: 1 },
    { field: "Cadenze", headerName: "Cadenze", flex: 1 },
    {
      field: "Preassuntiva",
      headerName: "Preassuntiva",
      width: 120,
      renderCell: (params) => (params.row.Preassuntiva === 1 ? "Sì" : "No"),
    },
    {
      field: "Extra",
      headerName: "Extra",
      width: 70,
      renderCell: (params) => (params.row.Extra === 1 ? "Sì" : "No"),
    },
    { field: "LineeGuida", headerName: "Linee Guida", flex: 1 },
  ];

  const handleOpen = async () => {
    let acc = { ...accertamento };
    console.log(acc);
    acc.Accertamenti = 0;
    acc.Cadenze = 0;
    acc.LineeGuida = "";
    acc.ID = 0;
    acc.Operazione = "Nuovo";
    acc.Preassuntiva = 0;
    acc.Extra = 0;
    setAccertamento(acc);

    let responseProtocollo = await unsplash.get("protocolloSanitario", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { mansione: props.mansione, cliente: props.cliente },
    });
    console.log(responseProtocollo.data.data);
    setProtocolloSanitario(responseProtocollo.data.data);
    setLoading(false);

    const responseAccertamenti = await unsplash.get("accertamenti", {
      params: { mansione: props.mansione, cliente: props.cliente },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setElencoAccertamenti(responseAccertamenti.data.data);
  };
  const handleClickOpen = () => {
    let acc = { ...accertamento };
    acc.Accertamenti = 0;
    acc.Cadenze = 0;
    acc.LineeGuida = "";
    acc.ID = 0;
    acc.Operazione = "Nuovo";
    acc.Preassuntiva = 0;
    acc.Extra = 0;
    setAccertamento(acc);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = async (idProtocollo) => {
    try {
      const responseDeleteProtocollo = await unsplash.delete(
        "protocolloSanitario",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          data: {
            ID: idProtocollo,
          },
        }
      );
      handleOpen();
      props.setSnack({
        severity: "success",
        messaggio: "Record cancellato con successo",
      });
      props.setOpenSnack(true);
    } catch {
      props.setSnack({
        severity: "error",
        messaggio: "Errore nella cancellazione del record",
      });
      props.setOpenSnack(true);
    }
  };
  const handleSave = async () => {
    let a = { ...accertamento };
    a.Cliente = props.cliente;
    a.Mansione = props.mansione;

    if (accertamento.Operazione == "Nuovo") {
      try {
        const responseProtocolloNew = await unsplash.post(
          "protocolloSanitario",
          { protocollo: a },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        props.setSnack({
          severity: "success",
          messaggio: "Protocollo Sanitario creato con successo",
        });
        props.setOpenSnack(true);
        setOpen(false);
      } catch {
        props.setSnack({
          severity: "error",
          messaggio: "Errore durante la creazione",
        });
        props.setOpenSnack(true);
        setOpen(false);
      }
    } else {
      try {
        const responseProtocolloEdit = await unsplash.put(
          "protocolloSanitario",
          { protocollo: a },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        props.setSnack({
          severity: "success",
          messaggio: "Protocollo Sanitario modificato con successo",
        });
        props.setOpenSnack(true);
        setOpen(false);
      } catch {
        props.setSnack({
          severity: "error",
          messaggio: "Errore durante la modifica",
        });
        props.setOpenSnack(true);
        setOpen(false);
      }
    }

    handleOpen();
  };

  return (
    <>
      {visible ? (
        <GlobalSnackbar
          severity={props.snack.severity}
          messaggio={props.snack.messaggio}
          openSnack={props.openSnack}
          handleCloseSnack={props.handleCloseSnack}
        />
      ) : (
        ""
      )}
      <Tooltip title="Protocollo sanitario">
        <IconButton
          onClick={() => {
            handleOpen();
            setVisible(!visible);
          }}
          aria-label="edit"
        >
          <HealthAndSafety
            style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
          />
        </IconButton>
      </Tooltip>
      <Dialog
        TransitionComponent={Transition}
        open={visible}
        onClose={() => setVisible(false)}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle>{props.descrizione}</DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="flex-end"
          >
            <ButtonGroup
              variant="contained"
              aria-label="outlined primary button group"
              sx={{ my: 2 }}
            >
              <Button
                variant="contained"
                className="ButtonMiller"
                startIcon={<Add />}
                onClick={handleClickOpen}
              >
                AGGIUNGI
              </Button>
            </ButtonGroup>
          </Grid>

          <Dialog
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
          >
            <DialogTitle>{accertamento.Operazione}</DialogTitle>
            <DialogContent>
              <Grid container spacing={2} sx={{ marginTop: "10px" }}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-multiple-name-label">
                      Accertamento
                    </InputLabel>
                    <Select
                      value={accertamento.Accertamenti}
                      onChange={(e) => {
                        let a = { ...accertamento };
                        a.Accertamenti = e.target.value;
                        setAccertamento(a);
                      }}
                      input={<OutlinedInput label="Accetamento" />}
                    >
                      {elencoAccertamenti?.map((accertamento) => (
                        <MenuItem key={accertamento.ID} value={accertamento.ID}>
                          {accertamento.Descrizione}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="outlined-read-only-input"
                    label="Cadenze"
                    Name="Cadenze"
                    type="number"
                    value={accertamento.Cadenze}
                    onChange={(e) => {
                      let a = { ...accertamento };
                      a.Cadenze = e.target.value;
                      setAccertamento(a);
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="Preassuntiva"
                        checked={accertamento?.Preassuntiva === 1}
                        onChange={(e) => {
                          setAccertamento({
                            ...accertamento,
                            Preassuntiva: e.target.checked ? 1 : 0,
                          });
                        }}
                      />
                    }
                    label="Preassuntiva"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="Extra"
                        checked={accertamento?.Extra === 1}
                        onChange={(e) => {
                          setAccertamento({
                            ...accertamento,
                            Extra: e.target.checked ? 1 : 0,
                          });
                        }}
                      />
                    }
                    label="Extra"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-read-only-input"
                    label="Linee Guida"
                    Name="LineeGuida"
                    value={accertamento.LineeGuida}
                    onChange={(e) => {
                      let a = { ...accertamento };
                      a.LineeGuida = e.target.value;
                      setAccertamento(a);
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "space-between",
                px: 3,
              }}
            >
              <Button
                startIcon={<Close />}
                variant="contained"
                className="ButtonMiller"
                onClick={handleClose}
              >
                Chiudi
              </Button>
              <Button
                variant="contained"
                className="ButtonMiller"
                startIcon={<Check />}
                onClick={handleSave}
              >
                Salva
              </Button>
            </DialogActions>
          </Dialog>
          <div style={{ height: 300, width: "100%" }}>
            <DataGridPro
              experimentalFeatures={{ newEditingApi: true }}
              components={{
                Toolbar: CustomToolbar,
                Footer: CustomFooterPersonalized,
              }}
              slots={{
                loadingOverlay: LinearProgress,
              }}
              loading={loading}
              localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
              initialState={{ pinnedColumns: { left: ["Actions"] } }}
              rows={protocollo}
              getRowId={(protocollo) => protocollo.ID}
              columns={columns}
            />
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            mt: 1,
            justifyContent: "space-between",
            px: 2,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={() => setVisible(false)}
          >
            Chiudi
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={() => setVisible(false)}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default Mansioni;

const DeleteProtocollo = (props) => {
  const [visible, setVisible] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Tooltip title="Protocollo sanitario">
        <IconButton
          onClick={() => {
            setVisible(!visible);
          }}
          aria-label="edit"
        >
          <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
        </IconButton>
      </Tooltip>
      <Dialog
        TransitionComponent={Transition}
        open={visible}
        onClose={() => setVisible(false)}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle>
          Sei sicuro di voler eliminare {props.protocollo.Descrizione}?
        </DialogTitle>
        <DialogContent
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button onClick={() => setVisible(false)}>Annulla</Button>
          <Button onClick={() => props.myFunction()}>Conferma</Button>{" "}
        </DialogContent>
      </Dialog>
    </>
  );
};
