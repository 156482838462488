import React, { useEffect, useState } from "react";
import unsplash from "../api/unsplash";
import GlobalSnackbar from "./Snackbar";
import LinearProgress from "@mui/material/LinearProgress";

import {
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Zoom,
  DialogActions,
  DialogContentText,
  Autocomplete,
  Tabs,
  Typography,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  FormControlLabel,
  Divider,
  RadioGroup,
  Radio,
  CircularProgress,
  Backdrop,
  Avatar,
} from "@mui/material";
import PropTypes from "prop-types";
import { MimeType } from "easy-template-x";

import { IconButton } from "@mui/material";

import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";

import {
  Add,
  Delete,
  Close,
  Check,
  Print,
  ConnectingAirportsOutlined,
  Download,
  RemoveRedEye,
  ExpandMore,
  Edit,
  ElectricRickshawOutlined,
} from "@mui/icons-material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import {
  LocalizationProvider,
  DatePicker,
  PickersDay,
  TimePicker,
  clockNumberClasses,
} from "@mui/x-date-pickers";
import { AdapterMoment as DateAdapter } from "@mui/x-date-pickers/AdapterMoment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Dvr = ({ idSede, idAzienda, sede, cliente }) => {
  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "130",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={async () => handleModifyDvr(params.row)}
          >
            <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
          </IconButton>
          {/* <IconButton aria-label="edit" onClick={async () => {}}>
            <Download
              style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
            />
          </IconButton> */}

          <DeleteDialog dvr={params.row} myFunction={handleDelete} />
        </>
      ),
    },
    {
      field: "NumeroRevisione",
      headerName: "Numero Revisione",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "NaturaModifica",
      headerName: "Natura Modifica",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Data",
      headerName: "Data",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>{params.row?.Data && moment(params.row.Data).format("DD/MM/YYYY")}</>
      ),
    },
  ];
  const columnsProcedure = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "130",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={() => {
              setProcedura(params.row);
              setOpenProcedura(true);
            }}
          >
            <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
          </IconButton>
          <DeleteDialogProcedure
            procedura={params.row}
            myFunction={handleDeleteProcedure}
          />
        </>
      ),
    },
    {
      field: "Tipo",
      headerName: "Tipo",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Titolo",
      headerName: "Titolo",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Data",
      headerName: "Data",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>{params.row?.Data && moment(params.row.Data).format("DD/MM/YYYY")}</>
      ),
    },
    {
      field: "Responsabile",
      headerName: "Responsabile",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Note",
      headerName: "Note",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];
  const [open, setOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const [dvr, setDvr] = useState([]);
  const [lastDvr, setLastDvr] = useState(null);
  const [rischi, setRischi] = useState([]);
  const [attrezzature, setAttrezzature] = useState([]);
  const [tariffazioni, setTariffazioni] = useState([]);
  const [tariffazioniSel, setTariffazioniSel] = useState([]);

  const [procedure, setProcedure] = useState([]);
  const [procedura, setProcedura] = useState(null);
  const [openProcedura, setOpenProcedura] = useState(false);

  const [mansioni, setMansioni] = useState([]);

  const [loading, setLoading] = useState(true);
  const [loadingStampa, setLoadingStampa] = useState(false);
  const [loadingSalvataggio, setLoadingSalvataggio] = useState(false);
  const [dvrSelezionata, setDvrSelezionata] = useState(null);
  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleClose = () => {
    reloadAfterUpload();
    setOpen(false);
  };

  const handleGetRischi = async (id) => {
    try {
      let response = await unsplash.get("rischiSicurezza", {
        params: {
          idSede: idSede,
          idCliente: idAzienda,
          idDiv: id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      setRischi(response.data.data);
    } catch (error) {
      console.error(error.message);
    }
  };
  const handleGetMansioni = async () => {
    try {
      let response = await unsplash.get("mansioniSicurezza", {
        params: {
          id: idAzienda,
          sede: idSede,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setMansioni(response.data.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleGetRel = async (idDvr) => {
    try {
      let tariffazione = await unsplash.get("dvr/tariffe", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      setTariffazioni(tariffazione.data.data);

      let rischiAttrezzature = await unsplash.get(
        "atrezzattureMansioniSicurezza",
        {
          params: {
            idSede: idSede,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      let rischiEsposizione = await unsplash.get("rischiSicurezza", {
        params: {
          esposizione: true,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      if (idDvr) {
        let procedure = await unsplash.get("dvr/procedure", {
          params: {
            idDvr: idDvr,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });

        setProcedure(procedure.data.data);
        let tariffazioneSel = await unsplash.get("dvr/tariffe", {
          params: {
            idDvr: idDvr,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        let IdsresponseTariffazione = tariffazioneSel.data.data?.map(
          (el) => el.ID_Tariffazione_INAIL
        );
        let selectedTariffazione = tariffazione.data.data?.filter((el) =>
          IdsresponseTariffazione.includes(el.ID)
        );
        setTariffazioniSel(selectedTariffazione);
        let rischiSel = await unsplash.get("dvr/rischi", {
          params: {
            esposizione: true,
            idDvr: idDvr,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        function getRischiSelected(rischiFiltrati) {
          let IdsresponseRischiEsp = rischiFiltrati?.map(
            (el) => el.ID_RischiSicurezza
          );
          let selectedRischiEsp = rischiEsposizione.data.data?.filter((el) =>
            IdsresponseRischiEsp.includes(el.ID)
          );
          return selectedRischiEsp;
        }
        //per fare funzionare l'accordion salvo tutto nello stesso array di oggetti con rischi selezionati e rischi totali
        const rischiInsideAttrezzature = rischiAttrezzature.data.data?.map(
          (att) => {
            let rischiFiltered = rischiSel.data.data.filter(
              (rischio) =>
                rischio.ID_Rel_Attrezzatura === att.ID_Rel_Attrezzatura
            );
            return {
              ...att,
              Rischi: [...rischiEsposizione.data.data],
              RischiSel: getRischiSelected(rischiFiltered),
            };
          }
        );
        setAttrezzature(rischiInsideAttrezzature);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClickOpen = async () => {
    handleSave();
    setOpen(true);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }

  const handleSaveProcedura = async (id) => {
    try {
      if (id) {
        await unsplash.put(
          "dvr/procedura",
          {
            procedura: {
              ...procedura,
              Data: moment(procedura.Data).format("YYYY-MM-DD"),
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Procedura aggiornata con successo",
        });
        setOpenSnack(true);
      } else {
        await unsplash.post(
          "dvr/procedura",
          {
            procedura: {
              ...procedura,
              ID_Dvr: lastDvr?.ID,
              Data: procedura.Data
                ? moment(procedura.Data).format("YYYY-MM-DD")
                : moment(new Date()).format("YYYY-MM-DD"),
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Procedura salvata con successo",
        });
        setOpenSnack(true);
      }
      //reload all
      let procedure = await unsplash.get("dvr/procedure", {
        params: {
          idDvr: lastDvr?.ID,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      setProcedure(procedure.data.data);
      setOpenProcedura(false);
    } catch (error) {
      console.log(error);
      setSnack({
        severity: "error",
        messaggio: "Errore durante il salvataggio",
      });
      setOpenSnack(true);
    }
    setProcedura(null);
  };

  const handleModifyDvr = async (selectedDvr) => {
    setLastDvr({ ...selectedDvr, OldId: selectedDvr.ID });
    handleGetRischi(selectedDvr.ID);
    handleGetRel(selectedDvr.ID);
    setUpdate(true);
    setOpen(true);
  };
  const reloadAfterUpload = async () => {
    try {
      let response = await unsplash.get("dvr", {
        params: {
          id: idSede,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      //se esiste uno storico carico il l'ultimo DVR per estrarre i valori associati ad esso
      if (response.data.data?.length > 0) {
        let lastDvr = response.data.data?.reduce((maxObj, currentObj) => {
          return currentObj.ID > maxObj.ID ? currentObj : maxObj;
        });
        //metto i'id null e tengo memorizzato il vecchio dvr che servirà per le varie chiamate
        setLastDvr({
          ...lastDvr,
          ID: null,
          Data: moment(new Date()).format("YYYY-MM-DD"),
          OldId: lastDvr.ID,
          NumeroRevisione: lastDvr.NumeroRevisione + 1,
        });
        handleGetRischi(lastDvr.ID);
        handleGetMansioni();
        handleGetRel(lastDvr.ID);
      } else {
        setLastDvr({
          NumeroRevisione: 1,
          NaturaModifica: "Prima Emissione",
          Data: moment(new Date()).format("YYYY-MM-DD"),
        });
        handleGetRischi();
        handleGetMansioni();
      }
      setDvr(response.data.data);
      if (cliente?.Logo) {
        const img = new Image();
        img.onload = () => {
          setDimensions({ width: img.width, height: img.height });
        };
        img.src = cliente?.Logo.includes("data:image/")
          ? cliente?.Logo
          : "data:image/png;base64," + cliente?.Logo;
      }
      setLoading(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleDownloadFile = async (response) => {
    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      })
    );
    const a = document.createElement("a");
    a.href = url;
    a.download = "dvrCompiled.docx";
    document.body.appendChild(a);
    a.click();

    // Cleanup
    a.remove();
    window.URL.revokeObjectURL(url);
    setLoadingStampa(false);
  };

  const handleSave = async () => {
    setLoadingSalvataggio(true);
    try {
      //Crei DVR e prendi l'ID
      let response = await unsplash.post(
        "dvr",
        {
          dvr: {
            ...lastDvr,
            IDSede: idSede,
            Data: moment(lastDvr?.Data).format("YYYY-MM-DD"),
          },
          attrezzature: [...attrezzature],
          tariffe: tariffazioniSel?.map((tar) => tar.ID),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      let newId = response.data.message.insertId;
      //Prendi i Rischi collegati
      let rischiIdUpdated = [];
      for (const mansione of mansioni) {
        let response = await unsplash.get("dvr/mansioni", {
          params: {
            idDvr: lastDvr?.OldId,
            idRel: mansione?.ID,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        //Crei rischi collegati e copi a specchio inserendoli nel nuovo DVR
        await unsplash.post(
          "dvr/mansioni",
          {
            mansione: {
              ...mansione,
              oldDvr: response.data.data[0]?.ID,
              ID_Dvr: newId,
              ID_RelClientiMansioniSicurezza: mansione?.ID,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
      }
      for (const rischio of rischi) {
        let resp = await unsplash.get("dvr/governoMisure", {
          params: {
            idDvr: rischio?.ID_Rel_Dvr_Rischi,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        //Crei rischi collegati e copi a specchio inserendoli nel nuovo DVR
        let responseRischio = await unsplash.post(
          "dvr/rischi",
          {
            rischio: {
              ...rischio,
              ID_Dvr: response.data.message.insertId,
              ID_Rel_Clienti_Rischi_Sicurezza: rischio?.ID_Rel_Rischi,
            },
            governi: resp.data.governo?.map(
              (gov) => gov.ID_Rel_Rischi_Sicurezza_Governo
            ),
            misure: resp.data.misure?.map(
              (mis) => mis.ID_Rel_Rischi_Sicurezza_Misure
            ),
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        //Se si tratta di un rischio con la tabella delle sostanze associato
        if (rischio.Tipologia === 2) {
          //Prendo la vecchia tabella e la copio
          const responseSostanze = await unsplash.get("dvr/sostanze", {
            params: {
              idDvrRischi: rischio?.ID_Rel_Dvr_Rischi,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          });
          for (const sostanza of responseSostanze.data.data) {
            await unsplash.post(
              "dvr/sostanze",
              {
                sostanza: {
                  ...sostanza,
                  ID_Rel_Dvr_Rischi: responseRischio.data.message.insertId,
                },
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
              }
            );
          }
        }
        //Se si tratta di un rischio con gli agenti biochimici collegati
        if (rischio.Tipologia === 3) {
          //Prendo la vecchia tabella e la copio
          const responseAgenti = await unsplash.get("dvr/agenti", {
            params: {
              idDvrRischi: rischio?.ID_Rel_Dvr_Rischi,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          });
          for (const agente of responseAgenti.data.data) {
            await unsplash.post(
              "dvr/agenti",
              {
                agente: {
                  ...agente,
                  ID_Rel_Dvr_Rischi: responseRischio.data.message.insertId,
                },
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
              }
            );
          }
          //Prendo tutte le associazioni e le copio
          if(rischio?.ID_Rel_Dvr_Rischi){
          const responseAgentiRel = await unsplash.get("agentiBiologici", {
            params: {
              idRel: rischio?.ID_Rel_Dvr_Rischi,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          });
          await unsplash.put(
            "agentiBiologici",
            {
              idRel: responseRischio.data.message.insertId,
              ids: responseAgentiRel.data.data?.map((ag) => ag.ID),
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );}
        }
        //carico l'ID nuovo del Rishcio collegato al DVR per eventuali aggiornamenti
        rischiIdUpdated = [
          ...rischiIdUpdated,
          { ...rischio, ID: responseRischio.data.message.insertId },
        ];
      }
      setSnack({
        severity: "success",
        messaggio: "Dvr salvato con successo",
      });
      setOpenSnack(true);
      setLastDvr({ ...lastDvr, ID: newId, OldId: newId });
      setDvr([{ ...lastDvr, ID: newId, OldId: newId }, ...dvr]);
      handleGetRischi(newId);
      handleGetRel(newId);
    } catch (error) {
      console.log(error);
      setSnack({
        severity: "error",
        messaggio: "Errore durante il salvataggio",
      });
      setOpenSnack(true);
    }
    setLoadingSalvataggio(false);
  };

  const handleUpdate = async () => {
    try {
      await unsplash.put(
        "dvr",
        {
          dvr: {
            ...lastDvr,
            IDSede: idSede,
            Data: moment(lastDvr?.Data).format("YYYY-MM-DD"),
          },
          attrezzature: [...attrezzature],
          tariffe: tariffazioniSel?.map((tar) => tar.ID),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      setSnack({
        severity: "success",
        messaggio: "Dvr salvato con successo",
      });
      setOpenSnack(true);
    } catch (error) {
      console.log(error);
      setSnack({
        severity: "error",
        messaggio: "Errore durante il salvataggio",
      });
      setOpenSnack(true);
    }
  };

  const calculateScaledWidth = (fixedHeight) => {
    if (dimensions.height === 0) return 0; // Prevent division by zero

    return (fixedHeight / dimensions.height) * dimensions.width;
  };

  const handlePrint = async () => {
    setLoadingStampa(true);
    //in base al gruppo selezionato cambia il testo che andrà nella tabella
    let gruppo = "";
    switch (lastDvr?.Gruppo) {
      case 1:
        gruppo = `Gruppo A
1.	Aziende o unità produttive con attività industriali, soggette all’obbligo di dichiarazione o notifica, di cui all’articolo 2 del D.lgs.17 agosto 1999, n. 334
2.	Centrali termoelettriche
3.	Impianti e laboratori nucleari
4.	Aziende estrattive
5.	Lavori in sotterraneo
6.	Aziende per la fabbricazione di esplosivi
7.	Aziende con oltre 5 lavoratori appartenenti o riconducibili ai gruppi tariffari INAIL con indice infortunistico di inabilità permanente superiore a quattro.
8.	Aziende o unità produttive con oltre cinque lavoratori a tempo indeterminato del comparto dell’agricoltura. 
    ${
      lastDvr?.SubGruppoA === 1 &&
      "Effettuata comunicazione all’ASL competente sul territorio in cui si svolge l’attività lavorativa della categoria di appartenenza della propria azienda od unità produttiva, per la predisposizione degli interventi di emergenza del caso."
    }`;
        break;
      case 2:
        gruppo = `Gruppo B 
Aziende o unità produttive con tre o più lavoratori che non rientrano del gruppo A.`;
        break;
      case 3:
        gruppo = `Gruppo C
Aziende o unità produttive con meno di tre lavoratori che non rientrano del gruppo A.`;
        break;

      default:
        break;
    }
    //carico il nuovo DVR nello storico delle revisioni
    let revisioni = [...dvr];
    revisioni = revisioni?.map((rev) => ({
      ...rev,
      Data: moment(rev.Data).format("DD-MM-YYYY"),
    }));

    const fixedHeight = 100;
    const scaledWidth = calculateScaledWidth(fixedHeight);
    //Carico tutte le informazioni necessarie per compilare il file Word del DVR
    try {
      let response = await unsplash.post(
        "dvr",
        {
          cliente: idAzienda,
          sede: idSede,
          stampa: {
            ...lastDvr,
            RischiIds: rischi.map((r) => r.ID),
            NonPresenti:
              lastDvr?.PresenzaSmart === 0 || lastDvr?.PresenzaSmart === "0",
            Presenti:
              lastDvr?.PresenzaSmart === 1 || lastDvr?.PresenzaSmart === "1",
            DataAttuale: moment(lastDvr?.Data).format("DD/MM/YYYY"),
            TestoGruppo: gruppo,
            Revisioni: revisioni,
            Tariffazione: tariffazioniSel,
            Attrezzature: [...attrezzature],
            Procedure: procedure.map((proc) => ({
              ...proc,
              Data: moment(proc.Data).format("DD-MM-YYYY"),
            })),
            RagioneSociale: cliente?.RagioneSociale,
            Logo: cliente?.Logo
              ? {
                  _type: "image",
                  source: cliente?.Logo.includes("data:image/")
                    ? cliente?.Logo
                    : "data:image/png;base64," + cliente?.Logo,
                  format: MimeType.Png,
                  width: scaledWidth,
                  height: fixedHeight,
                }
              : "",
            SedeLocalita: cliente?.SedeLocalita ? cliente?.SedeLocalita : "",
            SedeIndirizzo: cliente?.SedeIndirizzo ? cliente?.SedeIndirizzo : "",
            PIVA: cliente?.PIVA ? cliente?.PIVA : "",
            Telefono: cliente?.Telefono ? cliente?.Telefono : "",
            Ateco: sede?.Ateco ? sede?.Ateco : "",
            DescrizioneAttivita: sede?.DescrizioneAttivita
              ? sede?.DescrizioneAttivita
              : "",
            Via: sede?.Via ? sede?.Via : "",
            Cap: sede?.Cap ? sede?.Cap : "",
            Ubicazione: sede?.Ubicazione ? sede?.Ubicazione : "",
            Inps: sede?.Inps ? sede?.Inps : "",
            Inail: sede?.Inail ? sede?.Inail : "",
          },
        },
        {
          headers: {
            ContentType: "application/octet-stream",
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          responseType: "blob",
        }
      );

      handleDownloadFile(response);

      setSnack({
        severity: "success",
        messaggio: "Stampa scaricata con successo",
      });
      setOpenSnack(true);
      //handleClose();
    } catch (error) {
      setLoadingStampa(false);
      setSnack({
        severity: "error",
        messaggio: "Errore durante la stampa",
      });
      setOpenSnack(true);
    }
  };

  const handleDelete = async (idDvr) => {
    try {
      await unsplash.delete("dvr", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          ID: idDvr,
        },
      });

      setSnack({
        severity: "success",
        messaggio: "Dvr eliminato con successo",
      });
      setOpenSnack(true);
      reloadAfterUpload();
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione dell'Dvr",
      });
      setOpenSnack(true);
    }
  };
  const handleDeleteProcedure = async (idDvr) => {
    try {
      await unsplash.delete("dvr/procedure", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          ID: idDvr,
        },
      });

      setSnack({
        severity: "success",
        messaggio: "Procedura eliminata con successo",
      });
      setOpenSnack(true);
      let procedure = await unsplash.get("dvr/procedure", {
        params: {
          idDvr: lastDvr?.ID,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      setProcedure(procedure.data.data);
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione dell'Dvr",
      });
      setOpenSnack(true);
    }
  };

  useEffect(() => {
    (async () => {
      reloadAfterUpload();
    })();
  }, []);

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Button
          startIcon={<Print />}
          onClick={handleClickOpen}
          sx={{ color: "white" }}
          variant="outlined"
          className="ButtonMiller"
        >
          {dvr?.length === 0 ? "Crea DVR" : "Crea Revisione"}
        </Button>
      </Grid>
      <Box sx={{ height: "60vh", width: 1 }}>
        <DataGridPro
          initialState={{
            pinnedColumns: { left: ["Actions"] },
          }}
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooterPersonalized,
          }}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          loading={loading}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          rowHeight={52}
          rows={dvr}
          columns={columns}
          getRowId={(row) => row.ID}
          pageSize={20}
          rowsPerPageOptions={[20]}
          disableSelectionOnClick
        />
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="xl"
        TransitionComponent={Transition}
      >
        <DialogTitle
          sx={{
            mx: "auto",
          }}
        >
          DVR
        </DialogTitle>
        <DialogContent>
          <div className="grid grid-cols-2 gap-2 mt-2">
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                ampmInClock={false}
                renderInput={(props) => (
                  <TextField fullWidth required {...props} />
                )}
                label="Data"
                value={moment(lastDvr?.Data)}
                onChange={(newValue) => {
                  setLastDvr({
                    ...lastDvr,
                    Data: newValue ? newValue.format("YYYY-MM-DD") : null,
                  });
                }}
              />
            </LocalizationProvider>
            <TextField
              className="col-span-1"
              type="number"
              label="Numero Revisione"
              value={lastDvr?.NumeroRevisione}
              onChange={(e) =>
                setLastDvr({ ...lastDvr, NumeroRevisione: e.target.value })
              }
            />
            <TextField
              className="col-span-1"
              label="Natura Modifica"
              value={lastDvr?.NaturaModifica}
              onChange={(e) =>
                setLastDvr({ ...lastDvr, NaturaModifica: e.target.value })
              }
            />
            <TextField
              className="col-span-1"
              type="number"
              label="Lavoratori Stranieri"
              value={lastDvr?.LavoratoriStranieri}
              onChange={(e) =>
                setLastDvr({ ...lastDvr, LavoratoriStranieri: e.target.value })
              }
            />

            <Autocomplete
              multiple
              className="col-span-2"
              id="checkboxes-tags-demo"
              options={tariffazioni}
              value={[...tariffazioniSel]}
              onChange={(e, values) => setTariffazioniSel(values)}
              disableCloseOnSelect
              getOptionLabel={(option) =>
                option?.Codice + " - " + option?.Valore
              }
              renderOption={(props, option, { selected }) => {
                const { key, ...optionProps } = props;
                return (
                  <li key={key} {...optionProps}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option?.Codice + " - " + option?.Valore}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField {...params} label="Tariffazione INAIL" />
              )}
            />
            <TextField
              className="col-span-2"
              label="Descrizione Completa Delle Attivita’ Svolte Dall’azienda"
              value={lastDvr?.AttivitaSvolte}
              onChange={(e) =>
                setLastDvr({ ...lastDvr, AttivitaSvolte: e.target.value })
              }
            />
            {/* <TextField
              className="col-span-1"
              label="Reparto Aziendale"
              value={lastDvr?.RepartoAziendale}
              onChange={(e) =>
                setLastDvr({ ...lastDvr, RepartoAziendale: e.target.value })
              }
            /> */}
            <FormControl className="col-span-1">
              <InputLabel id="demo-multiple-checkbox-label">Gruppo</InputLabel>
              <Select
                fullWidth
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                defaultValue={lastDvr?.Gruppo}
                onChange={(e) =>
                  setLastDvr({ ...lastDvr, Gruppo: e.target.value })
                }
                input={<OutlinedInput label="Gruppo" />}
              >
                <MenuItem key={1} value={1}>
                  A
                </MenuItem>
                <MenuItem key={2} value={2}>
                  B
                </MenuItem>
                <MenuItem key={3} value={3}>
                  C
                </MenuItem>
              </Select>
            </FormControl>
            {lastDvr?.Gruppo === 1 && (
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={lastDvr?.SubGruppoA === 1}
                    onClick={(e) =>
                      setLastDvr({
                        ...lastDvr,
                        SubGruppoA: lastDvr?.SubGruppoA === 1 ? 0 : 1,
                      })
                    }
                  />
                }
                label="Effettuata comunicazione all’ASL"
              />
            )}
            {/* <TextField
              className="col-span-2"
              label="Descrizione Specifica Dell’Ambiente"
              value={lastDvr?.DescrizioneAmbiente}
              onChange={(e) =>
                setLastDvr({ ...lastDvr, DescrizioneAmbiente: e.target.value })
              }
            /> */}
            {/* <TextField
              className="col-span-2"
              label="Descrizione Mansione Omogenea"
              value={lastDvr?.DescrizioneMansione}
              onChange={(e) =>
                setLastDvr({ ...lastDvr, DescrizioneMansione: e.target.value })
              }
            /> */}
            <TextField
              className="col-span-2"
              label="Eventuale nota formazione addestramento specifico per mansione"
              value={lastDvr?.NoteFormazione}
              onChange={(e) =>
                setLastDvr({ ...lastDvr, NoteFormazione: e.target.value })
              }
            />
            <div className="col-span-2">
              Sono presenti lavoratori in regime di smart working?
            </div>
            <div className="col-span-2">
              <FormControl>
                <RadioGroup
                  row
                  value={lastDvr?.PresenzaSmart}
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) =>
                    setLastDvr({
                      ...lastDvr,
                      PresenzaSmart: e.target.value,
                    })
                  }
                >
                  <FormControlLabel value={1} control={<Radio />} label="Sì" />
                  <FormControlLabel value={0} control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </div>
            {attrezzature?.length > 0 && (
              <div className="text-lg font-semibold">Attrezzature:</div>
            )}
            {attrezzature?.map((att, index) => {
              return (
                <Accordion
                  className="col-span-2"
                  key={att?.ID_Rel_Attrezzatura}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    {att?.Attrezzatura}
                  </AccordionSummary>
                  <AccordionDetails>
                    {att?.Rischi.length > 0 && (
                      <Autocomplete
                        multiple
                        className="col-span-2"
                        id="checkboxes-tags-demo"
                        options={att?.Rischi}
                        value={[...att?.RischiSel]}
                        onChange={(e, values) => {
                          //setRischiEsposizioneSel(values)
                          const rischiInsideAttrezzature = attrezzature?.map(
                            (attre) => {
                              let newAtt = { ...attre };
                              if (
                                attre.ID_Rel_Attrezzatura ===
                                att.ID_Rel_Attrezzatura
                              ) {
                                return (newAtt = {
                                  ...attre,
                                  RischiSel: [...values],
                                });
                              }
                              return { ...newAtt };
                            }
                          );
                          setAttrezzature(rischiInsideAttrezzature);
                        }}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option?.Nome}
                        renderOption={(props, option, { selected }) => {
                          const { key, ...optionProps } = props;
                          return (
                            <li key={key} {...optionProps}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option?.Nome}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Rischi Di Esposizione"
                          />
                        )}
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
              );
            })}
            <div className="col-span-2 flex justify-end mt-2">
              <Button
                onClick={handleUpdate}
                variant="contained"
                className="ButtonMiller"
                startIcon={<Check />}
              >
                {"Salva"}
              </Button>
            </div>
            <Divider className="col-span-2" />
            {rischi?.length > 0 && (
              <div className="col-span-2">
                <div className="text-lg font-semibold">Rischi:</div>
                {rischi?.map((tab, index) => {
                  return (
                    <AccordionRischi
                      tab={tab}
                      dvr={lastDvr}
                      saved={lastDvr?.ID ? true : false}
                      update={update}
                      setUpdate={setUpdate}
                    />
                  );
                })}
              </div>
            )}
            <Divider className="col-span-2" />
            {mansioni?.length > 0 && (
              <div className="col-span-2">
                <div className="text-lg font-semibold">Mansioni:</div>
                {mansioni?.map((tab, index) => {
                  return (
                    <AccordionMansioni
                      tab={tab}
                      dvr={lastDvr}
                      saved={lastDvr?.ID ? true : false}
                      update={update}
                      setUpdate={setUpdate}
                    />
                  );
                })}
              </div>
            )}
            <Divider className="col-span-2" />
            <div className="text-lg font-semibold col-span-2">
              Elenco Procedure Aziendali:
            </div>
            <div className="col-span-2">
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                mb={1}
              >
                <Button
                  startIcon={<Add />}
                  onClick={() => setOpenProcedura(true)}
                  sx={{ color: "white" }}
                  variant="outlined"
                  className="ButtonMiller"
                >
                  Aggiungi
                </Button>
              </Grid>
              <Box sx={{ height: "300px", width: 1 }}>
                <DataGridPro
                  initialState={{
                    pinnedColumns: { left: ["Actions"] },
                  }}
                  components={{
                    //Toolbar: CustomToolbar,
                    Footer: CustomFooterPersonalized,
                  }}
                  slots={{
                    loadingOverlay: LinearProgress,
                  }}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  rowHeight={52}
                  rows={procedure}
                  columns={columnsProcedure}
                  getRowId={(row) => row.ID}
                  pageSize={20}
                  rowsPerPageOptions={[20]}
                  disableSelectionOnClick
                />
              </Box>
            </div>
          </div>
        </DialogContent>
        <DialogActions
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mx: 2,
          }}
        >
          <Button
            onClick={handleClose}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
          >
            Chiudi
          </Button>
          {lastDvr?.ID && (
            <>
              {/* {loadingStampa ? (
                <>
                  <CircularProgress
                    disableShrink
                    thickness={5}
                    sx={{ color: "#2265B1", marginRight: "20px" }}
                  />
                </>
              ) : ( */}
              <Button
                onClick={handlePrint}
                variant="contained"
                className="ButtonMiller"
                startIcon={<Print />}
              >
                Stampa
              </Button>
              {/* )} */}
            </>
          )}
        </DialogActions>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            overflowY: "hidden",
          }}
          open={loadingStampa}
          onClick={() => setLoadingStampa(false)}
        >
          <Grid
            container
            direction="row"
            gap={2}
            alignItems="center"
            justifyContent="center"
            flexWrap={"wrap"}
          >
            <div className="w-full text-center">Caricamento...</div>
            <CircularProgress disableShrink color="inherit" />
          </Grid>
        </Backdrop>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            overflowY: "hidden",
          }}
          open={loadingSalvataggio}
          onClick={() => loadingSalvataggio(false)}
        >
          <Grid
            container
            direction="row"
            gap={2}
            alignItems="center"
            justifyContent="center"
            flexWrap={"wrap"}
          >
            <div className="w-full text-center">Salvataggio in corso...</div>
            <CircularProgress disableShrink color="inherit" />
          </Grid>
        </Backdrop>
      </Dialog>
      <Dialog
        open={openProcedura}
        onClose={() => {
          setOpenProcedura(false);
          setProcedura(null);
        }}
        fullWidth={true}
        maxWidth="lg"
        TransitionComponent={Transition}
      >
        <DialogTitle
          sx={{
            mx: "auto",
          }}
        >
          Procedura
        </DialogTitle>
        <DialogContent>
          <div className="grid grid-cols-1 gap-2 mt-2">
            <TextField
              className="col-span-1"
              label="Tipo"
              value={procedura?.Tipo}
              onChange={(e) =>
                setProcedura({ ...procedura, Tipo: e.target.value })
              }
            />
            <TextField
              className="col-span-1"
              label="Titolo"
              value={procedura?.Titolo}
              onChange={(e) =>
                setProcedura({ ...procedura, Titolo: e.target.value })
              }
            />
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                ampmInClock={false}
                renderInput={(props) => (
                  <TextField fullWidth required {...props} />
                )}
                label="Data"
                value={moment(procedura?.Data)}
                onChange={(newValue) => {
                  setProcedura({
                    ...procedura,
                    Data: newValue ? newValue.format("YYYY-MM-DD") : null,
                  });
                }}
              />
            </LocalizationProvider>
            <TextField
              className="col-span-1"
              label="Responsabile"
              value={procedura?.Responsabile}
              onChange={(e) =>
                setProcedura({ ...procedura, Responsabile: e.target.value })
              }
            />
            <TextField
              className="col-span-1"
              label="Note"
              value={procedura?.Note}
              onChange={(e) =>
                setProcedura({ ...procedura, Note: e.target.value })
              }
            />
          </div>
        </DialogContent>
        <DialogActions
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mx: 2,
          }}
        >
          <Button
            onClick={() => {
              setOpenProcedura(false);
              setProcedura(null);
            }}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
          >
            Chiudi
          </Button>
          <Button
            onClick={() => handleSaveProcedura(procedura?.ID)}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Dvr;

const AccordionRischi = ({ tab, dvr, saved, update, setUpdate }) => {
  const [rischioSalvato, setRischioSalvato] = useState(false);
  const [governi, setGoverni] = useState([]);
  const [misure, setMisure] = useState([]);

  const [expanded, setExpanded] = useState(false);

  const [governiSel, setGoverniSel] = useState([]);
  const [misureSel, setMisureSel] = useState([]);
  const [sostanze, setSostanze] = useState([]);
  const [agenti, setAgenti] = useState([]);
  const [inputVari, setInputVari] = useState({
    Descrizione_Misure: "",
    Descrizione_Governo: "",
    Supera: 0,
  });

  const [batteri, setBatteri] = useState([]);
  const [virus, setVirus] = useState([]);
  const [malattie, setMalattie] = useState([]);
  const [parassiti, setParassiti] = useState([]);
  const [funghi, setFunghi] = useState([]);
  const [batteriSel, setBatteriSel] = useState([]);
  const [virusSel, setVirusSel] = useState([]);
  const [malattieSel, setMalattieSel] = useState([]);
  const [parassitiSel, setParassitiSel] = useState([]);
  const [funghiSel, setFunghiSel] = useState([]);

  const [openSostanze, setOpenSostanze] = useState(false);
  const [sostanza, setSostanza] = useState(null);

  const [openAgenti, setOpenAgenti] = useState(false);
  const [agente, setAgente] = useState(null);

  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const columnsSostanze = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "130",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={async () => {
              setOpenSostanze(true);
              setSostanza(params.row);
            }}
          >
            <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
          </IconButton>
          <DeleteDialogRischio
            sostanza={params.row}
            myFunction={handleDelete}
          />
        </>
      ),
    },
    {
      field: "Nome",
      headerName: "Nome",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Esposizione",
      headerName: "Esposizione",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Presente",
      headerName: "Presente",
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <>{params.row?.Presente === 1 ? "Sì" : "No"}</>,
    },
  ];

  const columnsAgentiNonPresenti = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "130",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={async () => {
              setOpenAgenti(true);
              setAgente(params.row);
            }}
          >
            <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
          </IconButton>
          <DeleteDialogAgente
            agente={params.row}
            myFunction={handleDeleteAgente}
          />
        </>
      ),
    },
    {
      field: "Nome",
      headerName: "Nome",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  async function getSostanze() {
    let responseSostanze = await unsplash.get("dvr/sostanze", {
      params: {
        idDvrRischi: tab?.ID_Rel_Dvr_Rischi,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setSostanze(responseSostanze.data.data);
  }
  async function getAgentiBiologici() {
    let responseAgenti = await unsplash.get("dvr/agenti", {
      params: {
        idDvrRischi: tab?.ID_Rel_Dvr_Rischi,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setAgenti(responseAgenti.data.data);

    let res = await unsplash.get("agentiBiologici", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setBatteri(res.data.data?.filter((el) => el.Categoria === 1));
    setVirus(res.data.data?.filter((el) => el.Categoria === 2));
    setMalattie(res.data.data?.filter((el) => el.Categoria === 3));
    setParassiti(res.data.data?.filter((el) => el.Categoria === 4));
    setFunghi(res.data.data?.filter((el) => el.Categoria === 5));

    const responseAgentiRel = await unsplash.get("agentiBiologici", {
      params: {
        idRel: tab?.ID_Rel_Dvr_Rischi,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    setBatteriSel(
      res.data.data
        ?.filter((el) => el.Categoria === 1)
        .filter((el) =>
          responseAgentiRel.data.data?.map((el) => el.ID).includes(el.ID)
        )
    );

    setVirusSel(
      res.data.data
        ?.filter((el) => el.Categoria === 2)
        .filter((el) =>
          responseAgentiRel.data.data?.map((el) => el.ID).includes(el.ID)
        )
    );
    setMalattieSel(
      res.data.data
        ?.filter((el) => el.Categoria === 3)
        .filter((el) =>
          responseAgentiRel.data.data?.map((el) => el.ID).includes(el.ID)
        )
    );
    setParassitiSel(
      res.data.data
        ?.filter((el) => el.Categoria === 4)
        .filter((el) =>
          responseAgentiRel.data.data?.map((el) => el.ID).includes(el.ID)
        )
    );
    setFunghiSel(
      res.data.data
        ?.filter((el) => el.Categoria === 5)
        .filter((el) =>
          responseAgentiRel.data.data?.map((el) => el.ID).includes(el.ID)
        )
    );
  }

  const handleUpdate = async () => {
    try {
      if (tab?.Tipologia === 3) {
        const allAgenti = [
          ...batteriSel,
          ...virusSel,
          ...malattieSel,
          ...parassitiSel,
          ...funghiSel,
        ];
        await unsplash.put(
          "agentiBiologici",
          {
            idRel: tab?.ID_Rel_Dvr_Rischi,
            ids: allAgenti?.map((gov) => gov.ID),
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
      }
      await unsplash.put(
        "dvr/rischi",
        {
          rischio: {
            //ID: dvr?.ID_Rel_Dvr_Rischi,
            ID: dvr?.ID,
            ...inputVari,
          },
          governi: governiSel?.map((gov) => gov.ID),
          misure: misureSel?.map((mis) => mis.ID),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setRischioSalvato(true);
      setSnack({
        severity: "success",
        messaggio: "Rischio salvato con successo",
      });
      setOpenSnack(true);
    } catch (error) {
      console.log(error);
      setSnack({
        severity: "error",
        messaggio: "Errore durante il salvataggio",
      });
      setOpenSnack(true);
    }
  };
  const handleSaveAgente = async (id) => {
    try {
      if (id) {
        await unsplash.put(
          "dvr/agenti",
          {
            agente: {
              ...agente,
              ID_Rel_Dvr_Rischi: tab?.ID_Rel_Dvr_Rischi,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Agente Biochimico aggiornato con successo",
        });
        setOpenSnack(true);
      } else {
        await unsplash.post(
          "dvr/agenti",
          {
            agente: {
              ...agente,
              ID_Rel_Dvr_Rischi: tab?.ID_Rel_Dvr_Rischi,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Agente Biochimico salvato con successo",
        });
        setOpenSnack(true);
      }
      getAgentiBiologici();
      setOpenAgenti(false);
    } catch (error) {
      console.log(error);
      setSnack({
        severity: "error",
        messaggio: "Errore durante il salvataggio",
      });
      setOpenSnack(true);
    }
    setAgente(null);
  };
  const handleDeleteAgente = async (id) => {
    try {
      await unsplash.delete("dvr/agenti", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          ID: id,
        },
      });

      setSnack({
        severity: "success",
        messaggio: "Agente eliminato con successo",
      });
      setOpenSnack(true);
      getAgentiBiologici();
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione della  sostanza",
      });
      setOpenSnack(true);
    }
  };
  const handleSaveSostanza = async (id) => {
    try {
      if (id) {
        await unsplash.put(
          "dvr/sostanze",
          {
            sostanza: {
              ...sostanza,
              ID_Rel_Dvr_Rischi: tab?.ID_Rel_Dvr_Rischi,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Sostanza aggiornata con successo",
        });
        setOpenSnack(true);
      } else {
        await unsplash.post(
          "dvr/sostanze",
          {
            sostanza: {
              ...sostanza,
              ID_Rel_Dvr_Rischi: tab?.ID_Rel_Dvr_Rischi,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Sostanza salvata con successo",
        });
        setOpenSnack(true);
      }
      getSostanze();
      setOpenSostanze(false);
    } catch (error) {
      console.log(error);
      setSnack({
        severity: "error",
        messaggio: "Errore durante il salvataggio",
      });
      setOpenSnack(true);
    }
    setSostanza(null);
  };

  const handleDelete = async (id) => {
    try {
      await unsplash.delete("dvr/sostanze", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          ID: id,
        },
      });

      setSnack({
        severity: "success",
        messaggio: "Sostanza eliminata con successo",
      });
      setOpenSnack(true);
      getSostanze();
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione della  sostanza",
      });
      setOpenSnack(true);
    }
  };

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded);
    if (isExpanded) {
      // Execute the backend call when the accordion is opened
      getData();
    }
  };
  const getData = async () => {
    try {
      let response = await unsplash.get("dvr/governoMisure", {
        params: {
          id: tab?.ID,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setGoverni(response.data?.governo);
      setMisure(response.data?.misure);
      if (dvr) {
        if (tab?.Tipologia === 2) {
          getSostanze();
        }
        if (tab?.Tipologia === 3) {
          getAgentiBiologici();
        }
        let responseRischi = await unsplash.get("dvr/rischi", {
          params: {
            idDvr: dvr?.OldId,
            idRel: tab?.ID_Rel_Rischi,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        let responseGoverno = await unsplash.get("dvr/rischi", {
          params: {
            idDvr: tab?.ID_Rel_Dvr_Rischi,
            governo: true,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        let IdsresponseGoverno = responseGoverno.data.data?.map(
          (el) => el.ID_Rel_Rischi_Sicurezza_Governo
        );
        let selectedGoverno = response.data?.governo.filter((el) =>
          IdsresponseGoverno.includes(el.ID)
        );
        let responseMisure = await unsplash.get("dvr/rischi", {
          params: {
            idDvr: tab?.ID_Rel_Dvr_Rischi,
            misure: true,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        let IdsresponseMisure = responseMisure.data.data?.map(
          (el) => el.ID_Rel_Rischi_Sicurezza_Misure
        );
        let selectedMisure = response.data?.misure.filter((el) =>
          IdsresponseMisure.includes(el.ID)
        );
        setMisureSel(selectedMisure);
        setGoverniSel(selectedGoverno);
        setInputVari(responseRischi.data.data[0]);
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  /*   useEffect(() => {
    (async () => {
      try {
        let response = await unsplash.get("dvr/governoMisure", {
          params: {
            id: tab?.ID,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        setGoverni(response.data?.governo);
        setMisure(response.data?.misure);
        if (dvr) {
          if (tab?.Tipologia === 2) {
            getSostanze();
          }
          if (tab?.Tipologia === 3) {
            getAgentiBiologici();
          }
          let responseRischi = await unsplash.get("dvr/rischi", {
            params: {
              idDvr: dvr?.OldId,
              idRel: tab?.ID_Rel_Rischi,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          });
          let responseGoverno = await unsplash.get("dvr/rischi", {
            params: {
              idDvr: tab?.ID_Rel_Dvr_Rischi,
              governo: true,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          });
          let IdsresponseGoverno = responseGoverno.data.data?.map(
            (el) => el.ID_Rel_Rischi_Sicurezza_Governo
          );
          let selectedGoverno = response.data?.governo.filter((el) =>
            IdsresponseGoverno.includes(el.ID)
          );
          let responseMisure = await unsplash.get("dvr/rischi", {
            params: {
              idDvr: tab?.ID_Rel_Dvr_Rischi,
              misure: true,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          });
          let IdsresponseMisure = responseMisure.data.data?.map(
            (el) => el.ID_Rel_Rischi_Sicurezza_Misure
          );
          let selectedMisure = response.data?.misure.filter((el) =>
            IdsresponseMisure.includes(el.ID)
          );
          setMisureSel(selectedMisure);
          setGoverniSel(selectedGoverno);
          setInputVari(responseRischi.data.data[0]);
        }
      } catch (error) {
        console.error(error.message);
      }
    })();
  }, [tab, dvr?.ID]); */
  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        className={
          rischioSalvato
            ? "border-2 border-[#40C18F]"
            : "border-2 border-transparent"
          //: "border-2 border-[#FF2768]"
        }
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          {tab?.Nome}
        </AccordionSummary>
        <AccordionDetails>
          {tab?.Tipologia !== 1 ? (
            <div className="grid grid-cols-3 gap-3">
              {tab?.Tipologia === 2 && (
                <>
                  <div className="col-span-3 text-base font-semibold">
                    Identificazione delle sostanze pericolose:
                  </div>
                  <div className="col-span-3">
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                      mb={1}
                    >
                      <Button
                        startIcon={<Add />}
                        onClick={() => setOpenSostanze(true)}
                        sx={{ color: "white" }}
                        variant="outlined"
                        className="ButtonMiller"
                      >
                        Aggiungi
                      </Button>
                    </Grid>
                    <Box sx={{ height: "300px", width: 1 }}>
                      <DataGridPro
                        initialState={{
                          pinnedColumns: { left: ["Actions"] },
                        }}
                        components={{
                          //Toolbar: CustomToolbar,
                          Footer: CustomFooterPersonalized,
                        }}
                        slots={{
                          loadingOverlay: LinearProgress,
                        }}
                        localeText={
                          itIT.components.MuiDataGrid.defaultProps.localeText
                        }
                        rowHeight={52}
                        rows={sostanze}
                        columns={columnsSostanze}
                        getRowId={(row) => row.ID}
                        pageSize={20}
                        rowsPerPageOptions={[20]}
                        disableSelectionOnClick
                      />
                    </Box>
                  </div>
                  <Divider className="col-span-3" />
                </>
              )}
              {tab?.Tipologia === 3 && (
                <>
                  <div className="col-span-3 text-base font-semibold">
                    Agenti Biologici Presenti nell'allegato XLVI del D.lgs.
                    81/08:
                  </div>
                  <Autocomplete
                    multiple
                    className="col-span-3"
                    id="checkboxes-tags-demo"
                    options={batteri}
                    value={[...batteriSel]}
                    onChange={(e, values) => setBatteriSel(values)}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option?.Nome}
                    renderOption={(props, option, { selected }) => {
                      const { key, ...optionProps } = props;
                      return (
                        <li key={key} {...optionProps}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option?.Nome}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Batteri e organismi simili"
                      />
                    )}
                  />
                  <Autocomplete
                    multiple
                    className="col-span-3"
                    id="checkboxes-tags-demo"
                    options={virus}
                    value={[...virusSel]}
                    onChange={(e, values) => setVirusSel(values)}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option?.Nome}
                    renderOption={(props, option, { selected }) => {
                      const { key, ...optionProps } = props;
                      return (
                        <li key={key} {...optionProps}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option?.Nome}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Virus" />
                    )}
                  />
                  <Autocomplete
                    multiple
                    className="col-span-3"
                    id="checkboxes-tags-demo"
                    options={malattie}
                    value={[...malattieSel]}
                    onChange={(e, values) => setMalattieSel(values)}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option?.Nome}
                    renderOption={(props, option, { selected }) => {
                      const { key, ...optionProps } = props;
                      return (
                        <li key={key} {...optionProps}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option?.Nome}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Malattie Prioniche" />
                    )}
                  />
                  <Autocomplete
                    multiple
                    className="col-span-3"
                    id="checkboxes-tags-demo"
                    options={parassiti}
                    value={[...parassitiSel]}
                    onChange={(e, values) => setParassitiSel(values)}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option?.Nome}
                    renderOption={(props, option, { selected }) => {
                      const { key, ...optionProps } = props;
                      return (
                        <li key={key} {...optionProps}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option?.Nome}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Parassiti" />
                    )}
                  />
                  <Autocomplete
                    multiple
                    className="col-span-3"
                    id="checkboxes-tags-demo"
                    options={funghi}
                    value={[...funghiSel]}
                    onChange={(e, values) => setFunghiSel(values)}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option?.Nome}
                    renderOption={(props, option, { selected }) => {
                      const { key, ...optionProps } = props;
                      return (
                        <li key={key} {...optionProps}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option?.Nome}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Funghi" />
                    )}
                  />
                  <Divider className="col-span-3" />
                  <div className="col-span-3 text-base font-semibold">
                    Agenti Biologici Non Presenti nell'allegato XLVI del D.lgs.
                    81/08:
                  </div>
                  <div className="col-span-3">
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                      mb={1}
                    >
                      <Button
                        startIcon={<Add />}
                        onClick={() => setOpenAgenti(true)}
                        sx={{ color: "white" }}
                        variant="outlined"
                        className="ButtonMiller"
                      >
                        Aggiungi
                      </Button>
                    </Grid>
                    <Box sx={{ height: "250px", width: 1 }}>
                      <DataGridPro
                        initialState={{
                          pinnedColumns: { left: ["Actions"] },
                        }}
                        components={{
                          //Toolbar: CustomToolbar,
                          Footer: CustomFooterPersonalized,
                        }}
                        slots={{
                          loadingOverlay: LinearProgress,
                        }}
                        localeText={
                          itIT.components.MuiDataGrid.defaultProps.localeText
                        }
                        rowHeight={52}
                        rows={agenti}
                        columns={columnsAgentiNonPresenti}
                        getRowId={(row) => row.ID}
                        pageSize={20}
                        rowsPerPageOptions={[20]}
                        disableSelectionOnClick
                      />
                    </Box>
                  </div>
                  <Divider className="col-span-3" />
                </>
              )}

              <div className="col-span-3 text-base font-semibold">
                Valutazione Rischio:
              </div>
              <TextField
                className="col-span-3"
                label="Descrizione Specifica Del Rischio Applicato Alla Realtà Aziendale"
                value={inputVari?.Descrizione_Governo}
                onChange={(e) =>
                  setInputVari({
                    ...inputVari,
                    Descrizione_Governo: e.target.value,
                  })
                }
              />
              {/* AUTOCOMPLETE MULTIPLO PER GOVERNO DEL RISCHIO */}
              <Autocomplete
                multiple
                className="col-span-3"
                id="checkboxes-tags-demo"
                options={governi}
                value={[...governiSel]}
                onChange={(e, values) => setGoverniSel(values)}
                disableCloseOnSelect
                getOptionLabel={(option) => option?.Descrizione}
                renderOption={(props, option, { selected }) => {
                  const { key, ...optionProps } = props;
                  return (
                    <li key={key} {...optionProps}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option?.Descrizione}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Governo del Rischio" />
                )}
              />
              <TextField
                className="col-span-3"
                multiline
                
                label="Misure per il miglioramento nel tempo dei livelli di rischio "
                value={inputVari?.Descrizione_Misure}
                onChange={(e) =>
                  setInputVari({
                    ...inputVari,
                    Descrizione_Misure: e.target.value,
                  })
                }
              />
              {/* AUTOCOMPLETE MULTIPLO PER MISURE PREVENZIONE */}
              <Autocomplete
                multiple
                className="col-span-3"
                id="checkboxes-tags-demo"
                options={misure}
                value={[...misureSel]}
                onChange={(e, values) => setMisureSel(values)}
                disableCloseOnSelect
                getOptionLabel={(option) => option?.Descrizione}
                renderOption={(props, option, { selected }) => {
                  const { key, ...optionProps } = props;
                  return (
                    <li key={key} {...optionProps}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option?.Descrizione}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Misure Cogenti Da Applicare Nell’immediato"
                  />
                )}
              />

              <div className="col-span-3 flex justify-end">
                <Button
                  onClick={handleUpdate}
                  variant="contained"
                  className="ButtonMiller"
                  startIcon={<Check />}
                >
                  {"Aggiorna"}
                </Button>
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-3 gap-3">
              <div className="col-span-3">
                La realtà aziendale, <b>supera i requisiti</b> richiesti per
                essere un’attività a non basso rischio di incendio?
              </div>
              <div className="col-span-3">
                <FormControl>
                  <RadioGroup
                    row
                    value={inputVari?.Supera}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={(e) =>
                      setInputVari({
                        ...inputVari,
                        Supera: e.target.value,
                      })
                    }
                  >
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Sì"
                    />
                    <FormControlLabel
                      value={0}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="col-span-3 flex justify-end">
                <Button
                  onClick={handleUpdate}
                  variant="contained"
                  className="ButtonMiller"
                  startIcon={<Check />}
                >
                  {"Aggiorna"}
                </Button>
              </div>
            </div>
          )}
        </AccordionDetails>
      </Accordion>
      <Dialog
        open={openSostanze}
        onClose={() => {
          setOpenSostanze(false);
          setSostanza(null);
        }}
        fullWidth={true}
        maxWidth="lg"
        TransitionComponent={Transition}
      >
        <DialogTitle
          sx={{
            mx: "auto",
          }}
        >
          Sostanza
        </DialogTitle>
        <DialogContent>
          <div className="flex flex-col gap-2 pt-2">
            <TextField
              className="w-full pt-2"
              label="Nome"
              value={sostanza?.Nome}
              onChange={(e) =>
                setSostanza({ ...sostanza, Nome: e.target.value })
              }
            />
            <TextField
              className="w-full"
              label="Esposizione"
              value={sostanza?.Esposizione}
              onChange={(e) =>
                setSostanza({ ...sostanza, Esposizione: e.target.value })
              }
            />
            <div className="w-full">Scheda di sicurezza presente?</div>
            <FormControl className="w-full">
              <RadioGroup
                row
                value={sostanza?.Presente}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(e) =>
                  setSostanza({
                    ...sostanza,
                    Presente: e.target.value,
                  })
                }
              >
                <FormControlLabel value={1} control={<Radio />} label="Sì" />
                <FormControlLabel value={0} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mx: 2,
          }}
        >
          <Button
            onClick={() => {
              setOpenSostanze(false);
              setSostanza(null);
            }}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
          >
            Chiudi
          </Button>
          <Button
            onClick={() => handleSaveSostanza(sostanza?.ID)}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openAgenti}
        onClose={() => {
          setOpenAgenti(false);
          setAgente(null);
        }}
        fullWidth={true}
        maxWidth="md"
        TransitionComponent={Transition}
      >
        <DialogTitle
          sx={{
            mx: "auto",
          }}
        >
          Agente
        </DialogTitle>
        <DialogContent>
          <div className="flex flex-col gap-2 pt-2">
            <TextField
              className="w-full pt-2"
              label="Nome"
              value={agente?.Nome}
              onChange={(e) => setAgente({ ...agente, Nome: e.target.value })}
            />
          </div>
        </DialogContent>
        <DialogActions
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mx: 2,
          }}
        >
          <Button
            onClick={() => {
              setOpenAgenti(false);
              setAgente(null);
            }}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
          >
            Chiudi
          </Button>
          <Button
            onClick={() => handleSaveAgente(agente?.ID)}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
const AccordionMansioni = ({ tab, dvr, saved, update, setUpdate }) => {
  const [mansioniDpi, setMansioniDpi] = useState([]);
  const [mansioniFormazione, setMansioniFormazione] = useState([]);
  const [mansioneSel, setMansioneSel] = useState(null);

  const [expanded, setExpanded] = useState(false);

  const [openFormazione, setOpenFormazione] = useState(false);
  const [formazione, setFormazione] = useState(null);
  const [openDpi, setOpenDpi] = useState(false);
  const [dpi, setDpi] = useState(null);

  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const columnsFormazione = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "130",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={async () => {
              setOpenFormazione(true);
              setFormazione(params.row);
            }}
          >
            <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
          </IconButton>
          <DeleteDialogFormazione
            formazione={params.row}
            myFunction={handleDeleteFormazione}
          />
        </>
      ),
    },
    {
      field: "Requisiti",
      headerName: "Requisiti",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Note",
      headerName: "Note",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  const columnsDpi = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "130",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={async () => {
              setOpenDpi(true);
              setDpi(params.row);
            }}
          >
            <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
          </IconButton>
          <DeleteDialogDpi dpi={params.row} myFunction={handleDeleteDpi} />
        </>
      ),
    },
    {
      field: "Protezione",
      headerName: "Tipologia DPI",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Pittogramma",
      headerName: "Pittogramma",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Avatar
          sx={{
            width: 50,
            height: 50,
          }}
        >
          <img
            style={{
              width: "100%",
              height: "100%",
            }}
            src={
              params.row.Immagine !== "" && params.row.Immagine
                ? "data:image/png;base64," + params.row.Immagine
                : require("../assets/images/MILLERLogo.jpg")
            }
            alt="Dpi"
          />
        </Avatar>
      ),
    },
    {
      field: "Tipologia",
      headerName: "Caratterisctiche",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Normativa",
      headerName: "Normativa",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Testo",
      headerName: "Formazione specifica per utilizzo DPI",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  const handleSaveFormazione = async (id) => {
    try {
      if (id) {
        await unsplash.put(
          "dvr/mansioni",
          {
            formazione: {
              ...formazione,
              ID_Rel_Dvr_Mansioni: mansioneSel?.ID,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Formazione aggiornata con successo",
        });
        setOpenSnack(true);
      } else {
        await unsplash.post(
          "dvr/mansioni",
          {
            formazione: {
              ...formazione,
              ID_Rel_Dvr_Mansioni: mansioneSel?.ID,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Formazione salvata con successo",
        });
        setOpenSnack(true);
      }
      loadMansioni();
      setOpenFormazione(false);
    } catch (error) {
      console.log(error);
      setSnack({
        severity: "error",
        messaggio: "Errore durante il salvataggio",
      });
      setOpenSnack(true);
    }
    setFormazione(null);
  };

  const handleDeleteFormazione = async (id) => {
    try {
      await unsplash.delete("dvr/mansioni", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          formazione: id,
        },
      });

      setSnack({
        severity: "success",
        messaggio: "Formazione eliminata con successo",
      });
      setOpenSnack(true);
      loadMansioni();
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione della  sostanza",
      });
      setOpenSnack(true);
    }
  };
  const handleSaveDpi = async (id) => {
    try {
      if (id) {
        await unsplash.put(
          "dvr/mansioni",
          {
            dpi: {
              ...dpi,
              ID_Rel_Dvr_Mansioni: mansioneSel?.ID,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Dpi aggiornata con successo",
        });
        setOpenSnack(true);
      } else {
        await unsplash.post(
          "dvr/mansioni",
          {
            dpi: {
              ...dpi,
              ID_Rel_Dvr_Mansioni: mansioneSel?.ID,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Dpi salvata con successo",
        });
        setOpenSnack(true);
      }
      loadMansioni();
      setOpenDpi(false);
    } catch (error) {
      console.log(error);
      setSnack({
        severity: "error",
        messaggio: "Errore durante il salvataggio",
      });
      setOpenSnack(true);
    }
    setDpi(null);
  };
  const handleDeleteDpi = async (id) => {
    try {
      await unsplash.delete("dvr/mansioni", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          dpi: id,
        },
      });

      setSnack({
        severity: "success",
        messaggio: "Dpi eliminato con successo",
      });
      setOpenSnack(true);
      loadMansioni();
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione del Dpi",
      });
      setOpenSnack(true);
    }
  };

  const loadMansioni = async () => {
    let response = await unsplash.get("dvr/mansioni", {
      params: {
        idDvr: dvr?.OldId,
        idRel: tab?.ID,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    let mansioneRel = response.data.data[0];
    setMansioneSel(mansioneRel);
    let responseFormazione = await unsplash.get("dvr/mansioni", {
      params: {
        formazione: true,
        idDvr: mansioneRel?.ID,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    let responseDpi = await unsplash.get("dvr/mansioni", {
      params: {
        dpi: true,
        idDvr: mansioneRel?.ID,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setMansioniFormazione(responseFormazione.data.data);
    setMansioniDpi(responseDpi.data.data);
  };

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded);
    if (isExpanded) {
      // Execute the backend call when the accordion is opened
      loadMansioni();
    }
  };

  /*   useEffect(() => {
    (async () => {
      try {
        if (dvr) {
          loadMansioni();
        }
      } catch (error) {
        console.error(error.message);
      }
    })();
  }, [tab, dvr?.ID]); */
  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Accordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          {tab?.Descrizione}
        </AccordionSummary>
        <AccordionDetails>
          <div className="grid grid-cols-3 gap-3">
            <div className="col-span-3 text-base font-semibold">
              Formazione mansione:
            </div>
            <div className="col-span-3">
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                mb={1}
              >
                <Button
                  startIcon={<Add />}
                  onClick={() => setOpenFormazione(true)}
                  sx={{ color: "white" }}
                  variant="outlined"
                  className="ButtonMiller"
                >
                  Aggiungi
                </Button>
              </Grid>
              <Box sx={{ height: "300px", width: 1 }}>
                <DataGridPro
                  initialState={{
                    pinnedColumns: { left: ["Actions"] },
                  }}
                  components={{
                    //Toolbar: CustomToolbar,
                    Footer: CustomFooterPersonalized,
                  }}
                  slots={{
                    loadingOverlay: LinearProgress,
                  }}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  rowHeight={52}
                  rows={mansioniFormazione}
                  columns={columnsFormazione}
                  getRowId={(row) => row.ID}
                  pageSize={20}
                  rowsPerPageOptions={[20]}
                  disableSelectionOnClick
                />
              </Box>
            </div>
            <div className="col-span-3 text-base font-semibold">
              Dispositivi di Protezione Individuali:
            </div>
            <div className="col-span-3">
              {/* <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                mb={1}
              >
                <Button
                  startIcon={<Add />}
                  onClick={() => setOpenDpi(true)}
                  sx={{ color: "white" }}
                  variant="outlined"
                  className="ButtonMiller"
                >
                  Aggiungi
                </Button>
              </Grid> */}
              <Box sx={{ height: "300px", width: 1 }}>
                <DataGridPro
                  initialState={{
                    pinnedColumns: { left: ["Actions"] },
                  }}
                  components={{
                    //Toolbar: CustomToolbar,
                    Footer: CustomFooterPersonalized,
                  }}
                  slots={{
                    loadingOverlay: LinearProgress,
                  }}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  rowHeight={52}
                  rows={mansioniDpi}
                  columns={columnsDpi}
                  getRowId={(row) => row.ID}
                  pageSize={20}
                  rowsPerPageOptions={[20]}
                  disableSelectionOnClick
                />
              </Box>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Dialog
        open={openDpi}
        onClose={() => {
          setOpenDpi(false);
          setDpi(null);
        }}
        fullWidth={true}
        maxWidth="lg"
        TransitionComponent={Transition}
      >
        <DialogTitle
          sx={{
            mx: "auto",
          }}
        >
          Dpi
        </DialogTitle>
        <DialogContent>
          <div className="flex flex-col gap-2 pt-2">
            <TextField
              className="w-full pt-2"
              label="Protezione"
              value={dpi?.Protezione}
              disabled
            />
          </div>
          <div className="flex flex-col gap-2 pt-2">
            <TextField
              className="w-full pt-2"
              label="Testo"
              value={dpi?.Testo}
              onChange={(e) => setDpi({ ...dpi, Testo: e.target.value })}
            />
          </div>
        </DialogContent>
        <DialogActions
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mx: 2,
          }}
        >
          <Button
            onClick={() => {
              setOpenDpi(false);
              setDpi(null);
            }}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
          >
            Chiudi
          </Button>
          <Button
            onClick={() => handleSaveDpi(dpi?.ID)}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openFormazione}
        onClose={() => {
          setOpenFormazione(false);
          setFormazione(null);
        }}
        fullWidth={true}
        maxWidth="lg"
        TransitionComponent={Transition}
      >
        <DialogTitle
          sx={{
            mx: "auto",
          }}
        >
          Formazione
        </DialogTitle>
        <DialogContent>
          <div className="flex flex-col gap-2 pt-2">
            <TextField
              className="w-full pt-2"
              label="Requisiti"
              value={formazione?.Requisiti}
              onChange={(e) =>
                setFormazione({ ...formazione, Requisiti: e.target.value })
              }
            />
          </div>
          <div className="flex flex-col gap-2 pt-2">
            <TextField
              className="w-full pt-2"
              label="Note"
              value={formazione?.Note}
              onChange={(e) =>
                setFormazione({ ...formazione, Note: e.target.value })
              }
            />
          </div>
        </DialogContent>
        <DialogActions
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mx: 2,
          }}
        >
          <Button
            onClick={() => {
              setOpenFormazione(false);
              setFormazione(null);
            }}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
          >
            Chiudi
          </Button>
          <Button
            onClick={() => handleSaveFormazione(formazione?.ID)}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
const DeleteDialog = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
          Sei sicuro di voler eliminare il DVR selezionato?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare:
            <br />
            <b>
              {moment(props.dvr.Data).format("DD/MM/YYYY")} - numero revisione:{" "}
              {props.dvr.NumeroRevisione}
            </b>
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button onClick={() => props.myFunction(props.dvr.ID)}>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
const DeleteDialogRischio = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
          Sei sicuro di voler eliminare la sostanza selezionata?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare:
            <b>{" " + props.sostanza?.Nome}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button onClick={() => props.myFunction(props.sostanza.ID)}>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
const DeleteDialogAgente = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
          Sei sicuro di voler eliminare l'agente selezionato?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare:
            <b>{" " + props.agente?.Nome}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button onClick={() => props.myFunction(props.agente.ID)}>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
const DeleteDialogFormazione = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
          Sei sicuro di voler eliminare la formazione selezionato?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare:
            <b>{" " + props.formazione?.Requisiti}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button onClick={() => props.myFunction(props.formazione.ID)}>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
const DeleteDialogDpi = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
          Sei sicuro di voler eliminare il DPI selezionato?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare il <b>testo</b> di :
            <b>{" " + props.dpi?.Protezione}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button onClick={() => props.myFunction(props.dpi.ID)}>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
const DeleteDialogProcedure = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
          Sei sicuro di voler eliminare la Procedura selezionata?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare la procedura:
            <b>
              {" " + props.procedura?.Tipo} {props.procedura?.Titolo}
            </b>
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button onClick={() => props.myFunction(props.procedura.ID)}>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
