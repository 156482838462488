import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import unsplash from "../api/unsplash";
import { useNavigate } from "react-router-dom";
import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";
import {
  TextField,
  Box,
  Typography,
  Tab,
  Tabs,
  Grid,
  Card,
  CardContent,
  Button,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  Zoom,
  LinearProgress,
  IconButton,
  DialogContentText,
} from "@mui/material";
import {
  Check,
  Coronavirus,
  BugReport,
  Sick,
  CleanHands,
  CrisisAlert,
  Edit,
  Close,
  Add,
  Delete,
} from "@mui/icons-material";
import { useLocation } from "react-router";
import GlobalSnackbar from "./Snackbar";
import "moment/locale/it";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const AgentiBiologici = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  const [batteri, setBatteri] = useState([]);
  const [virus, setVirus] = useState([]);
  const [malattie, setMalattie] = useState([]);
  const [parassiti, setParassiti] = useState([]);
  const [funghi, setFunghi] = useState([]);

  const [dialog, setDialog] = useState(null);
  const [open, setOpen] = useState(false);

  const [openSnack, setOpenSnack] = useState(false);

  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const columnsBatteri = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "130",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={async () => {
              setDialog({ ...params.row, tab: "batteri" });
              setOpen(true);
            }}
          >
            <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
          </IconButton>
          <DeleteModal
            agente={{ ...params.row, tab: "batteri" }}
            myFunction={handleDelete}
          />
        </>
      ),
    },
    {
      field: "Nome",
      headerName: "Nome",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];
  const columnsVirus = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "130",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={async () => {
              setDialog({ ...params.row, tab: "virus" });
              setOpen(true);
            }}
          >
            <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
          </IconButton>
          <DeleteModal
            agente={{ ...params.row, tab: "virus" }}
            myFunction={handleDelete}
          />
        </>
      ),
    },
    {
      field: "Nome",
      headerName: "Nome",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];
  const columnsMalattie = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "130",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={async () => {
              setDialog({ ...params.row, tab: "malattie" });
              setOpen(true);
            }}
          >
            <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
          </IconButton>
          <DeleteModal
            agente={{ ...params.row, tab: "malattie" }}
            myFunction={handleDelete}
          />
        </>
      ),
    },
    {
      field: "Nome",
      headerName: "Nome",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];
  const columnsParassiti = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "130",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={async () => {
              setDialog({ ...params.row, tab: "parassiti" });
              setOpen(true);
            }}
          >
            <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
          </IconButton>
          <DeleteModal
            agente={{ ...params.row, tab: "parassiti" }}
            myFunction={handleDelete}
          />
        </>
      ),
    },
    {
      field: "Nome",
      headerName: "Nome",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];
  const columnsFunghi = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "130",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={async () => {
              setDialog({ ...params.row, tab: "funghi" });
              setOpen(true);
            }}
          >
            <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
          </IconButton>
          <DeleteModal
            agente={{ ...params.row, tab: "funghi" }}
            myFunction={handleDelete}
          />
        </>
      ),
    },
    {
      field: "Nome",
      headerName: "Nome",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleDelete = async (agente) => {
    console.log(agente);
    try {
      await unsplash.delete("agentiBiologici", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          agente: agente?.ID,
          tab: agente?.tab,
        },
      });

      setSnack({
        severity: "success",
        messaggio: "Agente Biologico eliminato con successo",
      });
      setOpenSnack(true);
      getAgentiBiologici();
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione dell'Agente Biologico",
      });
      setOpenSnack(true);
    }
  };
  const handleSave = async (agente) => {
    console.log(agente);
    try {
      if (agente?.ID) {
        await unsplash.put(
          `agentiBiologici`,
          {
            agente: agente,
            tab: agente?.tab,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Agente Biologico aggiornato con successo",
        });
        setOpenSnack(true);
      } else {
        await unsplash.post(
          `agentiBiologici`,
          {
            agente: agente,
            tab: agente?.tab,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Agente Biologico salvato con successo",
        });
        setOpenSnack(true);
      }
      getAgentiBiologici();
      setOpen(false);
    } catch (error) {
      console.log(error);
      setSnack({
        severity: "error",
        messaggio: "Errore durante il salvataggio",
      });
      setOpenSnack(true);
    }
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }

  const getAgentiBiologici = async () => {
    let res = await unsplash.get("agentiBiologici", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    setBatteri(res.data.data?.filter((el) => el.Categoria === 1));
    setVirus(res.data.data?.filter((el) => el.Categoria === 2));
    setMalattie(res.data.data?.filter((el) => el.Categoria === 3));
    setParassiti(res.data.data?.filter((el) => el.Categoria === 4));
    setFunghi(res.data.data?.filter((el) => el.Categoria === 5));
  };

  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      try {
        getAgentiBiologici();
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Grid item xs={12}>
        <Card sx={{ marginTop: "10px" }}>
          <CardContent sx={{ p: 0 }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="scrollable"
              >
                <Tab
                  icon={<CleanHands />}
                  iconPosition="start"
                  label="Batteri"
                />
                <Tab
                  icon={<Coronavirus />}
                  iconPosition="start"
                  label="Virus"
                />
                <Tab icon={<Sick />} iconPosition="start" label="Malattie" />
                <Tab
                  icon={<BugReport />}
                  iconPosition="start"
                  label="Parassiti"
                />
                <Tab
                  icon={<CrisisAlert />}
                  iconPosition="start"
                  label="Funghi"
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                mb={1}
              >
                <Button
                  startIcon={<Add />}
                  onClick={() => {
                    setOpen(true);
                    setDialog({ Categoria: 1 });
                  }}
                  sx={{ color: "white" }}
                  variant="outlined"
                  className="ButtonMiller"
                >
                  Aggiungi
                </Button>
              </Grid>
              <div className="h-[70vh]">
                <DataGridPro
                  initialState={{
                    pinnedColumns: { left: ["Actions"] },
                  }}
                  components={{
                    Toolbar: CustomToolbar,
                    Footer: CustomFooterPersonalized,
                  }}
                  slots={{
                    loadingOverlay: LinearProgress,
                  }}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  rowHeight={52}
                  rows={batteri}
                  columns={columnsBatteri}
                  getRowId={(row) => row.ID}
                  pageSize={20}
                  rowsPerPageOptions={[20]}
                  disableSelectionOnClick
                />
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                mb={1}
              >
                <Button
                  startIcon={<Add />}
                  onClick={() => {
                    setOpen(true);
                    setDialog({ Categoria: 2 });
                  }}
                  sx={{ color: "white" }}
                  variant="outlined"
                  className="ButtonMiller"
                >
                  Aggiungi
                </Button>
              </Grid>
              <div className="h-[70vh]">
                <DataGridPro
                  initialState={{
                    pinnedColumns: { left: ["Actions"] },
                  }}
                  components={{
                    Toolbar: CustomToolbar,
                    Footer: CustomFooterPersonalized,
                  }}
                  slots={{
                    loadingOverlay: LinearProgress,
                  }}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  rowHeight={52}
                  rows={virus}
                  columns={columnsVirus}
                  getRowId={(row) => row.ID}
                  pageSize={20}
                  rowsPerPageOptions={[20]}
                  disableSelectionOnClick
                />
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                mb={1}
              >
                <Button
                  startIcon={<Add />}
                  onClick={() => {
                    setOpen(true);
                    setDialog({ Categoria: 3 });
                  }}
                  sx={{ color: "white" }}
                  variant="outlined"
                  className="ButtonMiller"
                >
                  Aggiungi
                </Button>
              </Grid>
              <div className="h-[70vh]">
                <DataGridPro
                  initialState={{
                    pinnedColumns: { left: ["Actions"] },
                  }}
                  components={{
                    Toolbar: CustomToolbar,
                    Footer: CustomFooterPersonalized,
                  }}
                  slots={{
                    loadingOverlay: LinearProgress,
                  }}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  rowHeight={52}
                  rows={malattie}
                  columns={columnsMalattie}
                  getRowId={(row) => row.ID}
                  pageSize={20}
                  rowsPerPageOptions={[20]}
                  disableSelectionOnClick
                />
              </div>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                mb={1}
              >
                <Button
                  startIcon={<Add />}
                  onClick={() => {
                    setOpen(true);
                    setDialog({ Categoria: 4 });
                  }}
                  sx={{ color: "white" }}
                  variant="outlined"
                  className="ButtonMiller"
                >
                  Aggiungi
                </Button>
              </Grid>
              <div className="h-[70vh]">
                <DataGridPro
                  initialState={{
                    pinnedColumns: { left: ["Actions"] },
                  }}
                  components={{
                    Toolbar: CustomToolbar,
                    Footer: CustomFooterPersonalized,
                  }}
                  slots={{
                    loadingOverlay: LinearProgress,
                  }}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  rowHeight={52}
                  rows={parassiti}
                  columns={columnsParassiti}
                  getRowId={(row) => row.ID}
                  pageSize={20}
                  rowsPerPageOptions={[20]}
                  disableSelectionOnClick
                />
              </div>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                mb={1}
              >
                <Button
                  startIcon={<Add />}
                  onClick={() => {
                    setOpen(true);
                    setDialog({ Categoria: 5 });
                  }}
                  sx={{ color: "white" }}
                  variant="outlined"
                  className="ButtonMiller"
                >
                  Aggiungi
                </Button>
              </Grid>
              <div className="h-[70vh]">
                <DataGridPro
                  initialState={{
                    pinnedColumns: { left: ["Actions"] },
                  }}
                  components={{
                    Toolbar: CustomToolbar,
                    Footer: CustomFooterPersonalized,
                  }}
                  slots={{
                    loadingOverlay: LinearProgress,
                  }}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  rowHeight={52}
                  rows={funghi}
                  columns={columnsFunghi}
                  getRowId={(row) => row.ID}
                  pageSize={20}
                  rowsPerPageOptions={[20]}
                  disableSelectionOnClick
                />
              </div>
            </TabPanel>
          </CardContent>
        </Card>
      </Grid>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setDialog(null);
        }}
        fullWidth={true}
        maxWidth="sm"
        TransitionComponent={Transition}
      >
        <DialogTitle
          sx={{
            mx: "auto",
          }}
        >
          Agente Biologico
        </DialogTitle>
        <DialogContent>
          <div className="flex flex-col gap-2 pt-2">
            <TextField
              className="w-full pt-2"
              label="Nome"
              value={dialog?.Nome}
              onChange={(e) => setDialog({ ...dialog, Nome: e.target.value })}
            />
          </div>
        </DialogContent>
        <DialogActions
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mx: 2,
          }}
        >
          <Button
            onClick={() => {
              setOpen(false);
              setDialog(null);
            }}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
          >
            Chiudi
          </Button>
          <Button
            onClick={() => handleSave(dialog)}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default AgentiBiologici;

const DeleteModal = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
          Sei sicuro di voler eliminare l'agente selezionato?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare:
            <b>{" " + props.agente.Nome}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button onClick={() => props.myFunction(props.agente)}>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
