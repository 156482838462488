import React, { useEffect, useState, useRef } from "react";

import "../style/style.css";
import {
  ClickAwayListener,
  Card,
  CardContent,
  Grid,
  IconButton,
  Popper,
  AppBar,
  Paper,
  Box,
  CssBaseline,
  Toolbar,
  Stack,
  Typography,
  useMediaQuery,
  ButtonBase,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Button,
  Chip,
  Fade,
  Collapse,
  ListItemButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormControlLabel,
  Checkbox,
  DialogActions,
  CardActionArea,
  CardMedia,
  Zoom,
  Tooltip,
} from "@mui/material";

import { useNavigate, useLocation } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import LogoBlack from "../assets/images/logoBlack.png";
import {
  Settings,
  AssignmentTurnedIn,
  ExpandLess,
  ExpandMore,
  HealthAndSafety,
  Vaccines,
  MedicalServices,
  Looks3,
  Business,
  School,
  Euro,
  AccountBalance,
  CalendarMonth,
  MenuBook,
  Group,
  Warning,
  ViewList,
  People,
  Psychology,
  LocalHospital,
  Category,
  Equalizer,
  Construction,
  Close,
  Check,
  Flight,
  TimeToLeave,
  Balance,
  FilterList,
  ImportContacts,
  Percent,
  AccountTree,
  Biotech,
} from "@mui/icons-material";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import {
  useTheme,
  ThemeProvider,
  createTheme,
  styled,
} from "@mui/material/styles";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import LogoutIcon from "@mui/icons-material/Logout";
import { logout } from "../utils";
import Preferiti from "./Preferiti";
import GestioneUtenti from "./GestioneUtenti";
import Consumi from "./Consumi";
import unsplash from "../api/unsplash";
import GlobalSnackbar from "./Snackbar";
import PasswordIcon from "@mui/icons-material/Password";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import { BreadcrumbContainer } from "./BreadcrumbContainer";
import MuiDrawer from "@mui/material/Drawer";
import SecurityIcon from "@mui/icons-material/Security";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import MenuIcon from "@mui/icons-material/Menu";
import EngineeringIcon from "@mui/icons-material/Engineering";
import StarIcon from "@mui/icons-material/Star";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  whiteSpace: "wrap",
  borderRight: 0,
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  whiteSpace: "nowrap",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  borderRight: 0,
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  boxSizing: "border-box",
  paddingRight: "10px",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const pages = [
  { pagina: "Clienti", percorso: "Clienti", icon: <Business /> },
  /* {
    pagina: "Checklist",
    percorso: "Checkhomepage",
    icon: <AssignmentTurnedIn />,
  },*/
];
const pMedicina = [
  { pagina: "Medici", percorso: "Medici", icon: <MedicalServices /> },
  { pagina: "Visite Mediche", percorso: "VisiteMediche", icon: <Vaccines /> },
  {
    pagina: "Scadenzario",
    percorso: "ScadenzarioMedicina",
    icon: <CalendarMonth />,
  },
  {
    pagina: "Distaccati",
    percorso: "Distaccati",
    icon: <Flight />,
    admin: false,
  },
  {
    pagina: "Riepilogativo Visite",
    percorso: "RiepilogativoVisite",
    icon: <ViewList />,
  },
  {
    pagina: "Tipologie Prestazioni",
    percorso: "TipologiePrestazioni",
    icon: <TimeToLeave />,
  },
  { pagina: "Allegato 3B", percorso: "Allegato3b", icon: <Looks3 /> },
  {
    pagina: "Anomalie",
    percorso: "Anomalie",
    icon: <Warning />,
  },
  {
    pagina: "Prefatturazione",
    percorso: "PrefatturazioneMedicina",
    icon: <Euro />,
  },
  {
    pagina: "Anagrafica Rischi",
    percorso: "AnagraficaRischi",
    icon: <AssignmentLateIcon />,
  },
  {
    pagina: "Anagrafica Accertamenti",
    percorso: "AnagraficaAccertamenti",
    icon: <AssignmentTurnedInIcon />,
  },
  {
    pagina: "Kpi Medicina",
    percorso: "KpiMedicina",
    icon: <Equalizer />,
    admin: false,
  },
];
const pSicurezza = [
  {
    pagina: "Categoria Adempimenti",
    percorso: "CategoriaAdempimenti",
    icon: <Category />,
    admin: true,
  },
  {
    pagina: "Anagrafiche DPI",
    percorso: "AnagraficheDPI",
    icon: <EngineeringIcon />,
    admin: true,
  },
  {
    pagina: "Adempimenti",
    percorso: "Adempimenti",
    icon: <FactCheckIcon />,
    admin: true,
  },
  {
    pagina: "Anagrafica Mansioni",
    percorso: "AnagraficaMansioni",
    icon: <AssignmentTurnedInIcon />,
    admin: false,
  },
  {
    pagina: "Anagrafica Attrezzature",
    percorso: "Attrezzature",
    icon: <Construction />,
    admin: false,
  },
  {
    pagina: "Anagrafica Miglioramenti",
    percorso: "Miglioramenti",
    icon: <StarIcon />,
    admin: false,
  },
  {
    pagina: "Agenti Biologici",
    percorso: "AgentiBiologici",
    icon: <Biotech />,
    admin: false,
  },
  {
    pagina: "Lavoratori",
    percorso: "LavoratoriSicurezza",
    icon: <People />,
    admin: false,
  },
];
const pCorsi = [
  {
    pagina: "Formatori",
    percorso: "Formatori",
    icon: <AccountBalance />,
    admin: false,
  },
  { pagina: "Aule corsi", percorso: "Corsi", icon: <MenuBook />, admin: false },
  {
    pagina: "Corsi Attestati",
    percorso: "CorsiAttestati",
    icon: <HistoryEduIcon />,
    admin: false,
  },
  {
    pagina: "Anagrafica Corsi",
    percorso: "AnagraficaCorsi",
    icon: <School />,
    admin: false,
  },
  {
    pagina: "Riepilogativo Formazione",
    percorso: "RiepilogativoFormazione",
    icon: <ViewList />,
    admin: false,
  },

  {
    pagina: "Scadenzario",
    percorso: "Scadenzario",
    icon: <CalendarMonth />,
    admin: false,
  },
  {
    pagina: "Distaccati",
    percorso: "Distaccati",
    icon: <Flight />,
    admin: false,
  },
  {
    pagina: "Prefatturazione",
    percorso: "PrefatturazioneCorsi",
    icon: <Euro />,
    admin: false,
  },
  {
    pagina: "Kpi Formazione",
    percorso: "KpiFormazione",
    icon: <Equalizer />,
    admin: false,
  },
];
const pParita = [
  {
    pagina: "Clienti",
    percorso: "Parita",
    icon: <Business />,
    admin: false,
  },
  { pagina: "Qualifica Contrattuale", percorso: "Qualifica-Contrattuale", icon: <Group />, admin: false },
  { pagina: "CCNL Applicato", percorso: "CCNL-Applicato", icon: <AccountTree />, admin: false },
  {
    pagina: "Inquadramento",
    percorso: "Inquadramento",
    icon: <ImportContacts />,
    admin: false,
  },
  {
    pagina: "Livelli",
    percorso: "Livelli",
    icon: <FilterList className="rotate-180"/>,
    admin: false,
  },
  {
    pagina: "KPI",
    percorso: "Kpi",
    icon: <Percent />,
    admin: false,
  },
];
const pagesBottom = [
  {
    pagina: "Utenti-Clienti",
    percorso: "UtentiClienti",
    icon: <People />,
    admin: false,
  },

  {
    pagina: "Collega Virtuale",
    percorso: "CollegaVirtuale",
    icon: <Psychology />,
  },
  /* {
    pagina: "Parità Di Genere",
    percorso: "Parita",
    icon: <Balance />,
  }, */
];
const Header = (props) => {
  const navigate = useNavigate();
  const loaction = useLocation();
  const [pathname, setPathname] = useState("");
  const [user, setUser] = useState({});
  const [link, setLink] = useState("");
  const [open, setOpen] = useState(false);
  const [oCollapse, setOCollapse] = useState({
    medicina: false,
    sicurezza: false,
    anagrafiche: false,
    corsi: false,
    parita: false,
  });
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openPop = Boolean(anchorEl);
  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const toggleDrawer = (val) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (val) {
      let oc = { ...oCollapse };
      if (oc.corsi && oc.medicina && oc.sicurezza && oc.parita) {
        oc.corsi = false;
        oc.medicina = false;
        oc.sicurezza = false;
        oc.parita = false;
      } else if (oCollapse.corsi) {
        oc.corsi = false;
      } else if (oCollapse.medicina) {
        oc.medicina = false;
      } else if (oCollapse.sicurezza) {
        oc.sicurezza = false;
      } else if (oCollapse.parita) {
        oc.parita = false;
      }
      setOCollapse(oc);
    }
    setOpen(!val);
  };

  const handleClose = () => {
    setOpen(false);
    setOCollapse({
      medicina: false,
      anagrafiche: false,
      corsi: false,
      parita: false,
    });
  };
  const handleClick = (tipo) => {
    let oc = { ...oCollapse };
    oc[tipo] = !oc[tipo];
    setOpen(true);
    setOCollapse(oc);
  };
  const handlePopper = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handlePopperAway = (event) => {
    setAnchorEl(openPop ? null : event.currentTarget);
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  useEffect(() => {
    (async () => {
      let response = await unsplash.get("utenti", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      const user = response.data.data.filter(
        (utente) => sessionStorage.getItem("username") === utente.username
      );
      setUser(user[0]);
    })();
  }, []);
  useEffect(() => {
    let pathnameNoBackslash = "";
    let linkBread = "";
    if (loaction.pathname === "/") {
      pathnameNoBackslash = "Home";
    } else if (loaction.pathname.includes("dettaglio")) {
      pathnameNoBackslash = loaction.pathname.replace("/", "");

      let firstStep = loaction.pathname.split("dettaglio");
      linkBread = "Dettaglio " + firstStep[1];
    } else {
      pathnameNoBackslash = loaction.pathname.replace("/", "");
    }

    setLink(linkBread);
    setPathname(pathnameNoBackslash);
    sessionStorage.setItem("page", linkBread);
  }, [navigate]);

  //style={{backgroundColor:"#2265B1"}}
  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        {/* header */}
        <AppBar
          enableColorOnDark
          color="inherit"
          elevation={0}
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, marginLeft: 10 }}
        >
          <Toolbar>
            <Box
              sx={{
                width: { xs: 50, md: 228 },
                display: "flex",
              }}
            >
              <Box
                component="span"
                sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}
              >
                <ButtonBase disableRipple onClick={() => navigate("/")}>
                  <img
                    src={props.foto === "light" ? Logo : LogoBlack}
                    style={{ height: "40px", marginTop: "5px" }}
                  ></img>
                </ButtonBase>
              </Box>
              <ButtonBase
                sx={{
                  borderRadius: "12px",
                  overflow: "hidden",
                }}
                onClick={toggleDrawer(open)}
              >
                <Avatar
                  sx={{
                    backgroundColor: "background.default",
                    color: "rgba(0, 0, 0, 0.74)",
                  }}
                  className="sidebarHover"
                  variant="rounded"
                  color="inherit"
                >
                  <MenuIcon fontSize="medium" />
                </Avatar>
              </ButtonBase>
            </Box>
            <BreadcrumbContainer pathname={pathname} link={link} />
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            <Chip
              onClick={handlePopper}
              sx={{
                cursor: "pointer",
                height: "48px",
                alignItems: "center",
                borderRadius: "27px",
                transition: "all .2s ease-in-out",
                borderColor: "#11526F",
                color: "#11526F",
                backgroundColor: "#11526F",
                '&[aria-controls="menu-list-grow"], &:hover': {
                  borderColor: "#36CAF6",
                  background: "#36CAF6!important",
                  color: "#36CAF6",
                },
                "& .MuiChip-label": {
                  lineHeight: 0,
                },
              }}
              icon={
                <Avatar
                  sx={{
                    color: "#11526F",
                    width: "34px",
                    height: "34px",
                    fontSize: "1.2rem",
                    margin: "8px 0 8px 8px !important",
                    cursor: "pointer",
                  }}
                  src={localStorage.getItem("fotoProfilo")}
                  aria-haspopup="true"
                />
              }
              label={
                <Settings
                  stroke={1.5}
                  style={{ height: "1.5rem", width: "1.5rem", color: "white" }}
                  color="white"
                />
              }
              variant="outlined"
              aria-haspopup="true"
              color="primary"
            />

            {openPop && ( //serve a verificare se il popper è aperto e se si clicca altrove lui chiude il popper. Ma lo fa solo se già aperto.
              <ClickAwayListener onClickAway={handlePopperAway}>
                <Popper
                  sx={{
                    zIndex: 100,
                  }}
                  open={openPop}
                  anchorEl={anchorEl}
                >
                  <Fade in={openPop}>
                    <Paper
                      sx={{
                        width: "300px",
                        marginTop: "20px",
                        marginRight: "10px",
                      }}
                    >
                      <Card
                        sx={{
                          bgcolor: theme.palette.primary,
                          my: 2,
                        }}
                      >
                        <CardContent>
                          <Grid container spacing={3} direction="column">
                            <Grid item>
                              <Grid
                                item
                                container
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Grid item xs={12}>
                                  <b>
                                    {" "}
                                    Ciao, {sessionStorage.getItem("username")} !
                                  </b>
                                </Grid>

                                <Grid item xs={12}>
                                  <hr
                                    style={{
                                      height: "2px",
                                      marginTop: "10px",
                                      marginBottom: "5px",
                                      width: "100%",
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <MyApp />
                                </Grid>
                                <Grid item xs={9}>
                                  <Typography variant="subtitle1">
                                    Modalità{" "}
                                    {props.mode === "light"
                                      ? "Giorno"
                                      : "Notte"}{" "}
                                  </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                  <hr
                                    style={{
                                      height: "2px",
                                      marginTop: "5px",
                                      marginBottom: "10px",
                                      width: "100%",
                                    }}
                                  />
                                </Grid>
                                {sessionStorage.getItem("Autorizzazione") ===
                                "1" ? (
                                  <Grid item xs={12}>
                                    <GestioneUtenti
                                      openSnack={openSnack}
                                      handleCloseSnack={handleCloseSnack}
                                      setSnack={setSnack}
                                      setOpenSnack={setOpenSnack}
                                    />
                                  </Grid>
                                ) : (
                                  ""
                                )}

                                {sessionStorage.getItem("Autorizzazione") ===
                                "1" ? (
                                  <Grid item xs={12} sx={{ marginTop: "10px" }}>
                                    <Consumi
                                      openSnack={openSnack}
                                      handleCloseSnack={handleCloseSnack}
                                      setSnack={setSnack}
                                      setOpenSnack={setOpenSnack}
                                    />
                                  </Grid>
                                ) : (
                                  ""
                                )}

                                <Grid item xs={12} sx={{ marginTop: "10px" }}>
                                  <ModificaUtente />
                                </Grid>
                                <Grid item xs={12} sx={{ marginTop: "10px" }}>
                                  <Button
                                    startIcon={<LogoutIcon />}
                                    className="ButtonMiller"
                                    fullWidth
                                    variant="contained"
                                    onClick={handleLogout}
                                  >
                                    Log out
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Paper>
                  </Fade>
                </Popper>
              </ClickAwayListener>
            )}
          </Toolbar>
        </AppBar>
      </Box>

      <Drawer open={open} variant="permanent">
        <Toolbar />
        <Box
          sx={{
            overflowX: "hidden",
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": {
              width: "0.4em",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
        >
          <List
            style={{
              paddingLeft: { xs: "0px", md: "10px" },
              marginLeft: "8px",
            }}
          >
            {pages.map((page, index) =>
              open ? (
                <ListItemButton
                  key={index}
                  id="style-1"
                  className="scrollbar sidebarHover"
                  onClick={() => {
                    navigate("/" + page.percorso);
                    handleClose();
                  }}
                >
                  <ListItemIcon>{page.icon}</ListItemIcon>
                  <ListItemText primary={page.pagina} />
                </ListItemButton>
              ) : (
                <Tooltip title={page.pagina} key={page.pagina} arrow>
                  <ListItemButton
                    id="style-1"
                    className="scrollbar sidebarHover"
                    onClick={() => {
                      navigate("/" + page.percorso);
                      handleClose();
                    }}
                  >
                    <ListItemIcon>{page.icon}</ListItemIcon>
                    <ListItemText primary={page.pagina} />
                  </ListItemButton>
                </Tooltip>
              )
            )}
            {user.Sicurezza ? (
              <>
                {open ? (
                  <ListItemButton
                    onClick={() => handleClick("sicurezza")}
                    className="sidebarHover"
                    //style={{ display: !user.Medicina ? "none" : "" }}
                  >
                    <ListItemIcon>
                      {/* import SecurityIcon from '@mui/icons-material/Security'; */}
                      <SecurityIcon
                        sx={{ color: oCollapse.sicurezza ? "#11526F" : "" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Sicurezza"
                      sx={{ fontWeight: "bold" }}
                    />
                    {oCollapse.sicurezza ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                ) : (
                  <Tooltip title="Sicurezza" arrow>
                    <ListItemButton
                      onClick={() => handleClick("sicurezza")}
                      className="sidebarHover"
                      //style={{ display: !user.Medicina ? "none" : "" }}
                    >
                      <ListItemIcon>
                        {/* import SecurityIcon from '@mui/icons-material/Security'; */}
                        <SecurityIcon
                          sx={{ color: oCollapse.sicurezza ? "#11526F" : "" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Sicurezza"
                        sx={{ fontWeight: "bold" }}
                      />
                      {oCollapse.sicurezza ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                  </Tooltip>
                )}
                <Collapse in={oCollapse.sicurezza} timeout="auto" unmountOnExit>
                  <List
                    component="div"
                    style={{ paddingLeft: "10px", paddingTop: "0px" }}
                  >
                    {pSicurezza.map((page, index) => (
                      <>
                        {page.percorso === "AnagraficheDPI" &&
                        sessionStorage.getItem("Autorizzazione") === "1" ? (
                          <ListItem
                            style={{
                              display:
                                page.admin && user.Admin
                                  ? ""
                                  : page.admin
                                  ? "None"
                                  : "",
                            }}
                            button
                            className="sidebarHover"
                            key={page.pagina}
                            onClick={() => {
                              //QUA SE SONO SU VISITE MEDICHE FACCIO LA CHIAMATA

                              navigate("/" + page.percorso);
                              handleClose();
                            }}
                          >
                            <ListItemIcon>{page.icon}</ListItemIcon>
                            <ListItemText primary={page.pagina} />
                          </ListItem>
                        ) : (
                          <ListItem
                            style={{
                              display:
                                page.admin && user.Admin
                                  ? ""
                                  : page.admin
                                  ? "None"
                                  : "",
                            }}
                            button
                            className="sidebarHover"
                            key={page.pagina}
                            onClick={() => {
                              //QUA SE SONO SU VISITE MEDICHE FACCIO LA CHIAMATA

                              navigate("/" + page.percorso);
                              handleClose();
                            }}
                          >
                            <ListItemIcon>{page.icon}</ListItemIcon>
                            <ListItemText primary={page.pagina} />
                          </ListItem>
                        )}
                      </>
                    ))}
                  </List>
                </Collapse>
              </>
            ) : (
              <></>
            )}
            {user.Medicina ? (
              <>
                {open ? (
                  <ListItemButton
                    onClick={() => handleClick("medicina")}
                    className="sidebarHover"
                    //style={{ display: !user.Medicina ? "none" : "" }}
                  >
                    <ListItemIcon>
                      <HealthAndSafety
                        sx={{ color: oCollapse.medicina ? "#FF2768" : "" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Medicina"
                      sx={{ fontWeight: "bold" }}
                    />
                    {oCollapse.medicina ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                ) : (
                  <Tooltip title="Medicina" arrow>
                    <ListItemButton
                      onClick={() => handleClick("medicina")}
                      className="sidebarHover"
                      //style={{ display: !user.Medicina ? "none" : "" }}
                    >
                      <ListItemIcon>
                        <HealthAndSafety
                          sx={{ color: oCollapse.medicina ? "#FF2768" : "" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Medicina"
                        sx={{ fontWeight: "bold" }}
                      />
                      {oCollapse.medicina ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                  </Tooltip>
                )}
                <Collapse in={oCollapse.medicina} timeout="auto" unmountOnExit>
                  <List
                    component="div"
                    style={{ paddingLeft: "10px", paddingTop: "0px" }}
                  >
                    {pMedicina.map((page, index) => (
                      <ListItem
                        button
                        className="sidebarHover"
                        key={page.pagina}
                        onClick={() => {
                          //QUA SE SONO SU VISITE MEDICHE FACCIO LA CHIAMATA

                          navigate("/" + page.percorso);
                          handleClose();
                        }}
                      >
                        <ListItemIcon>{page.icon}</ListItemIcon>
                        <ListItemText primary={page.pagina} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </>
            ) : (
              <></>
            )}
            {user.Formazione ? (
              <>
                {open ? (
                  <ListItemButton
                    onClick={() => handleClick("corsi")}
                    className="sidebarHover"
                  >
                    <ListItemIcon>
                      <School
                        sx={{ color: oCollapse.corsi ? "#11526F" : "" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Formazione"
                      sx={{ fontWeight: "bold" }}
                    />
                    {oCollapse.corsi ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                ) : (
                  <Tooltip title="Formazione" arrow>
                    <ListItemButton
                      onClick={() => handleClick("corsi")}
                      className="sidebarHover"
                    >
                      <ListItemIcon>
                        <School
                          sx={{ color: oCollapse.corsi ? "#11526F" : "" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Formazione"
                        sx={{ fontWeight: "bold" }}
                      />
                      {oCollapse.corsi ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                  </Tooltip>
                )}
                <Collapse in={oCollapse.corsi} timeout="auto" unmountOnExit>
                  <List
                    component="div"
                    style={{ paddingLeft: "10px", paddingTop: "0px" }}
                  >
                    {pCorsi.map((page, index) => (
                      <ListItem
                        button
                        className="sidebarHover"
                        key={page.pagina}
                        onClick={() => {
                          navigate("/" + page.percorso);
                          handleClose();
                        }}
                      >
                        <ListItemIcon>{page.icon}</ListItemIcon>
                        <ListItemText primary={page.pagina} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </>
            ) : (
              <></>
            )}
            {user.Parita ? (
              <>
                {open ? (
                  <ListItemButton
                    onClick={() => handleClick("parita")}
                    className="sidebarHover"
                  >
                    <ListItemIcon>
                      <Balance
                        sx={{ color: oCollapse.parita ? "#11526F" : "" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Parita"
                      sx={{ fontWeight: "bold" }}
                    />
                    {oCollapse.parita ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                ) : (
                  <Tooltip title="Parità di Genere" arrow>
                    <ListItemButton
                      onClick={() => handleClick("parita")}
                      className="sidebarHover"
                    >
                      <ListItemIcon>
                        <Balance
                          sx={{ color: oCollapse.parita ? "#11526F" : "" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Parita"
                        sx={{ fontWeight: "bold" }}
                      />
                      {oCollapse.parita ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                  </Tooltip>
                )}
                <Collapse in={oCollapse.parita} timeout="auto" unmountOnExit>
                  <List
                    component="div"
                    style={{ paddingLeft: "10px", paddingTop: "0px" }}
                  >
                    {pParita.map((page, index) => (
                      <ListItem
                        button
                        className="sidebarHover"
                        key={page.pagina}
                        onClick={() => {
                          navigate("/" + page.percorso);
                          handleClose();
                        }}
                      >
                        <ListItemIcon>{page.icon}</ListItemIcon>
                        <ListItemText primary={page.pagina} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </>
            ) : (
              <></>
            )}

            {pagesBottom.map((page, index) =>
              open ? (
                <ListItemButton
                  key={index}
                  id="style-1"
                  className="scrollbar sidebarHover"
                  onClick={() => {
                    navigate("/" + page.percorso);
                    handleClose();
                  }}
                >
                  <ListItemIcon>{page.icon}</ListItemIcon>
                  <ListItemText primary={page.pagina} />
                </ListItemButton>
              ) : (
                <Tooltip title={page.pagina} key={page.pagina} arrow>
                  <ListItemButton
                    id="style-1"
                    className="scrollbar sidebarHover"
                    onClick={() => {
                      navigate("/" + page.percorso);
                      handleClose();
                    }}
                  >
                    <ListItemIcon>{page.icon}</ListItemIcon>
                    <ListItemText primary={page.pagina} />
                  </ListItemButton>
                </Tooltip>
              )
            )}

            {/*open ? (
              <ListItem
                button
                className="sidebarHover"
                key="Collega Virtuale"
                onClick={() => {
                  window.open("https://chat.openai.com/auth/login");
                }}
              >
                <ListItemIcon>
                  {" "}
                  <Psychology />
                </ListItemIcon>
                <ListItemText primary="Collega Virtuale" />
              </ListItem>
            ) : (
              <Tooltip title="Collega virtuale">
                <ListItem
                  button
                  className="sidebarHover"
                  key="Collega Virtuale"
                  onClick={() => {
                    window.open("https://chat.openai.com/auth/login");
                  }}
                >
                  <ListItemIcon>
                    {" "}
                    <Psychology />
                  </ListItemIcon>
                  <ListItemText primary="Collega Virtuale" />
                </ListItem>
              </Tooltip>
                )*/}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

function MyApp() {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  return (
    <IconButton
      sx={{ ml: 1 }}
      onClick={colorMode.toggleColorMode}
      color="inherit"
    >
      {theme.palette.mode === "dark" ? (
        <Brightness7Icon />
      ) : (
        <Brightness4Icon />
      )}
    </IconButton>
  );
}

const ToggleColorMode = (props) => {
  const [mode, setMode] = useState(props.mode);

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
        props.mySetMode((prevMode) =>
          prevMode === "light" ? "dark" : "light"
        );
      },
    }),
    []
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );

  useEffect(() => {
    localStorage.setItem("theme", mode);
  }, [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Header foto={mode} mode={mode} />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

const ModificaUtente = (props) => {
  const [open, setOpen] = useState(false);
  const [foto, setFoto] = useState(localStorage.getItem("fotoProfilo"));
  const [password, setPassword] = useState("");
  const [confermaPassword, setConfermaPassword] = useState("");
  const [vecchiaPassword, setVecchiaPassword] = useState("");
  const [cambiaPassword, setCambiaPassword] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleChangeImage = async (e) => {
    const file = e.target.files[0]; // prendo la foto caricata sempre nell'evento
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      document.getElementById("Logo").src = reader.result;
      let foto = reader.result.replace("data:image/png;base64,", "");
      foto = reader.result.replace("data:image/jpeg;base64,", "");
      setFoto(foto);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const handleClickOpen = async () => {
    setFoto(localStorage.getItem("fotoProfilo"));

    setCambiaPassword(false);
    setPassword("");
    setVecchiaPassword("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //p[index].IDAggiuntivo = null;

  const handleSave = async () => {
    if (password !== confermaPassword) {
      setSnack({
        severity: "warning",
        messaggio: "Attenzione le password non coincidono",
      });
      setOpenSnack(true);
    } else {
      if (password === "" && cambiaPassword) {
        setSnack({
          severity: "warning",
          messaggio: "Attenzione la password non può essere vuota",
        });
        setOpenSnack(true);
        return;
      }

      let f = foto;
      f = f.replace("data:image/png;base64,", "");
      f = f.replace("data:image/jpeg;base64,", "");

      try {
        let response = await unsplash.put(
          "utenti",
          {
            utente: sessionStorage.getItem("username"),
            password: password,
            foto: f,
            cambiaPassword: cambiaPassword,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        localStorage.setItem("fotoProfilo", foto);
        setSnack({
          severity: "success",
          messaggio: "Modifica effettuata con successo",
        });
        setOpenSnack(true);
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Attenzione le password non coincidono",
        });
        setOpenSnack(true);
      }
    }
  };

  return (
    <>
      <Button
        startIcon={<Settings />}
        className="ButtonMiller"
        fullWidth
        variant="contained"
        onClick={handleClickOpen}
      >
        Impostazioni
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        TransitionComponent={Transition}
      >
        <DialogTitle>Modifica Utente</DialogTitle>
        <DialogContent>
          <GlobalSnackbar
            severity={snack.severity}
            messaggio={snack.messaggio}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
          />
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                my: "auto",
                height: "100%",
                backgroundColor: "white",
                borderRadius: "5px",
              }}
            >
              <Card>
                <Box
                  sx={{
                    alignContent: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardActionArea
                    onClick={() => {
                      document.getElementById("file").click();
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="300"
                      width="300"
                      image={
                        foto !== ""
                          ? foto
                          : require("../assets/images/MILLERLogo.jpg")
                      }
                      src={
                        foto ? foto : require("../assets/images/MILLERLogo.jpg")
                      }
                      id="Logo"
                      alt="img"
                      sx={{
                        maxHeight: "300px",
                        minHeight: "300px",
                        maxWidth: "300px",
                        minWidth: "300px",
                      }}
                    />
                  </CardActionArea>
                </Box>

                <Divider />
                <Box sx={{ alignItems: "flex-end" }}>
                  <Button
                    fullWidth
                    variant="text"
                    onClick={() => {
                      document.getElementById("file").click();
                    }}
                    sx={{}}
                  >
                    Carica Foto
                  </Button>
                </Box>
              </Card>
              <input
                type="file"
                id="file"
                accept="image/*"
                onChange={(e) => handleChangeImage(e)}
                style={{ display: "none" }}
              />
            </Grid>

            <Grid item xs={12}>
              <center>
                <Button
                  className="ButtonMiller"
                  variant="contained"
                  sx={{ width: "300px" }}
                  startIcon={<PasswordIcon />}
                  onClick={() => setCambiaPassword(true)}
                >
                  Cambia Password
                </Button>
              </center>
            </Grid>

            {/* <Grid item xs={12} sx={{display:cambiaPassword?"":"None"}}>
          <TextField
          //error={validation?.RagioneSociale}
            
            margin="dense"
            name="username"
            label="Vecchia Password"
            type="text"
            fullWidth
            onChange={(e) => setVecchiaPassword(e.target.value)}
            variant="outlined"
            //helperText={validation?.RagioneSociale?"Campo obbligatorio":""}
          />
          </Grid>
           */}
            <Grid item xs={12} sx={{ display: cambiaPassword ? "" : "None" }}>
              <TextField
                //error={validation?.RagioneSociale}

                margin="dense"
                name="email"
                label="Nuova Password"
                type="text"
                fullWidth
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
                //helperText={validation?.RagioneSociale?"Campo obbligatorio":""}
              />
            </Grid>
            <Grid item xs={12} sx={{ display: cambiaPassword ? "" : "None" }}>
              <TextField
                //error={validation?.RagioneSociale}

                margin="dense"
                name="email"
                label="Conferma Password"
                type="text"
                fullWidth
                onChange={(e) => setConfermaPassword(e.target.value)}
                variant="outlined"
                //helperText={validation?.RagioneSociale?"Campo obbligatorio":""}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between", mx: 2 }}
        >
          <Button
            onClick={handleClose}
            className="ButtonMiller"
            variant="contained"
            startIcon={<Close />}
          >
            CHiudi
          </Button>

          <Button
            onClick={handleSave}
            className="ButtonMiller"
            variant="contained"
            startIcon={<Check />}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ToggleColorMode;
